import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import {
  Button,
  CurrencyField,
  type CurrencyFieldProps,
  Flex,
  Icon,
  Loader,
  PercentageField,
  type PercentageFieldProps,
  ProgressBar,
  Table,
  type TableColumn,
  type TableEmptyState,
  type TableRow,
  type TableSortAddon,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  dotObject,
  formatCurrency,
  formatPercentage,
  parsePercentage,
} from "@adaptive/design-system/utils";
import type { InvoiceGetResponse, SummaryLine } from "@api/invoices";
import {
  useJobCategories,
  useJobCategoriesIsLoading,
  useJobSettings,
} from "@src/jobs";
import { useClientInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { curriedNoop, noop } from "@utils/noop";
import { stringCompare } from "@utils/string-compare";
import { sum } from "@utils/sum";
import { createContext, useContextSelector } from "use-context-selector";

import { CURRENCY_FORMAT, PERCENTAGE_FORMAT } from "../constants";

import type { Mode } from ".";
import { STRINGS } from "./constants";
import {
  useInvoice,
  useInvoiceActions,
  useInvoiceIsLoading,
  useInvoiceLines,
  useInvoiceMarkups,
} from "./invoice-context";
import {
  useInvoiceFormDrawScheduleSortBy,
  useInvoiceFormDrawScheduleViewMode,
  useInvoiceFormMode,
  useInvoiceFormSetDrawScheduleSortBy,
} from "./invoice-form-context";
import { groupLinesByCategory } from "./utils";

type DisabledReason = "linked" | "multiple" | "no-budget" | "markups";

export type EnhancedSummaryLine = SummaryLine & {
  category: string;
  disabled?: DisabledReason[];
};

type ThisDrawMode = "currency" | "percentage";

type CurriedOnChangeHandler = (props: {
  row: EnhancedSummaryLine;
  type: "currency" | "percentage";
}) => (value: number) => void;

type GetBaseAmountHandler = (row: SummaryLine) => number;

type Totals = Exclude<
  InvoiceGetResponse["summary"],
  null | undefined
>["totals"];

type InvoiceSummaryContextType = {
  totals: Totals;
  thisDrawMode: ThisDrawMode;
  getBaseAmount: GetBaseAmountHandler;
  curriedOnChange: CurriedOnChangeHandler;
  toggleThisDrawMode: () => void;
};

const DEFAULT_THIS_DRAW_MODE: ThisDrawMode = "currency";

const DEFAULT_TOTALS: Totals = {
  budget: 0,
  thisDraw: 0,
  ownersAmount: 0,
  changeAmount: 0,
  previousDraws: 0,
  revisedBudget: 0,
  totalInvoiced: 0,
  balanceToFinish: 0,
  invoicedPercent: 0,
};

const getDisabledTooltip = (disabled: EnhancedSummaryLine["disabled"] = []) => {
  if (disabled.length === 0) return "";

  let message = "";

  if (disabled.includes("no-budget")) {
    message += "You cannot enter a percent amount when budget is zero\n";
  }

  if (
    disabled.includes("linked") ||
    disabled.includes("markups") ||
    disabled.includes("multiple")
  ) {
    message += "Go to the Line items tab to adjust the price\n";
  }

  return message;
};

const InvoiceSummaryContext = createContext<InvoiceSummaryContextType>({
  totals: DEFAULT_TOTALS,
  thisDrawMode: DEFAULT_THIS_DRAW_MODE,
  getBaseAmount: noop,
  curriedOnChange: curriedNoop,
  toggleThisDrawMode: noop,
});

const useInvoiceSummaryThisDrawMode = () =>
  useContextSelector(InvoiceSummaryContext, (context) => context.thisDrawMode);

const useInvoiceSummaryTotals = (key: keyof Totals) =>
  useContextSelector(InvoiceSummaryContext, (context) => context.totals[key]);

const useInvoiceSummaryActions = () =>
  useContextSelector(InvoiceSummaryContext, (context) => ({
    getBaseAmount: context.getBaseAmount,
    curriedOnChange: context.curriedOnChange,
    toggleThisDrawMode: context.toggleThisDrawMode,
  }));

type ValueProps = {
  lazy?: boolean;
  bold?: boolean;
  value: number;
  format: "currency" | "percentage" | "progressbar";
  warning?: boolean;
};

const Value = memo(({ value, format, lazy, bold, warning }: ValueProps) => {
  const isLoading = useInvoiceIsLoading();

  const isWarning =
    warning && (format === "currency" ? value < 0 : value > 100);

  return (
    <Flex width="full" justify="flex-end">
      {isLoading && lazy ? (
        <Loader />
      ) : format === "progressbar" ? (
        <ProgressBar
          color={isWarning ? "warning" : "success"}
          value={value}
          message={false}
        />
      ) : (
        <Text
          align="right"
          color={isWarning ? "warning-200" : "neutral-800"}
          weight={bold ? "bold" : "regular"}
          truncate
        >
          {format === "currency"
            ? formatCurrency(value, CURRENCY_FORMAT)
            : formatPercentage(value, PERCENTAGE_FORMAT)}
        </Text>
      )}
    </Flex>
  );
});

Value.displayName = "Value";

type TotalProps = { property: keyof Totals } & Omit<ValueProps, "value">;

const Total = memo(({ property, ...props }: TotalProps) => {
  const value = useInvoiceSummaryTotals(property) ?? 0;

  return <Value value={value} {...props} />;
});

Total.displayName = "Total";

const ThisDrawName = memo(() => {
  const thisDrawMode = useInvoiceSummaryThisDrawMode();

  const { toggleThisDrawMode } = useInvoiceSummaryActions();

  const message =
    thisDrawMode === "percentage"
      ? STRINGS.SWITCH_TO_CURRENCY
      : STRINGS.SWITCH_TO_PERCENTAGE;

  return (
    <Flex align="center" justify="flex-end" gap="sm">
      <Text weight="bold">This draw</Text>
      <Tooltip message={message}>
        <Button
          size="sm"
          color="neutral"
          variant="text"
          onClick={toggleThisDrawMode}
          aria-label={message}
          data-testid="invoice-draw-schedule-switch-this-draw-mode-button"
        >
          <Icon
            name={thisDrawMode === "percentage" ? "percent" : "dollar-sign"}
            color="neutral-900"
          />
        </Button>
      </Tooltip>
    </Flex>
  );
});

ThisDrawName.displayName = "ThisDrawName";

const ThisDrawRender = memo((row: EnhancedSummaryLine & { mode: Mode }) => {
  const { getBaseAmount, curriedOnChange } = useInvoiceSummaryActions();

  const thisDrawMode = useInvoiceSummaryThisDrawMode();

  const base = getBaseAmount(row);

  const value =
    thisDrawMode === "currency"
      ? (row.thisDraw ?? 0)
      : base === 0
        ? 0
        : (row.thisDraw * 100) / base;

  const disabled =
    thisDrawMode === "currency"
      ? (row.disabled ?? []).filter((rule) => rule !== "no-budget")
      : (row.disabled ?? []);

  const props: Omit<CurrencyFieldProps | PercentageFieldProps, "min" | "max"> =
    {
      size: "sm",
      value,
      disabled: disabled.length > 0,
      onChange: curriedOnChange({ row, type: "currency" }),
      allowNegative: true,
      messageVariant: "hidden",
    };

  return row.mode === "view" ? (
    <Value value={value} format={thisDrawMode} />
  ) : (
    <Tooltip message={getDisabledTooltip(disabled)}>
      {thisDrawMode === "percentage" ? (
        <PercentageField
          min={PERCENTAGE_FORMAT.min}
          max={PERCENTAGE_FORMAT.max}
          placeholder={disabled.includes("no-budget") ? "—" : "0"}
          minimumFractionDigits={PERCENTAGE_FORMAT.minimumFractionDigits}
          maximumFractionDigits={PERCENTAGE_FORMAT.maximumFractionDigits}
          {...props}
        />
      ) : (
        <CurrencyField align="right" placeholder="0" {...props} />
      )}
    </Tooltip>
  );
});

ThisDrawRender.displayName = "ThisDrawRender";

const TotalDrawnPercentageRender = memo(
  (row: EnhancedSummaryLine & { mode: Mode }) => {
    const { curriedOnChange } = useInvoiceSummaryActions();

    return (
      <Flex width="full" direction="row" gap="md" align="center">
        <Flex shrink={false} width="100px">
          <Value
            value={
              row.disabled?.includes("no-budget") ? 101 : row.invoicedPercent
            }
            format="progressbar"
            warning
          />
        </Flex>
        <Flex align="center" width="full" gap="md">
          {row.mode === "view" ? (
            row.disabled?.includes("no-budget") ? (
              <Flex grow justify="flex-end">
                <Text>—</Text>
              </Flex>
            ) : (
              <Value value={row.invoicedPercent} format="percentage" warning />
            )
          ) : (
            <Tooltip message={getDisabledTooltip(row.disabled)}>
              <PercentageField
                min={PERCENTAGE_FORMAT.min}
                max={PERCENTAGE_FORMAT.max}
                size="sm"
                value={row.invoicedPercent}
                onChange={curriedOnChange({ row, type: "percentage" })}
                disabled={row.disabled && row.disabled.length > 0}
                placeholder={row.disabled?.includes("no-budget") ? "—" : "0"}
                allowNegative
                messageVariant="hidden"
                minimumFractionDigits={PERCENTAGE_FORMAT.minimumFractionDigits}
                maximumFractionDigits={PERCENTAGE_FORMAT.maximumFractionDigits}
              />
            </Tooltip>
          )}
          {row.disabled?.includes("no-budget") && (
            <Flex width="20px" height="20px" shrink={false}>
              <Tooltip
                as={Icon}
                name="exclamation-triangle"
                color="warning-200"
                message={STRINGS.NO_BUDGET_TOOLTIP}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }
);

TotalDrawnPercentageRender.displayName = "TotalDrawnPercentageRender";

type TotalDrawnPercentageFooterProps = { data: EnhancedSummaryLine[] };

const TotalDrawnPercentageFooter = memo(
  ({ data }: TotalDrawnPercentageFooterProps) => {
    const hasNoBudget = data.every((line) =>
      line.disabled?.includes("no-budget")
    );

    return (
      <Flex width="full" direction="row" gap="md" align="center">
        <Flex shrink={false} width="100px">
          {hasNoBudget ? (
            <Value value={101} format="progressbar" warning />
          ) : (
            <Total
              property="invoicedPercent"
              bold
              format="progressbar"
              warning
            />
          )}
        </Flex>
        {hasNoBudget ? (
          <Flex gap="md" grow justify="flex-end">
            <Text weight="bold" align="right">
              —
            </Text>
            <Flex width="20px" height="20px">
              <Tooltip
                as={Icon}
                name="exclamation-triangle"
                color="warning-200"
                message={STRINGS.NO_BUDGET_TOOLTIP}
              />
            </Flex>
          </Flex>
        ) : (
          <Total property="invoicedPercent" bold format="percentage" warning />
        )}
      </Flex>
    );
  }
);

TotalDrawnPercentageFooter.displayName = "TotalDrawnPercentageFooter";

export const InvoiceSummary = memo(() => {
  const previousLinesDataRef = useRef<EnhancedSummaryLine[]>(undefined);

  const mode = useInvoiceFormMode();

  const sortBy = useInvoiceFormDrawScheduleSortBy();

  const setSortBy = useInvoiceFormSetDrawScheduleSortBy();

  const navigate = useNavigate();

  const viewMode = useInvoiceFormDrawScheduleViewMode();

  const categories = useJobCategories();

  const invoiceLines = useInvoiceLines();

  const invoiceMarkups = useInvoiceMarkups();

  const categoriesIsLoading = useJobCategoriesIsLoading();

  const { updateInvoiceLines } = useInvoiceActions();

  const { id, summary, customer } = useInvoice(["id", "summary", "customer"]);

  const [thisDrawMode, setThisDrawMode] = useState<"currency" | "percentage">(
    "currency"
  );

  const {
    categoriesEnabled,
    changeTrackingEnabled,
    ownersAmountEnabled,
    itemDescriptionEnabled,
  } = useJobSettings();

  const { client } = useClientInfo();
  const clientExternalBudgetEnabled =
    client?.settings.external_revised_budget_enabled ?? false;

  let amountKey: keyof Pick<
    EnhancedSummaryLine,
    "budget" | "ownersAmount" | "ownersRevisedBudget" | "revisedBudget"
  > = "budget";

  if (ownersAmountEnabled) {
    amountKey = "ownersAmount";

    if (changeTrackingEnabled && clientExternalBudgetEnabled) {
      amountKey = "ownersRevisedBudget";
    }
  } else if (changeTrackingEnabled) {
    amountKey = "revisedBudget";
  }

  const footer = useMemo(
    () => ({ sticky: { offset: -64 }, hide: !summary?.lines.length }),
    [summary?.lines.length]
  );

  const header = useMemo(
    () => ({ hide: !summary?.lines.length, sticky: { offset: 80 } }),
    [summary?.lines.length]
  );

  const isCategoriesViewMode = viewMode === "categories";

  const getBaseAmount = useCallback<GetBaseAmountHandler>(
    (row) => dotObject.get(row, amountKey, 0),
    [amountKey]
  );

  const enhancedData = useMemo(() => {
    let lines: EnhancedSummaryLine[] = (summary?.lines ?? []).map((line) => {
      const category =
        categories.find((category) => category.value === line.categoryId)
          ?.label ?? STRINGS.UNKNOWN_CATEGORY;

      const lines = invoiceLines.filter(
        (invoiceLine) =>
          invoiceLine.jobCostMethod?.id === line.jobCostMethod?.id
      );

      const isLinked = lines.some((line) => !!line.transactionLine);
      const hasBudget = getBaseAmount(line) !== 0;
      const isMultiple = lines.length > 1;
      const hasMarkups = invoiceMarkups.some(
        (markup) => markup.jobCostMethod?.id === line.jobCostMethod?.id
      );

      const disabled: EnhancedSummaryLine["disabled"] = [];
      if (!hasBudget) {
        disabled.push("no-budget");
      }

      if (isLinked) {
        disabled.push("linked");
      }

      if (isMultiple) {
        disabled.push("multiple");
      }

      if (hasMarkups) {
        disabled.push("markups");
      }

      return { ...line, category, disabled };
    });

    const direction = sortBy.startsWith("-") ? -1 : 1;

    if (isCategoriesViewMode) {
      const { known, unknown } = groupLinesByCategory(lines, amountKey).reduce(
        (acc, line) => {
          if (line.category === STRINGS.UNKNOWN_CATEGORY) {
            acc.unknown.push(line);
          } else {
            acc.known.push(line);
          }

          return acc;
        },
        { known: [], unknown: [] } as Record<
          "known" | "unknown",
          EnhancedSummaryLine[]
        >
      );

      known.sort((a, b) => {
        const categoryA = direction === 1 ? a.category : b.category;
        const categoryB = direction === 1 ? b.category : a.category;
        return stringCompare(categoryA, categoryB);
      });

      lines = [...known, ...unknown];
    }

    if (lines.length === 0 || !sortBy) return lines;

    // eslint-disable-next-line
    if (!previousLinesDataRef.current || isCategoriesViewMode) {
      if (sortBy.endsWith("jobCostMethod")) {
        lines.sort((a, b) => {
          const jobCostMethodA =
            (direction === 1
              ? a.jobCostMethod?.displayName
              : b.jobCostMethod?.displayName) ?? "";
          const jobCostMethodB =
            (direction === 1
              ? b.jobCostMethod?.displayName
              : a.jobCostMethod?.displayName) ?? "";
          return stringCompare(jobCostMethodA, jobCostMethodB);
        });
      } else if (sortBy.endsWith("thisDraw")) {
        if (thisDrawMode === "currency") {
          lines.sort((a, b) => {
            const thisDrawA = direction === 1 ? a.thisDraw : b.thisDraw;
            const thisDrawB = direction === 1 ? b.thisDraw : a.thisDraw;
            return thisDrawA - thisDrawB;
          });
        } else {
          lines.sort((a, b) => {
            const thisDrawA =
              direction === 1 ? a.invoicedPercent : b.invoicedPercent;
            const thisDrawB =
              direction === 1 ? b.invoicedPercent : a.invoicedPercent;
            return thisDrawA - thisDrawB;
          });
        }
      } else if (sortBy.endsWith("categories")) {
        lines.sort((a, b) => {
          const categoryA = direction === 1 ? a.category : b.category;
          const categoryB = direction === 1 ? b.category : a.category;

          if (categoryA === STRINGS.UNKNOWN_CATEGORY) {
            return 1;
          } else if (categoryB === STRINGS.UNKNOWN_CATEGORY) {
            return -1;
          }

          return stringCompare(categoryA, categoryB);
        });
      }

      previousLinesDataRef.current = lines; // eslint-disable-line
    } else {
      // eslint-disable-next-line
      lines.sort((a, b) => {
        const indexA = previousLinesDataRef.current!.findIndex(
          (line) => line.jobCostMethod?.id === a.jobCostMethod?.id
        );

        const indexB = previousLinesDataRef.current!.findIndex(
          (line) => line.jobCostMethod?.id === b.jobCostMethod?.id
        );

        return indexA === -1 || indexB === -1 ? 0 : indexA - indexB;
      });
    }

    return lines;
  }, [
    summary?.lines,
    sortBy,
    isCategoriesViewMode,
    categories,
    invoiceLines,
    getBaseAmount,
    invoiceMarkups,
    amountKey,
    thisDrawMode,
  ]);

  const curriedOnChange = useCallback<CurriedOnChangeHandler>(
    ({ row, type }) =>
      async (value: number) => {
        let amount = type === "currency" ? value : 0;
        let percentage = type === "percentage" ? value : 0;

        const base = getBaseAmount(row);

        if (type === "currency") {
          if (thisDrawMode === "percentage") {
            amount = (base / 100) * value;
          }

          if (row.thisDraw === amount) return;

          /**
           * Since this result could have more than 2 decimal places, we need to
           * round it to 2 decimal places to avoid floating point errors
           */
          percentage =
            parsePercentage(
              String((sum(amount, row.previousDraws) * 100) / base),
              { maximumFractionDigits: PERCENTAGE_FORMAT.maximumFractionDigits }
            ) ?? 0;
        } else if (type === "percentage") {
          if (row.invoicedPercent === percentage) return;
          amount = sum(base * (percentage / 100), -row.previousDraws);
        }

        const hasLine = invoiceLines.some(
          (line) => line.jobCostMethod?.id === row.jobCostMethod?.id
        );

        if (hasLine) {
          const nextLines = invoiceLines.map((line) => {
            if (line.jobCostMethod?.id !== row.jobCostMethod?.id) return line;

            return { ...line, amount, invoicedAmount: percentage };
          });
          analytics.track("invoiceDrawScheduleUpdateLine", {
            id,
            type,
            customerId: customer.id,
            jobCostMethodId: row.jobCostMethod?.id,
          });
          updateInvoiceLines({ lines: nextLines });
        } else {
          analytics.track("invoiceDrawScheduleAddLine", {
            id,
            type,
            customerId: customer.id,
            jobCostMethodId: row.jobCostMethod?.id,
          });
          updateInvoiceLines({
            lines: [
              ...invoiceLines,
              { amount, isExtra: true, jobCostMethod: row.jobCostMethod },
            ],
          });
        }

        toast.success("Draw schedule line updated!");
      },
    [
      id,
      customer.id,
      thisDrawMode,
      invoiceLines,
      getBaseAmount,
      updateInvoiceLines,
    ]
  );

  const toggleThisDrawMode = useCallback(() => {
    setThisDrawMode((prev) => {
      const next = prev === "currency" ? "percentage" : "currency";

      localStorage.setItem(`invoice-summary-this-draw-mode-${id}`, next);

      return next;
    });
  }, [id]);

  const columns = useMemo<TableColumn<TableRow<EnhancedSummaryLine>>[]>(() => {
    let budgetColumns: TableColumn<TableRow<EnhancedSummaryLine>>[] = [
      {
        id: "budget",
        name: <Flex justify="flex-end">Budget</Flex>,
        width: 165,
        textAlign: "right",
        align: "center",
        render: (line) => <Value value={line.budget} format="currency" />,
        footer: <Total property="budget" bold format="currency" />,
      },
    ];

    if (amountKey === "ownersAmount") {
      budgetColumns = [
        {
          id: "externalBudget",
          name: <Flex justify="flex-end">Revenue budget</Flex>,
          width: 165,
          align: "center",
          textAlign: "right",
          render: (line) => (
            <Value value={line.ownersAmount} format="currency" />
          ),
          footer: <Total property="ownersAmount" bold format="currency" />,
        },
      ];
    } else if (amountKey === "ownersRevisedBudget") {
      budgetColumns = [
        {
          id: "externalBudget",
          name: <Flex justify="flex-end">Revenue budget</Flex>,
          width: 165,
          align: "center",
          textAlign: "right",
          render: (line) => (
            <Value value={line.ownersAmount} format="currency" />
          ),
          footer: <Total property="ownersAmount" bold format="currency" />,
        },
        {
          id: "externalChanges",
          name: <Flex justify="flex-end">Changes</Flex>,
          width: 165,
          align: "center",
          textAlign: "right",
          render: (line) => (
            <Value value={line.externalChangeAmount} format="currency" />
          ),
          footer: (
            <Total property="externalChangeAmount" bold format="currency" />
          ),
        },
        {
          id: "ownersRevisedBudget",
          name: <Flex justify="flex-end">Revised budget</Flex>,
          width: 165,
          align: "center",
          textAlign: "right",
          render: (line) => (
            <Value value={line.ownersRevisedBudget} format="currency" />
          ),
          footer: (
            <Total property="ownersRevisedBudget" bold format="currency" />
          ),
        },
      ];
    } else if (amountKey === "revisedBudget") {
      budgetColumns = [
        ...budgetColumns,
        {
          id: "changes",
          name: <Flex justify="flex-end">Changes</Flex>,
          width: 165,
          align: "center",
          textAlign: "right",
          render: (line) => (
            <Value value={line.changeAmount} format="currency" />
          ),
          footer: <Total property="changeAmount" bold format="currency" />,
        },
        {
          id: "revisedBudget",
          name: <Flex justify="flex-end">Revised budget</Flex>,
          width: 165,
          textAlign: "right",
          align: "center",
          render: (line) => (
            <Value value={line.revisedBudget} format="currency" />
          ),
          footer: <Total property="revisedBudget" bold format="currency" />,
        },
      ];
    }

    return [
      {
        id: isCategoriesViewMode ? "categories" : "jobCostMethod",
        sortable: "asc",
        name: isCategoriesViewMode ? "Category" : "Line item",
        width: "fill",
        align: "center",
        minWidth: 300,
        render: (line) => {
          if (
            isCategoriesViewMode &&
            line.category !== STRINGS.UNKNOWN_CATEGORY
          ) {
            return line.category;
          }

          return (
            <Flex direction="column">
              <Text>{line.jobCostMethod?.displayName || "Other"}</Text>
              {categoriesEnabled && <Text size="xs">{line.category}</Text>}
              {itemDescriptionEnabled && line.jobCostMethod?.description && (
                <Text size="xs">
                  Description: {line.jobCostMethod.description}
                </Text>
              )}
            </Flex>
          );
        },
        footer: "Totals",
      },
      ...budgetColumns,
      {
        id: "previousDraws",
        name: <Flex justify="flex-end">Previous draws</Flex>,
        width: 165,
        align: "center",
        textAlign: "right",
        render: (line) => (
          <Value value={line.previousDraws} format="currency" />
        ),
        footer: <Total property="previousDraws" bold format="currency" />,
      },
      {
        id: "thisDraw",
        sortable: true,
        name: <ThisDrawName />,
        visibility: { name: "This draw" },
        width: 170,
        textAlign: "right",
        align: "center",
        highlight: "success",
        render: (line) => (
          <ThisDrawRender
            mode={isCategoriesViewMode ? "view" : mode}
            {...line}
          />
        ),
        footer: <Total property="thisDraw" bold format="currency" />,
      },
      {
        id: "totalDrawnAmount",
        name: <Flex justify="flex-end">Total drawn ($)</Flex>,
        width: 165,
        align: "center",
        textAlign: "right",
        render: (line) => (
          <Value value={line.totalInvoiced} format="currency" lazy />
        ),
        footer: <Total property="totalInvoiced" bold format="currency" lazy />,
      },
      {
        id: "totalDrawnPercentage",
        name: <Flex justify="flex-end">Total drawn (%)</Flex>,
        align: "center",
        textAlign: "right",
        width: mode === "edit" && !isCategoriesViewMode ? 280 : 220,
        render: (line) => (
          <TotalDrawnPercentageRender
            mode={isCategoriesViewMode ? "view" : mode}
            {...line}
          />
        ),
        footer: (lines) => <TotalDrawnPercentageFooter data={lines} />,
      },
      {
        id: "balanceToFinish",
        name: <Flex justify="flex-end">Balance to finish</Flex>,
        width: 165,
        align: "center",
        textAlign: "right",
        render: (line) => (
          <Value value={line.balanceToFinish} warning format="currency" lazy />
        ),
        footer: (
          <Total
            warning
            property="balanceToFinish"
            bold
            format="currency"
            lazy
          />
        ),
      },
    ] as TableColumn<TableRow<EnhancedSummaryLine>>[];
  }, [
    mode,
    amountKey,
    categoriesEnabled,
    isCategoriesViewMode,
    itemDescriptionEnabled,
  ]);

  const emptyState = useMemo<TableEmptyState>(
    () => ({
      title: `You don't have a budget associated with this project yet`,
      action: {
        children: "Create budget",
        onClick: () => navigate(`/jobs/${customer.id}`),
      },
    }),
    [navigate, customer.id]
  );

  const sort = useMemo<TableSortAddon>(
    () => ({
      value: sortBy,
      onChange: (value) => {
        previousLinesDataRef.current = undefined;
        setSortBy(value);
      },
    }),
    [sortBy, setSortBy]
  );

  useEffect(() => {
    setThisDrawMode(
      window.IS_E2E
        ? DEFAULT_THIS_DRAW_MODE
        : ((localStorage.getItem(
            `invoice-summary-this-draw-mode-${id}`
          ) as ThisDrawMode) ?? DEFAULT_THIS_DRAW_MODE)
    );
  }, [id]);

  return (
    <InvoiceSummaryContext.Provider
      value={{
        totals: summary?.totals ?? DEFAULT_TOTALS,
        thisDrawMode,
        getBaseAmount,
        curriedOnChange,
        toggleThisDrawMode,
      }}
    >
      <Table
        id={`draw-schedule-${customer.id}`}
        size="sm"
        sort={sort}
        data={enhancedData}
        footer={footer}
        header={header}
        loading={categoriesIsLoading}
        columns={columns}
        emptyState={emptyState}
        data-testid="invoice-draw-schedule-table"
      />
    </InvoiceSummaryContext.Provider>
  );
});

InvoiceSummary.displayName = "InvoiceSummary";
