import React, { memo, type PropsWithChildren, useMemo } from "react";
import { Button, Flex, Icon, TextField } from "@adaptive/design-system";
import { useDeepMemo, useEvent } from "@adaptive/design-system/hooks";
import { debounce } from "@adaptive/design-system/utils";
import {
  TableFilterControls,
  type TableFilterControlsProps,
} from "@components/table-filter";
import type { Option } from "@shared/types";
import {
  useJobCategories,
  useJobCategoriesIsLoading,
  useJobSettings,
} from "@src/jobs/hooks";
import { useJobInfo } from "@store/jobs";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";

import {
  useBudgetsHasFilters,
  useBudgetsQuery,
  useBudgetsRawFilters,
  useBudgetsTableActions,
} from "./budgets-table-context";

const BUDGET_STATUSES = [
  {
    label: "Over-drawn lines",
    value: "over_drawn",
    groupLabel: "Budget status",
  },
  {
    label: "Over-budget lines",
    value: "over_budget",
    groupLabel: "Budget status",
  },
  {
    label: "Under-budget lines",
    value: "under_budget",
    groupLabel: "Budget status",
  },
] as const;

const DRAW_STATUS = [
  { label: "Paid", value: "paid", groupLabel: "Draw status" },
  { label: "Unpaid", value: "unpaid", groupLabel: "Draw status" },
] as const;

export const BudgetsFilters = memo(({ children }: PropsWithChildren) => {
  const query = useBudgetsQuery();

  const settings = useClientSettings();

  const { job } = useJobInfo();

  const hasFilters = useBudgetsHasFilters();

  const rawFilters = useBudgetsRawFilters();

  const categoriesData = useJobCategories();

  const { setQuery, setFilters } = useBudgetsTableActions();

  const categoriesIsLoading = useJobCategoriesIsLoading();

  const { categoriesEnabled } = useJobSettings();

  const extraData = useDeepMemo(() => {
    let data: Option[] = [...BUDGET_STATUSES, ...DRAW_STATUS];

    if (categoriesEnabled) {
      data = [
        ...categoriesData.map((category) => ({
          ...category,
          groupLabel: "Category",
        })),
        ...data,
      ];
    }

    return data;
  }, [categoriesData, categoriesEnabled]);

  const includeFilters = useDeepMemo(
    () =>
      [
        ...(settings.canItemsLinkToLinesDesktop ? ["costCodes"] : []),
        ...(window.BUDGETS_DRAW_ACCOUNTS_COST_CODE_ENABLED ? ["accounts"] : []),
        "vendors",
      ] as Exclude<TableFilterControlsProps["includeFilters"], undefined>,
    [settings.canItemsLinkToLinesDesktop]
  );

  const debouncedTrackQuery = useMemo(
    () =>
      debounce(
        (value: string) => {
          analytics.track("budgetAdvancedFilters", {
            jobId: job.id,
            action: "add",
            field: "query",
            value,
          });
        },
        import.meta.env.MODE === "test" ? 0 : 300
      ),
    [job.id]
  );

  const enhancedSetQuery = useEvent((value: string) => {
    setQuery(value);
    debouncedTrackQuery(value);
  });

  const enhancedSetFilters = useEvent<
    TableFilterControlsProps["onFiltersChange"]
  >((values) => {
    const addedFilters = new Map<string, any>();
    const removedFilters = new Map<string, any>();

    const updateDateRangeLabel = (
      filtersMap: Map<string, any>,
      key: string,
      value: string
    ) => {
      if (!filtersMap.has("dateRange")) {
        filtersMap.set("dateRange", {
          groupLabel: "Date range",
          label: "{after} - {before}",
        });
      }

      filtersMap.set("dateRange", {
        ...filtersMap.get("dateRange"),
        label: key.endsWith("after")
          ? filtersMap.get("dateRange").label.replace("{after}", value)
          : filtersMap.get("dateRange").label.replace("{before}", value),
      });
    };

    for (const [key, value] of Object.entries(values)) {
      if (!(key in rawFilters)) {
        addedFilters.set(key, value);
      }
    }

    for (const [key, value] of addedFilters) {
      if (key.startsWith("date_")) {
        addedFilters.delete(key);
        updateDateRangeLabel(addedFilters, key, value);
      }
    }

    for (const [key, value] of Object.entries(rawFilters)) {
      if (!(key in values) || values[key] === "") {
        removedFilters.set(key, value);
      }
    }

    for (const [key, value] of removedFilters) {
      if (key.startsWith("date_")) {
        removedFilters.delete(key);
        updateDateRangeLabel(removedFilters, key, value);
      }
    }

    const trackFilters = (
      filtersMap: Map<string, any>,
      action: "add" | "remove"
    ) => {
      for (const value of filtersMap.values()) {
        analytics.track("budgetAdvancedFilters", {
          jobId: job.id,
          action,
          field: value.groupLabel,
          value: value.label,
        });
      }
    };

    trackFilters(addedFilters, "add");
    trackFilters(removedFilters, "remove");

    setFilters(values as any);
  });

  const onClear = useEvent(() => {
    if (query) {
      analytics.track("budgetAdvancedFilters", {
        jobId: job.id,
        action: "remove",
        field: "query",
        value: "",
      });
    }

    setQuery("");
    enhancedSetFilters({});
  });

  return (
    <TableFilterControls
      filters={rawFilters}
      extraData={extraData}
      filterProps={{
        addonAfter: null,
        suffix: undefined,
        maxWidth: "240px",
        "data-testid": "budgets-filter",
      }}
      renderBefore={() => (
        <Flex maxWidth="240px" width="full">
          <TextField
            value={query}
            onChange={enhancedSetQuery}
            addonAfter={<Icon name="search" />}
            placeholder="Search"
            data-testid="budgets-search"
            messageVariant="hidden"
          />
        </Flex>
      )}
      withFilterTags
      includeFilters={includeFilters}
      onFiltersChange={enhancedSetFilters}
      withDateFilter
      extraDataLoading={categoriesIsLoading}
    >
      <Flex width="full" gap="xl" align="center">
        {hasFilters && (
          <>
            <button id="budgets-filters-clear" onClick={onClear} hidden />
            <Button as="label" htmlFor="budgets-filters-clear">
              Clear filters
            </Button>
          </>
        )}
        <Flex width="full" gap="2xl" justify="flex-end">
          {children}
        </Flex>
      </Flex>
    </TableFilterControls>
  );
});

BudgetsFilters.displayName = "BudgetsFilters";
