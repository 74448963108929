import React, { memo, useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Icon,
  Text,
  TextField,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import {
  RequestVendorAchAlert,
  RequestVendorAchButton,
} from "@components/request-vendor-ach";
import { useAppSelector } from "@store/hooks";

import { STRINGS } from "../constants/constants";
import { useVendorAction } from "../hooks/use-vendor-action";
import { useVendorInfo } from "../hooks/use-vendor-info";
import {
  selectErrors,
  vendorSelector,
  vendorSelectors,
} from "../store/selectors";

export const FormAccounting = memo(() => {
  const latestRoutingNumber = useRef("");

  const latestAccountNumber = useRef("");

  const taxId = useAppSelector((state) => vendorSelectors.info(state).taxId);

  const { routingNumber, accountNumber } = useAppSelector(
    vendorSelectors.banking
  );

  const { isSubmitting, canManagePaymentInfo } = useVendorInfo();

  const errors = useAppSelector((state) => {
    const taxId = selectErrors(state)?.info?.taxId;

    return { taxId, ...selectErrors(state)?.banking };
  }, isEqual);

  const { setTaxId, setRoutingNumber, setAccountNumber } = useVendorAction();

  const { info: vendor } = useAppSelector(vendorSelector);

  const { latestAchRequest: request } = vendor;

  const [isEdit, setIsEdit] = useState(
    vendor.id ? !vendor.hasBankingAch : true
  );

  const [acctNumberError, setAcctNumberError] = useState<string | undefined>(
    errors?.accountNumber
  );

  const [routingNumberError, setRoutingNumberError] = useState<
    string | undefined
  >(errors?.routingNumber);

  const [taxIdError, setTaxIdError] = useState<string | undefined>(
    errors?.taxId
  );

  const onEdit = useEvent(() => {
    if (isEdit) {
      setIsEdit(false);
      setRoutingNumber(latestRoutingNumber.current);
      setAccountNumber(latestAccountNumber.current);
    } else {
      setIsEdit(true);
      setRoutingNumber("");
      setAccountNumber("");
    }
  });

  useEffect(() => {
    setAcctNumberError(errors?.accountNumber);
  }, [errors?.accountNumber]);

  useEffect(() => {
    setRoutingNumberError(errors?.routingNumber);
  }, [errors?.routingNumber]);

  useEffect(() => {
    setTaxIdError(errors?.taxId);
  }, [errors?.taxId]);

  useEffect(() => {
    if (routingNumber?.includes("*") || accountNumber?.includes("*")) {
      setIsEdit(false);
      latestAccountNumber.current = accountNumber || "";
      latestRoutingNumber.current = routingNumber || "";
    }
  }, [routingNumber, accountNumber]);

  return (
    <Flex gap="xl" direction="column">
      <RequestVendorAchAlert />

      <Flex direction="column">
        <Flex gap="xl" direction="column">
          <Flex gap="xl" align="center" justify="space-between">
            <Text weight="bold">{STRINGS.HEADER_ACH_INFO}</Text>
            {vendor.achCanBeRequested && (
              <RequestVendorAchButton disabled={isSubmitting} size="sm" />
            )}
          </Flex>

          <Flex justify="space-between" gap="xl" align="center">
            <TextField
              label={STRINGS.LABEL_ROUTING_NUMBER}
              value={routingNumber || ""}
              onChange={setRoutingNumber}
              onFocus={() => setRoutingNumberError(undefined)}
              errorMessage={routingNumberError}
              disabled={!canManagePaymentInfo || isSubmitting || !isEdit}
            />
            <TextField
              label={STRINGS.LABEL_ACCOUNT_NUMBER}
              value={accountNumber || ""}
              onChange={setAccountNumber}
              onFocus={() => setAcctNumberError(undefined)}
              errorMessage={acctNumberError}
              disabled={!canManagePaymentInfo || isSubmitting || !isEdit}
            />
            {!vendor.achCanBeRequested &&
            canManagePaymentInfo &&
            !request &&
            vendor.id ? (
              <Flex>
                <Tooltip message={isEdit ? "Cancel" : "Edit ACH details"}>
                  <Button variant="ghost" color="neutral" onClick={onEdit}>
                    {isEdit ? <Icon name="times" /> : <Icon name="pen" />}
                  </Button>
                </Tooltip>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      </Flex>

      <Flex gap="xl" direction="column">
        <Text weight="bold">{STRINGS.HEADER_TAX_INFO}</Text>
        <TextField
          label="Tax ID"
          value={taxId || ""}
          onChange={setTaxId}
          onFocus={() => setTaxIdError(undefined)}
          disabled={!canManagePaymentInfo || isSubmitting}
          errorMessage={taxIdError}
        />
      </Flex>
    </Flex>
  );
});

FormAccounting.displayName = "FormAccounting";
