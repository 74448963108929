import React, { type ComponentProps, memo, useCallback, useState } from "react";
import { useSearchParams } from "react-router";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogStep,
  Icon,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import type { InvoiceMarkupMutate } from "@api/invoices";
import { useJobPermissions } from "@src/jobs";
import { getItemUrls } from "@utils/get-cost-code-account-values";

import { MarkupForm, type MarkupFormProps } from "../markup";

import {
  useInvoiceActions,
  useInvoiceLines,
  useInvoiceMarkups,
} from "./invoice-context";
import { transformInvoiceGetMarkupsToInvoicePayload } from "./utils";

const FORM_ID = "invoice-add-markup";

type InvoiceAddMarkupButtonProps = Pick<
  ComponentProps<typeof Button>,
  "size" | "variant" | "block" | "children"
>;

export const InvoiceAddMarkupButton = memo(
  ({
    size = "sm",
    block,
    variant = "ghost",
    children = "Add markup",
    ...props
  }: InvoiceAddMarkupButtonProps) => {
    const invoiceLines = useInvoiceLines();

    const { canManage } = useJobPermissions();

    const invoiceMarkups = useInvoiceMarkups();

    const { updateInvoice } = useInvoiceActions();

    const [, setSearchParams] = useSearchParams();

    const [isValid, setIsValid] = useState(false);

    const { hide: hideDialog, ...dialog } = useDialog({ lazy: true });

    const addMarkup = useCallback(
      async (markup: InvoiceMarkupMutate) => {
        const markups =
          transformInvoiceGetMarkupsToInvoicePayload(invoiceMarkups);

        await updateInvoice({ markups: [...markups, markup] });

        toast.success("Markup added to the draw");

        hideDialog();

        setSearchParams({ status: "line-items" });
      },
      [invoiceMarkups, setSearchParams, updateInvoice, hideDialog]
    );

    const onSubmitCost = useEvent<MarkupFormProps["onSubmitCost"]>((values) => {
      const itemUrls = getItemUrls(values.costCode);

      return addMarkup({
        item: itemUrls.item,
        itemAccount: itemUrls.account,
        lines: [],
        amount: values.value,
        isInlineMarkup: false,
      });
    });

    const onSubmitPercentage = useEvent<MarkupFormProps["onSubmitPercentage"]>(
      (values) => {
        const itemUrls = getItemUrls(values.costCode);

        return addMarkup({
          item: itemUrls.item,
          itemAccount: itemUrls.account,
          lines: values.lines.map((line) => line.id),
          percent: values.value,
          isInlineMarkup: false,
        });
      }
    );

    return (
      <>
        {dialog.isRendered && (
          <Dialog
            step="root"
            show={dialog.isVisible}
            size="auto"
            variant="multi-step-dialog"
            onClose={hideDialog}
          >
            <DialogStep name="root">
              <DialogHeader>Add draw markup</DialogHeader>
              <DialogContent>
                <MarkupForm
                  lines={invoiceLines}
                  formId={FORM_ID}
                  onSubmitCost={onSubmitCost}
                  onValidityChange={setIsValid}
                  disableBudgetLines={false}
                  onSubmitPercentage={onSubmitPercentage}
                />
              </DialogContent>
              <DialogFooter>
                <Button
                  block
                  color="neutral"
                  onClick={hideDialog}
                  variant="text"
                >
                  Cancel
                </Button>
                <Button
                  block
                  type="submit"
                  form={FORM_ID}
                  disabled={!isValid}
                  data-testid="markup-percentage-form-save"
                >
                  Save
                </Button>
              </DialogFooter>
            </DialogStep>
          </Dialog>
        )}
        <Tooltip
          message={!canManage ? "You don't have permission to do this" : ""}
        >
          <Button
            size={size}
            block={block}
            variant={variant}
            onClick={dialog.show}
            disabled={!canManage}
            data-testid="invoice-add-markup-button"
            {...props}
          >
            {variant === "ghost" && <Icon name="plus" />}
            {children}
          </Button>
        </Tooltip>
      </>
    );
  }
);

InvoiceAddMarkupButton.displayName = "InvoiceAddMarkupButton";
