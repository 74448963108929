import React, {
  type ComponentProps,
  memo,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router";
import {
  Button,
  ComboBox,
  CurrencyField,
  dialog,
  Flex,
  Icon,
  Link,
  Loader,
  MultipleTable,
  type MultipleTableFooter,
  type MultipleTableHeader,
  type MultipleTableProps,
  type MultipleTableRef,
  type TableColumn,
  type TableEmptyState,
  type TableFooterAddon,
  type TableHeaderAddon,
  type TableRow,
  type TableRowAddon,
  type TableSelectAddon,
  type TableSortAddon,
  Tag,
  Text,
  TextField,
  toast,
  Tooltip,
  VisuallyHidden,
} from "@adaptive/design-system";
import { useDeepMemo, useDialog } from "@adaptive/design-system/hooks";
import { formatCurrency, isEqual } from "@adaptive/design-system/utils";
import { CostCodeAccountComboBox } from "@components/cost-code-account-combobox";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import { useVendorsSimplified } from "@hooks/use-vendors-simplified";
import { getTransactionRoute } from "@src/bills/utils";
import { CURRENCY_FORMAT, INVOICE_STRINGS, useJobPermissions } from "@src/jobs";
import {
  CURRENCY_FIELD_FORMAT_PROPS,
  DEFAULT_VENDOR_INVOICE_OPTION_VALUE,
} from "@src/jobs/constants";
import { useJobInfo } from "@store/jobs";
import { useClientInfo } from "@store/user";
import { isCostCode } from "@utils/is-cost-code";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { stringCompare } from "@utils/string-compare";
import { sum } from "@utils/sum";

import {
  useInvoice,
  useInvoiceActions,
  useInvoiceIsLoading,
  useInvoiceLines,
  useInvoiceLinesIsLoading,
  useInvoiceLinesSelected,
  useInvoiceMarkups,
  useInvoiceMarkupsIsLoading,
} from "./invoice-context";
import {
  InvoiceCostTableContext,
  useInvoiceCostTableActions,
  useInvoiceCostTableHasMarkups,
} from "./invoice-cost-table-context";
import { InvoiceEditFixedMarkupDialog } from "./invoice-edit-fixed-markup-dialog";
import { InvoiceEditSeparatePercentMarkupDialog } from "./invoice-edit-separate-percent-markup-dialog";
import {
  useInvoiceFormLineItemsSortBy,
  useInvoiceFormMode,
  useInvoiceFormSetLineItemsSortBy,
} from "./invoice-form-context";
import {
  type CurriedOnChangeLineHandler,
  type CurriedOnChangeMarkupHandler,
  type CurriedOnDeleteMarkupHandler,
  type CurriedOnDeleteTransactionHandler,
  type CurriedOnEditMarkupHandler,
  InvoiceAddEmptyLineButton,
  InvoiceAddLineButton,
  InvoiceAddMarkupButton,
  type Line,
  type Markup,
  type Mode,
  type Transaction,
} from ".";

const MARKUPS_COLORS = ["#00FFFF", "#EE00FF", "#FFB902"];

const EditButton = memo(
  ({ disabled, ...props }: ComponentProps<typeof Button>) =>
    disabled ? (
      <Flex width="34px" />
    ) : (
      <Button size="sm" color="neutral" variant="ghost" {...props}>
        <Icon size="sm" name="pen" />
      </Button>
    )
);

EditButton.displayName = "EditButton";

const DeleteButton = memo(
  ({ disabled, ...props }: ComponentProps<typeof Button>) =>
    disabled ? (
      <Flex width="34px" />
    ) : (
      <Button size="sm" color="neutral" variant="ghost" {...props}>
        <Icon name="trash" />
      </Button>
    )
);

DeleteButton.displayName = "DeleteButton";

const TransactionLineRender = memo((row: TableRow<Line>) => {
  if (row.isExtra || !row.transactionLine?.parent) return null;

  return (
    <Text
      as={Link}
      rel="noreferrer"
      href={getTransactionRoute(row.transactionLine.parent.url)}
      target="_blank"
      truncate
    >
      <Text as="span" size="sm">
        {row.transactionLine.parent.docNumber}
      </Text>
    </Text>
  );
});

TransactionLineRender.displayName = "TransactionLineRender";

const TransactionLineFooter = memo(() => {
  const mode = useInvoiceFormMode();

  const hasMarkups = useInvoiceCostTableHasMarkups();

  return (
    <Flex width="full" gap="xl" align="center" justify="space-between">
      {mode === "edit" && (
        <Flex gap="md">
          <InvoiceAddLineButton />
          <InvoiceAddEmptyLineButton />
          {!hasMarkups ? <InvoiceAddMarkupButton /> : null}
        </Flex>
      )}
      <Text weight="bold" align="right">
        Totals
      </Text>
    </Flex>
  );
});

TransactionLineFooter.displayName = "TransactionLineFooter";

const VendorColumnName = memo(() => {
  return (
    <Flex align="center" gap="sm">
      <Text weight="bold">Vendor</Text>
      <Tooltip
        as={Icon}
        size="sm"
        name="info-circle"
        message="This value should reflect the party to be paid. Changing it will not remove the Vendor on the associated transaction"
      />
    </Flex>
  );
});

VendorColumnName.displayName = "VendorColumnName";

const VendorLineRender = memo((row: TableRow<Line>) => {
  const { canManage } = useJobPermissions();

  const vendorsSimplified = useVendorsSimplified();

  const { curriedOnChangeLine } = useInvoiceCostTableActions();

  const { client } = useClientInfo();

  const enhancedVendorsData = useMemo(() => {
    return [
      { label: `${client?.name}`, value: DEFAULT_VENDOR_INVOICE_OPTION_VALUE },
      ...vendorsSimplified.data,
    ];
  }, [vendorsSimplified.data, client]);

  return (
    <ComboBox
      data={enhancedVendorsData}
      size="sm"
      value={row.vendor?.url || DEFAULT_VENDOR_INVOICE_OPTION_VALUE}
      loading={vendorsSimplified.status === "loading"}
      disabled={!row.id || !canManage}
      onChange={curriedOnChangeLine({ line: row, field: "vendor" })}
      placeholder="Vendor to invoice"
      messageVariant="hidden"
    />
  );
});

VendorLineRender.displayName = "VendorLineRender";

const VendorMarkupRender = memo((row: TableRow<Markup>) => {
  const { canManage } = useJobPermissions();

  const vendorsSimplified = useVendorsSimplified();

  const { curriedOnChangeMarkup } = useInvoiceCostTableActions();

  const { client } = useClientInfo();

  const enhancedVendorsData = useMemo(() => {
    return [
      { label: `${client?.name}`, value: DEFAULT_VENDOR_INVOICE_OPTION_VALUE },
      ...vendorsSimplified.data,
    ];
  }, [vendorsSimplified.data, client]);

  return (
    <ComboBox
      data={enhancedVendorsData}
      size="sm"
      value={row.vendor?.url || DEFAULT_VENDOR_INVOICE_OPTION_VALUE}
      loading={vendorsSimplified.status === "loading"}
      disabled={!row.id || !canManage}
      onChange={curriedOnChangeMarkup({ markup: row, field: "vendor" })}
      placeholder="Vendor to invoice"
      messageVariant="hidden"
    />
  );
});

VendorMarkupRender.displayName = "VendorMarkupRender";

const ItemLineColumnName = memo(() => (
  <Flex align="center" gap="sm">
    <Text weight="bold">Item</Text>
  </Flex>
));

ItemLineColumnName.displayName = "ItemLineColumnName";

const ItemLineRender = memo((row: TableRow<Line>) => {
  const { canManage } = useJobPermissions();
  const { job } = useJobInfo();

  const { curriedOnChangeLine } = useInvoiceCostTableActions();

  const { jobCostMethod, vendor } = row;

  const value = useMemo(
    () =>
      jobCostMethod
        ? {
            label: jobCostMethod?.displayName ?? "",
            value: jobCostMethod?.url ?? "",
          }
        : "",
    [jobCostMethod]
  );

  const costCodeAccountQueryFilters = useMemo(() => {
    return {
      accountFilters: {
        vendorId: vendor?.id,
        customerId: job.id,
      },
      costCodeFilters: {
        vendorId: vendor?.id,
        customerId: job.id,
      },
    };
  }, [job.id, vendor?.id]);

  return (
    <CostCodeAccountComboBox
      size="sm"
      value={value}
      label=""
      disabled={!row.id || !canManage}
      onChange={curriedOnChangeLine({ line: row, field: "costCodeAccount" })}
      placeholder="Item to invoice"
      messageVariant="hidden"
      {...costCodeAccountQueryFilters}
      errorMessage={!row.jobCostMethod?.url ? "Item is required" : undefined}
    />
  );
});

ItemLineRender.displayName = "ItemLineRender";

const DescriptionColumnName = memo(() => (
  <Flex align="center" gap="sm">
    <Text weight="bold">Description</Text>
  </Flex>
));

DescriptionColumnName.displayName = "DescriptionColumnName";

const DescriptionLineRender = memo((row: TableRow<Line>) => {
  const { canManage } = useJobPermissions();

  const { curriedOnChangeLine } = useInvoiceCostTableActions();

  return (
    <Flex width="full" minWidth="150px">
      <TextField
        key={row.id}
        size="sm"
        value={row.description}
        onChange={curriedOnChangeLine({ line: row, field: "description" })}
        disabled={!row.id || !canManage}
        changeMode="lazy"
        placeholder="Description"
        messageVariant="hidden"
      />
    </Flex>
  );
});

DescriptionLineRender.displayName = "DescriptionLineRender";

const CostLineRender = memo((row: TableRow<Line>) => {
  const { transactionLine, amount } = row;

  const totalAmount = transactionLine?.totalAmount ?? 0;

  const enhancedTotalAmount = transactionLine?.totalAmount
    ? formatCurrency(totalAmount, CURRENCY_FORMAT)
    : "—";

  const difference = Number(((amount * 100) / totalAmount - 100).toFixed(2));

  const isValidResult =
    !Number.isNaN(difference) && Number.isFinite(difference);

  const hasPercentage = isValidResult && difference !== 0;

  return (
    <Flex gap="sm" width="full" align="center">
      <Flex justify="flex-end" grow>
        {enhancedTotalAmount}
      </Flex>
      {hasPercentage && (
        <Tooltip
          as={Flex}
          width="max-content"
          shrink={false}
          message={`${parseFloat(Math.abs(difference).toFixed(1))}% ${
            difference > 0 ? "increase" : "decrease"
          } from cost`}
        >
          <Tag size="sm" truncate={{ tooltip: false }}>
            {parseFloat(difference.toFixed(1))}%
          </Tag>
        </Tooltip>
      )}
    </Flex>
  );
});

CostLineRender.displayName = "CostLineRender";

const ItemMarkupRender = memo((row: TableRow<Markup>) => {
  const { canManage } = useJobPermissions();

  const costCodeAccounts = useJobsCostCodeAccountSimplified();

  const { curriedOnChangeMarkup } = useInvoiceCostTableActions();

  const { jobCostMethod } = row;

  const value = useMemo(
    () => ({
      label: jobCostMethod?.displayName ?? "",
      value: jobCostMethod?.url ?? "",
      groupLabel: isCostCode(jobCostMethod?.url) ? "Cost code" : "Account",
    }),
    [jobCostMethod?.displayName, jobCostMethod?.url]
  );

  return (
    <ComboBox
      data={costCodeAccounts.data}
      size="sm"
      value={value}
      loading={costCodeAccounts.status === "loading"}
      disabled={!row.id || !canManage}
      onChange={curriedOnChangeMarkup({
        markup: row,
        field: "costCodeAccount",
      })}
      placeholder="Item to invoice"
      messageVariant="hidden"
      errorMessage={!row.jobCostMethod?.url ? "Item is required" : undefined}
    />
  );
});

ItemMarkupRender.displayName = "ItemMarkupRender";

const CostLineFooter = memo(() => {
  const isLoading = useInvoiceIsLoading();

  const { totalCost } = useInvoice(["totalCost"]);

  return (
    <Flex width="full" justify="flex-end">
      {isLoading ? (
        <Loader />
      ) : (
        <Text weight="bold" align="right">
          {formatCurrency(totalCost, CURRENCY_FORMAT)}
        </Text>
      )}
    </Flex>
  );
});

CostLineFooter.displayName = "CostLineFooter";

const PriceLineRender = memo((row: TableRow<Line>) => {
  const mode = useInvoiceFormMode();

  const { canManage } = useJobPermissions();

  const { curriedOnChangeLine } = useInvoiceCostTableActions();

  return (
    <Flex width="full" align="center">
      {mode === "edit" ? (
        <CurrencyField
          {...CURRENCY_FIELD_FORMAT_PROPS}
          value={row.amount}
          disabled={!row.id || !canManage}
          onChange={curriedOnChangeLine({
            line: row,
            field: "amount",
          })}
          data-testid="invoice-cost-price-field"
          messageVariant="absolute"
        />
      ) : (
        formatCurrency(row.amount, CURRENCY_FORMAT)
      )}
    </Flex>
  );
});

PriceLineRender.displayName = "PriceLineRender";

const PriceLineFooter = memo(() => {
  const isLoading = useInvoiceIsLoading();

  const { amount } = useInvoice(["amount"]);

  return (
    <Flex width="full" justify="flex-end">
      {isLoading ? (
        <Loader />
      ) : (
        <Text weight="bold" align="right">
          {formatCurrency(amount, CURRENCY_FORMAT)}
        </Text>
      )}
    </Flex>
  );
});

PriceLineFooter.displayName = "PriceLineFooter";

const ActionsLineRender = memo((row: TableRow<Line>) => {
  const { canManage } = useJobPermissions();

  const { curriedOnDeleteTransaction } = useInvoiceCostTableActions();

  return (
    <DeleteButton
      onClick={curriedOnDeleteTransaction(row)}
      disabled={!row.id || !canManage}
      aria-label={`Delete ${row.jobCostMethod?.displayName ?? "Unknown item"}`}
    />
  );
});

ActionsLineRender.displayName = "ActionsLineRender";

const getLineColumns = (mode: Mode) => {
  const columns: TableColumn<TableRow<Line>>[] = [
    {
      id: "doc_number",
      sortable: "asc",
      name: "Ref #",
      width: 187,
      render: (row) => <TransactionLineRender {...row} />,
      footer: {
        render: <TransactionLineFooter />,
        colSpan: window.VENDOR_FOR_INVOICE_LINES_ENABLED ? 4 : 3,
      },
    },
    ...(window.VENDOR_FOR_INVOICE_LINES_ENABLED
      ? ([
          {
            id: "vendor__display_name",
            sortable: "asc",
            name: <VendorColumnName />,
            width: 322,
            render: (row) => <VendorLineRender {...row} />,
            footer: {
              render: () => null,
              colSpan: 0,
            },
          },
        ] as TableColumn<TableRow<Line>>[])
      : []),
    {
      id: "item_account",
      sortable: "asc",
      name: <ItemLineColumnName />,
      width: window.VENDOR_FOR_INVOICE_LINES_ENABLED ? 267 : 343,
      render: (row) => <ItemLineRender {...row} />,
      footer: { render: () => null, colSpan: 0 },
    },
    {
      id: "description",
      sortable: "asc",
      name: <DescriptionColumnName />,
      width: "fill",
      align: "center",
      render: (row) => <DescriptionLineRender {...row} />,
      footer: { render: () => null, colSpan: 0 },
    },
    {
      id: "cost",
      sortable: true,
      name: "Cost",
      width: 175,
      textAlign: "right",
      align: "center",
      render: (row) => <CostLineRender {...row} />,
      footer: <CostLineFooter />,
    },
    {
      id: "amount",
      sortable: true,
      name: "Price",
      width: 175,
      textAlign: "right",
      align: "center",
      render: (row) => <PriceLineRender {...row} />,
      footer: <PriceLineFooter />,
    },
  ];

  if (mode === "edit") {
    columns.push({
      id: "actions",
      name: <VisuallyHidden>Actions</VisuallyHidden>,
      width: 100,
      textAlign: "right",
      render: (row) => <ActionsLineRender {...row} />,
    });
  }

  return columns;
};

const TransactionLineMarkupRender = memo((row: TableRow<Markup>) => (
  <Flex align="center" width="full" shrink={false}>
    {row.jobCostMethod?.displayName ?? "Unknown item"}
  </Flex>
));

TransactionLineMarkupRender.displayName = "TransactionLineMarkupRender";

const DescriptionMarkupRender = memo((row: TableRow<Markup>) => {
  const mode = useInvoiceFormMode();

  const { curriedOnChangeMarkup } = useInvoiceCostTableActions();

  const { canManage } = useJobPermissions();

  return (
    <Flex width="full" minWidth="150px">
      {mode === "edit" ? (
        <TextField
          key={row.id}
          size="sm"
          value={row.description}
          disabled={!canManage}
          onChange={curriedOnChangeMarkup({
            field: "description",
            markup: row,
          })}
          changeMode="lazy"
          placeholder="Description"
          messageVariant="hidden"
        />
      ) : (
        row.description
      )}
    </Flex>
  );
});

DescriptionMarkupRender.displayName = "DescriptionMarkupRender";

const CostMarkupFooter = memo(() => {
  const isLoading = useInvoiceIsLoading();

  return (
    <Flex width="full" justify="flex-end">
      {isLoading ? (
        <Loader />
      ) : (
        <Text weight="bold" align="right">
          {formatCurrency(0, CURRENCY_FORMAT)}
        </Text>
      )}
    </Flex>
  );
});

CostMarkupFooter.displayName = "CostMarkupFooter";

const PriceMarkupRender = memo((row: TableRow<Markup>) => {
  return (
    <Flex width="full" justify="flex-end">
      <Text align="right">{formatCurrency(row.amount, CURRENCY_FORMAT)}</Text>
    </Flex>
  );
});

PriceMarkupRender.displayName = "PriceMarkupRender";

const PriceMarkupFooter = memo(() => {
  const isLoading = useInvoiceIsLoading();

  const { markupTotalAmount } = useInvoice(["markupTotalAmount"]);

  return (
    <Flex width="full" justify="flex-end">
      {isLoading ? (
        <Loader />
      ) : (
        <Text weight="bold" align="right">
          {formatCurrency(markupTotalAmount, CURRENCY_FORMAT)}
        </Text>
      )}
    </Flex>
  );
});

PriceMarkupFooter.displayName = "PriceMarkupFooter";

const ActionsMarkupRender = memo((row: TableRow<Markup>) => {
  const { canManage } = useJobPermissions();

  const { curriedOnDeleteMarkup, curriedOnEditMarkup } =
    useInvoiceCostTableActions();

  return (
    <Flex justify="space-around" gap="sm">
      <EditButton
        onClick={curriedOnEditMarkup(row)}
        disabled={!canManage}
        aria-label="Edit markup"
        data-testid="invoice-edit-markup-button"
      />
      <DeleteButton
        onClick={curriedOnDeleteMarkup(row)}
        disabled={!canManage}
        data-testid="invoice-delete-markup-button"
        aria-label={`Delete ${
          row.jobCostMethod?.displayName ?? "Unknown item"
        } markup`}
      />
    </Flex>
  );
});

ActionsMarkupRender.displayName = "ActionsMarkupRender";

const getMarkupColumns = (mode: Mode) => {
  const columns: TableColumn<Markup>[] = [
    { id: "doc_number", render: () => null },

    ...(window.VENDOR_FOR_INVOICE_LINES_ENABLED
      ? ([
          {
            id: "vendor__display_name",
            render: (row) => <VendorMarkupRender {...row} />,
            footer: { render: () => null, colSpan: 0 },
          },
        ] as TableColumn<TableRow<Markup>>[])
      : []),

    {
      id: "item_account",
      render: (row) => <ItemMarkupRender {...row} />,
    },
    {
      id: "description",
      render: (row) => <DescriptionMarkupRender {...row} />,
    },
    {
      id: "cost",
      textAlign: "right",
      render: () => (
        <Flex width="full" justify="flex-end">
          <Text align="right">—</Text>
        </Flex>
      ),
    },
    {
      id: "amount",
      textAlign: "right",
      render: (row) => <PriceMarkupRender {...row} />,
    },
  ];

  if (mode === "edit") {
    columns.push({
      id: "actions",
      name: <VisuallyHidden>Actions</VisuallyHidden>,
      textAlign: "right",
      render: (row) => <ActionsMarkupRender {...row} />,
    });
  }

  return columns;
};

const TransactionLineMarkupsFooter = memo(() => {
  const mode = useInvoiceFormMode();

  return (
    <Flex gap="xl" justify="space-between" align="center" width="full">
      {mode === "edit" && <InvoiceAddMarkupButton />}
      <Text weight="bold" align="right">
        Markup totals
      </Text>
    </Flex>
  );
});

TransactionLineMarkupsFooter.displayName = "TransactionLineMarkupsFooter";

const CostGrandFooter = memo(() => {
  const isLoading = useInvoiceIsLoading();

  const { totalCost } = useInvoice(["totalCost"]);

  return (
    <Flex width="full" justify="flex-end">
      {isLoading ? (
        <Loader />
      ) : (
        <Text weight="bold" align="right">
          {formatCurrency(totalCost, CURRENCY_FORMAT)}
        </Text>
      )}
    </Flex>
  );
});

CostGrandFooter.displayName = "CostGrandFooter";

const PriceGrandFooter = memo(() => {
  const isLoading = useInvoiceIsLoading();

  const { totalAmount } = useInvoice(["totalAmount"]);

  return (
    <Flex width="full" justify="flex-end">
      {isLoading ? (
        <Loader />
      ) : (
        <Text weight="bold" align="right">
          {formatCurrency(totalAmount, CURRENCY_FORMAT)}
        </Text>
      )}
    </Flex>
  );
});

PriceGrandFooter.displayName = "PriceGrandFooter";

const isLine = (data: unknown): data is Line =>
  typeof data === "object" && data !== null && !("data" in data);

const getTransactionLineParent = (a: Transaction, b: Transaction) => {
  const parentA = isLine(a) ? a.transactionLine?.parent : undefined;
  const parentB = isLine(b) ? b.transactionLine?.parent : undefined;

  return { parentA, parentB };
};

export const InvoiceCostTable = memo(() => {
  const multipleTableRef = useRef<MultipleTableRef>(null);

  const previousLinesDataRef = useRef<Line[]>(undefined);

  const [isLinesRendered, setIsLinesRendered] = useState(false);

  const [searchParams] = useSearchParams();

  const focus = searchParams.get("focus") ?? "";

  const mode = useInvoiceFormMode();

  const markupEditSeparatePercentDialog = useDialog({ lazy: true });

  const markupEditFixedAmountDialog = useDialog({ lazy: true });

  const [currentMarkup, setCurrentMarkup] = useState<Markup | undefined>();

  const sortBy = useInvoiceFormLineItemsSortBy();

  const setSortBy = useInvoiceFormSetLineItemsSortBy();

  const { client } = useClientInfo();

  const invoiceLines = useInvoiceLines();

  /** Static lines for comparison in propagations/sync */
  const staticInvoiceLines = useRef<typeof invoiceLines | undefined>(undefined);

  const invoiceLinesIsLoading = useInvoiceLinesIsLoading();

  const invoiceMarkupsIsLoading = useInvoiceMarkupsIsLoading();

  // TODO: We use the transaction line data to update the local state
  // for the optimistic render, but we are omitting the url
  // because the url is not necessary for this update. We should
  // remove unnecessary data from the optimistic update
  // payload. Ticket: BOB-6086
  const invoiceLinesWithoutTransactionLineUrl = useInvoiceLines({
    withTransactionLineUrl: false,
  });

  const invoiceLinesSelected = useInvoiceLinesSelected();

  const invoiceMarkups = useInvoiceMarkups();

  const { updateInvoiceLines, updateInvoiceMarkups, setInvoiceLinesSelected } =
    useInvoiceActions();

  const markups = useDeepMemo(() => {
    let markupColorIndex = -1;

    return invoiceMarkups
      .slice()
      .sort((a) => (a.isInlineMarkup === false ? -1 : 0))
      .map((markup) => {
        if (markup.isInlineMarkup === false) {
          markupColorIndex = markupColorIndex + 1;
          const markupColor = MARKUPS_COLORS[markupColorIndex];

          return { ...markup, color: markupColor } as Markup;
        }

        return markup;
      });
  }, [invoiceMarkups]);

  const markupsData = useMemo(
    () => markups.filter((markup) => !markup.isInlineMarkup),
    [markups]
  );

  const linesData = useDeepMemo(() => {
    const transactions: Line[] = [];

    invoiceLines.forEach((line) => {
      const enhancedLine: Line = {
        ...line,
        markups: markups.filter((markup) => markup.lines.includes(line.id)),
      };
      transactions.push(enhancedLine);
    });

    return transactions;
  }, [invoiceLines, markups]);

  const enhancedLinesData = useMemo<Line[]>(() => {
    const items = [...linesData];

    if (items.length === 0 || !sortBy) return items;

    const direction = sortBy.startsWith("-") ? -1 : 1;

    // eslint-disable-next-line
    if (!previousLinesDataRef.current) {
      if (sortBy.endsWith("doc_number")) {
        items.sort((a, b) => {
          const { parentA, parentB } = getTransactionLineParent(a, b);
          const docNumberA =
            (direction === 1 ? parentA?.docNumber : parentB?.docNumber) ?? "";
          const docNumberB =
            (direction === 1 ? parentB?.docNumber : parentA?.docNumber) ?? "";
          return stringCompare(docNumberA, docNumberB);
        });
      } else if (sortBy.endsWith("cost")) {
        items.sort((a, b) => {
          const totalAmountA =
            (direction === 1
              ? a?.transactionLine?.totalAmount
              : b?.transactionLine?.totalAmount) ?? 0;
          const totalAmountB =
            (direction === 1
              ? b?.transactionLine?.totalAmount
              : a?.transactionLine?.totalAmount) ?? 0;
          return totalAmountA - totalAmountB;
        });
      } else if (sortBy.endsWith("amount")) {
        items.sort((a, b) => {
          const amountA = (direction === 1 ? a?.amount : b?.amount) ?? 0;
          const amountB = (direction === 1 ? b?.amount : a?.amount) ?? 0;
          return amountA - amountB;
        });
      } else if (sortBy.endsWith("vendor__display_name")) {
        items.sort((a, b) => {
          const vendorA =
            (direction === 1
              ? a?.vendor?.displayName
              : b?.vendor?.displayName) ??
            client?.name ??
            "";
          const vendorB =
            (direction === 1
              ? b?.vendor?.displayName
              : a?.vendor?.displayName) ??
            client?.name ??
            "";
          return stringCompare(vendorA, vendorB);
        });
      } else if (sortBy.endsWith("item_account")) {
        items.sort((a, b) => {
          const jobCostMethodA =
            (direction === 1
              ? a?.jobCostMethod?.displayName
              : b?.jobCostMethod?.displayName) ?? "";
          const jobCostMethodB =
            (direction === 1
              ? b?.jobCostMethod?.displayName
              : a?.jobCostMethod?.displayName) ?? "";
          return stringCompare(jobCostMethodA, jobCostMethodB);
        });
      } else if (sortBy.endsWith("description")) {
        items.sort((a, b) => {
          const descriptionA =
            (direction === 1 ? a?.description : b?.description) ?? "";
          const descriptionB =
            (direction === 1 ? b?.description : a?.description) ?? "";
          return stringCompare(descriptionA, descriptionB);
        });
      }

      previousLinesDataRef.current = items; // eslint-disable-line
    } else {
      // eslint-disable-next-line
      items.sort((a, b) => {
        const indexA = previousLinesDataRef.current!.findIndex(
          (line) => line.id === a.id
        );

        const indexB = previousLinesDataRef.current!.findIndex(
          (line) => line.id === b.id
        );

        return indexA === -1 || indexB === -1 ? 0 : indexA - indexB;
      });
    }

    return items;
  }, [linesData, sortBy, client]);

  const curriedOnDeleteTransaction =
    useCallback<CurriedOnDeleteTransactionHandler>(
      (transaction) => () => {
        const handler = () => {
          updateInvoiceLines(
            invoiceLines.filter((line) => {
              return line.id !== transaction.id;
            })
          );

          toast.success(
            `Line removed from the ${INVOICE_STRINGS.LOWERCASE_INVOICE}`
          );
        };

        const isEmpty =
          !transaction.jobCostMethod &&
          !transaction.amount &&
          !transaction.description;

        if (isEmpty) return handler();

        dialog.confirmation({
          title: `Remove line from ${INVOICE_STRINGS.LOWERCASE_INVOICE}?`,
          action: {
            primary: {
              color: "error",
              onClick: () => handler(),
              children: "Remove",
            },
          },
        });
      },
      [invoiceLines, updateInvoiceLines]
    );

  const curriedOnDeleteMarkup = useCallback<CurriedOnDeleteMarkupHandler>(
    (markup) => () => {
      const handler = async () => {
        updateInvoiceMarkups(
          invoiceMarkups.filter(({ id }) => id !== markup.id)
        );

        toast.success(
          `Markup removed from the ${INVOICE_STRINGS.LOWERCASE_INVOICE}`
        );
      };

      dialog.confirmation({
        title: `Remove markup from ${INVOICE_STRINGS.LOWERCASE_INVOICE}?`,
        action: {
          primary: {
            color: "error",
            onClick: handler,
            children: "Remove",
          },
        },
      });
    },
    [invoiceMarkups, updateInvoiceMarkups]
  );

  const curriedOnChangeLine = useCallback<CurriedOnChangeLineHandler>(
    ({ line, field }) =>
      (value, option) => {
        if (
          ["costCodeAccount", "vendor"].some(
            (fieldName) => fieldName === field
          ) &&
          !value
        ) {
          return;
        }

        if (!staticInvoiceLines.current) {
          staticInvoiceLines.current = invoiceLines;
        }

        const nextLines = invoiceLinesWithoutTransactionLineUrl.map(
          (invoiceLine) => {
            if (invoiceLine.id === line.id) {
              if (field === "costCodeAccount" && option) {
                return {
                  ...invoiceLine,
                  jobCostMethod: {
                    id: Number(parseRefinementIdFromUrl(option.value)),
                    url: option.value,
                    parent: option.parent,
                    displayName: option.label,
                  },
                };
              } else if (field === "description") {
                return { ...invoiceLine, description: String(value) };
              } else if (field === "amount") {
                return { ...invoiceLine, amount: Number(value) };
              } else if (field === "totalAmount") {
                return { ...invoiceLine, totalAmount: Number(value) };
              } else if (field === "vendor" && option) {
                return {
                  ...invoiceLine,
                  vendor: {
                    id:
                      option.value === DEFAULT_VENDOR_INVOICE_OPTION_VALUE
                        ? ""
                        : `${parseRefinementIdFromUrl(option.value)}`,
                    url: option.value,
                    displayName: option.label,
                  },
                };
              }
            }

            return invoiceLine;
          }
        );

        let nextMarkups: Markup[] | undefined;

        const hasChangedTotalAmount =
          field === "totalAmount" && !line.isExtra && value !== line.amount;

        if (window.BUDGET_INLINE_MARKUP_ENABLED && hasChangedTotalAmount) {
          nextMarkups = invoiceMarkups.map((markup) => {
            if (!markup.lines.includes(line.id)) return markup;

            return {
              ...markup,
              lines: markup.lines.filter((id) => id !== line.id),
            };
          });

          if (value !== line.amount) {
            nextMarkups.push({
              id: "",
              item: line.item,
              itemAccount: line.itemAccount,
              jobCostMethod: line.jobCostMethod,
              lines: [line.id],
              amount: sum(value, -line.amount),
              percent: 0,
              description: "",
              isInlineMarkup: true,
            });
          }
        }

        const hasChangedLines = !isEqual(
          invoiceLinesWithoutTransactionLineUrl,
          nextLines
        );

        const hasChangedMarkups =
          nextMarkups && !isEqual(invoiceMarkups, nextMarkups);

        if (hasChangedLines) updateInvoiceLines(nextLines);

        if (hasChangedMarkups) updateInvoiceMarkups(nextMarkups!);

        if (hasChangedLines || hasChangedMarkups) {
          toast.success(
            `Line updated for ${INVOICE_STRINGS.LOWERCASE_INVOICE}`
          );
        }
      },
    [
      invoiceLines,
      invoiceLinesWithoutTransactionLineUrl,
      invoiceMarkups,
      updateInvoiceLines,
      updateInvoiceMarkups,
    ]
  );

  const curriedOnEditMarkup = useCallback<CurriedOnEditMarkupHandler>(
    (markup) => () => {
      setCurrentMarkup(markup);

      if (markup.lines.length > 0) {
        markupEditSeparatePercentDialog.show();
      } else {
        markupEditFixedAmountDialog.show();
      }
    },
    [markupEditFixedAmountDialog, markupEditSeparatePercentDialog]
  );

  const curriedOnChangeMarkup = useCallback<CurriedOnChangeMarkupHandler>(
    ({ field, markup }) =>
      (value, option) => {
        const nextMarkups = invoiceMarkups.map((invoiceMarkup) => {
          if (invoiceMarkup.id === markup.id) {
            if (field === "costCodeAccount" && option) {
              return {
                ...invoiceMarkup,
                jobCostMethod: {
                  id: Number(parseRefinementIdFromUrl(option.value)),
                  url: option.value,
                  parent: option.parent,
                  displayName: option.label,
                },
              };
            } else if (field === "description") {
              return { ...invoiceMarkup, description: String(value) };
            } else if (field === "amount") {
              return { ...invoiceMarkup, amount: Number(value) };
            } else if (field === "vendor" && option) {
              return {
                ...invoiceMarkup,
                vendor: {
                  id:
                    option.value === DEFAULT_VENDOR_INVOICE_OPTION_VALUE
                      ? ""
                      : `${parseRefinementIdFromUrl(option.value)}`,
                  url: option.value,
                  displayName: option.label,
                },
              };
            }
          }

          return invoiceMarkup;
        });

        if (isEqual(invoiceMarkups, nextMarkups)) return;

        updateInvoiceMarkups(nextMarkups);

        toast.success(
          `Markup updated for ${INVOICE_STRINGS.LOWERCASE_INVOICE}`
        );
      },
    [invoiceMarkups, updateInvoiceMarkups]
  );

  const hasLines = enhancedLinesData.length > 0;

  const hasMarkups = markupsData.length > 0;

  const lineRow = useMemo<TableRowAddon<Line>>(
    () => ({
      isLoading: (row) => !row.id,
      onRender: () => setIsLinesRendered(true),
    }),
    []
  );

  const lineHeader = useMemo<TableHeaderAddon>(
    () => ({ hide: !hasLines && !hasMarkups, sticky: { offset: 80 } }),
    [hasLines, hasMarkups]
  );

  const lineFooter = useMemo<TableFooterAddon>(
    () => ({ hide: !hasLines && !hasMarkups }),
    [hasLines, hasMarkups]
  );

  const lineColumns = useMemo(() => getLineColumns(mode), [mode]);

  const markupColumns = useMemo(() => getMarkupColumns(mode), [mode]);

  const lineEmptyState = useMemo<TableEmptyState>(
    () =>
      hasMarkups && !hasLines
        ? false
        : {
            title: `You don't have any transactions for this ${INVOICE_STRINGS.LOWERCASE_INVOICE}`,
            action: (
              <Flex width="full" gap="md">
                <InvoiceAddLineButton block size="md" variant="solid" />
                <InvoiceAddEmptyLineButton block size="md" variant="solid" />
              </Flex>
            ),
          },
    [hasLines, hasMarkups]
  );

  const lineSort = useMemo<TableSortAddon>(
    () => ({
      value: sortBy,
      onChange: (value) => {
        previousLinesDataRef.current = undefined;
        setSortBy(value);
      },
    }),
    [sortBy, setSortBy]
  );

  const select = useMemo<TableSelectAddon<Line>>(
    () => ({
      value: invoiceLinesSelected,
      onChange: setInvoiceLinesSelected,
    }),
    [invoiceLinesSelected, setInvoiceLinesSelected]
  );

  const data = useMemo<MultipleTableProps<[Line, Markup]>["data"]>(() => {
    const hasMarkups = markupsData.length;

    return [
      {
        row: lineRow,
        data: enhancedLinesData,
        sort: lineSort,
        header: lineHeader,
        footer: lineFooter,
        loading: invoiceLinesIsLoading || invoiceMarkupsIsLoading,
        columns: lineColumns,
        emptyState: lineEmptyState,
        select: select,
      },
      {
        data: markupsData,
        hide: !hasMarkups || invoiceLinesIsLoading,
        columns: markupColumns,
        footer: { hide: true },
      },
    ];
  }, [
    select,
    lineRow,
    lineSort,
    lineHeader,
    lineFooter,
    lineColumns,
    markupsData,
    markupColumns,
    lineEmptyState,
    enhancedLinesData,
    invoiceLinesIsLoading,
    invoiceMarkupsIsLoading,
  ]);

  const footer = useMemo<MultipleTableFooter>(
    () => ({
      data: markupsData.length
        ? [
            [
              {
                id: "doc_number",
                render: <TransactionLineMarkupsFooter />,
              },
              ...(window.VENDOR_FOR_INVOICE_LINES_ENABLED
                ? [
                    { id: "doc_number", render: "__SPAN__" },
                    { id: "item_account", render: "__SPAN__" },
                  ]
                : [{ id: "doc_number", render: "__SPAN__" }]),
              {
                id: "description",
                render: "__SPAN__",
              },
              { id: "cost", render: <CostMarkupFooter /> },
              {
                id: "amount",
                render: <PriceMarkupFooter />,
              },
              ...(mode === "edit" ? [{ id: "actions", render: "" }] : []),
            ],
            [
              {
                id: "doc_number",
                render: (
                  <Flex justify="flex-end" width="full">
                    <Text weight="bold" align="right">
                      Grand totals
                    </Text>
                  </Flex>
                ),
              },
              ...(window.VENDOR_FOR_INVOICE_LINES_ENABLED
                ? [
                    { id: "doc_number", render: "__SPAN__" },
                    { id: "item_account", render: "__SPAN__" },
                  ]
                : [{ id: "doc_number", render: "__SPAN__" }]),
              {
                id: "description",
                render: "__SPAN__",
              },
              { id: "cost", render: <CostGrandFooter /> },
              {
                id: "amount",
                render: <PriceGrandFooter />,
              },
              ...(mode === "edit" ? [{ id: "actions", render: "" }] : []),
            ],
          ]
        : [],
      offset: -64,
    }),
    [mode, markupsData.length]
  );

  const header = useMemo<MultipleTableHeader>(
    () => ({ data: [], offset: 80 }),
    []
  );

  const focusIndex = enhancedLinesData.findIndex((line) => line?.id == focus);

  useLayoutEffect(() => {
    if (focusIndex !== -1 && isLinesRendered && multipleTableRef.current) {
      requestAnimationFrame(() => {
        multipleTableRef.current?.tables?.[0]?.scrollToIndex?.(focusIndex);
      });
    }
  }, [focusIndex, isLinesRendered]);

  return (
    <InvoiceCostTableContext.Provider
      value={{
        hasMarkups,
        curriedOnChangeLine,
        curriedOnChangeMarkup,
        curriedOnDeleteMarkup,
        curriedOnDeleteTransaction,
        curriedOnEditMarkup,
      }}
    >
      <MultipleTable
        ref={multipleTableRef}
        size="sm"
        data={data}
        header={header}
        footer={hasLines || hasMarkups ? footer : undefined}
        data-testid="invoice-cost-table"
      />
      {markupEditFixedAmountDialog.isRendered && (
        <InvoiceEditFixedMarkupDialog
          dialog={markupEditFixedAmountDialog}
          markup={currentMarkup}
        />
      )}

      {markupEditSeparatePercentDialog.isRendered && (
        <InvoiceEditSeparatePercentMarkupDialog
          dialog={markupEditSeparatePercentDialog}
          markup={currentMarkup}
        />
      )}
    </InvoiceCostTableContext.Provider>
  );
});

InvoiceCostTable.displayName = "InvoiceCostTable";
