import React, { Fragment, type ReactElement, useMemo } from "react";
import { Flex, Tag, Text } from "@adaptive/design-system";
import { formatCurrency, suffixify } from "@adaptive/design-system/utils";
import { WorkflowApprovers } from "@components/workflow-approvers";
import type {
  Workflow,
  WorkflowItem,
  WorkflowVendor,
} from "@shared/api/workflows";
import { useWorkflowInfo } from "@shared/store/workflow";

import { READABLE_TYPE } from "./utils";

type Props = Workflow;

const getJobsTitles = (workflow: Workflow) => {
  const jobs = workflow.customers;
  const tags = jobs.reduce((titles, job) => {
    return [...titles, <Tag key={`job-${job.id}`}>{job.displayName}</Tag>];
  }, [] as ReactElement[]);
  if (workflow.customersGroupUnderParent) {
    tags.push(<span key="child-title">or its child-jobs</span>);
  }
  return tags;
};

const getCostCodesTitles = (costCodes: WorkflowItem[]) =>
  costCodes.reduce(
    (titles, costCode) => [
      ...titles,
      <Tag key={`cost-code-${costCode.id}`}>{costCode.displayName}</Tag>,
    ],
    [] as ReactElement[]
  );

const getAccountTitles = (accounts: WorkflowItem[]) =>
  accounts.reduce(
    (titles, account) => [
      ...titles,
      <Tag key={`account-${account.id}`}>{account.displayName}</Tag>,
    ],
    [] as ReactElement[]
  );

const getVendorTitles = (vendors: WorkflowVendor[]) =>
  vendors.reduce(
    (titles, vendor) => [
      ...titles,
      <Tag key={suffixify("vendor", vendor.id)}>{vendor.displayName}</Tag>,
    ],
    [] as ReactElement[]
  );

export const WorkflowTitle = (workflow: Props) => {
  const {
    hasAmountCondition,
    hasCostCodeCondition,
    hasJobCondition,
    hasVendorCondition,
    hasAccountCondition,
  } = useWorkflowInfo();

  const workflowConditions = useMemo(() => {
    const tags = [];

    if (hasAmountCondition(workflow)) {
      if (!!workflow.amountFrom && !!workflow.amountTo) {
        tags.push(
          `the amount is between $${formatCurrency(
            workflow.amountFrom
          )} - $${formatCurrency(workflow.amountTo)}`
        );
      } else if (workflow.amountFrom) {
        tags.push(
          `the amount is greater than $${formatCurrency(workflow.amountFrom)}`
        );
      } else if (workflow.amountTo) {
        tags.push(
          `the amount is lower than $${formatCurrency(workflow.amountTo)}`
        );
      }
    }

    if (hasJobCondition(workflow)) {
      if (tags.length) tags.push(" and ");
      if (
        workflow.customerRequirementType &&
        ["ONE_OF", "NONE_OF"].includes(workflow.customerRequirementType)
      ) {
        const jobConditionText =
          workflow.customerRequirementType === "ONE_OF" ? "one of" : "none of";
        const jobText =
          workflow.customers.length > 1
            ? `the job is ${jobConditionText}`
            : `the job is ${workflow.customerRequirementType === "ONE_OF" ? "" : "not "}`;

        tags.push(jobText);
      }
      tags.push(...getJobsTitles(workflow));
    }

    if (hasCostCodeCondition(workflow)) {
      if (tags.length) tags.push(" and ");
      tags.push(
        workflow.items.length > 1
          ? "the cost code is one of"
          : "the cost code is"
      );
      tags.push(...getCostCodesTitles(workflow.items));
    }

    if (hasAccountCondition(workflow)) {
      if (tags.length) tags.push(" and ");
      tags.push(
        workflow.accounts.length > 1
          ? "the account is one of"
          : "the account is"
      );
      tags.push(...getAccountTitles(workflow.accounts));
    }

    if (hasVendorCondition(workflow)) {
      if (tags.length) tags.push(" and ");
      tags.push(
        workflow.vendors.length > 1 ? "the vendor is one of" : "the vendor is"
      );
      tags.push(...getVendorTitles(workflow.vendors));
    }

    if (workflow.customerRequirementType === "ANY_JOB") {
      if (tags.length) tags.push(" and ");
      tags.push("there is at least one job");
    }

    if (workflow.customerRequirementType === "NO_JOB") {
      if (tags.length) tags.push(" and ");
      tags.push("there is no job");
    }

    return tags;
  }, [
    hasAmountCondition,
    hasCostCodeCondition,
    hasJobCondition,
    hasVendorCondition,
    hasAccountCondition,
    workflow,
  ]);

  return (
    <Flex gap="sm" wrap align="center" as={Text} size="md" weight="bold">
      {workflow.isDefault ? (
        "If the transaction does not meet the conditions from any approval workflows"
      ) : (
        <>If {workflowConditions}</>
      )}
    </Flex>
  );
};

const WorkflowColumnTitle = ({ title, isDefault }: Workflow) => {
  let renderedTitle = title;

  if (isDefault) {
    renderedTitle = "Fallback Workflow";
  }

  if (renderedTitle) {
    return (
      <Text size="md" weight="bold">
        {renderedTitle}
      </Text>
    );
  }

  return null;
};

export const WorkflowCard = (workflow: Props) => {
  return (
    <Flex gap="md" direction="column" data-testid="workflow-card">
      <WorkflowColumnTitle {...workflow} />
      <WorkflowTitle {...workflow} />
      <WorkflowApprovers steps={workflow.steps} />
      <div>
        Applies to:{" "}
        {workflow.types
          .filter((type) => type in READABLE_TYPE)
          .map((type, i) => (
            <Fragment key={type}>
              {i !== 0 ? " and " : ""}
              <Tag color="warning">
                {READABLE_TYPE[type as keyof typeof READABLE_TYPE]}
              </Tag>
            </Fragment>
          ))}
      </div>
    </Flex>
  );
};
