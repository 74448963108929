import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Switch } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { QuickBooksSyncedIcon } from "@components/quickbooks-icon";
import { recordPurchaseOrderUpdate } from "@store/purchaseOrderSlice";
import { useClientSettings } from "@store/user";

import { PurchaseOrderIcon } from "./purchase-order-icon";
import { selectPurchaseOrder } from "./purchase-orders-selectors";

export const PurchaseOrderFormHeader = ({ status, permissions }) => {
  const dispatch = useDispatch();

  const settings = useClientSettings();

  const purchaseOrder = useSelector(selectPurchaseOrder);

  const onSwitch = useEvent((value) => {
    const payload = { po_status: value ? "Open" : "Closed" };
    if (payload.po_status === "Open") {
      payload.lines = purchaseOrder.lines;
    } else {
      payload.lines = purchaseOrder.lines.map((line) => ({
        ...line,
        status: "Closed",
      }));
    }

    dispatch(recordPurchaseOrderUpdate(payload));
  });

  return (
    <>
      <Flex gap="xl" justify="space-between" align="center">
        <Flex gap="lg" justify="flex-start" align="center">
          {status === "pending" && "Loading..."}
          {status === "fulfilled" && (
            <>
              {!purchaseOrder.id ? "New" : ""} Purchase order{" "}
              {purchaseOrder.id ? `#${purchaseOrder.doc_number}` : ""}
              {settings.adaptivePosEnabled ? (
                <PurchaseOrderIcon
                  size={32}
                  type={purchaseOrder.type}
                  labelled={false}
                />
              ) : purchaseOrder.published_to_quickbooks ? (
                <QuickBooksSyncedIcon size={32} />
              ) : null}
            </>
          )}
          {status === "rejected" && "Purchase order not found"}
        </Flex>
        {status === "fulfilled" && (
          <Switch
            id="po-status"
            label={purchaseOrder.po_status === "Open" ? "Opened" : "Closed"}
            checked={
              purchaseOrder.po_status
                ? purchaseOrder.po_status === "Open"
                : true
            }
            disabled={
              !permissions.canEdit ||
              permissions.isQbPo ||
              permissions.isBuilderTrendPo
            }
            onChange={onSwitch}
            placement="right"
            data-testid="po-status"
          />
        )}
      </Flex>
    </>
  );
};
