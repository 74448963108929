import React, { forwardRef } from "react";
import { Tooltip } from "@adaptive/design-system";

import BuilderTrendSvg from "./bt.svg?react";

type BuilderTrendIconProps = {
  size?: number;
};

export const BuilderTrendIcon = forwardRef<
  SVGSVGElement,
  BuilderTrendIconProps
>(({ size = 24, ...props }, ref) => (
  <BuilderTrendSvg
    ref={ref}
    role="img"
    width={size}
    height={size}
    style={{ verticalAlign: "middle" }}
    aria-label="QuickBooks"
    {...props}
  />
));

BuilderTrendIcon.displayName = "BuilderTrendIcon";

export const BuilderTrendSyncedIcon = forwardRef<
  SVGSVGElement,
  BuilderTrendIconProps
>(({ size = 24 }, ref) => (
  <Tooltip
    as={BuilderTrendIcon}
    ref={ref}
    size={size}
    message="Synced with BuilderTrend"
  />
));

BuilderTrendSyncedIcon.displayName = "BuilderTrendSyncedIcon";
