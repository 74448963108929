import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router";
import {
  Button,
  ButtonGroup,
  Dialog,
  dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Link,
  Loader,
  ResponsiveProvider,
  Text,
  toast,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  useVisibilityChange,
} from "@adaptive/design-system/hooks";
import { useLazyCheckBillDuplicationQuery } from "@api/bills";
import { getNonFieldErrors, handleErrors } from "@api/handle-errors";
import { RecordPreview } from "@components/attachment";
import { DropZone } from "@components/draggable/draggable";
import {
  Main,
  MainContent,
  MainHeader,
  MainHeaderBack,
  MainTitle,
} from "@components/main";
import { NotFound } from "@components/not-found";
import { StageNavigation } from "@components/stage-navigation";
import {
  MIN_LEFT_SIZE,
  MIN_RIGHT_SIZE,
  TransactionSplitView,
} from "@components/transaction-split-view";
import { api } from "@store/api-simplified";
import {
  addLine,
  addPredictions,
  autoSplit,
  changeCurrentBillStatus,
  checkBillFormStatus,
  loadBill,
  putBill,
  recordBillUpdate,
  removeAttachable,
  resetBill,
  selectBill,
  setEnterBillManually,
  setShowCreateNewDialog,
  syncBill,
  syncInvoiceChanges,
  uploadAttachable,
} from "@store/billSlice";
import { BasePermissions, useClientSettings, useUserInfo } from "@store/user";
import { usePropagationDialog } from "@transaction-propagation/hooks";
import * as analytics from "@utils/analytics";
import { noop } from "@utils/noop";
import { UNLINK_INVOICE_LINES_OPTION } from "@utils/transaction-confirm-messages";
import axios from "axios";

import { useCurrentClientFromRealm } from "../shared/hooks/useCurrentClientFromRealm";

import { ApproveBillStep } from "./components/approve-bill-step";
import { CycleProvider, useCycle } from "./components/cycle-provider";
import { EditBillStep } from "./components/edit-bill-step";
import { PayBillStep } from "./components/pay-bill-step";
import { BillFormContext } from "./bill-form-context";
import {
  BILL_STATUS,
  DROPZONE_MESSAGES,
  getTransactionType,
  IMAGE_FORMATS,
  LIEN_WAIVER_REQUESTED_STATUS,
  LIEN_WAIVER_SIGNED_STATUS,
} from "./constants";
import {
  approvalsBillSelector,
  billFileSyncStatusSelector,
  billIdSelector,
  billPropagationPayloadSelector,
  camelCaseBillSelector,
  currentStaticReviewStatus,
  formEditedBillSelector,
  isLoadingBillOcr,
  isLoadingBillSelector,
  predictionBillSelector,
  showCreateNewDialogBillSelector,
  staticLinesBillSelector,
  statusBillSelector,
  workflowsBillSelector,
} from "./utils";

/**
 * @todo Change steps to use bill status instead of number
 * in that way we can avoid this map
 */
const STEP_STATUS = {
  1: BILL_STATUS.DRAFT,
  2: BILL_STATUS.APPROVAL,
  3: BILL_STATUS.FOR_PAYMENT,
};

const NAME = {
  all: "All",
  draft: "Draft",
  approval: "For approval",
  "for-payment": "For payment",
};

const ERROR_MAP = {
  "The total amount of a Bill must be greater than zero. If this is a Vendor Credit, change the Transaction Type in the Bill Details section.":
    "vendorCredit",
  "The total amount of a Vendor Credit must be greater than zero. If this is a Bill, change the Transaction Type in the Vendor Credit Details section.":
    "bill",
};

const LEFT_BREAKPOINTS = {
  mobile: 0,
  tablet: MIN_LEFT_SIZE + MIN_RIGHT_SIZE,
};

const RIGHT_BREAKPOINTS = {
  mobile: 0,
  tablet: MIN_RIGHT_SIZE + 50,
};

const useOcrPolling = () => {
  const toastRef = useRef({ update: noop, dismiss: noop });
  const sourceRef = useRef(null);
  const dispatch = useDispatch();

  const id = useSelector(billIdSelector);
  const fileSyncStatus = useSelector(billFileSyncStatusSelector);
  const isPendingOcr = isLoadingBillOcr({ fileSyncStatus });
  const isLoadingBill = useSelector(isLoadingBillSelector);

  useEffect(() => {
    if (!isLoadingBill && isPendingOcr && !sourceRef.current) {
      const CancelToken = axios.CancelToken;
      sourceRef.current = CancelToken.source();

      toastRef.current = toast.loading(
        "Our AI is still working! Please wait a few seconds for it to finish"
      );

      dispatch(syncBill(id, true, sourceRef.current)).then(() => {
        toastRef.current.update("Adaptive AI completed successfully!", {
          type: "success",
        });
        sourceRef.current = null;
      });
    }

    return () => {
      if (sourceRef.current) {
        sourceRef.current.cancel("Operation canceled by the user.");
        toastRef.current.dismiss();
        sourceRef.current = null;
      }
    };
  }, [dispatch, id, isLoadingBill, isPendingOcr]);
};

const RawBillForm = memo(() => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const prediction = useSelector(predictionBillSelector);

  const headerRef = useRef();

  const stageNavigationRef = useRef();

  const responsivePlaceholderRef = useRef();

  const { user, role, hasPermission } = useUserInfo();

  const { state: locationState } = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const [triggerCheckBillDuplication] = useLazyCheckBillDuplicationQuery();

  const [searchParams, setSearchParams] = useSearchParams();

  const { aiAutoSplitEnabled } = useClientSettings();

  const { enable: enableCycle, ...cycle } = useCycle();

  const {
    hide: hidePredictDialog,
    show: showPredictDialog,
    ...predictDialog
  } = useDialog();

  const {
    id: billId,
    realmUrl,
    vendorId,
    docNumber,
    isCreator,
    isArchivedByUser,
    canBeEdited,
    reviewStatus,
    fileSyncStatus,
    isVendorCredit,
    hasAttachables,
    initialReviewStatus,
    linkedInvoices,
    lienWaivers,
    url: billUrl,
  } = useSelector(camelCaseBillSelector);

  const staticBillStatus = useSelector(currentStaticReviewStatus);
  const billStatus = useSelector(statusBillSelector);

  const isPendingOcr = isLoadingBillOcr({ fileSyncStatus });

  const billFormEdited = useSelector(formEditedBillSelector);

  const billShowCreateNewDialog = useSelector(showCreateNewDialogBillSelector);

  const billPropagationPayload = useSelector(billPropagationPayloadSelector);

  const staticBillLines = useSelector(staticLinesBillSelector);

  const {
    hide: hideCreateNewBillDialog,
    show: showCreateNewBillDialog,
    ...createNewBillDialog
  } = useDialog(billShowCreateNewDialog);

  const approvals = useSelector(approvalsBillSelector);

  const approvalsWorkflows = useSelector(workflowsBillSelector);

  useCurrentClientFromRealm(realmUrl);

  const billIsNew = id === "create";

  const checkDuplication = useEvent(async ({ vendorId, docNumber }) => {
    let duplicate = [];

    if (vendorId && docNumber) {
      const { data } = await triggerCheckBillDuplication({
        vendorId,
        docNumber,
      });

      if (data?.duplicate) {
        duplicate = data?.duplicate;
      }
    }

    dispatch(recordBillUpdate({ duplicate }));
  });

  const permissions = useMemo(() => {
    const isUserOnApproveChain = approvalsWorkflows.some((workflow) =>
      workflow.steps.some((step) =>
        step.approvers.some((approver) =>
          (approver.id === approver.type) === "role" ? role.id : user.id
        )
      )
    );

    const canAddBill = hasPermission(BasePermissions.ADD_BILL);
    const canAddPurchaseOrder =
      hasPermission(BasePermissions.ADD_PO) ||
      hasPermission(BasePermissions.MANAGE_POS);

    return {
      canPayBill: hasPermission(BasePermissions.PAY_BILLS),
      canAddBill,
      canEditBill:
        (canAddBill && isCreator) ||
        isUserOnApproveChain ||
        hasPermission(BasePermissions.EDIT_ALL_BILLS),
      canApproveBill: hasPermission(BasePermissions.APPROVE_BILLS),
      canAddPurchaseOrder,
      canViewPurchaseOrder: hasPermission(BasePermissions.VIEW_ALL_POS),
      canManagePurchaseOrder: hasPermission(BasePermissions.MANAGE_POS),
    };
  }, [approvalsWorkflows, hasPermission, isCreator, user.id, role.id]);

  const transactionType = useMemo(
    () => getTransactionType(isVendorCredit),
    [isVendorCredit]
  );

  const currentStep = useMemo(() => {
    if (!billIsNew && !billId) return -1;

    if (reviewStatus === BILL_STATUS.DRAFT) {
      return 1;
    } else if (reviewStatus === BILL_STATUS.APPROVAL) {
      return 2;
    } else if (
      [
        BILL_STATUS.FOR_PAYMENT,
        BILL_STATUS.PARTIALLY_PAID,
        BILL_STATUS.PAID,
        BILL_STATUS.ACH_PROCESSING,
        BILL_STATUS.ACH_INFO_REQUESTED,
        BILL_STATUS.PAYMENT_SCHEDULED,
        BILL_STATUS.PAYMENT_FAILED,
      ].includes(reviewStatus)
    ) {
      return 3;
    }

    return -1;
  }, [billIsNew, billId, reviewStatus]);

  /**
   * We disable lint rule here because we only need to update this value
   * when the initial status changes.
   */
  const initialStep = useMemo(() => currentStep, [initialReviewStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = useEvent(() => navigate(locationState?.prev || "/bills"));

  const saveCallback = useCallback(
    (
      propagation,
      {
        status,
        onSuccess,
        unlinkInvoiceLinesOption = UNLINK_INVOICE_LINES_OPTION.SKIP,
      } = {}
    ) => {
      const { propagate, toastMessage } = propagation || {};

      const convertBill = (isVendorCredit) => () => {
        dispatch(recordBillUpdate({ is_vendor_credit: isVendorCredit }));
        saveCallback({ status: BILL_STATUS.DRAFT });
      };

      const handler = async (syncInvoiceLines = false) => {
        setIsLoading(true);

        try {
          const data = await dispatch(
            putBill({
              status,
              syncInvoiceLines,
              unlinkInvoiceLinesOption,
              propagate,
            })
          );

          if (data.review_status === BILL_STATUS.FOR_PAYMENT) {
            dispatch(api.util.invalidateTags(["CostCodesAccountsSimplified"]));
          }

          const message = `${transactionType} ${
            data.doc_number ? `#${data.doc_number} ` : ""
          }${billId ? "updated" : "created"}`;

          setIsLoading(false);

          if (onSuccess) {
            onSuccess(data);
          } else {
            if (toastMessage) {
              toast.success(toastMessage);
            }
            toast.success(message);
            if (data.created || billIsNew) {
              analytics.track("billCreate", { billId: data.id });
              navigate(`/bills/${data.id}`, {
                state: locationState,
                replace: true,
              });
            } else {
              analytics.track("billUpdate", { billId: data.id });
              dispatch(loadBill(data.id));
            }
          }

          return data;
        } catch (e) {
          handleErrors(e);

          const nonFieldError = getNonFieldErrors(e)?.[0] ?? undefined;
          const convertTo = ERROR_MAP[nonFieldError];
          if (convertTo === "bill") {
            toast.info(
              <Link as="button" onClick={convertBill(false)}>
                Convert this vendor credit to a bill
              </Link>
            );
          } else if (convertTo === "vendorCredit") {
            toast.info(
              <Link as="button" onClick={convertBill(true)}>
                Convert this bill to a vendor credit
              </Link>
            );
          }

          throw e;
        } finally {
          setIsLoading(false);
        }
      };
      if (
        status == "DRAFT" &&
        lienWaivers.some((lw) =>
          LIEN_WAIVER_REQUESTED_STATUS.includes(lw.status.toUpperCase())
        )
      ) {
        dialog.confirmation({
          title: "You have a lien waiver request",
          message:
            "To revert the bill to draft, you need to cancel it first. Do you to cancel it?",
          action: {
            primary: {
              onClick: handler,
            },
          },
        });
      } else {
        return handler();
      }
    },
    [
      lienWaivers,
      dispatch,
      transactionType,
      billId,
      billIsNew,
      navigate,
      locationState,
    ]
  );

  const { propagationDialog, saveWithPropagation } =
    usePropagationDialog(saveCallback);

  const save = useCallback(
    ({ status, onSuccess, propagationPayload } = {}) => {
      const args = {
        status,
        onSuccess,
        unlinkInvoiceLinesOption: UNLINK_INVOICE_LINES_OPTION.SKIP,
      };

      saveWithPropagation(propagationPayload ?? billPropagationPayload, args);
    },
    [billPropagationPayload, saveWithPropagation]
  );

  const onDrop = useEvent(async (files) => {
    setIsLoading(true);

    try {
      await Promise.all(
        files.map((file, index) =>
          dispatch(
            uploadAttachable(
              { file, index },
              {
                onUploadSave: ["parent"],
                isForm: true,
              }
            )
          )
        )
      );
    } catch (e) {
      handleErrors(e);
    } finally {
      setIsLoading(false);
    }
  });

  const isApprover = useMemo(
    () =>
      approvals.some((approval) => approval.approvedBy.id === user.id) ||
      permissions.canApproveBill,
    [user, approvals, permissions.canApproveBill]
  );

  const getStepStatus = useCallback(
    (step) =>
      currentStep === step
        ? "selected"
        : currentStep > step
          ? "done"
          : "pending",
    [currentStep]
  );

  const getStepDisabledMessage = useCallback(
    (step) => {
      if (getStepStatus(step) !== "done") return undefined;

      if (isArchivedByUser) {
        return `An archived ${transactionType.toLowerCase()} cannot be moved back to this stage`;
      }

      if (!isApprover) {
        return `You don't have permission to move this ${transactionType.toLowerCase()} back to this stage`;
      }

      if (!canBeEdited) {
        if (
          [
            BILL_STATUS.PARTIALLY_PAID,
            BILL_STATUS.PAID,
            BILL_STATUS.ACH_PROCESSING,
            BILL_STATUS.ACH_INFO_REQUESTED,
            BILL_STATUS.PAYMENT_SCHEDULED,
            BILL_STATUS.PAYMENT_FAILED,
          ].includes(reviewStatus)
        ) {
          return `Paid ${transactionType.toLowerCase()} cannot be moved back to this stage`;
        }

        return `You don't have permission to move this ${transactionType.toLowerCase()} back to this stage`;
      }

      if (
        lienWaivers.some((lw) =>
          LIEN_WAIVER_SIGNED_STATUS.includes(lw.status.toUpperCase())
        )
      ) {
        return `You can't move this ${transactionType.toLowerCase()} back to this stage because a lien waiver has been signed`;
      }
      return undefined;
    },
    [
      canBeEdited,
      getStepStatus,
      isApprover,
      isArchivedByUser,
      lienWaivers,
      reviewStatus,
      transactionType,
    ]
  );

  const onStageChange = useEvent(async (stage) => {
    const newStatus = STEP_STATUS[stage];
    const updateBillStatus = async () => {
      setIsLoading(true);
      cycle.disable();
      await dispatch(changeCurrentBillStatus(newStatus));
      setIsLoading(false);
    };

    if (
      linkedInvoices.length &&
      staticBillStatus === BILL_STATUS.FOR_PAYMENT &&
      newStatus !== BILL_STATUS.FOR_PAYMENT
    ) {
      save({
        status: newStatus,
        onSuccess: () => dispatch(loadBill(id)),
        propagationPayload: {
          ...billPropagationPayload,
          lines: staticBillLines.map((line) => ({
            id: line.id,
            isDeleted: true,
          })),
          values: {
            name: docNumber,
            url: billUrl,
            fields: {
              reverted: true,
            },
            lines: staticBillLines.map((line) => ({
              id: line.id,
              parent: line.parent.id,
              order: line.order,
              amount: line.amount,
              fields: {
                account: line.account?.url,
                item: line.item?.url,
              },
            })),
          },
        },
      });
    } else {
      updateBillStatus();
    }
  });

  const renderTitle = useCallback(() => {
    if (billIsNew) return `Create ${transactionType.toLowerCase()}`;

    if (!cycle.status) {
      return (
        <div style={{ display: "grid" }}>
          <Text as="span" size="2xl" weight="bold" truncate>
            {transactionType} {docNumber ? `#${docNumber}` : ""}
          </Text>
        </div>
      );
    }

    return cycle.isLoading ? null : (
      <Flex align="center" grow justify="space-between" gap="md">
        <Flex gap="md" align="baseline" wrap>
          {NAME[cycle.status]}
          <Text weight="regular" size="xl" as="span">
            ({cycle.current} of {cycle.total})
          </Text>
        </Flex>
        <ButtonGroup
          size="sm"
          color="neutral"
          variant="ghost"
          direction="vertical"
        >
          <Button
            onClick={() => cycle.previous()}
            disabled={!cycle.hasNavigation}
            aria-label={`Go to previous ${transactionType.toLowerCase()}`}
          >
            <Icon variant="solid" name="chevron-up" />
          </Button>
          <Button
            onClick={() => cycle.next()}
            disabled={!cycle.hasNavigation}
            aria-label={`Go to next ${transactionType.toLowerCase()}`}
          >
            <Icon variant="solid" name="chevron-down" />
          </Button>
        </ButtonGroup>
      </Flex>
    );
  }, [docNumber, billIsNew, cycle, transactionType]);

  const stages = useMemo(
    () => [
      {
        name: 1,
        title: "Create",
        disabled: getStepDisabledMessage(1),
        subtitle: `Add the details of the ${transactionType.toLowerCase()}`,
      },
      {
        name: 2,
        title: "Review",
        disabled: getStepDisabledMessage(2),
        subtitle: `Approve the ${transactionType.toLowerCase()} for payment`,
      },
      {
        name: 3,
        title: isVendorCredit ? "Apply" : "Pay",
        subtitle: isVendorCredit ? "Use vendor credit" : "Send the money",
      },
    ],
    [getStepDisabledMessage, transactionType, isVendorCredit]
  );

  const onAttachmentDelete = useEvent(() => {
    dispatch(removeAttachable());
    analytics.track("billAttachmentActions", {
      action: "remove-attachment",
      billId: id,
    });
  });

  const curriedAddPredictions = useCallback(
    (adjust) => () => dispatch(addPredictions(adjust)),
    [dispatch]
  );

  const onResize = useEvent(({ left, right }) => {
    if (!stageNavigationRef.current) return;

    let width = `${right}px`;

    if (left === 0 || right === 0) {
      width = "100%";
    }

    stageNavigationRef.current.style.minWidth = width;
    stageNavigationRef.current.style.maxWidth = width;
  });

  const onAutoSplit = useEvent(() => {
    dispatch(autoSplit());
    analytics.track("billAttachmentActions", {
      action: "auto-split",
      billId: id,
    });
  });

  const contextValue = useMemo(
    () => ({ save, close: onClose, permissions }),
    [onClose, permissions, save]
  );

  useVisibilityChange((isVisible) => {
    if (isVisible && !billIsNew && billId) {
      dispatch(syncInvoiceChanges(billId));
    }
  });

  useOcrPolling();

  useEffect(() => {
    if (id === "create") {
      if (permissions.canAddBill) {
        dispatch(resetBill());
        dispatch(addLine());
      } else {
        onClose();
      }
    } else {
      dispatch(loadBill(id));
    }
  }, [permissions.canAddBill, dispatch, id, onClose]);

  useEffect(() => {
    const status = searchParams.get("status");

    if (status) {
      enableCycle(status);
      setSearchParams({}, { replace: true, state: locationState });
    }
  }, [enableCycle, locationState, searchParams, setSearchParams]);

  useEffect(() => {
    if (hasAttachables) hideCreateNewBillDialog();
  }, [hasAttachables, hideCreateNewBillDialog]);

  useEffect(() => {
    if (prediction.canPredict) {
      dispatch(checkBillFormStatus());
    } else {
      hidePredictDialog();
    }
  }, [dispatch, hidePredictDialog, prediction.canPredict]);

  useEffect(() => {
    if (billFormEdited) showPredictDialog();
  }, [billFormEdited, showPredictDialog]);

  useEffect(() => {
    if (billShowCreateNewDialog) {
      showCreateNewBillDialog();
      dispatch(setShowCreateNewDialog(false));
    }
  }, [dispatch, showCreateNewBillDialog, billShowCreateNewDialog]);

  useEffect(() => {
    if (billIsNew) dispatch(setShowCreateNewDialog(true));
  }, [dispatch, billIsNew]);

  useEffect(() => {
    checkDuplication({ vendorId, docNumber });
  }, [checkDuplication, vendorId, docNumber]);

  return billStatus === "failed" ? (
    <NotFound resource="bills" to={locationState?.prev || "/bills"} />
  ) : (
    <Main>
      <ResponsiveProvider
        ref={headerRef}
        breakpoints={LEFT_BREAKPOINTS}
        initialBreakpoint="tablet"
      >
        <MainHeader variant="unspaced" ref={headerRef}>
          <Flex
            gap="xl"
            height="full"
            direction={{ mobile: "column", tablet: "row" }}
          >
            <Flex
              gap="xl"
              width="full"
              align="center"
              padding={{
                mobile: ["none", "2xl", "none", "none"],
                tablet: "none",
              }}
              borderWidth={{
                mobile: ["none", "none", "sm", "none"],
                tablet: "none",
              }}
              minHeight={{ mobile: "90px", tablet: "auto" }}
              borderColor="neutral-300"
            >
              <MainHeaderBack onClick={onClose} data-testid="back-button" />
              {billStatus !== "loading" && !isLoading && (
                <MainTitle>{renderTitle()}</MainTitle>
              )}
            </Flex>
            <StageNavigation
              ref={stageNavigationRef}
              data={stages}
              value={currentStep}
              onChange={onStageChange}
              initialValue={initialStep}
            />
          </Flex>
        </MainHeader>
      </ResponsiveProvider>
      <MainContent scrollable={false} variant="unspaced">
        <Flex width="full" shrink={false} height="full">
          {(billStatus === "loading" ||
            isLoading ||
            isPendingOcr ||
            cycle.isLoading) && <Loader position="absolute" />}

          <TransactionSplitView
            left={
              <RecordPreview
                onDelete={
                  permissions.canEditBill ? onAttachmentDelete : undefined
                }
                dataSelector={selectBill}
                action={
                  aiAutoSplitEnabled &&
                  permissions.canEditBill &&
                  initialReviewStatus === BILL_STATUS.DRAFT ? (
                    <Flex grow justify="flex-end">
                      <Button
                        size="sm"
                        color="neutral"
                        variant="ghost"
                        onClick={onAutoSplit}
                      >
                        <Icon name="split" />
                        Split document
                      </Button>
                    </Flex>
                  ) : null
                }
              >
                <DropZone
                  concurrentLimit={1}
                  grow
                  height="full"
                  showBorder
                  width="full"
                  onDrop={onDrop}
                  hasPermission={permissions.canEditBill}
                  idleMessage={DROPZONE_MESSAGES.IDLE}
                  imageFormats={IMAGE_FORMATS}
                  pendingMessage={DROPZONE_MESSAGES.PENDING}
                  draggingMessage={DROPZONE_MESSAGES.DRAGGING}
                />
              </RecordPreview>
            }
            right={
              <Flex height="full" width="full">
                <ResponsiveProvider
                  ref={responsivePlaceholderRef}
                  breakpoints={RIGHT_BREAKPOINTS}
                  initialBreakpoint="tablet"
                >
                  <div className="steps-section">
                    <Flex
                      style={{ overflowY: "scroll" }}
                      padding={["none", "5xl"]}
                      direction="column"
                    >
                      <div ref={responsivePlaceholderRef} />
                    </Flex>
                    <BillFormContext.Provider value={contextValue}>
                      {currentStep === 1 && (
                        <>
                          <EditBillStep />

                          <Dialog
                            show={createNewBillDialog.isVisible}
                            modal={false}
                            variant="confirmation"
                          >
                            <DialogHeader>Add a new bill</DialogHeader>
                            <DialogContent>
                              Upload a bill on the left and Adaptive will
                              automatically read the details for you. Or, you
                              can manually enter the bill details.
                            </DialogContent>
                            <DialogFooter>
                              <Button
                                block
                                onClick={() => {
                                  hideCreateNewBillDialog();
                                  dispatch(setEnterBillManually(true));
                                }}
                              >
                                Do it manually
                              </Button>
                            </DialogFooter>
                          </Dialog>

                          <Dialog
                            show={predictDialog.isVisible}
                            modal={false}
                            variant="confirmation"
                          >
                            <DialogHeader>Bill details conflict</DialogHeader>
                            <DialogContent>
                              Some of the fields in the uploaded bill do not
                              match the fields already filled in.
                            </DialogContent>
                            <DialogFooter>
                              <Button
                                block
                                variant="ghost"
                                onClick={curriedAddPredictions(true)}
                              >
                                Automatically adjust
                              </Button>
                              <Button
                                block
                                onClick={curriedAddPredictions(false)}
                              >
                                Keep original
                              </Button>
                            </DialogFooter>
                          </Dialog>
                        </>
                      )}
                      {currentStep === 2 && <ApproveBillStep />}
                      {currentStep === 3 && <PayBillStep />}
                    </BillFormContext.Provider>
                  </div>
                </ResponsiveProvider>
              </Flex>
            }
            onResize={onResize}
          />
        </Flex>
      </MainContent>
      {propagationDialog}
    </Main>
  );
});

RawBillForm.displayName = "RawBillForm";

export const BillForm = memo(() => (
  <CycleProvider>
    <RawBillForm />
  </CycleProvider>
));

BillForm.displayName = "BillForm";
