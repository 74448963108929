import { type Matcher } from "react-day-picker";
import addDays from "date-fns/addDays";

import { is } from "../../utils/is";

import type { CustomModifier, DatePickerRangeValue } from "./date-picker";

type ParsedModifiers = Record<string, Matcher[]>;

type EnhancedCustomModifiers =
  | Date
  | DatePickerRangeValue
  | CustomModifier
  | CustomModifier[];

type ObjectModifiers = { type: string; modifiers?: EnhancedCustomModifiers };

type ParseModifiersParam = (ObjectModifiers | EnhancedCustomModifiers)[];

const enhanceModifiers = (
  modifiers: ParsedModifiers,
  matchers: EnhancedCustomModifiers[]
) => {
  for (const matcher of matchers) {
    if (is.object(matcher)) {
      const from = "from" in matcher ? matcher.from : undefined;
      const to = "to" in matcher ? matcher.to : undefined;

      if (from) modifiers["range_start"].push(from);

      if (to) modifiers["range_end"].push(to);

      if (from && to) {
        const days = [];

        let current = addDays(from, 1);

        while (current < to) {
          days.push(current);
          current = addDays(current, 1);
        }

        modifiers["range_middle"].push(...days);
      }
    }
  }

  return modifiers;
};

export const parseModifiers = (customModifiers: ParseModifiersParam) => {
  let nextModifiers: Record<string, Matcher[]> = {
    range_end: [],
    range_start: [],
    range_middle: [],
  };

  for (const modifier of customModifiers) {
    if (!is.object(modifier)) continue;

    if (!("type" in modifier)) {
      nextModifiers = enhanceModifiers(nextModifiers, [modifier]);
      continue;
    }

    const { type, modifiers } = modifier;

    const enhancedModifiers = is.array(modifiers)
      ? modifiers
      : modifiers
        ? [modifiers]
        : [];

    if (!(type in nextModifiers)) {
      nextModifiers[type] = [];
    }

    for (const modifier of enhancedModifiers) {
      const matchers: Matcher[] = [];

      if (is.object(modifier)) {
        const tempMatcher = (
          "matcher" in modifier ? modifier.matcher : modifier
        ) as Matcher[];
        const matcher = is.array(tempMatcher) ? tempMatcher : [tempMatcher];

        if ("message" in modifier) {
          nextModifiers[`${type}.${modifier.message}`] = matcher;
          matchers.push(...matcher);
        } else {
          matchers.push(...matcher);
        }
      } else {
        matchers.push(modifier);
      }

      nextModifiers[type].push(...matchers);
      nextModifiers = enhanceModifiers(nextModifiers, matchers);
    }
  }

  return nextModifiers;
};
