import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router";

export const useViewBillPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onViewBillPayment = useCallback(
    (billPaymentId: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("billPaymentId", billPaymentId);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    [location.pathname, location.search, navigate]
  );

  return { onViewBillPayment };
};
