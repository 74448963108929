import React, {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogStep,
  Flex,
  toast,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useEvent,
  type UseMultiStepDialogReturn,
} from "@adaptive/design-system/hooks";

import { STRINGS, TYPE_STRINGS } from "../constants/constants";
import { usePropagationContext } from "../hooks/use-propagation-context";
import { type LinkObject, type LinkTransactionType } from "../types";
import { getDetailsFromUrl } from "../utils/get-details-from-url";
import { getFieldsToSkip } from "../utils/get-fields-to-skip";
import { getStepName } from "../utils/get-step-name";
import { trackPropagationAnalytics } from "../utils/track-propagations-analytics";

import { PropagationFinalStep } from "./propagation-final-step";
import { PropagationTable } from "./propagation-table";
import { PropagationTableProvider } from "./propagation-table-provider";

type PropagationStepsModalProps = {
  dialog: UseMultiStepDialogReturn<string>;
  onPropagationComplete?: () => void;
  onClose: () => void;
};

type PropagationStepsModalDialogStep = {
  step: {
    name: string;
    type: string;
    index: number;
    deleted: boolean;
  };
  link: LinkObject;
  parent: {
    id: string;
    url?: string | null;
    name: string;
    type: LinkTransactionType;
  };
};

export const PropagationStepsModal = ({
  dialog,
  onClose,
}: PropagationStepsModalProps) => {
  const [title, setTitle] = useState<ReactNode>("");
  const stepDirectionRef = useRef<"forward" | "backward">("forward");

  const { steps, form, linkedTransactions, propagationFields } =
    usePropagationContext();

  const onDialogClose = useCallback(async () => {
    await dialog.hide();
    form?.reset();
    onClose();
  }, [dialog, form, onClose]);

  const cancelPropagation = useCallback(() => {
    toast.warning(STRINGS.CHANGES_NOT_SAVED);
    trackPropagationAnalytics({
      event: "cancel",
      propagationDetails: propagationFields,
      linkedTransactions,
      currentStep: dialog.step,
    });
    onDialogClose();
  }, [dialog.step, linkedTransactions, onDialogClose, propagationFields]);

  const onBackStep = useCallback(() => {
    stepDirectionRef.current = "backward";
    dialog.back();
  }, [dialog]);

  const onNextStep = useCallback(
    (index: number) => {
      stepDirectionRef.current = "forward";
      const nextStep = steps[index + 1] ?? "final";
      dialog.setStep(nextStep);
    },
    [steps, dialog]
  );

  const curriedSkip = useCallback(
    (index: number) => () => {
      if (stepDirectionRef.current === "forward") {
        onNextStep(index);
      } else {
        onBackStep();
      }
    },
    [onBackStep, onNextStep]
  );

  const fieldsToSkip = useDeepMemo(
    () =>
      getFieldsToSkip({
        values: form?.values ?? {},
        sourceUrl: propagationFields?.url,
        linkedTransactions,
      }),
    [form?.values, linkedTransactions, propagationFields?.url]
  );

  const clearFields = useEvent(() => {
    for (const key of fieldsToSkip) {
      const [parent, type, field] = key.split(".");
      const parentValues = { ...form?.values[parent] };

      if (!parentValues) continue;

      const values = parentValues[type];

      if (!values || !(field in values)) continue;

      delete values[field];

      if (Object.keys(values).length === 0) {
        if (!(type in parentValues)) continue;

        delete parentValues[type];
        form?.setValue(`${parent}`, parentValues);
      } else {
        form?.setValue(`${parent}.${type}`, values);
      }
    }
  });

  const dialogSteps = useMemo(() => {
    const items: PropagationStepsModalDialogStep[] = [];

    for (const transaction of linkedTransactions ?? []) {
      const transactionName = propagationFields?.name || "";

      const parent: PropagationStepsModalDialogStep["parent"] = {
        id: transaction.id,
        url: propagationFields?.url,
        name: transactionName,
        type: transaction.type,
      };

      for (const link of transaction.links) {
        const stepName = getStepName(transaction, link);

        const step: PropagationStepsModalDialogStep["step"] = {
          name: stepName,
          type: TYPE_STRINGS[link.type],
          index: steps?.indexOf(stepName),
          deleted:
            (["line", "invoice_line"].includes(link.type) &&
              propagationFields?.lines?.find(
                (line) => line.id === Number(transaction.id)
              )?.fields?.deleted) ??
            false,
        };

        const { typeTitle } = getDetailsFromUrl(link.fields.parent?.url);

        if (typeTitle) {
          step.type = typeTitle;
        }

        items.push({ step, link, parent });
      }
    }

    return items;
  }, [steps, linkedTransactions, propagationFields]);

  useEffect(() => {
    if (dialog.step === "final") clearFields();
  }, [dialog.step, clearFields]);

  return (
    <Dialog
      variant="multi-step-dialog"
      size="auto"
      step={dialog.step}
      show={dialog.isVisible}
      onClose={cancelPropagation}
      hideOnNavigate={false}
    >
      {dialogSteps.map(({ step, link, parent }) => (
        <DialogStep
          key={step.name}
          name={step.name}
          onBack={step.index > 0 ? onBackStep : undefined}
        >
          <DialogHeader>
            <Flex width="755px">{title}</Flex>
          </DialogHeader>
          <DialogContent>
            {dialog.step === step.name && (
              <PropagationTableProvider
                skip={curriedSkip(step.index)}
                stepName={step.name}
                linkedItem={link}
                parentInfo={parent}
                setTitle={setTitle}
              >
                <PropagationTable />
              </PropagationTableProvider>
            )}
          </DialogContent>
          <DialogFooter>
            <Button
              block
              variant="text"
              color="neutral"
              onClick={() =>
                step.index === 0 ? cancelPropagation() : onBackStep()
              }
            >
              {step.index === 0 ? STRINGS.CANCEL : STRINGS.BACK}
            </Button>
            <Button block onClick={() => onNextStep(step.index)}>
              {STRINGS.NEXT}
            </Button>
          </DialogFooter>
        </DialogStep>
      ))}
      <PropagationFinalStep onBack={onBackStep} />
    </Dialog>
  );
};
