import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  memo,
  type MouseEventHandler,
  type ReactNode,
  useId,
  useMemo,
  useState,
} from "react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { formatPercentage } from "../../utils/format-percentage";
import { suffixify } from "../../utils/suffixify";
import { Flex } from "../flex";
import { Tooltip } from "../tooltip";

import styles from "./progress-bar.module.css";

type DefaultComponent = "div";

type Props = ComponentPropsWithoutRef<DefaultComponent> & {
  value?: number;
  color?: "success" | "error" | "warning";
  message?: ReactNode;
  "data-testid"?: string;
};

const ProgressBar = forwardRef<ComponentRef<DefaultComponent>, Props>(
  (
    {
      style,
      value = 0,
      color = "success",
      message,
      className,
      "data-testid": testId,
      ...props
    },
    ref
  ) => {
    const id = useId();

    const [show, setShow] = useState(false);

    const offset = useMemo(() => ({ x: 8 }), []);

    const isMinOrMax = value === 0 || value >= 100;

    const Wrapper = (
      isMinOrMax && message !== false ? Tooltip : Flex
    ) as React.ElementType;

    const onMouseOut = useEvent<MouseEventHandler<HTMLDivElement>>((e) => {
      if (e.currentTarget.contains(e.relatedTarget as Node)) return;

      setShow(false);
    });

    const onMouseEnter = useEvent(() => setShow(true));

    const enhancedMessage =
      message || formatPercentage(value, { percentSign: true });

    const enhancedStyle = useMemo(
      () => ({
        ...style,
        "--progress-bar-value": `${value}%`,
      }),
      [style, value]
    );

    return (
      <Wrapper
        as="div"
        ref={ref}
        role="progressbar"
        style={enhancedStyle}
        message={isMinOrMax ? enhancedMessage : undefined}
        className={cn(className, styles["progress-bar"], {
          [styles[`-${color}`]]: color,
        })}
        offset={isMinOrMax ? offset : undefined}
        placement={isMinOrMax ? "bottom" : undefined}
        data-testid={testId}
        onMouseOut={onMouseOut}
        onMouseEnter={onMouseEnter}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={value}
        aria-describedby={!isMinOrMax ? id : undefined}
        {...props}
      >
        <div className={styles["content"]}>
          {!isMinOrMax && message !== false && (
            <Tooltip
              id={id}
              show={show}
              offset={offset}
              message={enhancedMessage}
              placement="bottom"
              className={styles["tooltip"]}
              data-testid={suffixify(testId, "internal")}
            />
          )}
        </div>
      </Wrapper>
    );
  }
);

ProgressBar.displayName = "ProgressBar";

const MemoizedProgressBar = memo(ProgressBar);

export { MemoizedProgressBar as ProgressBar };
