import { fuzzySearch } from "../../utils/fuzzy-search";
import { is } from "../../utils/is";
import { isEqual } from "../../utils/is-equal";
import { isOption } from "../../utils/is-option";

import type {
  EnhancedOption,
  GetInitialStateHandler,
  GetOptionIndexHandler,
  Option,
  State,
  Value,
} from "./types";

const OPTION_HEIGHT = {
  sm: 40,
  md: 48,
  lg: 48,
};

export const optimizedStateUpdate = (
  currentState: State,
  nextState: Partial<State>
): State =>
  isEqual(currentState, nextState)
    ? currentState
    : { ...currentState, ...nextState };

export const getDefaultOptionHeight = (size: "sm" | "md") =>
  OPTION_HEIGHT[size];

export const getInitialState: GetInitialStateHandler = ({
  size,
  data,
  multiple,
}) => {
  const hasGroup = data.some((option) => option.groupLabel);
  const mode = hasGroup ? "group-interacting" : "option-interacting";

  return {
    mode,
    open: false,
    data,
    query: "",
    group: "",
    index: getNextOptionIndex({ mode, data, currentIndex: -1 }),
    option: multiple ? [] : undefined,
    queryMode: "idle",
    itemsRendered: false,
    tallOptionHeight: getDefaultOptionHeight(size),
  };
};

export const getNextOptionIndex: GetOptionIndexHandler = ({
  data,
  mode,
  strict = false,
  currentIndex = -1,
}) => {
  let nextIndex = currentIndex + 1;
  let option = data[nextIndex];

  while (option && option.disabled && mode !== "group-interacting") {
    nextIndex++;
    if (nextIndex >= data.length) break;
    option = data[nextIndex];
  }

  if (strict && !option) {
    nextIndex = getPreviousOptionIndex({ data, mode, currentIndex: nextIndex });
  }

  return nextIndex;
};

export const getPreviousOptionIndex: GetOptionIndexHandler = ({
  mode,
  data,
  currentIndex = -1,
}) => {
  let previousIndex = currentIndex - 1;
  let option = data[previousIndex];

  while (option && option.disabled && mode !== "group-interacting") {
    previousIndex--;
    if (previousIndex < 0) break;
    option = data[previousIndex];
  }

  return previousIndex;
};

export const search = (
  haystack: Option[],
  needle: string,
  options: { threshold: number; keys: string[] }
) => {
  const fuzzyInstance = fuzzySearch(haystack, {
    keys: options.keys,
    threshold: options.threshold,
  });

  const matches = fuzzyInstance.search(needle) as EnhancedOption[];

  const optionMap = new Map<string, EnhancedOption>();

  const childrenMap = new Map<string, EnhancedOption[]>();

  fuzzyInstance.all.forEach((option) => {
    optionMap.set(option.value, option as EnhancedOption);
    if (option.parent) {
      if (!childrenMap.has(option.parent)) {
        childrenMap.set(option.parent, []);
      }
      childrenMap.get(option.parent)?.push(option as EnhancedOption);
    }
  });

  const results: EnhancedOption[] = [];

  matches.forEach((option) => {
    const stack: EnhancedOption[] = [option];

    const visited = new Set<string>();

    while (stack.length > 0) {
      const current = stack.pop()!;
      if (!visited.has(current.value)) {
        visited.add(current.value);
        results.push(current);
        const children = childrenMap.get(current.value) || [];
        children.forEach((child) => {
          if (!matches.some((match) => match.value === child.value)) {
            results.push(child);
            stack.push(child);
          }
        });
      }
    }
  });

  results.sort((a, b) =>
    a.parent === b.parent
      ? a.label.localeCompare(b.label, "en", { numeric: true })
      : 0
  );

  return [...new Set(results)];
};

export const getMatch = (option: EnhancedOption) =>
  option.matches?.find((match) => match.value === option.label);

export const getDepth = (
  option: Option,
  options: Option[],
  depth = 0
): number => {
  let currentOption = option;
  let currentDepth = depth;

  while (currentOption.parent) {
    const parent = options.find(
      (item) =>
        item.value === currentOption.parent &&
        (!currentOption.groupLabel ||
          item.groupLabel === currentOption.groupLabel)
    );

    if (!parent) break;

    currentOption = parent;
    currentDepth++;
  }

  return currentDepth;
};

export const isSingleOption = (value: unknown): value is Option =>
  isOption(value);

export const isMultipleOption = (value: unknown): value is Option[] =>
  is.array(value) && value.some(isOption);

export const getGroupOrder = (options: Option[]) =>
  Array.from(
    new Set(options.map((option) => option.groupLabel ?? option.label))
  );

export const orderByGroup = (
  options: EnhancedOption[],
  groupOrder?: string[]
) => {
  const groupsMap = new Map<string, EnhancedOption[]>();

  for (const option of options) {
    if (!option.groupLabel) continue;

    if (!groupsMap.has(option.groupLabel)) {
      groupsMap.set(option.groupLabel, []);
    }
    groupsMap.get(option.groupLabel)!.push(option);
  }

  const groupsArray = Array.from(groupsMap.values());

  if (groupOrder?.length) {
    groupsArray.sort(
      (a, b) =>
        groupOrder.indexOf(a[0].groupLabel ?? "") -
        groupOrder.indexOf(b[0].groupLabel ?? "")
    );
  }

  return groupsArray.flat();
};

const arrayToObject = <T, K extends string | number | symbol, V>(
  array: T[],
  keyFn: (item: T) => K,
  valueFn: (item: T) => V
): Record<K, V> => {
  return array.reduce(
    (acc, item) => {
      acc[keyFn(item)] = valueFn(item);
      return acc;
    },
    {} as Record<K, V>
  );
};

export const nearestSelectedParentCurried = (
  allOptions: Option[],
  selectedOptions: Option[]
): ((optionVal: Value | null) => Value | null) => {
  const selectedMap = arrayToObject(
    selectedOptions,
    (o) => o.value,
    () => true
  );

  const parentMap = arrayToObject(
    allOptions,
    (o) => o.value,
    (o) => o.parent
  );

  return (optionVal: Value | null): Value | null => {
    while (optionVal) {
      if (selectedMap[parentMap[optionVal]!]) {
        return parentMap[optionVal]!;
      }
      optionVal = parentMap[optionVal]!;
    }
    return null;
  };
};

export const highestSelectedParentCurried = ({
  allOptions,
  selectedOptions,
}: { allOptions: Option[]; selectedOptions: Option[] }): ((
  optionVal: Value
) => Value) => {
  const nearestSelectedParent = nearestSelectedParentCurried(
    allOptions,
    selectedOptions
  );
  return (optionVal: Value): Value => {
    let parent = null;
    let nextParent = nearestSelectedParent(optionVal);
    do {
      parent = nextParent;
      nextParent = nearestSelectedParent(parent);
    } while (nextParent);

    if (parent === null) return optionVal;

    return parent;
  };
};

export const allChildrenCurried = (
  allOptions: Option[]
): ((option: Option) => Option[]) => {
  const parentMap = arrayToObject(
    allOptions,
    (o) => o.value,
    (o) => o.parent
  );

  return (option: Option): Option[] =>
    allOptions.filter((o) => {
      let parent = parentMap[o.value];
      while (parent) {
        if (parent === option.value) return true;
        parent = parentMap[parent];
      }
      return false;
    });
};

/**
 * when we have multiple options, this is how we select what should be selected/de-selected after
 * some user event.
 */
export const getNewOptions = ({
  allOptions,
  previousOptions,
  groupUnderParent,
  option,
}: {
  allOptions: Option[];
  previousOptions: Option[];
  groupUnderParent: boolean;
  option: Option;
}): Option[] => {
  const isSelected = previousOptions.some(
    (item: Option) => item.value == option.value
  );

  if (groupUnderParent && isSelected) {
    /**
     * Since we group all options under the highest parent, we want to deselect that parent
     */
    const findHighestSelectedParent = highestSelectedParentCurried({
      allOptions,
      selectedOptions: previousOptions,
    });

    const optionParent = findHighestSelectedParent(option.value);

    const childrenToBeDeleted: { [key: Value]: true } = previousOptions.reduce(
      (acc, option) => {
        const highestSelectedParent = findHighestSelectedParent(option.value);

        if (highestSelectedParent !== optionParent) return acc;

        return { ...acc, [option.value]: true };
      },
      {}
    );

    return previousOptions.filter(
      (item: Option) =>
        item.value !== option.value && !childrenToBeDeleted[item.value]
    );
  } else if (groupUnderParent) {
    const allElements = [...previousOptions, option];
    allChildrenCurried(allOptions)(option).forEach((child) => {
      if (allElements.some((item) => item.value === child.value)) return;
      allElements.push(child);
    });

    return allElements;
  }
  return isSelected
    ? previousOptions.filter((item) => item.value !== option.value)
    : [...previousOptions, option];
};
