import React, { memo } from "react";

import { Checkbox } from "../checkbox";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { Text } from "../text";
import { Tooltip } from "../tooltip";

import type { ComboBoxOptionProps } from "./types";

export const ComboBoxOption = memo(
  ({
    data,
    label,
    parent,
    disabled,
    selected,
    multiple,
    children,
    groupLabel,
  }: ComboBoxOptionProps) => {
    const parentLabel = data.find((item) => item.value === parent)?.label;

    return (
      <Flex align="center" gap="lg">
        {multiple && (
          <Checkbox
            aria-hidden="true"
            checked={selected}
            disabled={!!disabled}
          />
        )}
        <Flex direction="column">
          <Flex gap="sm" align="center">
            {label}
            {typeof disabled === "string" && (
              <Tooltip
                as={Icon}
                size="sm"
                name="exclamation-circle"
                message={disabled}
              />
            )}
          </Flex>
          {groupLabel && (
            <Text
              size="xs"
              color={selected && !multiple ? undefined : "neutral-600"}
              truncate
            >
              {groupLabel}
            </Text>
          )}
          {parentLabel && (
            <Text
              size="xs"
              color={selected && !multiple ? undefined : "neutral-600"}
              truncate
            >
              Parent: {parentLabel}
            </Text>
          )}
          {children}
        </Flex>
      </Flex>
    );
  }
);

ComboBoxOption.displayName = "ComboBoxOption";
