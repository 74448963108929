import React from "react";
import {
  Avatar,
  Button,
  dialog,
  Flex,
  Loader,
  Text,
  toast,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  formatDate,
  parseDate,
  parseStringCopy,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@shared/api/handle-errors";
import { useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";

import {
  useSendLienWaiverReminderMutation,
  useUpdateLienWaiverRequestMutation,
} from "../api/api";
import type { LienWaiver } from "../api/types";
import { LIEN_WAIVER_STATUS, STRINGS } from "../constants/constants";

import { type BillLienWaiverFieldProps } from "./bill-lien-waiver-field";

type BillLienWaiverFieldRequestedProps = {
  billLienWaiver: LienWaiver;
} & Pick<BillLienWaiverFieldProps, "onRequestUpdate" | "isProcessPrerequisite">;

export const BillLienWaiverFieldRequested = ({
  billLienWaiver,
  onRequestUpdate,
  isProcessPrerequisite,
}: BillLienWaiverFieldRequestedProps) => {
  const { revisionEvent } = billLienWaiver;
  const revisionEventDate = parseDate(revisionEvent?.createdAt || "", "iso");
  const { canManageLienWaiverRequest } = useUserInfo();

  const [
    updateLienWaiverRequestMutation,
    { isLoading: requestUpdateIsLoading },
  ] = useUpdateLienWaiverRequestMutation();
  const [
    sendLienWaiverReminder,
    { isLoading: sendLienWaiverReminderIsLoading },
  ] = useSendLienWaiverReminderMutation();

  const isLoading = requestUpdateIsLoading || sendLienWaiverReminderIsLoading;

  const onCancelRequest = useEvent(() => {
    dialog.confirmation({
      size: isProcessPrerequisite ? "md" : "sm",
      title: STRINGS.CANCEL_LIEN_WAIVER_REQUEST_CONFIRMATION_TITLE,
      message: isProcessPrerequisite
        ? STRINGS.CANCEL_LIEN_WAIVER_REQUEST_PREREQUISITE_FOR_PAYMENT_CONFIRMATION_TEXT
        : STRINGS.CANCEL_LIEN_WAIVER_REQUEST_CONFIRMATION_TEXT,
      action: {
        primary: {
          children: isProcessPrerequisite
            ? STRINGS.CANCEL_LIEN_WAIVER_REQUEST_AND_PAYMENT_CONFIRM
            : STRINGS.CANCEL_LIEN_WAIVER_REQUEST_CONFIRM,
          color: "error",
          onClick: async () => {
            try {
              await updateLienWaiverRequestMutation({
                id: billLienWaiver.id,
                status: LIEN_WAIVER_STATUS.NOT_REQUESTED,
              }).unwrap();
              onRequestUpdate?.();
              toast.success(STRINGS.CANCEL_LIEN_WAIVER_SUCCESS);
            } catch (error) {
              handleErrors(error);
            }
          },
        },
        secondary: {
          children: STRINGS.CANCEL_LIEN_WAIVER_REQUEST_KEEP,
        },
      },
    });
  });

  const onSendReminder = useEvent(async () => {
    try {
      await sendLienWaiverReminder({
        id: billLienWaiver.id.toString(),
      }).unwrap();
      analytics.track("sendLienWaiverReminder", {
        lienWaiverId: billLienWaiver.id,
      });
      toast.success(STRINGS.REMINDER_SENT);
    } catch (error) {
      handleErrors(error);
    }
  });

  const isRequested = billLienWaiver.status === LIEN_WAIVER_STATUS.REQUESTED;
  const titleCopyString = isRequested
    ? STRINGS.LIEN_WAIVER_REQUESTED_TITLE
    : STRINGS.LIEN_WAIVER_MARKED_AS_REQUESTED_TITLE;

  return (
    <Flex width="full" gap="md" align="flex-start">
      {isLoading && <Loader position="fixed" />}
      <Avatar name="paper-plane-top" color="info" size="lg" />
      <Flex align="flex-start" direction="column" gap="xs">
        <Text weight="bold">
          {parseStringCopy(titleCopyString, {
            receiver: billLienWaiver?.vendor?.displayName,
            sender: revisionEvent?.author?.fullName,
          })}
        </Text>
        <Text color="neutral-700" size="sm">
          {parseStringCopy(STRINGS.LIEN_WAIVER_ACTIVITY_TIME, {
            date: formatDate(revisionEventDate, "MM/dd/yy"),
            time: formatDate(revisionEventDate, "hh:mma"),
          })}
        </Text>
        <Flex width="full" gap="md" padding={["md", "none", "none"]}>
          {isRequested && (
            <Button
              size="sm"
              onClick={onSendReminder}
              color="info"
              disabled={isLoading || !canManageLienWaiverRequest}
            >
              {STRINGS.LIEN_WAIVER_SEND_REMINDER}
            </Button>
          )}
          <Button
            size="sm"
            variant="ghost"
            color="info"
            disabled={isLoading || !canManageLienWaiverRequest}
            onClick={onCancelRequest}
          >
            {STRINGS.LIEN_WAIVER_CANCEL_REQUEST}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
