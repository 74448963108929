import React, { type ReactNode, useEffect, useState } from "react";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import * as analytics from "@utils/analytics";

import type { CardTransaction } from "../api/types";
import { STRINGS } from "../constants/constants";
import { useMatchCardTransaction } from "../hooks/use-match-card-transaction";
import { getConflicts } from "../utils/get-conflicts";

import { MatchingTransactionDialogGenericSelectCardTransaction } from "./matching-transaction-dialog-generic-select-card-transaction";
import type {
  CardFilter,
  DaysThreshold,
  LinkedCost,
  MatchingTransactionOnChangeHandler,
} from "./types";

export type MatchingTransactionDialogSelectCardTransactionStepProps = {
  cost: LinkedCost;
  onError: (payload: {
    conflicts: ReturnType<typeof getConflicts>;
    cardTransaction: CardTransaction;
  }) => void;
  onCancel: () => void;
  onSuccess: MatchingTransactionOnChangeHandler;
  title?: ReactNode;
  subtitle?: ReactNode;
  daysThreshold?: DaysThreshold;
  cardFilters?: CardFilter[];
  filtersAsFixed?: boolean;
};

export const MatchingTransactionDialogSelectCardTransactionStep = ({
  cost,
  onError,
  onCancel,
  onSuccess,
  title,
  subtitle,
  daysThreshold = { daysBefore: 1, daysAfter: 1 },
  cardFilters,
  filtersAsFixed,
}: MatchingTransactionDialogSelectCardTransactionStepProps) => {
  const [enabled, setEnabled] = useState(false);

  const [matchCardTransactionTrigger, matchCardTransactionInfo] =
    useMatchCardTransaction();

  let date = "";

  if ("transactionDate" in cost) {
    const transactionDate = parseDate(cost.transactionDate, "yyyy-MM-dd");

    if (transactionDate) {
      date = formatDate(transactionDate);
    } else if ("trueCreatedAt" in cost) {
      const trueCreatedAt = parseDate(cost.trueCreatedAt, "iso");

      if (trueCreatedAt) {
        date = formatDate(trueCreatedAt);
      }
    }
  } else {
    date = formatDate(cost.date);
  }

  const onSubmit = useEvent<MatchingTransactionOnChangeHandler>(
    async ({ cardTransaction }) => {
      if (!cardTransaction) return;

      analytics.track("costMatchCardTransaction", {
        costUrl: cost.url,
        cardTransactionId: cardTransaction.id,
        costStatus:
          "reviewStatus" in cardTransaction
            ? cardTransaction.reviewStatus
            : "status" in cardTransaction
              ? cardTransaction.status
              : "unknown",
      });

      try {
        await matchCardTransactionTrigger({
          cost: cost.url ?? cost.id,
          cardTransactionId: cardTransaction.id,
        }).unwrap();

        onSuccess({ cost, cardTransaction });
      } catch (e) {
        const conflicts = getConflicts(e);

        if (!conflicts.length) return handleErrors(e);

        onError({ conflicts, cardTransaction });
      }
    }
  );

  useEffect(() => {
    setEnabled(true);
  }, []);

  if (!enabled) return null;

  return (
    <MatchingTransactionDialogGenericSelectCardTransaction
      cost={cost}
      title={
        title ||
        STRINGS.MATCHING_TRANSACTION_DIALOG_SELECT_CARD_TRANSACTION_STEP_TITLE
      }
      filtersAsFixed={filtersAsFixed}
      cardFilters={cardFilters}
      loading={matchCardTransactionInfo.isLoading}
      onSubmit={onSubmit}
      onCancel={onCancel}
      daysThreshold={daysThreshold}
      subtitle={
        subtitle ||
        `${date || "Unknown date"}: ${formatCurrency(cost.totalAmount || 0, {
          currencySign: true,
          allowNegative: true,
        })} ${cost.vendor?.displayName ?? "Unknown vendor"}`
      }
    />
  );
};
