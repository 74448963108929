import React, { memo, useEffect, useRef } from "react";
import {
  type ResponsiveProp,
  useEvent,
  useResizeObserver,
  useResponsiveProp,
} from "@adaptive/design-system/hooks";
import { forwardRefAs, mergeRefs } from "@adaptive/design-system/utils";
import cn from "clsx";

import styles from "./main.module.css";

const DEFAULT_COMPONENT = "main";

type Props = {
  variant?: ResponsiveProp<"unspaced" | "spaced-sm" | "spaced-md">;
  scrollable?: boolean;
};

const MainContent = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      variant: variantRaw,
      scrollable = true,
      className,
      ...props
    },
    ref
  ) => {
    const internalRef = useRef<HTMLElement>(null);

    const variant = useResponsiveProp(variantRaw, "spaced-md");

    const setScrollBarWidth = useEvent(() => {
      if (!internalRef.current) return;

      const scrollbarWidth =
        internalRef.current.offsetWidth - internalRef.current.clientWidth;
      internalRef.current.style.setProperty(
        "--main-content-scrollbar-width",
        `${scrollbarWidth}px`
      );
    });

    useResizeObserver(internalRef, setScrollBarWidth);

    useEffect(() => setScrollBarWidth(), [setScrollBarWidth]);

    return (
      <Component
        ref={mergeRefs(ref, internalRef)}
        className={cn(className, styles["main-content"], {
          [styles[`-${variant}`]]: variant,
          [styles["-scrollable"]]: scrollable,
        })}
        data-main-content=""
        {...props}
      />
    );
  }
);

MainContent.displayName = "MainContent";

const MemoizedMainContent = memo(MainContent) as typeof MainContent;

export { MemoizedMainContent as MainContent };
