import { dotObject, isEqual, omit, pick } from "@adaptive/design-system/utils";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "@store/types";
import type { SaveWithPropagationParams } from "@transaction-propagation/types";
import { getLinesInfos } from "@transaction-propagation/utils";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { sum } from "@utils/sum";
import { sumBy } from "@utils/sumBy";

export const selectPurchaseOrderState = (state: RootState) =>
  state.purchaseOrder;

export const selectPurchaseOrder = createSelector(
  selectPurchaseOrderState,
  (state) => state.purchaseOrder
);

export const selectPurchaseOrderType = createSelector(
  selectPurchaseOrder,
  (state) => state.type
);

export const selectPurchaseOrderIsLoading = createSelector(
  selectPurchaseOrderState,
  (state) => state.purchaseOrderLoading
);

export const selectStaticPurchaseOrder = createSelector(
  selectPurchaseOrderState,
  (state) => state.staticPurchaseOrder
);

export const selectPurchaseOrderTotals = createSelector(
  selectPurchaseOrder,
  (state) => {
    const total = sumBy(
      state.lines.filter((line) => !("deleted" in line) || !line.deleted),
      "amount"
    );

    let linkedBills = 0;

    for (const line of state.lines) {
      linkedBills = sum(linkedBills, line.linked_bills_total);
    }

    return {
      total,
      percentage: total === 0 ? 0 : (linkedBills / total) * 100,
      linkedBills,
      openBalance: total - linkedBills,
    };
  }
);

/**
 * Adjust amount field since it could be either 0 or 0.00
 */
const normalizePurchaseOrder = (purchaseOrder: any) => {
  const normalizedPurchaseOrder = { ...purchaseOrder };

  normalizedPurchaseOrder.lines = normalizedPurchaseOrder.lines.map(
    (line: any) => {
      const amount = Number(Number(String(line.amount)).toFixed(2));

      return omit({ ...line, amount: amount || 0 }, ["balance"]);
    }
  );

  normalizedPurchaseOrder.private_note =
    normalizedPurchaseOrder.private_note || "";

  const vendorId =
    normalizedPurchaseOrder.vendor?.id || normalizedPurchaseOrder.vendor?.url
      ? parseRefinementIdFromUrl(normalizedPurchaseOrder.vendor?.id)
      : null;

  const vendorUrl =
    normalizedPurchaseOrder.vendor?.url || vendorId
      ? `${window.location.pathname}/api/vendors/${vendorId}/`
      : null;

  const hasVendor = vendorId || vendorUrl;

  if (hasVendor) {
    normalizedPurchaseOrder.vendor = {
      ...omit(normalizedPurchaseOrder.vendor, ["has_pending_requests"]),
      id: String(vendorId),
      url: vendorUrl,
    };
  }

  normalizedPurchaseOrder.shipping_address =
    normalizedPurchaseOrder.shipping_address || "";

  return omit(normalizedPurchaseOrder, [
    "realm",
    "total_amount",
    "open_balance",
  ]);
};

export const createSelectorPurchaseOrderIsDirty = (
  field?: string | string[],
  deepField?: string | string[]
) =>
  createSelector(
    selectPurchaseOrder,
    selectStaticPurchaseOrder,
    (purchaseOrder, staticPurchaseOrder) => {
      const normalizedPurchaseOrder = normalizePurchaseOrder(purchaseOrder);
      const normalizedStaticPurchaseOrder =
        normalizePurchaseOrder(staticPurchaseOrder);

      if (!field) {
        return !isEqual(normalizedPurchaseOrder, normalizedStaticPurchaseOrder);
      }

      const purchaseOrderValues = {};
      const staticPurchaseOrderValues = {};

      (Array.isArray(field) ? field : [field]).forEach((f) => {
        let value = dotObject.get(normalizedPurchaseOrder, f);
        let staticValue = dotObject.get(normalizedStaticPurchaseOrder, f);

        if (deepField) {
          const fieldsToPick = Array.isArray(deepField)
            ? deepField
            : [deepField];

          value = pick(value, fieldsToPick);
          staticValue = pick(staticValue, fieldsToPick);
        }

        dotObject.set(purchaseOrderValues, f, value);
        dotObject.set(staticPurchaseOrderValues, f, staticValue);
      });

      return !isEqual(purchaseOrderValues, staticPurchaseOrderValues);
    }
  );

export const selectPurchaseOrderIsDirty = createSelectorPurchaseOrderIsDirty();

const LINE_FIELDS_TO_DIRTY_CHECK = [
  "item",
  "status",
  "amount",
  "account",
  "customer",
  "description",
];

export const selectPurchaseOrderDirtyLines = createSelector(
  selectPurchaseOrder,
  selectStaticPurchaseOrder,
  (purchaseOrder, staticPurchaseOrder) => {
    const normalizedPurchaseOrderLines =
      normalizePurchaseOrder(purchaseOrder)?.lines;
    const normalizedStaticPurchaseOrderLines =
      normalizePurchaseOrder(staticPurchaseOrder)?.lines;

    return (normalizedPurchaseOrderLines || []).filter(
      (line: any, index: number) => {
        const lineFields = pick(line, LINE_FIELDS_TO_DIRTY_CHECK);
        const staticLineFields = pick(
          normalizedStaticPurchaseOrderLines?.[index],
          LINE_FIELDS_TO_DIRTY_CHECK
        );

        return !isEqual(lineFields, staticLineFields);
      }
    );
  }
);

export const purchaseOrderPropagationPayloadSelector = createSelector(
  selectPurchaseOrder,
  selectStaticPurchaseOrder,
  (purchaseOrder, staticPurchaseOrder) => {
    const propagationParams: SaveWithPropagationParams = { values: {} };

    const normalizedPurchaseOrder = normalizePurchaseOrder(purchaseOrder);
    const normalizedStaticPurchaseOrder =
      normalizePurchaseOrder(staticPurchaseOrder);

    const purchaseOrderVendorValue = normalizedPurchaseOrder.vendor?.url;
    const staticPurchaseOrderVendorValue =
      normalizedStaticPurchaseOrder.vendor?.url;

    const parsedId = purchaseOrder.id && parseFloat(purchaseOrder.id);

    if (!parsedId) return propagationParams;

    propagationParams.id = parsedId;
    propagationParams.type = "purchase_order";
    propagationParams.values.name = staticPurchaseOrder.doc_number;
    propagationParams.values.url = staticPurchaseOrder.url;

    if (!isEqual(purchaseOrderVendorValue, staticPurchaseOrderVendorValue)) {
      propagationParams.updatedFields = ["vendor"];
      propagationParams.values.fields = {
        vendor: purchaseOrderVendorValue,
      };
      propagationParams.values.prevValues = {
        vendor: staticPurchaseOrderVendorValue,
      };
    }

    const linesInfo = getLinesInfos({
      lines: normalizedPurchaseOrder.lines,
      staticLines: normalizedStaticPurchaseOrder.lines,
      parentId: parsedId,
    });

    if (linesInfo?.lines?.length) {
      propagationParams.lines = linesInfo.lines;
      propagationParams.values.lines = linesInfo.values;
    }

    return propagationParams;
  }
);
