import React, { memo, useEffect, useMemo, useRef } from "react";
import {
  Card,
  ComboBox,
  type ComboBoxRef,
  DateField,
  Flex,
  LabelValue,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  dotObject,
  formatCurrency,
  formatDate,
  isEqual,
  parseDate,
} from "@adaptive/design-system/utils";
import { SelectVendor } from "@components/form";
import { QuickBooksSyncedIcon } from "@components/quickbooks-icon";
import { RefNumberField } from "@components/ref-number-field";
import type { Option } from "@shared/types";
import { useBillFormPermissions } from "@src/bills/bill-form-context";
import { BILL_STATUS, getTransactionType } from "@src/bills/constants";
import {
  billSelector,
  camelCaseBillSelector,
  camelCaseLinkedTransactionsBillSelector,
  getReceivedDate,
  getSourceEmail,
  hasAnyLienWaiverRequired,
  isInPaymentStatusBill,
} from "@src/bills/utils";
import { recordBillUpdate } from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { asReferenceNode } from "@utils/converters/utils";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";
import { selectedOptionHasValue } from "@utils/validator";

const TRANSACTION_TYPES: Option[] = [
  { label: "Bill", value: "false" },
  { label: "Vendor credit", value: "true" },
];

const vendorValidator = selectedOptionHasValue("Vendor name cannot be empty");

export const Info = memo(() => {
  const {
    id,
    date,
    balance,
    dueDate,
    docNumber,
    createdAt,
    isArchivedByUser,
    totalAmount,
    reviewStatus,
    isVendorCredit,
    enterBillManually,
    publishedToQuickbooks,
    lienWaivers,
  } = useAppSelector(camelCaseBillSelector);

  const billLinkedTransactions = useAppSelector(
    camelCaseLinkedTransactionsBillSelector
  );

  const isLinkedToQBPO = useMemo(() => {
    return billLinkedTransactions.some(
      (transaction) => transaction.parent.type === "qb"
    );
  }, [billLinkedTransactions]);

  const isLinkedToBuilderTrendPO = useMemo(() => {
    return billLinkedTransactions.some(
      (transaction) => transaction.parent.type === "bt"
    );
  }, [billLinkedTransactions]);

  const isDisabledByLinkedPurchaseOrders = useMemo(() => {
    if (!billLinkedTransactions) return false;
    return (
      isLinkedToQBPO ||
      isLinkedToBuilderTrendPO ||
      billLinkedTransactions.length > 1
    );
  }, [isLinkedToQBPO, isLinkedToBuilderTrendPO, billLinkedTransactions]);

  const sourceEmail = useAppSelector(
    (state) => ({
      email_body_attachable: billSelector(state).email_body_attachable,
    }),
    isEqual
  );

  const receivedDate = useMemo(
    () => ({ id, created_at: createdAt }),
    [createdAt, id]
  );

  const dispatch = useAppDispatch();

  const vendorRef = useRef<ComboBoxRef>(null);

  const permissions = useBillFormPermissions();

  const activeVendorSelector = (state: object) =>
    transformKeysToCamelCase(
      dotObject.get(state, "bill.bill.vendor", {
        url: null,
        display_name: null,
      })
    );

  const onRefNumberChange = useEvent((value: string) => {
    dispatch(recordBillUpdate({ doc_number: value }));
  });

  const onVendorChange = useEvent((_: string, option?: Option) => {
    const vendor = transformKeysToSnakeCase(
      asReferenceNode(option) ?? { url: null, display_name: null }
    );
    dispatch(recordBillUpdate({ vendor }));
  });

  const setTransactionType = useEvent((isVendorCredit: string) =>
    dispatch(recordBillUpdate({ is_vendor_credit: isVendorCredit === "true" }))
  );

  const isAchInfoRequested = reviewStatus === BILL_STATUS.ACH_INFO_REQUESTED;
  const isPaymentScheduled = reviewStatus === BILL_STATUS.PAYMENT_SCHEDULED;

  const isInPaymentStatus = isInPaymentStatusBill(reviewStatus);
  const hasRequiredLienWaiver = hasAnyLienWaiverRequired(lienWaivers);

  useEffect(() => {
    if ((enterBillManually || id) && reviewStatus === "DRAFT") {
      requestAnimationFrame(() => !window.IS_E2E && vendorRef.current?.focus());
    }
  }, [enterBillManually, id, reviewStatus]);

  return (
    <Flex gap="xl" direction="column">
      <Flex gap="xl" align="flex-start">
        <Flex
          gap="md"
          padding={["xs", "none", "none"]}
          justify="flex-start"
          align="center"
        >
          <Text size="xl" weight="bold">
            {getTransactionType(isVendorCredit)} details
          </Text>
          {publishedToQuickbooks && <QuickBooksSyncedIcon />}
        </Flex>
        <Flex gap="md" wrap grow basis={0} justify="flex-end">
          {getSourceEmail(sourceEmail)}
          {getReceivedDate(receivedDate)}
        </Flex>
      </Flex>

      <Card>
        <Flex direction="column">
          <SelectVendor
            ref={vendorRef}
            required
            disabled={
              isArchivedByUser ||
              !permissions.canEditBill ||
              isInPaymentStatus ||
              isAchInfoRequested ||
              isPaymentScheduled ||
              isDisabledByLinkedPurchaseOrders ||
              hasRequiredLienWaiver
            }
            selector={activeVendorSelector}
            onChange={onVendorChange}
            validator={vendorValidator as any}
            helperMessage={
              !isArchivedByUser && permissions.canEditBill
                ? isInPaymentStatus
                  ? "This field cannot be edited because this bill has already been paid"
                  : isAchInfoRequested
                    ? "This field cannot be edited because this bill has already been requested for ACH info"
                    : isPaymentScheduled
                      ? "This field cannot be edited because this bill has a payment scheduled"
                      : isDisabledByLinkedPurchaseOrders
                        ? isLinkedToQBPO
                          ? "Not editable when linked to QB POs"
                          : isLinkedToBuilderTrendPO
                            ? "Not editable when linked to BuilderTrend POs"
                            : "Not editable when linked to a many POs"
                        : undefined
                : isDisabledByLinkedPurchaseOrders
                  ? isLinkedToQBPO
                    ? "Not editable when linked to QB POs"
                    : isLinkedToBuilderTrendPO
                      ? "Not editable when linked to BuilderTrend POs"
                      : "Not editable when linked to a many POs"
                  : undefined
            }
          />
          <Flex gap="xl">
            <ComboBox
              data={TRANSACTION_TYPES}
              label="Transaction type"
              value={isVendorCredit ? "true" : "false"}
              disabled={
                isArchivedByUser ||
                !permissions.canEditBill ||
                isInPaymentStatus ||
                isAchInfoRequested ||
                isPaymentScheduled ||
                isLinkedToQBPO
              }
              onChange={setTransactionType}
              helperMessage={
                !isArchivedByUser && permissions.canEditBill
                  ? isInPaymentStatus
                    ? "This field cannot be edited because this bill has already been paid"
                    : isAchInfoRequested
                      ? "This field cannot be edited because this bill has already been requested for ACH info"
                      : isPaymentScheduled
                        ? "This field cannot be edited because this bill has a payment scheduled"
                        : isLinkedToQBPO
                          ? "Not editable when linked to QB POs"
                          : undefined
                  : undefined
              }
              data-testid="bill-transaction-type"
            />
            <RefNumberField
              label="Ref #"
              value={docNumber ? docNumber : ""}
              disabled={
                isArchivedByUser || !permissions.canEditBill || isLinkedToQBPO
              }
              data-testid="bill-ref-number"
              onChange={onRefNumberChange}
              // Bills allow up to 21 characters for QBDT
              maxLength={{
                QBO: window.QBO_REF_NUMBER_MAX_LENGTH_BILL,
                QBDT: window.QBDT_REF_NUMBER_MAX_LENGTH_BILL,
              }}
              helperMessage={
                isLinkedToQBPO
                  ? "Not editable when linked to QB POs"
                  : undefined
              }
            />
          </Flex>

          <Flex gap="xl">
            <DateField
              required
              label="Date"
              value={date ? (parseDate(date, "yyyy-MM-dd") as Date) : null}
              disabled={
                isArchivedByUser || !permissions.canEditBill || isLinkedToQBPO
              }
              onChange={(date) =>
                dispatch(
                  recordBillUpdate({
                    date: date ? formatDate(date, "yyyy-MM-dd") : null,
                  })
                )
              }
              helperMessage={
                isLinkedToQBPO
                  ? "Not editable when linked to QB POs"
                  : undefined
              }
              errorMessage={!date && "Date cannot be empty"}
              messageVariant="absolute"
            />
            {!isVendorCredit && (
              <DateField
                label="Due date"
                value={
                  dueDate ? (parseDate(dueDate, "yyyy-MM-dd") as Date) : null
                }
                disabled={
                  isArchivedByUser || !permissions.canEditBill || isLinkedToQBPO
                }
                onChange={(date) =>
                  dispatch(
                    recordBillUpdate({
                      due_date: date ? formatDate(date, "yyyy-MM-dd") : null,
                    })
                  )
                }
                helperMessage={
                  isLinkedToQBPO
                    ? "Not editable when linked to QB POs"
                    : undefined
                }
                messageVariant="absolute"
              />
            )}
          </Flex>

          {reviewStatus !== BILL_STATUS.DRAFT &&
            reviewStatus !== BILL_STATUS.APPROVAL && (
              <Flex gap="xl" margin={["xl", "none", "none"]}>
                <Flex width="full">
                  <LabelValue
                    label="Total"
                    value={formatCurrency(totalAmount, {
                      allowNegative: true,
                      currencySign: true,
                    })}
                  />
                </Flex>
                <Flex width="full">
                  <LabelValue
                    label="Balance"
                    value={formatCurrency(balance, {
                      currencySign: true,
                      allowNegative: true,
                    })}
                  />
                </Flex>
              </Flex>
            )}
        </Flex>
      </Card>
    </Flex>
  );
});

Info.displayName = "Info";
