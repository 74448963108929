import React from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import type { AccountBalanceV1, BankAccountV1 } from "@api/bank-accounts";

import { STRINGS } from "../constants";
import { isBankVerified } from "../utils";

import { Statuses } from "./statuses";

type BankTableColumnProps = {
  row: BankAccountV1;
  onManageBank: (id: string) => void;
  onVerifyBank: (id: string) => void;
  onQBConnect: (id: string) => void;
  onDelete: (id: string) => void;
  onMakeDefault: (row: AccountBalanceV1) => void;
};

const BankAccountName = ({ name, mask }: AccountBalanceV1) => (
  <Flex
    direction="row"
    width="full"
    gap="md"
    padding={["none", "none", "xl", "none"]}
  >
    <Text size="xl" weight="bold">
      {name}
    </Text>
    <Text size="md">**** {mask}</Text>
  </Flex>
);

type VerticalDotMenuProps = {
  account: AccountBalanceV1;
  onDelete: () => void;
  onMakeDefault: () => void;
};

const VerticalDotMenu = ({
  account,
  onDelete,
  onMakeDefault,
}: VerticalDotMenuProps) => {
  return (
    <Flex align="flex-end">
      <Flex height="full">
        <Tooltip
          message={
            account.is_default
              ? STRINGS.REMOVE_AS_DEFAULT
              : STRINGS.SET_AS_DEFAULT
          }
        >
          <Button
            variant="text"
            block
            color={account.is_default ? "primary" : "neutral"}
            onClick={onMakeDefault}
          >
            <Icon
              name="star"
              variant={account.is_default ? "solid" : "light"}
            />
          </Button>
        </Tooltip>
      </Flex>
      <Dropdown placement="bottom-end">
        <DropdownTrigger
          as={Button}
          color="neutral"
          variant="text"
          aria-label="Click me"
        >
          <Icon name="ellipsis-vertical" variant="solid" />
        </DropdownTrigger>
        <DropdownList>
          <DropdownItem onClick={onDelete}>Remove Bank</DropdownItem>
        </DropdownList>
      </Dropdown>
    </Flex>
  );
};

export const SameDayManualVerificationBankColumns = ({
  row,
  onVerifyBank,
  onQBConnect,
  onDelete,
  onMakeDefault,
}: BankTableColumnProps) => (
  <Flex direction="row" align="center">
    {row.account_balances.map((account: AccountBalanceV1) => (
      <Flex
        padding={["xl", "none", "xl", "none"]}
        align="center"
        justify="space-between"
        direction="row"
        key={account.id}
        width="full"
      >
        <div>
          <BankAccountName {...account} />
          <Statuses {...account} />
        </div>
        <Flex gap="xl">
          {account.verification_status === "pending_manual_verification" && (
            <Button onClick={() => onVerifyBank(row.id)}>Verify</Button>
          )}
          {isBankVerified(account.verification_status) &&
            !account.payment_account?.display_name && (
              <Button
                onClick={
                  // @ts-expect-error: temporary issue with jsx and tsx compatibility until refactor
                  () => onQBConnect(account)
                }
              >
                Connect to QuickBooks account
              </Button>
            )}
          <VerticalDotMenu
            onDelete={() => onDelete(row.id)}
            onMakeDefault={() => onMakeDefault(account)}
            account={account}
          />
        </Flex>
      </Flex>
    ))}
  </Flex>
);
