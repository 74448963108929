import { useEffect, useState } from "react";
import { getBankAccounts } from "@api/bank-accounts";
import type { DataAccessState } from "@shared/hooks/types";
import { useClientInfo } from "@store/user";
import { formatCard } from "@utils/format-card";

type UseBankAccountsProps = { enabled?: boolean };

export const useBankAccountsV1 = ({
  enabled = true,
}: UseBankAccountsProps = {}) => {
  const [state, setState] = useState<DataAccessState>({
    data: [],
    status: "loading",
  });

  const { client } = useClientInfo();

  useEffect(() => {
    const clientId = client?.id;

    if (!clientId || !enabled) return;

    getBankAccounts({
      client: clientId,
      refresh_balance: false,
    })
      .then((response) => {
        const parsed: DataAccessState = {
          status: "success",
          data: [],
        };
        response.results.forEach((bank_account_client) => {
          bank_account_client.bank_accounts.forEach((bank_account) => {
            bank_account.account_balances
              .filter(
                (account_balance) => account_balance.checkbook_account_is_active
              )
              .forEach((account_balance) => {
                const card = formatCard({
                  name: account_balance.name,
                  mask: account_balance.mask,
                });

                const account = account_balance.payment_account
                  ? ` - ${account_balance.payment_account.display_name}`
                  : "";

                parsed.data.push({
                  label: `${card}${account}`,
                  value: account_balance.url,
                });
              });
          });
        });
        setState(parsed);
      })
      .catch(() => setState({ status: "failed", data: [] }));
  }, [enabled, client?.id]);

  return state;
};
