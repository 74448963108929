import React, { createContext, useMemo, useState } from "react";
import {
  Button,
  Flex,
  Icon,
  Table,
  type TableColumn,
  TableProvider,
  Tag,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useDebouncedValue, useEvent } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { CustomersComboBox } from "@components/customers-combobox";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";
import { curriedNoop } from "@utils/noop";

import { type BuilderTrendJob } from "../api/types";
import { useBuildertrend } from "../hooks/use-buildertrend";

type JobsContextType = {
  curriedOnChangeCustomer: (row: BuilderTrendJob) => (value: string) => void;
  curriedCreateAdaptiveCustomer: (row: BuilderTrendJob) => () => void;
  curriedSyncBudget: (row: BuilderTrendJob) => () => void;
};

const JobsContext = createContext<JobsContextType>({
  curriedOnChangeCustomer: curriedNoop,
  curriedCreateAdaptiveCustomer: curriedNoop,
  curriedSyncBudget: curriedNoop,
});

const COLUMNS: TableColumn<BuilderTrendJob>[] = [
  {
    id: "job_name",
    name: "BuilderTrend job",
    sortable: true,
    render: (row) => (
      <Flex align="center" gap="sm">
        <Text>{row.jobName}</Text>
        {row.customerReadOnly && (
          <Tag color="success">
            <Flex align="center" gap="md">
              <Icon name="arrows-rotate" size="sm" />
              <Text>Synced</Text>
            </Flex>
          </Tag>
        )}
      </Flex>
    ),
    width: "fill",
  },
  {
    id: "customer__display_name",
    name: "Adaptive job",
    sortable: true,
    render: (row) => (
      <JobsContext.Consumer>
        {({ curriedOnChangeCustomer }) => (
          <CustomersComboBox
            size="sm"
            onChange={curriedOnChangeCustomer(row)}
            value={row.customer}
            messageVariant="hidden"
            withAction={false}
            active={null}
            label=""
          />
        )}
      </JobsContext.Consumer>
    ),
    width: 290,
  },
  {
    id: "buildertrend-job-actions",
    name: "Actions",
    width: 290,
    render: (row) => (
      <JobsContext.Consumer>
        {({ curriedCreateAdaptiveCustomer, curriedSyncBudget }) => (
          <Flex gap="sm">
            <Button
              size="sm"
              color="neutral"
              variant="ghost"
              onClick={curriedCreateAdaptiveCustomer(row)}
            >
              <Icon name="plus" />
              Create job
            </Button>
            <Button
              size="sm"
              color="neutral"
              variant="ghost"
              onClick={curriedSyncBudget(row)}
            >
              <Icon name="arrows-rotate" />
              Sync budget
            </Button>
          </Flex>
        )}
      </JobsContext.Consumer>
    ),
  },
];

export const JobsTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("job_name");

  const [debouncedSearchQuery] = useDebouncedValue(
    searchQuery,
    import.meta.env.MODE === "test" ? 0 : 300
  );

  const sort = useMemo(
    () => ({ value: sortBy, onChange: setSortBy }),
    [sortBy]
  );

  const {
    jobs,
    jobsIsLoading: isLoading,
    updateBuilderTrendJob,
    pagination,
    createBuilderTrendCustomer,
    onPullBuilderTrendBudget,
  } = useBuildertrend({ jobsQuery: debouncedSearchQuery, jobsSortBy: sortBy });

  const [offset, setOffset] = useState(0);
  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setOffset(sticky.height);
  });

  const tablePagination = useMemo(
    () => ({
      page: pagination.page,
      total: jobs?.count ?? 0,
      perPage: pagination.perPage,
      onChange: pagination.setPage,
      onPerPageChange: pagination.setPerPage,
    }),
    [
      pagination.page,
      jobs?.count,
      pagination.perPage,
      pagination.setPage,
      pagination.setPerPage,
    ]
  );

  const curriedOnChangeCustomer =
    (row: BuilderTrendJob) => async (value: string) => {
      try {
        await updateBuilderTrendJob({ id: row.id, customer: value }).unwrap();
        toast.success("BuilderTrend job updated");
      } catch (error) {
        handleErrors(error);
      }
    };

  const curriedCreateAdaptiveCustomer = (row: BuilderTrendJob) => async () => {
    try {
      await createBuilderTrendCustomer(row.id).unwrap();
      toast.success("Adaptive job created");
    } catch (error) {
      handleErrors(error);
    }
  };

  const curriedSyncBudget = (row: BuilderTrendJob) => async () => {
    onPullBuilderTrendBudget(row);
  };

  return (
    <TableProvider id="buildertrend-jobs-table">
      <StickyProvider onResize={onResize}>
        <Sticky
          style={{
            paddingTop: "var(--spacing-2xl)",
            paddingBottom: "var(--spacing-lg)",
          }}
        >
          <Flex width="full" gap="xl">
            <Flex width="full" maxWidth="350px">
              <TextField
                addonAfter={<Icon name="search" />}
                placeholder="Filter jobs"
                value={searchQuery}
                onChange={setSearchQuery}
                messageVariant="hidden"
              />
            </Flex>
          </Flex>
        </Sticky>
        <StickyMeasurer>
          <JobsContext.Provider
            value={{
              curriedOnChangeCustomer,
              curriedCreateAdaptiveCustomer,
              curriedSyncBudget,
            }}
          >
            <Table
              sort={sort}
              data={jobs?.results}
              size="sm"
              header={{ hide: jobs?.count === 0, sticky: { offset } }}
              loading={isLoading}
              columns={COLUMNS}
              pagination={tablePagination}
            />
          </JobsContext.Provider>
        </StickyMeasurer>
      </StickyProvider>
    </TableProvider>
  );
};
