import { arraySchema, idSchema } from "@utils/schema";
import { z } from "zod";

import { LINKS } from "./constants";

export const Types = z.enum([
  "bill",
  "billpayment",
  "comment",
  "customer",
  "expense",
  "invoice",
  "line",
  "purchaseorder",
  "vendor",
  "invoiceline",
  "change",
  "changeline",
  "cardtransactionexpenselink",
  "lienwaiver",
]);

const itemSchema = z.object({
  id: idSchema.nullish(),
  to: z
    .string()
    .nullish()
    .transform((value) => value ?? ""),
  urlName: z.string().nullish(),
  queryString: z.string().nullish(),
  searchDisplayName: z.string(),
});

const itemsSchema = arraySchema(itemSchema).transform((values) =>
  values.filter(
    (
      value
    ): value is typeof value & { id: NonNullable<(typeof value)["id"]> } =>
      !!value.id
  )
);

type Entries = [z.infer<typeof Types>, z.infer<typeof itemsSchema>][];

export const searchGetResponseSchema = z
  .object({ results: z.record(Types, itemsSchema) })
  .transform(({ results }) => {
    return (Object.entries(results ?? {}) as Entries)
      .reduce<Entries>((acc, [key, values]) => {
        if (values.length === 0) return acc;

        return [
          ...acc,
          [
            key,
            values
              .filter((value) => Boolean(value.id))
              .map((value) => {
                let to = LINKS[key].replace("{{record.id}}", value.id);

                const isCurrentUrlRedirect = to.includes("{{currentUrl}}");

                const currentUrl = new URL(window.location.href);
                to = to.replace("{{currentUrl}}", currentUrl.pathname);

                if (value.urlName) {
                  to = to.replace("{{record.urlName}}", value.urlName);
                }

                if (value.queryString) {
                  // Merge current query params with the new query params
                  const [searchPart, hashPart] = value.queryString.split("#");
                  const newParams = new URLSearchParams(searchPart);

                  if (isCurrentUrlRedirect) {
                    for (const [
                      paramKey,
                      paramValue,
                    ] of currentUrl.searchParams) {
                      newParams.set(paramKey, paramValue);
                    }
                  }

                  to = `${to}?${newParams.toString()}${hashPart ? `#${hashPart}` : ""}`;
                }

                return { ...value, to };
              }),
          ],
        ];
      }, [])
      .sort(([keyA], [keyB]) => {
        if (keyA === "vendor") return -1;

        if (keyB === "vendor") return 1;

        if (keyA === "customer") return -1;

        if (keyB === "customer") return 1;

        return 0;
      });
  });
