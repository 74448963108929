import { formatDate, parseDate } from "@adaptive/design-system/utils";
import { addDays, subDays } from "date-fns";

import type {
  CardFilter,
  DaysThreshold,
  LinkedCost,
} from "../components/types";

type TransformCostToFiltersParams = Partial<LinkedCost> & {
  daysThreshold?: DaysThreshold;
  cardFilters?: CardFilter[];
};
export const transformCostToFilters = ({
  daysThreshold = { daysBefore: 1, daysAfter: 1 },
  cardFilters,
  ...cost
}: TransformCostToFiltersParams) => {
  const enhancedFilters: Record<string, unknown> = {};

  if ("paymentAccount" in cost && cost.paymentAccount?.url) {
    enhancedFilters[cost.paymentAccount.url] = {
      label: cost.paymentAccount.displayName,
      value: cost.paymentAccount.url,
      groupLabel: "Payment account",
    };
  }

  if (cost.vendor?.url) {
    enhancedFilters[cost.vendor.url] = {
      label: cost.vendor.displayName,
      value: cost.vendor.url,
      groupLabel: "Vendor",
    };
  }

  if ("date" in cost && cost.date) {
    enhancedFilters["date_after"] = formatDate(
      subDays(cost.date, daysThreshold.daysBefore),
      "yyyy-MM-dd"
    );
    enhancedFilters["date_before"] = formatDate(
      addDays(cost.date, daysThreshold.daysAfter),
      "yyyy-MM-dd"
    );
  }

  if ("transactionDate" in cost && cost.transactionDate) {
    const date = parseDate(cost.transactionDate, "yyyy-MM-dd") as Date | null;

    if (date) {
      enhancedFilters["date_after"] = formatDate(
        subDays(date, daysThreshold.daysBefore),
        "yyyy-MM-dd"
      );
      enhancedFilters["date_before"] = formatDate(
        addDays(date, daysThreshold.daysAfter),
        "yyyy-MM-dd"
      );
    }
  }

  for (const filter of cardFilters ?? []) {
    if (!filter?.name) continue;

    enhancedFilters[filter.id] = {
      label: filter.name,
      value: filter.id.toString(),
      groupLabel: "Linked card",
    };
  }

  return enhancedFilters;
};
