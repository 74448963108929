import { dotObject } from "@adaptive/design-system/utils";

type GenerateUrlByHumanReadableTypeParams = {
  id: string | number;
  humanReadableType:
    | "Bill"
    | "Expense"
    | "Receipt"
    | "Vendor Credit"
    | "Labor"
    | "Invoice"
    | "PO"
    | "Purchase Order"
    | (string & {}); // eslint-disable-line @typescript-eslint/ban-types
  [key: string]: unknown;
};

export const generateUrlByHumanReadableType = ({
  id,
  humanReadableType,
  ...rest
}: GenerateUrlByHumanReadableTypeParams) => {
  if (["Bill", "Vendor Credit"].includes(humanReadableType)) {
    return `/bills/${id}`;
  }

  if (["Expense", "Receipt", "Labor"].includes(humanReadableType)) {
    return `/expenses/${id}`;
  }

  if (["Invoice"].includes(humanReadableType)) {
    return `/invoices/${id}`;
  }

  if (["PO", "Purchase Order"].includes(humanReadableType)) {
    return `/purchase-orders/${id}`;
  }

  if (["Bill payment"].includes(humanReadableType)) {
    return `/bills/?billPaymentId=${id}`;
  }

  const customerId = dotObject.get(rest, "customer.id");

  if (["Budget line"].includes(humanReadableType) && customerId) {
    return `/jobs/${customerId}?commentsForBudgetLineId=${id}`;
  }
};
