import { useEffect, useMemo, useRef, useState } from "react";
import {
  useDebouncedValue,
  useDeepMemo,
  useEvent,
  useLocalStorageState,
} from "@adaptive/design-system/hooks";
import { is, isEqual, sortBy, suffixify } from "@adaptive/design-system/utils";
import {
  useGetBudgetLineMarkupsQuery,
  useGetBudgetLinesQuery,
} from "@api/budgets";
import type { QueryItem } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useJobSettings } from "@src/jobs/hooks";
import { useJobInfo } from "@store/jobs";
import { useClientSettings } from "@store/user";

import { LINE_COLUMN_ID_TO_PROP, MARKUP_COLUMN_ID_TO_PROP } from "../constants";
import type { Line } from "../lines";
import type { Markup } from "../markups";
import {
  formatter,
  getBudgetRemaining,
  getMarkupTotals,
  getRemaining,
  groupByCategory,
  groupByParent,
  matchItem,
  sortByReference,
  summarizeGroup,
} from "../utils";

import { useTableTotals } from "./use-table-totals";

export type UseLinesAndMarkupsFilters = Record<
  | "vendor"
  | "account"
  | "cost_code"
  | "category"
  | "draw_status"
  | "budget_status"
  | "billable_status"
  | "transaction_type",
  string[]
> &
  Record<"query" | "combined_date_before" | "combined_date_after", string>;

const EMPTY_MARKUPS: Markup[] = [];

const EMPTY_BUDGET_LINES: Line[] = [];

const API_PARAMS: (keyof UseLinesAndMarkupsFilters)[] = [
  "vendor",
  "draw_status",
  "budget_status",
  "billable_status",
  "transaction_type",
  "combined_date_after",
  "combined_date_before",
];

const VIEW_MODE_SORT: Record<string, string> = {
  items: "nameItems",
  quickbooks: "nameQuickbooks",
  categories: "categoryCategories",
};

export const useLinesAndMarkups = () => {
  const previousLinesRef = useRef<Line[]>(undefined);

  const previousMarkupsRef = useRef<Markup[]>(undefined);

  const previousGroupedLinesRef = useRef<Line[]>(undefined);

  const previousGroupedMarkupsRef = useRef<Markup[]>(undefined);

  const settings = useClientSettings();

  const { job, status } = useJobInfo();

  const [isFiltering, setIsFiltering] = useState(false);

  const { changeTrackingEnabled, ownersAmountEnabled, categoriesEnabled } =
    useJobSettings();

  const [query, setQuery] = useLocalStorageState(
    suffixify("budgets-table", job.id, "query"),
    ""
  );

  const [viewMode, setViewMode] = useLocalStorageState(
    suffixify("budgets-table", job.id, "view-mode"),
    "items"
  );

  const [sort, setSort] = useLocalStorageState(
    suffixify("budgets-table", job.id, "sort"),
    `nameItems`
  );

  const viewModes = useMemo(
    () => [
      { label: "Items", value: "items" },
      { label: "Categories", value: "categories" },
      { label: "QuickBooks", value: "quickbooks" },
    ],
    []
  );

  const adjustedViewMode = useMemo(() => {
    const mode =
      viewModes.find((mode) => mode.value === viewMode) ?? viewModes[0];

    return (mode.value === "categories" && !categoriesEnabled) ||
      !settings.budgetsViewModeEnabled
      ? viewModes[0]
      : mode;
  }, [categoriesEnabled, viewMode, viewModes, settings.budgetsViewModeEnabled]);

  const {
    filters: internalFilters = [],
    setFilters,
    rawFilters,
  } = useTableFilters({
    formatter,
    storageKey: suffixify("budgets-table", job.id, "filters"),
  });

  const [isLoadingJob] = useDebouncedValue(
    status !== "loaded",
    import.meta.env.MODE === "test" ? 0 : 1
  );

  const enhancedFilters = useDeepMemo(
    () =>
      internalFilters.reduce(
        (acc, { dataIndex, value }) =>
          dataIndex === "realm"
            ? acc
            : {
                ...acc,
                [dataIndex]: [
                  ...(acc[dataIndex as keyof UseLinesAndMarkupsFilters] || []),
                  value,
                ],
              },
        {} as UseLinesAndMarkupsFilters
      ),
    [internalFilters]
  );

  const hasFilters =
    Object.values(enhancedFilters).some((value) =>
      is.array(value) ? value.filter(Boolean).length > 0 : !!value
    ) || !!query;

  const params = useDeepMemo(() => {
    const items: QueryItem[] = [];

    for (const key of API_PARAMS) {
      const value = enhancedFilters[key];

      (is.array(value) ? value : [value]).forEach((value) => {
        if (value) items.push({ dataIndex: key, value });
      });
    }

    return items.length ? items : undefined;
  }, [enhancedFilters]);

  const {
    data: filteredLines = EMPTY_BUDGET_LINES,
    isLoading: filteredLinesIsLoading,
    requestId: filteredLinesRequestId,
    isFetching: filteredLinesIsFetching,
    isError: filteredLinesIsError,
  } = useGetBudgetLinesQuery({ customerId: job.id, params });

  const {
    data: filteredMarkups = EMPTY_MARKUPS,
    isLoading: filteredMarkupsIsLoading,
    requestId: filteredMarkupsRequestId,
    isFetching: filteredMarkupsIsFetching,
    isError: filteredMarkupsIsError,
  } = useGetBudgetLineMarkupsQuery({ customerId: job.id, params });

  const [debouncedQuery] = useDebouncedValue(
    query,
    import.meta.env.MODE === "test" ? 0 : 300
  );

  const localFilters = useDeepMemo<Partial<UseLinesAndMarkupsFilters>>(() => {
    const items: Record<string, string | string[]> = {
      ...(debouncedQuery ? { query: debouncedQuery } : {}),
    };

    Object.entries(enhancedFilters).forEach(([key, value]) => {
      const typedKey = key as keyof UseLinesAndMarkupsFilters;
      if (!API_PARAMS.includes(typedKey)) {
        items[typedKey] = value;
      }
    });

    return Object.keys(items).length ? items : {};
  }, [debouncedQuery, enhancedFilters]);

  const isSearching = !isEqual(query, debouncedQuery);

  const isLoading =
    isSearching ||
    isFiltering ||
    isLoadingJob ||
    filteredLinesIsLoading ||
    filteredMarkupsIsLoading;

  const enhancedSetSort = useEvent((value: string) => {
    previousLinesRef.current = undefined;
    previousMarkupsRef.current = undefined;
    previousGroupedLinesRef.current = undefined;
    previousGroupedMarkupsRef.current = undefined;
    setSort(value);
  });

  const enhancedSetViewMode = useEvent((value: string) => {
    enhancedSetSort(VIEW_MODE_SORT[value]);
    setViewMode(value);
  });

  const sortOptions = useMemo(() => {
    let key = sort;
    let direction: "asc" | "desc" = "asc";
    let nullishOrder: "first" | "last" = "first";

    if (sort.startsWith("-")) {
      key = sort.slice(1);
      direction = "desc";
      nullishOrder = "last";
    }

    return { key, direction, nullishOrder };
  }, [sort]);

  const extraQueryKeys = useMemo(
    () =>
      settings.itemDescriptionEnabled
        ? ["jobCostMethod.description"]
        : undefined,
    [settings.itemDescriptionEnabled]
  );

  const lines = useMemo(() => {
    const key =
      LINE_COLUMN_ID_TO_PROP[
        sortOptions.key as keyof typeof LINE_COLUMN_ID_TO_PROP
      ];

    const enhancedLines = filteredLines.reduce((acc, line) => {
      const enhancedLine = {
        ...line,
        remaining: getRemaining({
          ...line,
          ownersAmountEnabled,
          builderRevisedAmount: line.builderRevisedAmount ?? line.builderAmount,
          changeTrackingEnabled,
        }).invoicedRemainingAmount,
        budgetRemaining: getBudgetRemaining(line),
      };

      return matchItem({
        item: enhancedLine,
        filters: localFilters,
        extraQueryKeys,
      })
        ? [...acc, enhancedLine]
        : acc;
    }, [] as Line[]);

    if (!key) return enhancedLines;

    const sortedLines = previousLinesRef.current
      ? sortByReference({
          key: "id",
          original: enhancedLines,
          reference: previousLinesRef.current,
        })
      : sortBy(enhancedLines, key, sortOptions);

    if (!previousLinesRef.current && !isLoading) {
      previousLinesRef.current = sortedLines;
    }

    return sortedLines;
  }, [
    isLoading,
    sortOptions,
    localFilters,
    filteredLines,
    extraQueryKeys,
    ownersAmountEnabled,
    changeTrackingEnabled,
  ]);

  const markups = useMemo(() => {
    if (isLoading) return [];

    const key =
      MARKUP_COLUMN_ID_TO_PROP[
        sortOptions.key as keyof typeof MARKUP_COLUMN_ID_TO_PROP
      ];

    const enhancedMarkups = filteredMarkups.reduce((acc, markup) => {
      const enhancedMarkup = {
        ...markup,
        budgetLine: {
          ...markup.budgetLine,
          remaining: getRemaining({
            ...markup,
            spent: markup.budgetLine?.spent ?? 0,
            invoicedAmount: markup.budgetLine?.invoicedAmount ?? 0,
            ownersAmountEnabled,
            changeTrackingEnabled,
          }).invoicedRemainingAmount,
          budgetRemaining: markup.budgetLine
            ? getBudgetRemaining(markup.budgetLine)
            : 0,
        },
      } as Markup;

      return matchItem({
        item: enhancedMarkup,
        filters: localFilters,
        extraQueryKeys,
      })
        ? [...acc, enhancedMarkup]
        : acc;
    }, [] as Markup[]);

    if (!key) return enhancedMarkups;

    const sortedMarkups = previousMarkupsRef.current
      ? sortByReference({
          key: "jobCostMethod.id",
          original: enhancedMarkups,
          reference: previousMarkupsRef.current,
        })
      : sortBy(enhancedMarkups, key, sortOptions);

    if (!previousMarkupsRef.current && !isLoading) {
      previousMarkupsRef.current = sortedMarkups;
    }

    return sortedMarkups;
  }, [
    isLoading,
    sortOptions,
    localFilters,
    extraQueryKeys,
    filteredMarkups,
    ownersAmountEnabled,
    changeTrackingEnabled,
  ]);

  const { lineItemsTotals, markupLineTotals, grandLineItemTotals } =
    useTableTotals({
      budgetLines: lines,
      markupLines: markups,
    });

  const enhancedLines = useMemo(() => {
    if (adjustedViewMode.value === "items") return lines;

    if (adjustedViewMode.value === "categories") {
      const unsortedLines = summarizeGroup({
        data: groupByCategory(lines),
        ownersAmountEnabled,
        changeTrackingEnabled,
      });

      let key =
        LINE_COLUMN_ID_TO_PROP[
          sortOptions.key as keyof typeof LINE_COLUMN_ID_TO_PROP
        ];

      if (!key) return unsortedLines;

      if (key === "jobCostMethod.displayName") {
        key = "category.displayName";
      }

      const sortedLines = previousGroupedLinesRef.current
        ? sortByReference({
            key: "category.id",
            original: unsortedLines,
            reference: previousGroupedLinesRef.current,
          })
        : sortBy(unsortedLines, key, sortOptions);

      if (!previousGroupedLinesRef.current && !isLoading) {
        previousGroupedLinesRef.current = sortedLines;
      }

      return sortedLines;
    }

    if (adjustedViewMode.value === "quickbooks") {
      const unsortedLines = summarizeGroup({
        data: groupByParent(lines),
        ownersAmountEnabled,
        changeTrackingEnabled,
      });

      const key =
        LINE_COLUMN_ID_TO_PROP[
          sortOptions.key as keyof typeof LINE_COLUMN_ID_TO_PROP
        ];

      if (!key) return unsortedLines;

      const sortedLines = previousGroupedLinesRef.current
        ? sortByReference({
            key: "id",
            original: unsortedLines,
            reference: previousGroupedLinesRef.current,
          })
        : sortBy(unsortedLines, key, sortOptions);

      if (!previousGroupedLinesRef.current && !isLoading) {
        previousGroupedLinesRef.current = sortedLines;
      }

      return sortedLines;
    }

    return lines;
  }, [
    lines,
    isLoading,
    sortOptions,
    ownersAmountEnabled,
    changeTrackingEnabled,
    adjustedViewMode.value,
  ]);

  const enhancedMarkups = useMemo(() => {
    if (adjustedViewMode.value === "items") return markups;

    if (adjustedViewMode.value === "categories") {
      const unsortedMarkups = markups
        .reduce((acc, markup) => {
          const groupIndex = acc.findIndex(
            (group) =>
              group.budgetLine?.category?.id === markup.budgetLine?.category?.id
          );

          if (groupIndex !== -1) {
            (acc[groupIndex].data ??= []).push(markup);
            return acc;
          }

          return [...acc, { ...markup, data: [markup] }];
        }, [] as Markup[])
        .map((markup) => {
          const {
            invoicedAmount,
            builderRemainingAmount,
            invoicedRemainingAmount,
            ...totals
          } = getMarkupTotals({
            data: markup.data ?? [],
            ownersAmountEnabled,
            changeTrackingEnabled,
          });

          return {
            ...markup,
            budgetLine: markup.budgetLine
              ? {
                  ...markup.budgetLine,
                  remaining: invoicedRemainingAmount,
                  invoicedAmount,
                  budgetRemaining: builderRemainingAmount,
                }
              : undefined,
            ...totals,
          } as Markup;
        });

      let key =
        MARKUP_COLUMN_ID_TO_PROP[
          sortOptions.key as keyof typeof MARKUP_COLUMN_ID_TO_PROP
        ];

      if (!key) return unsortedMarkups;

      if (key === "jobCostMethod.displayName") {
        key = "category.displayName";
      }

      const sortedMarkups = previousGroupedMarkupsRef.current
        ? sortByReference({
            key: "category.id",
            original: unsortedMarkups,
            reference: previousGroupedMarkupsRef.current,
          })
        : sortBy(unsortedMarkups, key, sortOptions);

      if (!previousGroupedMarkupsRef.current && !isLoading) {
        previousGroupedMarkupsRef.current = sortedMarkups;
      }

      return sortedMarkups;
    }

    return markups;
  }, [
    markups,
    isLoading,
    sortOptions,
    ownersAmountEnabled,
    changeTrackingEnabled,
    adjustedViewMode.value,
  ]);

  useEffect(() => {
    if (params?.length) setIsFiltering(true);
  }, [params]);

  useEffect(() => {
    if (!filteredLinesIsFetching && !filteredMarkupsIsFetching) {
      setIsFiltering(false);
    }
  }, [
    filteredLinesRequestId,
    filteredLinesIsFetching,
    filteredMarkupsRequestId,
    filteredMarkupsIsFetching,
  ]);

  return {
    sort,
    setSort: enhancedSetSort,
    lines: enhancedLines,
    totals: grandLineItemTotals,
    markups: enhancedMarkups,
    filters: {
      ...(debouncedQuery ? { query: debouncedQuery } : {}),
      ...enhancedFilters,
    },
    query,
    setQuery,
    viewMode: adjustedViewMode,
    isLoading,
    viewModes,
    setFilters,
    rawFilters,
    hasFilters,
    isError: filteredLinesIsError || filteredMarkupsIsError,
    lineTotals: lineItemsTotals,
    setViewMode: enhancedSetViewMode,
    markupTotals: markupLineTotals,
  };
};
