import { arraySchema, idSchema } from "@utils/schema";
import { z } from "zod";

export const LIEN_WAIVER_STATUS = {
  NOT_SELECTED: "not_selected",
  NOT_REQUIRED: "not_required",
  NOT_REQUESTED: "not_requested",
  REQUESTED: "requested",
  MARKED_AS_REQUESTED: "marked_as_requested",
  SIGNED: "signed",
  MARKED_AS_SIGNED: "marked_as_signed",
} as const;

const lienWaiverStatusSchema = z.enum([
  LIEN_WAIVER_STATUS.NOT_SELECTED,
  LIEN_WAIVER_STATUS.NOT_REQUIRED,
  LIEN_WAIVER_STATUS.NOT_REQUESTED,
  LIEN_WAIVER_STATUS.REQUESTED,
  LIEN_WAIVER_STATUS.MARKED_AS_REQUESTED,
  LIEN_WAIVER_STATUS.SIGNED,
  LIEN_WAIVER_STATUS.MARKED_AS_SIGNED,
]);

const notRequiredStatuses = z.enum([
  LIEN_WAIVER_STATUS.NOT_SELECTED,
  LIEN_WAIVER_STATUS.NOT_REQUIRED,
]);

export const lienWaiverTemplateSchema = z.object({
  id: idSchema,
  type: z.string(),
  contentHtml: z
    .string()
    .nullish()
    .transform((value) => value || ""),
  isDefault: z.boolean(),
  signatureFields: arraySchema(z.string()),
  updatedAt: z
    .string()
    .nullish()
    .transform((value) => value || ""),
  url: z
    .string()
    .url()
    .nullish()
    .transform((value) => value || ""),
});

export const lienWaiverTemplateFormSchema = z.object({
  id: idSchema.nullish(),
  type: z.string().min(1, "Required"),
  contentHtml: z.string().min(1, "Required"),
  isDefault: z.boolean(),
  signatureFields: z.record(z.boolean()),
  url: z.string().url().nullish(),
});

export const dynamicVariablesListSchema = z.object({
  id: idSchema,
  type: z.string(),
  label: z.string(),
  mockValue: z.string(),
  order: z.number(),
  url: z.string().url(),
});

export const signatureFieldsSchema = z.object({
  id: idSchema,
  type: z.string(),
  isDefault: z.boolean(),
  isEditable: z.boolean(),
  url: z.string().url(),
});

export const lienWaiverTypeSchema = z.object({
  id: idSchema,
  name: z.string(),
  url: z.string().url(),
});

export const getLienWaiverTypesResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(lienWaiverTypeSchema),
});

export const getSignatureFieldsResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(signatureFieldsSchema),
});

export const getDynamicVariablesListResponseSchema = z.object({
  count: z.number(),
  results: arraySchema(dynamicVariablesListSchema),
});

export const getLienWaiverTemplatesPayloadSchema = z
  .object({
    withStatus: z.boolean().optional(),
  })
  .or(z.void());

export const getLienWaiverTemplatesResponseSchema = arraySchema(
  lienWaiverTemplateSchema
);

export const saveLienWaiverTemplatePayloadSchema = lienWaiverTemplateFormSchema
  .omit({ signatureFields: true })
  .merge(lienWaiverTemplateSchema.pick({ signatureFields: true }));

export const saveLienWaiverTemplateResponseSchema = z.any().nullish();

export const deleteLienWaiverTemplatePayloadSchema = z.object({
  id: idSchema,
});
export const simpleBillSchema = z.object({
  id: idSchema,
  docNumber: z.string(),
});

export const deleteLienWaiverTemplateSuccessResponseSchema = z.unknown();

export const deleteLienWaiverTemplateErrorResponseSchema =
  simpleBillSchema.array();

export const deleteLienWaiverTemplateResponseSchema = z.union([
  deleteLienWaiverTemplateErrorResponseSchema,
  deleteLienWaiverTemplateSuccessResponseSchema,
]);

export const getLienWaiverTemplatePayloadSchema = z.object({
  id: idSchema,
});

export const getLienWaiverTemplateResponseSchema = lienWaiverTemplateSchema;

export const renderLienWaiverPdfPayloadSchema = z.object({
  htmlContent: z.string(),
  signatureFields: arraySchema(z.string()),
});

export const renderBillLienWaiverPdfPayloadSchema = z.object({
  billId: idSchema,
  billPaymentId: idSchema.optional(),
  customerId: idSchema.optional(),
  lienWaiverTemplateId: idSchema,
  paymentAmount: z.number().nullish(),
});

export const renderLienWaiverPDFResponseSchema = z.object({
  pdf: z.string().nullish(),
  missingFields: z.array(z.string()).nullish(),
});

export const requestLienWaiverFormSchema = z.object({
  vendorEmail: z.string().email().min(1, "Required"),
  message: z.string(),
  files: z.array(z.any()),
});

export const requestLienWaiverPayloadSchema = z.object({
  vendorEmail: z.string().email().nullish(),
  vendorId: idSchema,
  files: z.array(z.any()).nullish(),
  status: z.string().optional(),
  billId: idSchema.optional(),
  billPaymentId: idSchema.optional(),
  customerId: idSchema.optional(),
  message: z.string(),
  lienWaiverTemplateId: idSchema,
  paymentAmount: z.number().nullish(),
  signedAt: z.string().nullish(),
  vendor: z.string().nullish(),
});

export const updateLienWaiverRequestPayloadSchema = z.object({
  id: idSchema,
  status: lienWaiverStatusSchema,
  pdf: z.any().nullish(),
  paymentAmount: z.number().nullish(),
  lienWaiverTemplateId: idSchema.nullish(),
});

export const patchLienWaiverRequestPayloadSchema =
  // billPaymentId will be used to optimistically update the bill payment with the new lien waiver status
  updateLienWaiverRequestPayloadSchema.extend({
    billPaymentId: idSchema.nullish(),
  });

export const getExternalLienWaiverRequestPayloadSchema = z.object({
  id: idSchema,
});

export const fileExportSchema = z.object({
  id: idSchema,
  document: z.string(),
});

export const getExternalLienWaiverRequestResponseSchema = z.object({
  id: idSchema,
  vendor: z.string(),
  fileExport: fileExportSchema,
  bill: z.object({
    id: idSchema,
    docNumber: z.string(),
    job: z.string(),
    url: z.string(),
    token: z.string(),
  }),
  signatureFields: z.array(z.string()),
  client: z.string(),
  paymentAmount: z.string(),
  comment: z.array(z.any()),
  signedAt: z.string().nullish(),
  pdf: z.string().nullish(),
  job: z.string().nullish(),
});

export const signLienWavierRequestPayloadSchema = z.object({
  id: idSchema,
  signature: z.any().nullish(),
  printedName: z.string().nullish(),
  title: z.string().nullish(),
});

export const lienWaiverVendorSchema = z.object({
  id: idSchema,
  url: z.string(),
  displayName: z.string(),
  email: z.string().email().nullish(),
});

const revisionEventSchema = z.object({
  author: z.object({
    id: idSchema,
    url: z.string(),
    fullName: z.string(),
  }),
  createdAt: z.string(),
  timelineEventType: z.string(),
});

export const lienWaiverSchema = z.object({
  id: idSchema,
  bill: z.string().url().nullish(),
  billId: idSchema,
  billPayment: z.string().url().nullish(),
  fileExport: fileExportSchema,
  lienWaiverTemplate: z.string().url().nullish(),
  message: z.string(),
  printedName: z.string().nullish(),
  paymentAmount: z.string(),
  pdf: z.string().nullish(),
  status: lienWaiverStatusSchema,
  vendor: lienWaiverVendorSchema,
  vendorEmail: z.string().nullish(),
  vendorReminderCanBeSent: z.boolean(),
  signedAt: z.string().nullish(),
  revisionEvent: revisionEventSchema.nullish(),
});

export const lienWaiverCustomerSchema = z.object({
  customer: z.string().url(),
  lienWaiverTemplate: z.string().optional(),
  status: notRequiredStatuses.optional(),
  sendLienWaiverOnPayment: z.boolean(),
  lienWaiverTemplateIsValid: z.boolean(),
});
