import React from "react";
import { dialog, Flex, Prose, Text, toast } from "@adaptive/design-system";

import { PurchaseOrderLearnMoreLink } from "./purchase-order-learn-more-link";

type PromptConversionWarningParams = {
  onConfirm: () => void | Promise<void>;
};

export const promptConversionWarning = ({
  onConfirm,
}: PromptConversionWarningParams) => {
  dialog.confirmation({
    title: (
      <>
        Please confirm that you understand
        <br />
        the following before conversion:
      </>
    ),
    size: "auto",
    align: "left",
    variant: "dialog",
    message: (
      <Flex gap="md" direction="column" minWidth="505px">
        <Prose>
          <ul>
            <li>Adaptive PO&apos;s don&apos;t sync with QuickBooks</li>
            <li>Your QuickBooks PO will be deleted</li>
            <li>
              After conversion you will be able to edit linked objects within
              Adaptive
            </li>
          </ul>
        </Prose>
        <Flex margin={["none", "none", "none", "28px"]}>
          <PurchaseOrderLearnMoreLink />
        </Flex>
      </Flex>
    ),
    action: {
      primary: {
        children: "Confirm",
        onClick: () => onConfirm(),
      },
    },
  });
};

type PromptConversionToastParams = {
  count?: number;
};

export const promptConversionToast = ({
  count,
}: PromptConversionToastParams = {}) => {
  toast.success(
    <Flex as="span" direction="column">
      <Text as="strong" weight="bold">
        {count !== undefined ? `${count} ` : ""}Purchase order
        {count !== undefined && count > 1 ? `s` : ""} converted
      </Text>
      <Text as="span">
        Note: it may take some time for the original purchase
        <br />
        order to be deleted in QuickBooks
      </Text>
    </Flex>
  );
};

type PromptMergeToastParams = {
  count: number;
  action: "link" | "merge";
  docNumber: string;
  type: "qb" | "bt";
};

export const ACTIONS: Record<PromptMergeToastParams["action"], string> = {
  link: "linked",
  merge: "merged",
};

export const promptMergeToast = ({
  count,
  action,
  docNumber,
  type,
}: PromptMergeToastParams) => {
  toast.success(
    <Flex as="span" direction="column">
      <Text as="strong" weight="bold">
        {count} line
        {count > 1 ? "s" : ""} {ACTIONS[action]} to{" "}
        {type === "qb" ? "QuickBooks" : "BuilderTrend"} PO#{docNumber}
      </Text>
      <Text as="span">
        Bills linked to {type === "qb" ? "QuickBooks" : "BuilderTrend"} POs are
        not editable.{" "}
        {type === "qb" && (
          <>
            Convert
            <br />
            the linked PO for full edit access.
          </>
        )}
      </Text>
    </Flex>,
    { duration: 10000 }
  );
};
