import { updateBillPayment } from "@bill-payment/store";
import { api } from "@store/api-simplified";
import { handleResponse } from "@utils/api";
import {
  camelToSnakeCase,
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import {
  deleteLienWaiverTemplateErrorResponseSchema,
  getDynamicVariablesListResponseSchema,
  getExternalLienWaiverRequestResponseSchema,
  getLienWaiverTemplateResponseSchema,
  getLienWaiverTemplatesResponseSchema,
  getLienWaiverTypesResponseSchema,
  getSignatureFieldsResponseSchema,
  renderLienWaiverPDFResponseSchema,
  saveLienWaiverTemplateResponseSchema,
} from "./schemas";
import {
  type DeleteLienWaiverTemplatePayload,
  type DeleteLienWaiverTemplateResponse,
  type GetDynamicVariablesListResponse,
  type GetExternalLienWaiverRequestPayloadSchema,
  type GetExternalLienWaiverRequestResponseSchema,
  type GetLienWaiverTemplatePayload,
  type GetLienWaiverTemplateResponse,
  type GetLienWaiverTemplatesPayload,
  type GetLienWaiverTemplatesResponse,
  type GetLienWaiverTypesListResponse,
  type GetSignatureFieldsResponse,
  type PatchLienWaiverRequestPayloadSchema,
  type RenderBillLienWaiverTemplatePDFPayload,
  type RenderLienWaiverTemplatePDFPayload,
  type RenderLienWaiverTemplatePDFResponse,
  type RequestLienWaiverPayloadSchema,
  type SaveLienWaiverTemplatePayload,
  type SaveLienWaiverTemplateResponse,
  type SignLienWavierRequestPayloadSchema,
  type UpdateLienWaiverRequestPayloadSchema,
} from "./types";

const TAG = "LienWaiverTemplates";
const EXTERNAL_LIEN_WAIVER_TAG = "ExternalLienWaiver";
const TEMPLATE_PDF_TAG = "TemplatePDF";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: [TAG, EXTERNAL_LIEN_WAIVER_TAG, TEMPLATE_PDF_TAG],
});

export const lienWaiverTemplatesApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getDynamicVariables: builder.query<GetDynamicVariablesListResponse, void>({
      query: () => {
        return {
          url: "dynamicvariables/",
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getDynamicVariablesListResponseSchema
            );
          },
        };
      },
    }),
    getLienWaiverTypesList: builder.query<GetLienWaiverTypesListResponse, void>(
      {
        query: () => {
          return {
            url: "lienwaivertypes/",
            responseHandler: async (response) => {
              const data = await response.json();

              return handleResponse(
                transformKeysToCamelCase(data),
                getLienWaiverTypesResponseSchema
              );
            },
          };
        },
      }
    ),
    getSignatureFields: builder.query<GetSignatureFieldsResponse, void>({
      query: () => {
        return {
          url: "signaturefields/",
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getSignatureFieldsResponseSchema
            );
          },
        };
      },
    }),
    getLienWaiverTemplates: builder.query<
      GetLienWaiverTemplatesResponse,
      GetLienWaiverTemplatesPayload
    >({
      query: (args) => {
        const { withStatus } = args || {};

        return {
          url: `lienwaivertemplates/${withStatus ? "?with_status=true" : ""}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              data.map(transformKeysToCamelCase),
              getLienWaiverTemplatesResponseSchema
            );
          },
        };
      },
      providesTags: (response) =>
        response?.length
          ? [
              ...response.map(
                ({ id }) =>
                  ({
                    type: TAG,
                    id,
                  }) as const
              ),
            ]
          : [{ type: TAG, id: "LIST" }],
    }),
    getLienWaiverTemplate: builder.query<
      GetLienWaiverTemplateResponse,
      GetLienWaiverTemplatePayload
    >({
      query: ({ id }) => {
        return {
          url: `lienwaivertemplates/${id}/`,
          responseHandler: async (response) => {
            const data = await response.json();

            if (response.status === 404) return response;

            return handleResponse(
              transformKeysToCamelCase(data),
              getLienWaiverTemplateResponseSchema
            );
          },
        };
      },
      providesTags: (response) =>
        response && "id" in response && response?.id
          ? [{ type: TAG, id: response.id }]
          : [{ type: TAG, id: "DETAIL" }],
    }),
    saveLienWaiverTemplate: builder.mutation<
      SaveLienWaiverTemplateResponse,
      SaveLienWaiverTemplatePayload
    >({
      query: (payload) => {
        const isNewTemplate = !("id" in payload) || !payload.id;
        if (isNewTemplate) {
          delete payload.id;
        }

        return {
          url: `lienwaivertemplates/${isNewTemplate ? "" : `${payload.id}/`}`,
          method: isNewTemplate ? "POST" : "PATCH",
          body: transformKeysToSnakeCase(payload),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              saveLienWaiverTemplateResponseSchema
            );
          },
        };
      },
      invalidatesTags: (_, error) => (error ? [] : [TAG]),
    }),
    deleteLienWaiverTemplate: builder.mutation<
      DeleteLienWaiverTemplateResponse,
      DeleteLienWaiverTemplatePayload
    >({
      query: (payload) => {
        return {
          url: `lienwaivertemplates/${payload.id}/`,
          method: "DELETE",
          body: transformKeysToSnakeCase(payload),
          responseHandler: async (response) => {
            const isError = response.status === 400;
            const data = isError ? await response.json() : null;
            return isError
              ? handleResponse(
                  data.map(transformKeysToCamelCase),
                  deleteLienWaiverTemplateErrorResponseSchema
                )
              : data;
          },
        };
      },
      invalidatesTags: (_, error) => (error ? [] : [TAG]),
    }),
    renderLienWaiverPdf: builder.mutation<
      RenderLienWaiverTemplatePDFResponse,
      RenderLienWaiverTemplatePDFPayload
    >({
      query: (payload) => {
        return {
          url: "vendor-comms/lien-waiver-to-pdf/",
          method: "POST",
          body: transformKeysToSnakeCase(payload),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              renderLienWaiverPDFResponseSchema
            );
          },
        };
      },
    }),
    renderBillLienWaiverPdf: builder.query<
      RenderLienWaiverTemplatePDFResponse,
      RenderBillLienWaiverTemplatePDFPayload
    >({
      query: (payload) => {
        const params = new URLSearchParams();
        Object.entries(payload).forEach(([key, value]) => {
          if (value) {
            params.append(camelToSnakeCase(key), String(value));
          }
        });

        return {
          url: "vendor-comms/lien-waiver-bill-to-pdf/",
          params,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              renderLienWaiverPDFResponseSchema
            );
          },
        };
      },
      providesTags: (_, __, { billId, customerId }) => [
        { type: TEMPLATE_PDF_TAG, billId, customerId },
      ],
    }),
    requestLienWaiver: builder.mutation<void, RequestLienWaiverPayloadSchema>({
      query: (payload) => {
        const { files, ...restPayload } = payload;
        const body = new FormData();
        Object.entries(transformKeysToSnakeCase(restPayload)).forEach(
          ([key, value]) => {
            if (value) body.append(key, String(value));
          }
        );
        files?.forEach((file) => {
          body.append("files", file);
        });

        return {
          url: "lienwaiverrequest/",
          method: "POST",
          headers: { "Content-Type": undefined },
          body,
        };
      },
    }),
    updateLienWaiverRequest: builder.mutation<
      void,
      UpdateLienWaiverRequestPayloadSchema
    >({
      query: (payload) => {
        const { id, pdf, ...rest } = payload;

        const body = new FormData();
        Object.entries(transformKeysToSnakeCase(rest)).forEach(
          ([key, value]) => {
            body.append(key, String(value));
          }
        );
        if (pdf) {
          body.append("pdf", pdf);
        }

        return {
          url: `lienwaiverrequest/${id}/`,
          headers: { "Content-Type": undefined },
          method: "PUT",
          body,
        };
      },
    }),
    patchLienWaiverRequest: builder.mutation<
      void,
      PatchLienWaiverRequestPayloadSchema
    >({
      async onQueryStarted(
        { id, status, lienWaiverTemplateId, billPaymentId },
        { dispatch, queryFulfilled }
      ) {
        if (!billPaymentId) return;
        const { undo } = dispatch(
          updateBillPayment(billPaymentId?.toString())((draft) => {
            draft.lienWaivers = draft.lienWaivers.map((lw) => {
              if (lw.id === id) {
                lw.status = status;
                lw.lienWaiverTemplate = lienWaiverTemplateId?.toString();
              }
              return lw;
            });
          })
        );
        queryFulfilled.catch(undo);
      },
      query: (payload) => {
        const { id, pdf, ...rest } = payload;

        const body = new FormData();
        Object.entries(transformKeysToSnakeCase(rest)).forEach(
          ([key, value]) => {
            if (value) {
              body.append(key, String(value));
            } else {
              body.append(key, "");
            }
          }
        );
        if (pdf) {
          body.append("pdf", pdf);
        }

        return {
          url: `lienwaiverrequest/${id}/`,
          headers: { "Content-Type": undefined },
          method: "PATCH",
          body,
        };
      },
    }),
    deleteLienWaiverRequest: builder.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `lienwaiverrequest/${id}/`,
          method: "DELETE",
        };
      },
    }),
    sendLienWaiverReminder: builder.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `lienwaiverrequest/${id}/send-reminder/`,
          method: "POST",
        };
      },
    }),
    getExternalLienWaiver: builder.query<
      GetExternalLienWaiverRequestResponseSchema,
      GetExternalLienWaiverRequestPayloadSchema
    >({
      query: (payload) => {
        return {
          url: `lienwaiverrequest/${payload.id}/external/`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getExternalLienWaiverRequestResponseSchema
            );
          },
        };
      },
      providesTags: (response) =>
        response && "id" in response && response?.id
          ? [{ type: EXTERNAL_LIEN_WAIVER_TAG, id: response.id }]
          : [{ type: EXTERNAL_LIEN_WAIVER_TAG, id: "DETAIL" }],
    }),
    signLienWaiver: builder.mutation<void, SignLienWavierRequestPayloadSchema>({
      query: (payload) => {
        const { id, signature, ...rest } = payload;

        const body = new FormData();
        Object.entries(transformKeysToSnakeCase(rest)).forEach(
          ([key, value]) => {
            body.append(key, String(value));
          }
        );
        body.append("signature", signature);
        return {
          url: `lienwaiverrequest/${id}/sign/`,
          method: "POST",
          body,
          headers: { "Content-Type": undefined },
        };
      },
      invalidatesTags: (_, error) => (error ? [] : [EXTERNAL_LIEN_WAIVER_TAG]),
    }),
  }),
});

export const {
  useDeleteLienWaiverRequestMutation,
  useDeleteLienWaiverTemplateMutation,
  useGetDynamicVariablesQuery,
  useGetExternalLienWaiverQuery,
  useGetLienWaiverTemplateQuery,
  useGetLienWaiverTemplatesQuery,
  useGetLienWaiverTypesListQuery,
  useGetSignatureFieldsQuery,
  useLazyRenderBillLienWaiverPdfQuery,
  useRenderLienWaiverPdfMutation,
  useRequestLienWaiverMutation,
  useSaveLienWaiverTemplateMutation,
  useSendLienWaiverReminderMutation,
  useUpdateLienWaiverRequestMutation,
  usePatchLienWaiverRequestMutation,
  useSignLienWaiverMutation,
} = lienWaiverTemplatesApi;
