import { type Option } from "@src/shared/types";

import type { CardFilter, LinkedCost } from "../components/types";

type TransformCostToFiltersParams = Partial<LinkedCost> & {
  cardFilters?: CardFilter[];
};
export const transformCostToFixedFilters = ({
  cardFilters,
  ...cost
}: TransformCostToFiltersParams) => {
  const enhancedFilters: Record<string, Option> = {};

  if ("paymentAccount" in cost && cost.paymentAccount?.url) {
    enhancedFilters[cost.paymentAccount.url] = {
      label: cost.paymentAccount.displayName!,
      value: cost.paymentAccount.url,
      groupLabel: "Payment account",
    };
  }

  for (const filter of cardFilters ?? []) {
    if (!filter?.name) continue;

    enhancedFilters[filter.id] = {
      label: filter.name,
      value: filter.id.toString(),
      groupLabel: "Linked card",
    };
  }

  return enhancedFilters;
};
