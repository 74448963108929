import React from "react";
import { Main, MainHeader, MainTitle } from "@components/main";

export const BillPaymentPage = () => {
  return (
    <Main>
      <MainHeader>
        <MainTitle>Payments</MainTitle>
      </MainHeader>
    </Main>
  );
};
