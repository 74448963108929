import { z } from "zod";

export const amountConditionSchema = z.object({
  amountFrom: z.number().nonnegative().nullable(),
  amountTo: z.number().nonnegative().nullable(),
  type: z.literal("AMOUNT"),
});
export const itemsConditionSchema = z.object({
  type: z.literal("COST_CODE"),
  items: z.array(z.string()).min(1),
});

export const vendorsConditionSchema = z.object({
  type: z.literal("VENDOR"),
  vendors: z.array(z.string()).min(1),
});

export const accountConditionSchema = z.object({
  type: z.literal("ACCOUNT"),
  accounts: z.array(z.string()).min(1),
});

export const customerConditionSchema = z.object({
  type: z.literal("JOB"),
  customers: z.array(z.string()).optional(),
  requirementType: z.enum(["ONE_OF", "ANY_JOB", "NO_JOB", "NONE_OF"]),
  groupUnderParent: z.boolean(),
});

export const workflowStepTypesSchema = z.enum(["ALL_OF", "ONE_OF", "NONE_OF"]);

export const stepsSchema = z.array(
  z.object({
    requirementType: workflowStepTypesSchema,
    approvers: z.array(z.string()).min(1),
  })
);

export const schema = z
  .object({
    title: z.string().nullish(),
    isDefault: z.boolean(),
    types: z
      .array(z.string().min(1))
      .min(1, "Workflow must apply to at least one transaction type"),
    conditions: z
      .array(
        z
          .discriminatedUnion("type", [
            amountConditionSchema,
            customerConditionSchema,
            itemsConditionSchema,
            vendorsConditionSchema,
            accountConditionSchema,
          ])
          .superRefine((values, context) => {
            if (
              values.type === "AMOUNT" &&
              values.amountTo &&
              values.amountFrom &&
              values.amountFrom > values.amountTo
            ) {
              context.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["amountFrom"],
                message:
                  "The amount from must be lower or equal to the amount to",
              });

              context.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["amountTo"],
                message:
                  "The amount to must be higher or equal to the amount from",
              });
            }
            if (
              values.type === "JOB" &&
              ["ONE_OF", "NONE_OF"].includes(values.requirementType) &&
              values.customers?.length === 0
            ) {
              context.addIssue({
                code: z.ZodIssueCode.custom,
                path: ["customers"],
                message: "At least one customer must be selected",
              });
            }
          })
      )
      .refine(
        (values) => values.filter((x) => x.type === "AMOUNT").length < 2,
        {
          message: "The amount condition could be added once",
        }
      ),
    steps: stepsSchema,
  })
  .refine((obj) => obj.isDefault || obj.conditions.length > 0, {
    message: "Must have more than one condition in non-default workflow",
  });

export const approvalsSchema = z.object({
  steps: stepsSchema,
});

export type Steps = z.infer<typeof stepsSchema>;
export type Fields = z.infer<typeof schema>;
export type StepTypes = z.infer<typeof workflowStepTypesSchema>;
export type AmountCondition = z.infer<typeof amountConditionSchema>;
export type CustomerCondition = z.infer<typeof customerConditionSchema>;
export type VendorCondition = z.infer<typeof vendorsConditionSchema>;
export type AccountCondition = z.infer<typeof accountConditionSchema>;
export type ItemCondition = z.infer<typeof itemsConditionSchema>;
export type ConditionType =
  | "AMOUNT"
  | "COST_CODE"
  | "JOB"
  | "VENDOR"
  | "ACCOUNT";

export type Condition =
  | AmountCondition
  | CustomerCondition
  | ItemCondition
  | VendorCondition
  | AccountCondition;

export type FieldsApprovals = z.infer<typeof approvalsSchema>;
