import { useMemo } from "react";
import { useEvent } from "@adaptive/design-system/hooks";
import { useAppSelector } from "@store/hooks";
import { useDrawerVisibility } from "@store/ui";
import { BasePermissions, useUserInfo } from "@store/user";

import {
  creationInfoSelector,
  selectHasEmailChange,
  selectIsSubmitting,
  selectUnsavedVendorInfoOrBankingChanges,
} from "../store/selectors";

export const useVendorInfo = () => {
  const {
    state: drawerState,
    visible: drawerOpen,
    setState: drawerSetState,
  } = useDrawerVisibility("vendor");
  const { hasPermission } = useUserInfo();

  const hasUnsavedInfoOrBankingChanges = useAppSelector(
    selectUnsavedVendorInfoOrBankingChanges
  );

  const creationInfo = useAppSelector(creationInfoSelector);

  const hasUnsavedChanges =
    !!hasUnsavedInfoOrBankingChanges || !!drawerState.hasUnsavedDocuments;

  const hasEmailChange = useAppSelector(selectHasEmailChange);

  const isSubmitting = useAppSelector(selectIsSubmitting);

  const canManageNonPaymentInfo = useMemo(
    () => hasPermission(BasePermissions.MANAGE_NON_PAYMENT_VENDORS),
    [hasPermission]
  );

  const canManagePaymentInfo = useMemo(
    () => hasPermission(BasePermissions.MANAGE_PAYMENT_VENDORS),
    [hasPermission]
  );

  const setHasUnsavedDocuments = useEvent((hasUnsavedDocuments: boolean) => {
    drawerSetState({ ...drawerState, hasUnsavedDocuments });
  });

  return {
    drawerOpen,
    creationInfo,
    isSubmitting,
    hasEmailChange,
    hasUnsavedChanges,
    hasUnsavedDocuments: drawerState.hasUnsavedDocuments,
    canManagePaymentInfo,
    setHasUnsavedDocuments,
    canManageNonPaymentInfo,
    hasUnsavedInfoOrBankingChanges,
  };
};
