import React, { memo, useCallback, useMemo } from "react";
import {
  Button,
  ComboBox,
  type ComboBoxProps,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { useUpdateQuickBooksErrorsMutation } from "@api/quickbooks";
import { CostCodeAccountComboBox } from "@components/cost-code-account-combobox";
import { renderCostCodeAccountPlaceholder } from "@components/cost-code-account-combobox/cost-code-account-combobox-utils";
import { DownloadButton } from "@components/download-button";
import { AchRequestDialog } from "@components/request-vendor-ach";
import {
  DocumentSelectDialog,
  type DocumentSelectDialogProps,
  type DocumentSelectDialogStep,
} from "@components/request-vendor-document";
import { type QuerySets } from "@components/table-filter/formatters";
import { useClientInfo, useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";
import { isAccount } from "@utils/is-account";

import { useLazyExportVendorDocumentsQuery } from "../api/api";
import type { Vendor, VendorsVendor } from "../api/types";
import { useVendorAction } from "../hooks/use-vendor-action";

export type VendorFilters = {
  [s: string]: string | number | boolean | Set<number>;
};

type BatchActionsProps = {
  vendors: VendorsVendor[];
  filters: QuerySets | undefined;
  onAction?: () => void;
};

const EMAIL_FORWARD_OPTIONS = [
  {
    label: "Receipts",
    value: "true",
  },
  {
    label: "Bills",
    value: "false",
  },
];

const COMMON_PROPS: Omit<ComboBoxProps<false>, "label"> & { label: string } = {
  flip: true,
  size: "sm",
  value: "",
  label: "",
  portal: true,
  listSize: 4,
  messageVariant: "hidden",
};

export const PageBatchActions = memo(
  ({ vendors, filters, onAction }: BatchActionsProps) => {
    const { realmId } = useClientInfo();

    const { failSafeCostCodesEnabled } = useClientSettings();

    const requestDocumentsDialog = useMultiStepDialog<DocumentSelectDialogStep>(
      {
        lazy: true,
        initialStep: "select-documents",
      }
    );

    const requestAchDialog = useDialog({
      lazy: true,
    });

    const { updateVendors } = useVendorAction();

    const [updateQuickBooksErrorsMutation] =
      useUpdateQuickBooksErrorsMutation();

    const [exportVendorDocuments] = useLazyExportVendorDocumentsQuery();

    const initialValues = useMemo<DocumentSelectDialogProps["initialValues"]>(
      () => ({
        vendors: vendors.map((vendor) => ({
          id: vendor.id,
          email: vendor.email || "",
          displayName: vendor.displayName,
        })),
      }),
      [vendors]
    );

    const onDownload = useEvent(({ mode, params }) => {
      params.append("realm", String(realmId));

      if (mode === "selection") {
        (vendors || []).map(({ id }) => params.append("id", String(id)));
      }

      Object.entries(filters || {})
        .filter(([, val]) => val === false || !!val)
        .forEach(([key, value]) => {
          if ((value as unknown) instanceof Set) {
            (value as unknown as Set<string | number | boolean>).forEach(
              (item) => params.append(key, item.toString())
            );
          } else {
            params.set(key, value?.toString());
          }
        });

      return exportVendorDocuments(params).unwrap();
    });

    const onIgnoreSyncErrors = useEvent(async () => {
      const ids = vendors
        .map((item) => item.errors.map((error) => error.id))
        .flat();

      try {
        await updateQuickBooksErrorsMutation({ ids, isIgnored: true }).unwrap();
        analytics.track("vendorBatchActions", {
          action: "ignore-sync-errors",
          vendorsIds: vendors.map(({ id }) => id),
        });
        toast.success(
          `${ids.length} Vendor${
            ids.length > 1 ? "s" : ""
          } with sync errors ignored!`
        );
        onAction?.();
      } catch (e) {
        handleErrors(e);
      }
    });

    const onRequestDocuments = useEvent(() => {
      analytics.track("vendorBatchActions", {
        action: "request-documents",
        vendorsIds: vendors.map(({ id }) => id),
      });
      onAction?.();
    });

    const onRequestAch = useEvent(() => {
      analytics.track("vendorBatchActions", {
        action: "request-ach",
        vendorsIds: vendors.map(({ id }) => id),
      });
      onAction?.();
    });

    const isDisabledSyncErrors = vendors.some(
      (item) =>
        item.errors?.length === 0 ||
        item.errors?.some((error) => error.isIgnored)
    );

    const curriedOnChange = useCallback(
      (fieldName: string) => (value: string) => {
        const newVendors = vendors.map((vendor) => {
          const newVendor = { ...vendor } as Vendor;

          if (fieldName === "item_account") {
            const isAccountValue = isAccount(value);

            const defaultValue = { url: value, displayName: "" };

            newVendor.defaultItem = !isAccountValue ? defaultValue : null;
            newVendor.defaultAccount = isAccountValue ? defaultValue : null;
            newVendor.defaultItems = undefined;
            newVendor.defaultAccounts = undefined;
          }

          if (fieldName === "types") {
            newVendor.types = value === "true" ? ["expense"] : [];
          }

          return newVendor;
        });

        analytics.track("vendorBatchActions", {
          action: "edit-properties",
          vendorsIds: newVendors.map(({ id }) => id),
          field: fieldName,
        });

        updateVendors(newVendors);
      },
      [vendors, updateVendors]
    );

    return (
      <>
        <DownloadButton
          type={[{ label: "Vendor documents (ZIP)", value: "export_zip" }]}
          size="md"
          mode={{
            all: { enabled: true },
            selection: { enabled: vendors?.length > 0 },
          }}
          initialType={{ export_zip: true }}
          onDownload={onDownload}
          data-testid="vendors"
        />
        {vendors?.length > 0 && (
          <Dropdown placement="bottom-end" flip={false} listSize={5}>
            <DropdownTrigger
              as={Button}
              color="primary"
              data-testid="actions-trigger"
            >
              Actions
              <Icon name="ellipsis-vertical" variant="solid" />
            </DropdownTrigger>

            <DropdownList>
              <DropdownItem
                onClick={requestDocumentsDialog.show}
                data-testid="request-documents"
              >
                Request documents
              </DropdownItem>
              <DropdownItem
                onClick={requestAchDialog.show}
                data-testid="request-ach"
              >
                Request ACH
              </DropdownItem>
              <Tooltip
                message={
                  isDisabledSyncErrors
                    ? "You can only ignore sync errors on vendors that have sync errors"
                    : ""
                }
                placement="left"
              >
                <DropdownItem
                  onClick={onIgnoreSyncErrors}
                  disabled={isDisabledSyncErrors}
                  data-testid="ignore-sync-errors"
                >
                  Ignore sync errors
                </DropdownItem>
              </Tooltip>
              <DropdownList label="Edit properties">
                <DropdownItem>
                  <Flex gap="xl" width="220px" direction="column">
                    {!failSafeCostCodesEnabled && (
                      <CostCodeAccountComboBox
                        {...COMMON_PROPS}
                        onChange={curriedOnChange("item_account")}
                        placeholder={(data) =>
                          `Set default ${renderCostCodeAccountPlaceholder({
                            data,
                            transform: "lowercase",
                          })}`
                        }
                        filters={["costCodeAccount"]}
                        accountFilters={{ only_line_item_accounts: true }}
                      />
                    )}
                    <ComboBox
                      {...COMMON_PROPS}
                      data={EMAIL_FORWARD_OPTIONS}
                      onChange={curriedOnChange("types")}
                      placeholder="Email forward destination"
                      messageVariant="hidden"
                    />
                  </Flex>
                </DropdownItem>
              </DropdownList>
            </DropdownList>
          </Dropdown>
        )}
        {requestDocumentsDialog.isRendered && (
          <DocumentSelectDialog
            dialog={requestDocumentsDialog}
            multiple
            onSubmit={onRequestDocuments}
            initialValues={initialValues}
          />
        )}
        {requestAchDialog.isRendered && (
          <AchRequestDialog
            dialog={requestAchDialog}
            multiple
            onSubmit={onRequestAch}
            initialValues={initialValues}
          />
        )}
      </>
    );
  }
);

PageBatchActions.displayName = "PageBatchActions";
