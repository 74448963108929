import { referenceNodeSchema } from "@api/expenses/response";
import { CUSTOMER_ACCOUNT_TYPE, PAYMENT_METHOD } from "@bill-payment/constants";
import {
  arraySchema,
  commentSchema,
  dateSchema,
  errorSchema,
  idSchema,
  userSchema,
} from "@utils/schema";
import { z } from "zod";

export const getPaymentOptionsRequestSchema = z.object({
  billIds: z.array(idSchema),
  // We want vendorId to be part of the query cache key so it refetches if the vendor on a bill changes
  vendorIds: z.array(idSchema),
});

export const paymentMethodEnumSchema = z.enum([
  PAYMENT_METHOD.MARK_AS_PAID,
  PAYMENT_METHOD.ACH,
  PAYMENT_METHOD.PRINT_CHECK,
  PAYMENT_METHOD.MAIL_CHECK,
  PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK,
  PAYMENT_METHOD.VIRTUAL_SMS_CHECK,
]);

export const customerAccountTypeEnumSchema = z.enum([
  CUSTOMER_ACCOUNT_TYPE.CREDIT_CARD,
  CUSTOMER_ACCOUNT_TYPE.BANK_ACCOUNT,
]);

export const lienWaiverBasePayloadSchema = z.object({
  customer: z.string().url(),
  status: z.enum(["not_required", "not_selected"]).nullish(),
  sendLienWaiverOnPayment: z.boolean(),
});

export const lienWaiverFormPayloadSchema = lienWaiverBasePayloadSchema.merge(
  z.object({
    lienWaiverTemplate: z.string().url().nullish(),
  })
);

export const lienWaiverPayloadSchema = lienWaiverBasePayloadSchema.merge(
  z.object({
    lienWaiverTemplateId: idSchema.nullish(),
  })
);

export const accountTypeEnumSchema = z.enum([
  "depository",
  "loan",
  "credit",
  "investment",
  "other",
]);

export const accountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  parent: z.string().url().nullish(),
  displayName: z.string(),
  isPaymentAccount: z.boolean(),
  isCreditCard: z.boolean(),
  isBankAccount: z.boolean(),
  fullyQualifiedCategory: z.string().nullish(),
  isBudgetCode: z.boolean(),
  isVendorCode: z.boolean(),
  isRemovedVendorCode: z.boolean(),
  parentName: z.string().nullish(),
});

export const customerSimpleSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  displayName: z.string(),
});

export const itemSchema = z
  .object({
    id: idSchema,
    url: z.string().url(),
    parent: z.string().url().nullish(),
    displayName: z.string(),
    fullyQualifiedName: z.string().nullish(),
  })
  .transform((item) => ({
    ...item,
    fullyQualifiedName: item.fullyQualifiedName ?? item.displayName,
  }));

export const customerCardSchema = z.object({
  id: idSchema,
  name: z.string(),
  mask: z.string().nullish(),
  url: z.string().url(),
  user: userSchema.nullish(),
  account: accountSchema.nullish(),
  item: itemSchema.nullish(),
  fullName: z.string(),
  customer: customerSimpleSchema.nullish(),
});

export const plaidAccountOwnerBasicSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  cardId: idSchema,
  accountOwner: z.string().nullish(),
});

export const customerBankAccountSchema = z.object({
  id: z.string(),
  url: z.string().url(),
  name: z.string(),
  currency: z.string().nullish(),
  type: accountTypeEnumSchema,
  mask: z.string(),
  plaidAccountId: z.string(),
  paymentAccount: accountSchema.nullish(),
  plaidAccountOwners: arraySchema(plaidAccountOwnerBasicSchema),
  bankName: z.string().nullish(),
  verificationStatus: z.string().nullish(),
  isTransactionPullEnabled: z.boolean(),
  checkbookAccountIsActive: z.boolean().nullish(),
  lastSyncDate: z.string().nullish(),
  isDefault: z.boolean().nullish(),
});

export const paymentScheduleSchema = z.object({
  processOn: dateSchema,
  expectedDeliveryAfter: dateSchema,
  expectedDeliveryBefore: dateSchema,
});

export const vendorBankAccountSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  routingNumber: z.string(),
  accountNumber: z.string(),
});

export const processPrerequisiteSchema = z.object({
  type: z.string(),
  isSatisfied: z.boolean(),
  satisfiedOn: z.nullable(z.string()),
});

export const optionSchema = z.object({
  default: z.boolean().nullish(),
  customerAccountType: customerAccountTypeEnumSchema,
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerCard: customerCardSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  vendorBankAccount: vendorBankAccountSchema.nullish(),
  paymentMethod: paymentMethodEnumSchema,
  hasUnmatchedCardTransactions: z
    .boolean()
    .nullish()
    .transform((value) => !!value),
  processPrerequisites: arraySchema(processPrerequisiteSchema),
  paymentSchedules: arraySchema(paymentScheduleSchema),
});

export const bankAccountOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
  mask: z.string(),
  type: z.string().nullish(),
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerCard: customerCardSchema.nullish(),
  hasUnmatchedCardTransactions: z.boolean(),
  markAsPaidOption: optionSchema.nullish(),
  isCreditCard: z.boolean(),
  accountOwner: plaidAccountOwnerBasicSchema.nullish(),
  paymentMethods: arraySchema(
    z.object({
      customerBankAccount: customerBankAccountSchema.nullish(),
      customerCard: customerCardSchema.nullish(),
      paymentMethod: paymentMethodEnumSchema,
      customerPaymentAccount: z
        .object({ url: z.string().url().nullish() })
        .nullish(),
      vendorBankAccount: z
        .object({ url: z.string().url().nullish() })
        .nullish(),
      processPrerequisites: arraySchema(
        z.object({
          type: z.string(),
        })
      ),
      paymentSchedules: arraySchema(paymentScheduleSchema),
    })
  ),
});

export const billPaymentFormPayloadSchema = z.object({
  payments: arraySchema(
    z.object({
      amountToPay: z.number(),
      billUrls: arraySchema(z.string().url()),
      balance: z.number(),
      paymentMethod: paymentMethodEnumSchema,
      signature: z.string().nullish(),
      bankAccountOption: bankAccountOptionSchema,
      vendor: z.object({
        id: idSchema,
        email: z.string().nullish(),
      }),
      payWhenLienWaiverIsSigned: z.boolean(),
      currentDebitDate: z.string().nullish(),
    })
  ),
  lienWaivers: arraySchema(lienWaiverFormPayloadSchema),
});

export const billPaymentV2PayloadSchema = z.object({
  bills: arraySchema(idSchema),
  options: z.object({
    appliedAmount: z.number(),
    vendorBankAccount: z.string().url().nullish(),
    customerPaymentAccount: z.string().url().nullish(),
    appliedProcessOn: z.string().nullish(),
    appliedCommentText: z.string().nullish(),
    appliedVendorEmail: z.string().nullish(),
    customerBankAccount: z.string().url().nullish(),
    customerCard: z.string().url().nullish(),
    paymentMethod: paymentMethodEnumSchema.nullish(),
    appliedSignature: z.string().nullish(),
    appliedCardTransaction: z.string().url().nullish(),
    processPrerequisites: arraySchema(
      z.object({
        type: z.string(),
      })
    ).nullish(),
  }),
  lienWaivers: arraySchema(lienWaiverPayloadSchema).nullish(),
});

export const getBillPaymentRequestSchema = z.object({
  billPaymentId: idSchema,
});

export const cancelBillPaymentRequestSchema = z.object({
  billPaymentId: idSchema,
});

export const voidBillPaymentRequestSchema = z.object({
  billPaymentId: idSchema,
});

export const reviewStatusSchema = z.enum([
  "ALL",
  "PAID",
  "DRAFT",
  "APPROVAL",
  "FOR_PAYMENT",
  "PAYMENT_FAILED",
  "PARTIALLY_PAID",
  "ACH_PROCESSING",
  "ACH_INFO_REQUESTED",
  "PAYMENT_SCHEDULED",
]);

export const vendorSchema = z.object({
  id: idSchema,
  url: z.string(),
  displayName: z.string(),
  email: z.string().email().nullish(),
});
export const customerSchema = z.object({
  id: idSchema,
  displayName: z.string(),
  url: z.string(),
  realm: z.string().url(),
  parent: z.string().url().nullish(),
  displayNameWithoutCompany: z.string(),
  disabled: z.boolean(),
});

export const fileExportSchema = z.object({
  document: z.string(),
  id: z.string(),
});

export const revisionEventSchema = z.object({
  author: z.object({
    id: z.number(),
    url: z.string(),
    fullName: z.string(),
  }),
  createdAt: z.string(),
  timelineEventType: z.string(),
});
// Define LIEN_WAIVER_STATUS as a const array
export const LIEN_WAIVER_STATUS = <const>[
  "not_selected",
  "not_required",
  "not_requested",
  "requested",
  "marked_as_requested",
  "signed",
  "marked_as_signed",
];
export const LIEN_WAIVER_STATUS_UPPER = <const>[
  "NOT_SELECTED",
  "NOT_REQUIRED",
  "REQUESTED",
  "MARKED_AS_REQUESTED",
  "SIGNED",
  "MARKED_AS_SIGNED",
];
// Zod schema for LIEN_WAIVER_STATUS
export const lienWaiverStatusSchema = z.enum(LIEN_WAIVER_STATUS);
export const lienWaiverStatusUpperSchema = z.enum(LIEN_WAIVER_STATUS_UPPER);
// Derived status arrays
export const LIEN_WAIVER_REQUESTED_STATUS = <const>[
  "requested",
  "marked_as_requested",
];
export const LIEN_WAIVER_SIGNED_STATUS = <const>["signed", "marked_as_signed"];
export const LIEN_WAIVER_LINKED_STATUS = <const>[
  ...LIEN_WAIVER_REQUESTED_STATUS,
  ...LIEN_WAIVER_SIGNED_STATUS,
];

export const lienWaiverSchema = z.object({
  bill: z.string().nullable(),
  billId: idSchema.nullish(),
  billPayment: z.string().nullish(),
  fileExport: fileExportSchema.nullish(),
  id: z.string(),
  url: z.string().url(),
  lienWaiverTemplate: z.string().nullish(),
  message: z.string().nullish(),
  printedName: z.string().nullish(),
  paymentAmount: z.string().nullish(),
  pdf: z.string().nullish(),
  status: lienWaiverStatusSchema,
  vendor: vendorSchema,
  vendorEmail: z.string().nullish(),
  vendorReminderCanBeSent: z.boolean(),
  signedAt: z.string().nullish(),
  revisionEvent: revisionEventSchema.nullish(),
  customer: customerSchema.nullish(),
  isProcessPrerequisite: z.boolean().nullish(),
});

export const billSchema = z.object({
  id: idSchema,
  date: z.string(),
  docNumber: z.string(),
  humanReadableType: z.string(),
  isInQuickbooks: z.boolean(),
  reviewStatus: z.string(),
  totalAmount: z.string(),
  url: z.string(),
});

export const paymentScheduledSchema = z.object({
  processedOn: dateSchema,
  deliveredOn: dateSchema,
  expectedDeliveryAfter: dateSchema,
  expectedDeliveryBefore: dateSchema,
});

export const paymentOptionSchema = z.object({
  bills: arraySchema(billSchema),
  options: arraySchema(optionSchema),
  vendor: vendorSchema,
  vendorCredits: arraySchema(billSchema),
});
export const paymentOptionResponseSchema = arraySchema(paymentOptionSchema);
export const createBillPaymentV2ResponseItemSchema = z.object({
  id: idSchema,
  url: z.string().url(),
});
export const createBillPaymentV2ResponseSchema = arraySchema(
  createBillPaymentV2ResponseItemSchema
);

export const paymentErrorsSchema = z.object({
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  error: z.string().nullish(),
  errorDetail: z.string().nullish(),
  link: z.string().nullish(),
});

export const billSimpleSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  humanReadableType: z.string().nullish(),
  docNumber: z.string(),
  reviewStatus: reviewStatusSchema,
  date: dateSchema.nullish(),
  isInQuickbooks: z.boolean(),
});

export const cardTransactionSimpleSchema = z
  .object({
    id: z.string().nullish(),
    amount: z
      .string()
      .nullish()
      .transform((amt) => (amt ? parseFloat(amt) : null)),
    date: dateSchema,
    name: z.string().nullish(),
    merchantName: z.string().nullish(),
    displayName: z.string().nullish(),
    url: z.string().url().nullish(),
    card: z.object({ user: userSchema.nullish() }).nullish(),
    paymentAccount: z.optional(referenceNodeSchema).or(z.null()),
    pending: z.boolean(),
    description: z.string().nullish(),
    mask: z.string().nullish(),
    plaidAccountOwner: z
      .object({
        id: idSchema,
        url: z.string().url(),
        accountOwner: z.string().nullish(),
      })
      .nullish(),
  })
  .nullish();

/**
 * Contains just enough data for the bill detail page and the payments' page table
 */
export const billPaymentListingV2Schema = z.object({
  id: idSchema,
  url: z.string().url(),
  docNumber: z.string().nullish(),
  status: z.string(),
  customerAccountType: customerAccountTypeEnumSchema,
  customerBankAccount: customerBankAccountSchema.nullish(),
  customerCard: customerCardSchema.nullish(),
  customerPaymentAccount: accountSchema.nullish(),
  vendorBankAccount: vendorBankAccountSchema.nullish(),
  paymentMethod: paymentMethodEnumSchema,
  appliedCardTransaction: cardTransactionSimpleSchema,
  processPrerequisites: arraySchema(processPrerequisiteSchema),
  vendor: vendorSchema,
  lienWaivers: arraySchema(lienWaiverSchema),
  appliedAmount: z.string(),
  totalAmount: z.string(),
  paymentSchedule: paymentScheduledSchema,
  updatedAt: z.string().nullish(),
  updatedBy: userSchema.nullish(),
  isVoided: z.boolean(),
});

export const billPaymentDetailV2Schema = billPaymentListingV2Schema.extend({
  bills: arraySchema(
    z.object({
      bill: billSimpleSchema,
      appliedAmount: z.string(),
      openBalance: z.string(),
      customers: arraySchema(customerSimpleSchema),
    })
  ),
  appliedVendorCredits: arraySchema(
    z.object({
      vendorCredit: billSimpleSchema,
      appliedAmount: z.string(),
      openBalance: z.string(),
      customers: arraySchema(customerSimpleSchema),
    })
  ),
  syncErrors: arraySchema(errorSchema),
  paymentErrors: arraySchema(paymentErrorsSchema),
  timelineEvents: arraySchema(commentSchema),
  hasUnmatchedCardTransactions: z.boolean().nullish(),
});

const plaidAccountOwnerV1Schema = z.object({
  id: idSchema,
  url: z.string(),
  accountBalance: z.string(),
  accountOwner: z.string().nullish(),
  user: userSchema.nullish(),
  paymentAccount: accountSchema.nullish(),
});

export const accountBalanceV1Schema = z.object({
  id: idSchema,
  url: z.string(),
  name: z.string(),
  balance: z.string(),
  currency: z.string(),
  type: z.string(),
  mask: z.string().nullish(),
  plaidAccountId: z.string(),
  paymentAccount: accountSchema,
  bankName: z.string(),
  verificationStatus: z.string(),
  users: arraySchema(userSchema).nullish(),
  plaidAccountOwners: arraySchema(plaidAccountOwnerV1Schema).nullish(),
  checkbookAccountIsActive: z.boolean(),
});

export const cancelBillPaymentResponseSchema = z.object({
  id: z.string(),
  account: z.null(),
  accountBalance: accountBalanceV1Schema,
  internalMethod: z.string(),
  createdAt: z.string(),
  externalMethod: z.null(),
  status: z.string(),
  totalAmount: z.string(),
  vendor: z.string(),
  vendorAddress: z.string().nullish(),
  vendorBankingAch: z.string().nullish(),
  vendorEmail: z.string(),
  vendorPhoneNumber: z.string().nullish(),
});
