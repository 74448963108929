import React from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Flex,
  Loader,
  Text,
  toast,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { useConvertToAdaptiveMutation } from "@api/purchase-orders";
import { BuilderTrendIcon } from "@components/buildertrend-icon";
import { QuickBooksIcon } from "@components/quickbooks-icon";
import { useBillFormActions } from "@src/bills/bill-form-context";
import { camelCaseQuickBooksLinkedTransactionsBillSelector } from "@src/bills/utils";
import { PurchaseOrderLearnMoreLink } from "@src/purchase-orders/purchase-order-learn-more-link";
import {
  promptConversionToast,
  promptConversionWarning,
} from "@src/purchase-orders/utils";
import { refetchCurrentBill, unlinkPOLine } from "@store/billSlice";
import { useAppSelector } from "@store/hooks";
import { useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";
import type { AnyAction } from "redux";

type LinkedTransactionProps = ReturnType<
  typeof camelCaseQuickBooksLinkedTransactionsBillSelector
>[number];

const LinkedTransaction = ({
  lines,
  parent,
  objectId,
}: LinkedTransactionProps) => {
  const { save } = useBillFormActions();

  const dispatch = useDispatch();

  const [convertToAdaptive, convertToAdaptiveInfo] =
    useConvertToAdaptiveMutation();

  const onConvert = useEvent(() => {
    const handle = async () => {
      try {
        await save();
        await convertToAdaptive(objectId).unwrap();
        promptConversionToast();
        await dispatch(refetchCurrentBill() as unknown as AnyAction);
      } catch (e) {
        handleErrors(e);
      }
    };

    promptConversionWarning({ onConfirm: handle });
  });

  const onUnlink = useEvent(() => {
    lines.forEach((line) => {
      analytics.track("purchaseOrderUnlinkLine", {
        action: "unlink",
        location: "conversion-alert",
        purchaseOrderId: objectId,
        purchaseOrderLineId: line,
      });
      dispatch(unlinkPOLine(line));
    });

    return toast.success(
      `${lines.length} line${
        lines.length > 1 ? "s" : ""
      } unlinked from PO#${parent.docNumber}`
    );
  });

  return (
    <Alert
      variant={{
        icon:
          parent.type === "qb" ? (
            <QuickBooksIcon size={24} />
          ) : (
            <BuilderTrendIcon size={24} />
          ),
        background: parent.type === "qb" ? "success-100" : "neutral-300",
      }}
    >
      <AlertTitle>
        This bill is linked to a{" "}
        {parent.type === "qb" ? "QuickBooks" : "BuilderTrend"} purchase order
        (PO#
        {parent.docNumber})
      </AlertTitle>
      <AlertContent as={Flex} gap="md" direction="column">
        <Text>
          You may not edit any bills linked to{" "}
          {parent.type === "qb" ? "QuickBooks" : "BuilderTrend"} purchase orders
          in Adaptive.
          {parent.type === "qb" ? (
            <>
              <br />
              Convert the linked PO to an Adaptive purchase order for full edit
              access.
            </>
          ) : null}
        </Text>
        {parent.type === "qb" ? (
          <Flex>
            <PurchaseOrderLearnMoreLink />
          </Flex>
        ) : null}
        <Flex gap="lg" wrap align="center" padding={["sm", "none", "none"]}>
          {parent.type === "qb" ? (
            <Flex width="184px">
              <Button
                size="sm"
                block
                onClick={onConvert}
                disabled={convertToAdaptiveInfo.isLoading}
              >
                {convertToAdaptiveInfo.isLoading ? (
                  <Loader />
                ) : (
                  "Convert to Adaptive PO"
                )}
              </Button>
            </Flex>
          ) : null}
          <Button
            size="sm"
            variant="ghost"
            onClick={onUnlink}
            disabled={convertToAdaptiveInfo.isLoading}
          >
            Unlink PO from bill
          </Button>
        </Flex>
      </AlertContent>
    </Alert>
  );
};

export const PurchaseOrdersAlert = () => {
  const linkedTransactions = useAppSelector(
    camelCaseQuickBooksLinkedTransactionsBillSelector
  );

  const settings = useClientSettings();

  if (!settings.adaptivePosEnabled || linkedTransactions.length === 0) {
    return null;
  }

  return (
    <Flex gap="xl" direction="column">
      {linkedTransactions.map((transaction) => (
        <LinkedTransaction key={transaction.id} {...transaction} />
      ))}
    </Flex>
  );
};
