/**
 * @todo Enable eslint/tslint and fix all issues
 */
/* eslint-disable */
// @ts-nocheck
import { toast } from "@adaptive/design-system";
import { omit } from "@adaptive/design-system/utils";
import { UNKNOWN_ERROR_MESSAGE } from "@api/handle-errors";
import { createReducer } from "@reduxjs/toolkit";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { formatZodError } from "@utils/schema/converters";
import { ZodError } from "zod";

import type { Address, BankingACH, Vendor } from "../api/types";
import { getInitialAddress } from "../utils/get-initial-address";
import { getInitialBanking } from "../utils/get-initial-banking";
import { getInitialForm } from "../utils/get-initial-form";
import { getInitialState } from "../utils/get-initial-state";

import {
  clearCreationId,
  createVendor,
  setAchField,
  setAddressField,
  setField,
  setIsSubmitting,
} from "./actions";
import {
  addDocument,
  commitAchInfo,
  commitVendor,
  createDocument,
  fetchById,
  removeAchInfo,
  removeDocument,
  syncDocuments,
  toggleEditDocument,
  updateDocument,
} from "./thunks";
import type { Document, Stage } from "./types";

type Status = "IDLE" | "PENDING" | "LOADED";

export type Vendors = {
  status: Status;
  vendors: any[];
  offset: number;
  next: null;
};

type Info = Omit<Vendor, "bankingAchs" | "documents" | "address"> & {
  address: Address;
};

type Banking = BankingACH;

type FilteredType<Source, Condition> = Pick<
  Source,
  { [K in keyof Source]: Source[K] extends Condition ? K : never }[keyof Source]
>;

type Errors<S> = FilteredType<S, string | undefined | null>;

export type Form = {
  info: Info;
  banking: Banking;
  documents: Document[];
  errors: {
    nonFieldErrors: string[] | undefined;
    info: Errors<Partial<Info>>;
    banking: Errors<Partial<Banking>>;
    documents: Errors<Partial<Document[]>>;
  } | null;
  initialSnapshot: Pick<Form, "info" | "banking" | "documents">;
  initialStage: Stage;
  isSubmitting: boolean;
};

export type Transaction = {
  action: "updated" | "created" | null;
  vendor: Form["info"] | null;
  status: "pending" | "fulfilled" | "rejected";
};

type Transactions = {
  [key: string]: Transaction;
};

export type VendorState = {
  form: Form;
  creationId: string | null;
  transactions: Transactions;
  lastAction: "updated" | "created" | null;
  lastCommit: Form["info"] | null;
  fetchStatus: string;
};

const getSnapshot = (obj: Record<string, any>) => Object.assign({}, obj);

export const reducer = createReducer(getInitialState(), (builder) => {
  builder.addCase(createVendor, (state, { payload }) => {
    state.form = getInitialForm(payload.data.displayName);

    state.creationId = payload.meta.creationId;
    state.transactions[payload.meta.creationId] = {};
  });

  builder.addCase(clearCreationId, (state) => {
    state.creationId = null;
  });

  builder.addCase(setField, (state, { payload }) => {
    const [key] = Object.keys(payload);
    state.form.info[key] = payload[key];

    if (state.form.errors?.info) {
      state.form.errors.info[key] = null;
    }
  });

  builder.addCase(setAddressField, (state, { payload }) => {
    const [key] = Object.keys(payload);
    state.form.info.address[key] = payload[key];
  });

  builder.addCase(setAchField, (state, { payload }) => {
    const [key] = Object.keys(payload);
    state.form.banking[key] = payload[key];

    if (state.form.errors?.banking) {
      state.form.errors.banking[key] = null;
    }
  });

  builder.addCase(fetchById.pending, (state, action) => {
    const isDifferentVendor =
      action.meta.arg.id != state.form.info.id &&
      state.fetchStatus !== "pending";

    if (isDifferentVendor) {
      state.form = getInitialForm();
    }

    state.fetchStatus = action.meta.requestStatus;
  });

  builder.addCase(fetchById.rejected, (state, action) => {
    state.fetchStatus = action.meta.requestStatus;
  });

  builder.addCase(fetchById.fulfilled, (state, { payload, meta }) => {
    const { bankingAchs, documents, initialStage, ...info } = payload;

    info.address = info.address || getInitialAddress();

    const form = {
      info,
      banking: { ...getInitialBanking(), ...bankingAchs },
      documents,
      initialStage,
    };

    state.form = form;
    state.form.errors = null;
    state.form.initialSnapshot = getSnapshot(form);
    state.fetchStatus = meta.requestStatus;
  });

  builder.addCase(commitVendor.rejected, (state, action) => {
    if (!action.payload) return;

    const errors =
      action.payload instanceof ZodError
        ? formatZodError(action.payload)
        : action.payload;

    const { nonFieldErrors, ...info } = errors;
    if (nonFieldErrors) {
      state.form.errors = {
        ...state.form.errors,
        nonFieldErrors: [
          ...(state.form.errors?.nonFieldErrors || []),
          ...nonFieldErrors,
        ],
      };
    }

    if (info) {
      state.form.errors = {
        ...state.form.errors,
        info,
      };
    }
  });

  builder.addCase(commitVendor.fulfilled, (state, { payload, meta }) => {
    state.fetchStatus = meta.requestStatus;
    if (!payload) return;

    if (state.form.errors) {
      state.form.errors.info = null;
    }

    const info: Partial<Form["info"]> = {
      ...state.form.info,
      ...payload.vendor,
      address: payload.vendor.address || getInitialAddress(),
    };

    if (payload.commit === "created" && payload.vendor.url) {
      info.id = parseRefinementIdFromUrl(payload.vendor.url);
    }

    state.form.info = info;
    state.form.initialSnapshot.info = getSnapshot(info);

    if (payload.commit === "created" && payload.creationId) {
      state.transactions[payload.creationId] = {
        action: payload.commit,
        vendor: payload.vendor,
        status: "fulfilled",
      };
    }
  });

  builder.addCase(commitAchInfo.rejected, (state, action) => {
    if (!action.payload) return;

    const errors =
      action.payload instanceof ZodError
        ? formatZodError(action.payload)
        : action.payload;

    const { nonFieldErrors, ...banking } = errors;

    if (nonFieldErrors) {
      state.form.errors = {
        ...state.form.errors,
        nonFieldErrors: [
          ...(state.form.errors?.nonFieldErrors || []),
          ...nonFieldErrors,
        ],
      };
    }

    if (banking) {
      state.form.errors = {
        ...state.form.errors,
        banking,
      };
    }
  });

  builder.addCase(commitAchInfo.fulfilled, (state, { payload }) => {
    if (!payload) return;

    state.form.banking = payload;
    state.form.initialSnapshot.banking = Object.assign({}, payload);

    if (state.form.errors) {
      state.form.errors.banking = null;
    }
  });

  builder.addCase(removeAchInfo.rejected, () => {
    toast.error(UNKNOWN_ERROR_MESSAGE);
  });

  builder.addCase(removeAchInfo.fulfilled, (state, { payload }) => {
    if (payload !== true) return;
    state.form.banking = getInitialBanking();

    state.form.errors = omit(Object.assign({}, state.form.errors), ["banking"]);
    state.form.initialSnapshot.banking = getInitialBanking();
  });

  builder.addCase(createDocument.fulfilled, (state, { payload, meta }) => {
    if (!payload || !meta) return;

    // replace virtual doc with saved one
    state.form.documents = state.form.documents.map((doc) =>
      doc.id === meta?.arg?.id ? payload : doc
    );
  });

  builder.addCase(createDocument.rejected, (state, action) => {
    state.form.errors = {
      ...state.form.errors,
      documents: action.payload,
    };
  });

  builder.addCase(updateDocument.fulfilled, (state, { payload, meta }) => {
    if (!payload || !meta) return;

    state.form.documents = state.form.documents.map((doc) =>
      doc.id === meta?.arg?.id ? payload : doc
    );
  });

  builder.addCase(updateDocument.rejected, (state, action) => {
    state.form.errors = {
      ...state.form.errors,
      documents: action.payload,
    };
  });

  builder.addCase(addDocument, (state, { payload }) => {
    state.form.documents = [...state.form.documents, payload];
  });

  builder.addCase(toggleEditDocument, (state, { payload }) => {
    state.form.documents = state.form.documents.map((doc) =>
      doc.id === payload.id ? { ...doc, isEditing: !doc.isEditing } : doc
    );
  });

  builder.addCase(setIsSubmitting, (state, { payload }) => {
    state.form.isSubmitting = payload;
  });

  builder.addCase(removeDocument, (state, { payload }) => {
    if (!payload) return;
    state.form.documents = state.form.documents.filter(
      (doc) => doc.id !== payload.id
    );
  });

  builder.addCase(syncDocuments.fulfilled, (state, { payload }) => {
    state.form.initialSnapshot.documents = Object.assign(
      {},
      state.form.documents
    );

    const { added, removed } = payload;

    const addedSuccess = added?.success ?? 0;

    if (addedSuccess > 0) {
      toast.success(
        `${addedSuccess} document${addedSuccess > 1 ? "s" : ""} added`
      );
    }

    const addedError = added?.error ?? 0;

    if (addedError > 0) {
      toast.error(
        `Failed to save ${addedError} document${addedError > 1 ? "s" : ""}`
      );
    }

    const removedSuccess = removed?.success ?? 0;

    if (removedSuccess > 0) {
      toast.success(
        `${removedSuccess} document${removedSuccess > 1 ? "s" : ""} deleted`
      );
    }

    const removedError = removed?.error ?? 0;

    if (removedError > 0) {
      toast.error(
        `Failed to delete ${removedError} document${
          removedError > 1 ? "s" : ""
        }`
      );
    }
  });

  builder.addCase(syncDocuments.rejected, () => {
    toast.error(UNKNOWN_ERROR_MESSAGE);
  });
});
