import { api } from "@store/api-simplified";
import { handleRequest, handleResponse } from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import { checkBillDuplicationRequestSchema } from "./request";
import {
  checkBillDuplicationResponseSchema,
  linkedInvoicesResponseSchema,
} from "./response";
import type {
  CheckBillDuplicationPayload,
  CheckBillDuplicationResponse,
  LinkedInvoicesPayload,
  LinkedInvoicesResponse,
} from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["CheckBillDuplication", "GetBillLinkedInvoices"],
});

export const billsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    checkBillDuplication: builder.query<
      CheckBillDuplicationResponse,
      CheckBillDuplicationPayload
    >({
      query: (params) => ({
        url: `bills/check_duplicated/`,
        params: {
          ...transformKeysToSnakeCase(
            handleRequest(params, checkBillDuplicationRequestSchema)
          ),
          total_amount: 0,
        },
        responseHandler: async (response) => {
          const data = await response.json();

          return handleResponse(
            transformKeysToCamelCase(data),
            checkBillDuplicationResponseSchema
          );
        },
      }),
    }),
    getBillLinkedInvoices: builder.query<
      LinkedInvoicesResponse,
      LinkedInvoicesPayload
    >({
      query: (args) => {
        const params = args.billIds.map((id) => `bill_ids=${id}`).join("&");

        return {
          url: `bills/linked_invoices/?${params}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              linkedInvoicesResponseSchema
            );
          },
        };
      },
      providesTags: ["GetBillLinkedInvoices"],
    }),
  }),
});

export const {
  useLazyCheckBillDuplicationQuery,
  useLazyGetBillLinkedInvoicesQuery,
} = billsApi;
