export const groupApprovers = (approvers: string[]) =>
  approvers.reduce(
    (acc, approver) => ({
      ...acc,
      ...(approver.includes("users")
        ? { users: [...acc.users, approver] }
        : { roles: [...acc.roles, approver] }),
    }),
    { roles: [], users: [] } as Record<"users" | "roles", string[]>
  );

export const READABLE_TYPE = {
  bill: "Bills",
  // expense: "Receipts",
  // invoice: "Draws",
} as const;

export const TYPES_DATA = Object.entries(READABLE_TYPE).map(([key, value]) => ({
  label: value,
  value: key,
  groupLabel: "Type",
}));
