import React, {
  type ComponentProps,
  useCallback,
  useEffect,
  useState,
} from "react";
import { CurrencyField, Flex, toast } from "@adaptive/design-system";
import { useEvent, useForm } from "@adaptive/design-system/hooks";
import { formatCurrency, parseCurrency } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import type { BudgetLineItems } from "@api/jobs";
import {
  CostCodeAccountComboBox,
  type CostCodeAccountComboBoxProps,
} from "@components/cost-code-account-combobox";
import {
  DEFAULT_COST_CODES_ACCOUNTS_FILTERS,
  useJobsCostCodeAccountSimplified,
} from "@hooks/use-jobs-cost-codes-accounts-simplified";
import { useGetBudgetLinesQuery } from "@shared/api/budgets";
import { CURRENCY_FORMAT } from "@src/jobs";
import { CURRENCY_FIELD_FORM_FORMAT_PROPS } from "@src/jobs/constants";
import { useJobInfo } from "@store/jobs";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import { useInvoiceActions, useInvoiceMarkups } from "./invoice-context";
import {
  type AmountFields,
  type AmountFormProps,
  amountFormSchema,
} from "./types";

type OnInputHandler = Exclude<
  ComponentProps<typeof CurrencyField>["onInput"],
  undefined
>;

const AMOUNT_INITIAL_VALUES: AmountFields = {
  value: 0,
  costCode: "",
};

const EMPTY_BUDGET_LINES: BudgetLineItems[] = [];

const COST_CODE_ACCOUNT_COMBOBOX_FILTERS: CostCodeAccountComboBoxProps<false>["filters"] =
  ["costCodeAccount"];

export const AmountForm = ({
  onClose,
  onFormChange,
  markup,
  formId,
  initialValues = AMOUNT_INITIAL_VALUES,
}: AmountFormProps) => {
  const [currencyFieldValid, setCurrencyFieldValid] = useState(false);

  const { job } = useJobInfo();
  const { updateInvoiceMarkups } = useInvoiceActions();
  const invoiceMarkups = useInvoiceMarkups();
  const { data: budgetLines = EMPTY_BUDGET_LINES } = useGetBudgetLinesQuery({
    customerId: job.id,
  });
  const costCodeAccountsSimplified = useJobsCostCodeAccountSimplified({
    disableBudgetLines: budgetLines,
  });

  const onEditMarkup = useCallback(
    async (values: AmountFields) => {
      let messageSuccess = "";
      const nextMarkups = invoiceMarkups.map((invoiceMarkup) => {
        if (invoiceMarkup.id !== markup?.id) return invoiceMarkup;

        const jobCostMethod = costCodeAccountsSimplified.data.find(
          (item) => item.value === values.costCode
        );
        messageSuccess = `Markup ${jobCostMethod?.label} updated for ${formatCurrency(
          values.value,
          CURRENCY_FORMAT
        )}`;
        return {
          ...invoiceMarkup,
          jobCostMethod: {
            id: Number(parseRefinementIdFromUrl(jobCostMethod?.value)),
            url: jobCostMethod?.value ?? "",
            parent: jobCostMethod?.parent,
            displayName: jobCostMethod?.label ?? "",
          },
          amount: values.value,
        };
      });

      updateInvoiceMarkups(nextMarkups);
      if (messageSuccess) toast.success(messageSuccess);
    },
    [
      markup,
      invoiceMarkups,
      updateInvoiceMarkups,
      costCodeAccountsSimplified.data,
    ]
  );

  const { setValues, ...form } = useForm<AmountFields>({
    id: formId,
    schema: amountFormSchema,
    onSubmit: async (values: AmountFields) => {
      if (!job.id) {
        return toast.error("Job ID is required to add markup");
      }
      try {
        await onEditMarkup(values);
        form.reset();
        setCurrencyFieldValid(false);
        onClose?.();
      } catch (e: unknown) {
        handleErrors(e);
      }
    },
    initialValues,
  });

  useEffect(() => {
    if (markup) {
      setValues({ value: markup.amount, costCode: markup.jobCostMethod?.url });
      setCurrencyFieldValid(true);
    }
  }, [setValues, markup]);

  useEffect(() => {
    onFormChange?.(form.isValid && currencyFieldValid);
  }, [form.id, form.isValid, currencyFieldValid, onFormChange]);

  const onInput = useEvent<OnInputHandler>((e) => {
    const parsedValue = parseCurrency(e.currentTarget.value);
    setCurrencyFieldValid(!!parsedValue);
  });

  return (
    <form {...form.props} id={formId ?? `${markup ? "edit-" : ""}amount`}>
      <Flex wrap gap="xl" align="center" width="full">
        <Flex grow={true}>
          <CostCodeAccountComboBox
            placeholder="ex: Builder's Fee"
            label="Item Name"
            required
            filters={COST_CODE_ACCOUNT_COMBOBOX_FILTERS}
            accountFilters={DEFAULT_COST_CODES_ACCOUNTS_FILTERS.accountFilters}
            costCodeFilters={
              DEFAULT_COST_CODES_ACCOUNTS_FILTERS.costCodeFilters
            }
            disabledBudgetLines={budgetLines}
            {...form.register("costCode")}
          />
        </Flex>
        <Flex maxWidth="200px">
          <CurrencyField
            {...CURRENCY_FIELD_FORM_FORMAT_PROPS}
            label="Amount"
            placeholder="ex: 10,000.00"
            onInput={onInput}
            required
            {...form.register({ name: "value", type: "currency" })}
            messageVariant="relative"
          />
        </Flex>
      </Flex>
    </form>
  );
};
