import React from "react";
import { Navigate, Route, Routes as ReactRouterRoutes } from "react-router";
import { BuilderTrend } from "@buildertrend/components";
import { NotFound } from "@components/not-found";
import { TemplatePage as LienWaiverTemplatePage } from "@lien-waiver/components";
import { withSentryReactRouterV6Routing } from "@sentry/react";
import { useClientSettings } from "@store/user";

import { ProtectedRoute } from "../app/protected-route";
import { BasePermissions, useUserInfo } from "../shared/store/user";

import { AdaptiveCard } from "./components/adaptive-card";
import { ApprovalWorkflows } from "./components/approval-workflows";
import { BankAccounts } from "./components/bank-accounts";
import { Cards } from "./components/cards";
import { General } from "./components/general";
import { People } from "./components/people";
import { Profile } from "./components/profile";
const Routes = withSentryReactRouterV6Routing(ReactRouterRoutes);

export const Settings = () => {
  const {
    capitalOsEnabled,
    canManageLienWaivers,
    buildertrendIntegrationEnabled,
  } = useClientSettings();
  const { hasPermission } = useUserInfo();

  const hasCompanyPermission = hasPermission(BasePermissions.COMPANY_ADMIN);

  return (
    <Routes>
      <Route
        element={
          <Navigate
            to={hasCompanyPermission ? "company/general" : "profile"}
            replace
          />
        }
        path=""
      />
      <Route element={<Profile />} path="profile" />
      <Route path="company">
        <Route
          element={
            <ProtectedRoute somePermissionsOf={[BasePermissions.COMPANY_ADMIN]}>
              <General />
            </ProtectedRoute>
          }
          path="general"
        />
        {canManageLienWaivers && (
          <Route
            element={
              <ProtectedRoute
                allPermissionsOf={[
                  BasePermissions.COMPANY_ADMIN,
                  BasePermissions.CREATE_LIEN_WAIVER_TEMPLATE,
                ]}
              >
                <LienWaiverTemplatePage />
              </ProtectedRoute>
            }
            path="lien-waiver-template/:id"
          />
        )}
        <Route
          element={
            <ProtectedRoute somePermissionsOf={[BasePermissions.COMPANY_ADMIN]}>
              <BankAccounts />
            </ProtectedRoute>
          }
          path="bank-accounts"
        />
        <Route
          element={
            <ProtectedRoute somePermissionsOf={[BasePermissions.COMPANY_ADMIN]}>
              <Cards />
            </ProtectedRoute>
          }
          path="cards"
        />
        {capitalOsEnabled && (
          <Route element={<AdaptiveCard />} path="adaptive-cards" />
        )}
        <Route
          path="plaid-cards"
          element={<Navigate to="/settings/company/cards" replace />}
        />
        <Route
          element={
            <ProtectedRoute
              somePermissionsOf={[BasePermissions.MANAGE_APPROVAL_WORKFLOWS]}
            >
              <ApprovalWorkflows />
            </ProtectedRoute>
          }
          path="approval-workflows"
        />
        <Route
          element={
            <ProtectedRoute somePermissionsOf={[BasePermissions.COMPANY_ADMIN]}>
              <People />
            </ProtectedRoute>
          }
          path="people"
        ></Route>
      </Route>
      {buildertrendIntegrationEnabled && (
        <Route element={<BuilderTrend />} path="buildertrend"></Route>
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
