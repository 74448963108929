import React, {
  type ComponentPropsWithoutRef,
  memo,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  type CollectionItemProps,
  Tab,
  useStoreState,
  useTabContext,
} from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { useEvent } from "../../hooks/use-event";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import { mergeRefs } from "../../utils/merge-refs";

import styles from "./tabs.module.css";

const DEFAULT_COMPONENT = "button";

type TabsTabProps = Omit<
  ComponentPropsWithoutRef<typeof DEFAULT_COMPONENT>,
  "value"
> & { value: string; groupLabel?: ReactNode };

type GetItemHandler = Exclude<CollectionItemProps["getItem"], undefined>;

type Item = ReturnType<GetItemHandler> &
  Pick<TabsTabProps, "groupLabel" | "disabled">;

const TabsTab = forwardRefAs<typeof DEFAULT_COMPONENT, TabsTabProps>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      value,
      children,
      disabled,
      className,
      groupLabel,
      ...props
    },
    ref
  ) => {
    const internalRef = useRef<HTMLButtonElement>(null);

    const tabContext = useTabContext();

    const items = useStoreState(tabContext, "items") as Item[];

    const activeId = useStoreState(tabContext, "activeId");

    let enhancedDisabled = disabled;

    let enhancedChildren = children;

    const currentGroupLabel = items.find(
      (item) => item.id === activeId
    )?.groupLabel;

    const getItem = useCallback<GetItemHandler>(
      (item) => ({ ...item, groupLabel }),
      [groupLabel]
    );

    const isFirstItemOfGroup = useMemo(() => {
      const groups = items.reduce((acc, item) => {
        if (item.groupLabel) {
          const previousValues = acc.get(item.groupLabel) || [];
          acc.set(item.groupLabel, [...previousValues, item]);
        }

        return acc;
      }, new Map<ReactNode, Item[]>());

      let item = groups.get(groupLabel)?.shift();

      while (item?.disabled) {
        item = groups.get(groupLabel)?.shift();
      }

      return item?.id === value;
    }, [items, groupLabel, value]);

    const isInCurrentGroup = currentGroupLabel === groupLabel;

    if (!isInCurrentGroup && activeId) {
      if (!isFirstItemOfGroup) {
        enhancedDisabled = true;
      } else {
        enhancedChildren = groupLabel;
      }
    }

    const updateTabsWidth = useEvent(() => {
      if (!internalRef.current) return;

      internalRef.current.style.setProperty(
        "--tabs-tab-width",
        `${internalRef.current.offsetWidth}px`
      );
    });

    useResizeObserver(internalRef, updateTabsWidth);

    useEffect(() => {
      updateTabsWidth();
    }, [updateTabsWidth]);

    return (
      <Tab
        id={value}
        ref={mergeRefs(ref, internalRef)}
        render={(innerProps) => <Component {...innerProps} />}
        getItem={getItem}
        className={cn(
          styles["tab"],
          { [styles["-group"]]: !isInCurrentGroup },
          className
        )}
        disabled={enhancedDisabled}
        aria-hidden={Boolean(
          !isInCurrentGroup && activeId && !isFirstItemOfGroup
        )}
        accessibleWhenDisabled={false}
        {...props}
      >
        {enhancedChildren}
      </Tab>
    );
  }
);

TabsTab.displayName = "TabsTab";

const MemoizedTabsTab = memo(TabsTab);

export { MemoizedTabsTab as TabsTab };
