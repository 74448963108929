import type { Dispatch, SetStateAction } from "react";
import type { InvoicesInvoice } from "@api/invoices";
import type { GetCustomerCategoriesResponse } from "@api/jobs";
import { noop } from "@utils/noop";
import { createContext } from "use-context-selector";

import type { Line } from "./detail-view/budget/budgets-table/lines";

type JobContextType = {
  settings: {
    commentsEnabled: boolean;
    categoriesEnabled: boolean;
    ownersAmountEnabled: boolean;
    changeTrackingEnabled: boolean;
    clientChangeTrackingEnabled: boolean;
    externalRevisedBudgetEnabled: boolean;
    itemDescriptionEnabled: boolean;
  };
  categories: GetCustomerCategoriesResponse;
  refetchJob: () => Promise<void>;
  permissions: {
    canManage: boolean;
    canEditInitialBudget: boolean;
    canSyncJobs: boolean;
    budgetReadOnly: boolean;
  };
  invoiceCreate: () => Promise<void>;
  invoiceIsLoading: boolean;
  categoriesIsLoading: boolean;
  budgetSelectedLines: Line[];
  setBudgetSelectedLines: Dispatch<SetStateAction<Line[]>>;
  invoiceSelectedDraws: InvoicesInvoice[];
  setInvoiceSelectedDraws: Dispatch<SetStateAction<InvoicesInvoice[]>>;
  updateJobCategoriesEnabled: (value: boolean) => Promise<void>;
};

export const JobContext = createContext<JobContextType>({
  settings: {
    commentsEnabled: false,
    categoriesEnabled: false,
    ownersAmountEnabled: false,
    changeTrackingEnabled: false,
    clientChangeTrackingEnabled: false,
    externalRevisedBudgetEnabled: false,
    itemDescriptionEnabled: false,
  },
  categories: [],
  refetchJob: noop,
  permissions: {
    canManage: false,
    canEditInitialBudget: false,
    canSyncJobs: false,
    budgetReadOnly: false,
  },
  invoiceCreate: noop,
  invoiceIsLoading: false,
  categoriesIsLoading: false,
  budgetSelectedLines: [],
  setBudgetSelectedLines: noop,
  invoiceSelectedDraws: [],
  setInvoiceSelectedDraws: noop,
  updateJobCategoriesEnabled: noop,
});
