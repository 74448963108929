import { useMemo } from "react";
import { omit } from "@adaptive/design-system/utils";
import { useAsSimplifiedData } from "@shared/hooks/useSimplifiedData";
import { useGetAccountsSimplifiedQuery } from "@store/api-simplified";
import { useClientInfo } from "@store/user";

const GROUP_LABEL = "Account";

export type AccountFilters = {
  exclude_accounts_receivable?: boolean;
  exclude_accounts_payable?: boolean;
  exclude_payment_accounts?: boolean;
  only_line_item_accounts?: boolean;
  only_payment_accounts?: boolean;
  can_accounts_link_to_lines_desktop?: boolean;
  vendorId?: number | string;
  showAll?: boolean;
  customerId?: number | string;
};

export type UseAccountsSimplifiedProps = {
  enabled?: boolean;
  filters?: AccountFilters;
  includeLabel?: boolean | string;
};

export const useAccountsSimplified = ({
  enabled = true,
  filters = {},
  includeLabel = false,
}: UseAccountsSimplifiedProps = {}) => {
  const { client, realmId } = useClientInfo();

  const params = useMemo(
    () =>
      omit(
        {
          ...filters,
          vendor_id: filters.vendorId?.toString(),
          customer_id: filters.customerId?.toString(),
          show_all: filters.showAll,
          realm: realmId as number,
        },
        [
          "can_accounts_link_to_lines_desktop",
          "customerId",
          "vendorId",
          "showAll",
        ]
      ),
    [filters, realmId]
  );

  const canAccountsLinkToLinesDesktop =
    filters.can_accounts_link_to_lines_desktop ??
    client?.settings.can_accounts_link_to_lines_desktop;

  const skip = !enabled || !realmId || !canAccountsLinkToLinesDesktop;

  const query = useGetAccountsSimplifiedQuery(params, { skip });

  const { status, data, ...results } = useAsSimplifiedData(query, {
    groupLabel: includeLabel
      ? typeof includeLabel === "boolean"
        ? GROUP_LABEL
        : includeLabel
      : undefined,
    skip,
  });

  return {
    ...results,
    ...query,
    data: data || [],
    status: skip ? "success" : status,
  };
};
