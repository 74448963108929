import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  ComboBox,
  CurrencyField,
  DateField,
  Flex,
  Icon,
  Image,
  Label,
  Link,
  Loader,
  Text,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useDialog,
  useEvent,
  useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  generateSignature,
  isEqual,
} from "@adaptive/design-system/utils";
import {
  patchAccountBalance,
  patchPlaidAccountOwner,
} from "@api/bank-accounts";
import { handleErrors } from "@api/handle-errors";
import { PAYMENT_METHOD } from "@bill-payment/constants";
import {
  useCreatePaymentV2Mutation,
  usePaymentOptionsInfo,
  useViewBillPayment,
} from "@bill-payment/hooks";
import {
  type BankAccountOption,
  type BillPaymentListingV2,
  type CustomerCard,
  type PaymentMethod,
} from "@bill-payment/types";
import { CardTransactionField } from "@card-feed/components";
import type {
  CardTransaction,
  CardTransactionFieldOnChangeHandler,
  LinkedCost,
} from "@card-feed/types";
import { BankAccountComboBox } from "@components/bank-account-combobox";
import { Form } from "@components/form";
import { useObjectInternalVersion } from "@hooks/use-object-internal-version";
import { BillLienWaivers } from "@lien-waiver/components";
import {
  LIEN_WAIVER_LINKED_STATUS,
  LIEN_WAIVER_STATUS,
} from "@lien-waiver/constants";
import type {
  LienWaiverCustomer,
  OnLoadTemplateDataHandler,
} from "@lien-waiver/types";
import {
  useBillFormActions,
  useBillFormPermissions,
} from "@src/bills/bill-form-context";
import {
  BILL_STATUS,
  FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION,
  PAY_BUTTON_LABEL,
  PAYMENT_STEP_FORM_STRINGS,
  STRINGS,
} from "@src/bills/constants";
import {
  billParsedAmountToPay,
  camelCaseBillSelector,
  camelCaseLinesBillSelector,
  isDirtyBillSelector,
  isInPaymentStatusBill,
  paymentsBillV2Selector,
  selectStaticBill,
  vendorBillSelector,
  workflowsBillSelector,
} from "@src/bills/utils";
import {
  AccountSelectDialog,
  type Step as StepSelectAccountDialog,
} from "@src/settings/components/account-select-dialog";
import {
  loadBill,
  recordBillUpdate,
  refetchCurrentBill,
  setBillStatus,
  unarchiveCurrentBill,
} from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useTwoFactorAuth } from "@store/ui";
import { useClientSettings, useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { transformKeysToSnakeCase } from "@utils/schema/converters";
import { sum } from "@utils/sum";
import { useVendorAction } from "@vendors/hooks";
import type { Stage } from "@vendors/types";
import endOfDay from "date-fns/endOfDay";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import startOfDay from "date-fns/startOfDay";
import lodashIsEmpty from "lodash.isempty";

import { BillAlerts } from "../alerts";
import { ApprovalsSection } from "../approvals-section";
import { Comments } from "../comments";
import { useCycle } from "../cycle-provider";
import { DynamicActions } from "../dynamic-actions";
import { Info } from "../info";
import { Items } from "../items";
import { PurchaseOrders } from "../purchase-orders";
import { UpdateSignatureDialog } from "../update-signature-dialog";

import { BillPaymentInfoV2 } from "./bill-payment-info-v2";
import { BillPaymentConfirmationDialog } from "./payment-bill-step-confirmation-dialog";
import type {
  BillPaymentFormProps,
  BillPaymentsProps,
  FormValidationStatus,
  PaymentDetail,
  Signature,
  SignatureFieldProps,
} from "./types";
import {
  addressIsValid,
  getSignatureString,
  selectVendorPaymentData,
} from "./utils";

const DAYS_THRESHOLD = {
  daysBefore: 1,
  daysAfter: 30,
};

const SIGNATURE_NAME = "signature-name";

const SignatureField = ({
  signature,
  onChange,
  dialog,
}: SignatureFieldProps) => {
  const { user } = useUserInfo();

  return (
    <Card>
      <Flex direction="column">
        <Text size="sm">Signature</Text>
        <Flex padding={["xl", "none"]}>
          <Image
            src={signature.url}
            width={signature.width}
            height={signature.height}
          />
        </Flex>
        <Button variant="ghost" color="primary" onClick={dialog.show}>
          Update signature
        </Button>
      </Flex>
      <UpdateSignatureDialog
        dialog={dialog}
        signature={signature.name}
        defaultSignature={user.full_name}
        onChange={async (value) => {
          onChange(await generateSignature(value || user.full_name));
        }}
      />
    </Card>
  );
};

const BillPaymentInfo = ({ billPayments }: BillPaymentsProps) => {
  const { onViewBillPayment } = useViewBillPayment();

  return (
    <Flex gap="2xl" direction="column" padding={["none", "none", "xl"]}>
      {billPayments.map((payment) => (
        <BillPaymentInfoV2
          key={payment.id}
          billPayment={payment}
          onViewPayment={onViewBillPayment}
        />
      ))}
    </Flex>
  );
};

const BillPaymentForm = ({
  formValidationStatus,
  payment,
  nextAvailablePaymentSchedule,
  selectedAccountBalance,
  debitDate,
  paymentMethods,
  cardTransaction,
  paymentOptions,
  onSetPaymentAccount,
}: BillPaymentFormProps) => {
  const { canManageLienWaivers, vendorCreditsEnabled } = useClientSettings();
  const permissions = useBillFormPermissions();
  const dispatch = useAppDispatch();
  const [touched, setTouched] = useState(false);

  const currentDate = useMemo(() => {
    return new Date();
  }, []);

  const selectedPaymentMethod = useMemo(
    () =>
      selectedAccountBalance?.paymentMethods.find(
        (item) => item.paymentMethod === payment.value.method
      ),
    [selectedAccountBalance, payment.value.method]
  );

  const accountSelectDialog = useMultiStepDialog({
    initialStep: "set-account" as StepSelectAccountDialog,
  });

  const onChangePaymentAmount = useEvent((value) =>
    dispatch(recordBillUpdate({ amount_to_pay: value }))
  );

  const {
    balance,
    lienWaivers,
    isArchivedByUser,
    date: billDate,
  } = useAppSelector(camelCaseBillSelector);
  const amountToPay = useAppSelector(billParsedAmountToPay);

  const billVendor = useAppSelector(vendorBillSelector);

  const { vendor, addressOptions: vendorAddresses } = useAppSelector(
    selectVendorPaymentData
  );

  const isPaid = balance === 0;

  const highlightedDates = useMemo(() => {
    if (!nextAvailablePaymentSchedule) return;
    return [
      {
        message: PAYMENT_STEP_FORM_STRINGS.PROCESS_DATE_TITLE,
        matcher: nextAvailablePaymentSchedule.processOn!,
      },
      {
        message: PAYMENT_STEP_FORM_STRINGS.DELIVERY_DATE_TITLE,
        matcher: {
          from: nextAvailablePaymentSchedule.expectedDeliveryAfter!,
          to: nextAvailablePaymentSchedule.expectedDeliveryBefore!,
        },
      },
    ];
  }, [nextAvailablePaymentSchedule]);

  const paymentVendorEmail = useMemo(
    () => payment.value.vendorEmail || billVendor.email || "",
    [payment, billVendor]
  );

  const costForCardTransaction = useMemo(() => {
    return billVendor
      ? ({
          vendor: {
            displayName: `${billVendor.displayName}`,
            email: paymentVendorEmail,
            url: `${billVendor.url}`,
          },
          paymentAccount: selectedPaymentMethod?.customerPaymentAccount,
          date: billDate ? new Date(billDate) : currentDate,
          cardTransaction: cardTransaction?.value,
        } as LinkedCost)
      : undefined;
  }, [
    billDate,
    billVendor,
    cardTransaction?.value,
    currentDate,
    paymentVendorEmail,
    selectedPaymentMethod?.customerPaymentAccount,
  ]);

  const enhancedCardFilters = useMemo(() => {
    if (!selectedAccountBalance?.customerCard) return [];

    const accountOwnerFilter = !selectedAccountBalance?.accountOwner;

    return (
      paymentOptions.data?.accounts
        .filter(
          (account) =>
            !!account.customerCard &&
            ((account.customerBankAccount?.id ===
              selectedAccountBalance?.customerBankAccount?.id &&
              !!account.accountOwner === accountOwnerFilter) ||
              account.customerCard?.id ===
                selectedAccountBalance?.customerCard?.id)
        )
        .map((option) => option.customerCard as CustomerCard) || []
    );
  }, [
    paymentOptions.data?.accounts,
    selectedAccountBalance?.accountOwner,
    selectedAccountBalance?.customerBankAccount?.id,
    selectedAccountBalance?.customerCard,
  ]);

  const enhancedCanPay = useMemo(
    () => permissions.canPayBill && !isPaid,
    [permissions.canPayBill, isPaid]
  );

  const lienWaiver = useMemo(
    () => lienWaivers.find((item) => !item.billPayment),
    [lienWaivers]
  );

  const haveLinkedLienWaiver =
    lienWaiver &&
    LIEN_WAIVER_LINKED_STATUS.some((status) => status === lienWaiver.status);

  const canBeReviewedAndPaid = useMemo(() => {
    const allowedPaymentMethods = [
      PAYMENT_METHOD.ACH,
      PAYMENT_METHOD.MAIL_CHECK,
      PAYMENT_METHOD.PRINT_CHECK,
    ];
    return (
      !selectedAccountBalance?.isCreditCard &&
      selectedAccountBalance?.paymentMethods.some(
        (item) => item.paymentMethod === PAYMENT_METHOD.ACH
      ) &&
      allowedPaymentMethods.some(
        (paymentMethod) => payment.value.method === paymentMethod
      )
    );
  }, [payment.value.method, selectedAccountBalance]);

  const achShouldBeRequested =
    !selectedAccountBalance?.isCreditCard &&
    payment.value.method === PAYMENT_METHOD.ACH &&
    !!vendor.url &&
    !vendor.hasBankingAch;

  const { showVendorById } = useVendorAction();

  const curriedOpenVendor = useCallback(
    (stage: Stage) => () => {
      showVendorById(billVendor.id, stage);
    },
    [billVendor.id, showVendorById]
  );

  const onChangeCardTransaction = useEvent<CardTransactionFieldOnChangeHandler>(
    (_, option) => {
      cardTransaction.onChange(option);
      const newDate = option?.date as Date;
      const newAccountBalance = paymentOptions.data?.accounts.find(
        (account) => account.customerCard?.id === option?.card?.id
      );
      payment?.onChange((prev) => ({
        ...prev,
        currentDebitDate: newDate || currentDate,
        accountBalance: newAccountBalance?.value || prev.accountBalance,
        payWhenLienWaiverIsSigned: false,
      }));
      debitDate.onChange(newDate);
      dispatch(recordBillUpdate({ amount_to_pay: option?.amount || balance }));
    }
  );

  const onPutObject = useEvent(async (values: any) => {
    try {
      await (selectedAccountBalance?.accountOwner
        ? patchPlaidAccountOwner({
            plaidAccountOwner: selectedAccountBalance?.accountOwner,
            ...values,
          })
        : patchAccountBalance({
            account: selectedAccountBalance?.customerBankAccount,
            ...values,
          }));
      onSetPaymentAccount?.();
    } catch (e) {
      handleErrors(e);
    }
  });

  const onSetPayWhenLienWaiverIsSigned = useEvent((value: boolean) =>
    payment?.onChange((prev) => ({
      ...prev,
      payWhenLienWaiverIsSigned: value,
      customerLienWaivers: prev.customerLienWaivers.map((item) =>
        value && item.lienWaiverTemplate
          ? { ...item, sendLienWaiverOnPayment: value }
          : item
      ),
    }))
  );

  const onChangeBankAccount = useEvent((value: string) => {
    payment?.onChange((previousPayment) => ({
      ...previousPayment,
      accountBalance: value,
    }));
    if (cardTransaction.value) {
      onChangePaymentAmount(balance);
      cardTransaction.onChange(undefined);
    }
    setTouched(true);
  });

  const debitDateDisabled = payment.value.payWhenLienWaiverIsSigned;

  const debitDateHelperMessage = useMemo(() => {
    const getMessage = () => {
      if (achShouldBeRequested && payment.value.payWhenLienWaiverIsSigned) {
        return PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_ACH_IS_COLLECTED_AND_LIEN_WAIVER_IS_SIGNED;
      }
      if (achShouldBeRequested) {
        return PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_ACH_DATA_COLLECTED;
      }
      if (payment.value.payWhenLienWaiverIsSigned) {
        return PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_LIEN_WAIVER_SIGNED_TITLE;
      }
    };

    return debitDateDisabled ? getMessage() : undefined;
  }, [
    achShouldBeRequested,
    debitDateDisabled,
    payment.value.payWhenLienWaiverIsSigned,
  ]);

  const showCardTransactionField = useMemo(() => {
    return (
      (selectedAccountBalance?.isCreditCard ||
        selectedAccountBalance?.customerCard) &&
      selectedAccountBalance.hasUnmatchedCardTransactions &&
      costForCardTransaction
    );
  }, [
    selectedAccountBalance?.isCreditCard,
    selectedAccountBalance?.customerCard,
    selectedAccountBalance?.hasUnmatchedCardTransactions,
    costForCardTransaction,
  ]);

  return (
    <>
      <Flex gap="xl" direction="column" padding={["xs", "none"]}>
        <Text size="xl" weight="bold">
          {PAYMENT_STEP_FORM_STRINGS.PAYMENT_SECTION_TITLE}
        </Text>
        <Card
          as={Flex}
          gap="2xl"
          direction={{ mobile: "column", tablet: "row" }}
          width="full"
          separator={{ mobile: false, tablet: true }}
        >
          <Flex width="full">
            <Flex gap="xl" width="full" direction="column" separator>
              <Flex gap="xl" width="full" direction="column">
                <Flex
                  width="full"
                  align="center"
                  gap="xl"
                  justify="space-between"
                >
                  <Text size="sm">
                    {PAYMENT_STEP_FORM_STRINGS.OPEN_BALANCE_TITLE}
                  </Text>
                  <Text weight="bold">
                    {" "}
                    {formatCurrency(balance, {
                      currencySign: true,
                      allowNegative: true,
                    })}
                  </Text>
                </Flex>
                {vendorCreditsEnabled && (
                  <Flex direction="column">
                    <Flex
                      width="full"
                      align="center"
                      gap="xl"
                      justify="space-between"
                    >
                      <Text size="sm">
                        {PAYMENT_STEP_FORM_STRINGS.VENDOR_CREDITS_TITLE}
                      </Text>
                      {/* Update this amounts when vendor credits are implemented */}
                      <Text weight="bold">$0.00</Text>
                    </Flex>
                    <Flex
                      width="full"
                      align="center"
                      gap="xl"
                      justify="space-between"
                    >
                      <Text size="sm" color="neutral-500">
                        {/* Update this amounts when vendor credits are implemented */}
                        $0 available
                      </Text>
                      <Flex margin={["none", "-13px", "none"]}>
                        <Button
                          size="sm"
                          variant="text"
                          color="neutral"
                          aria-label="Edit vendor credits"
                        >
                          <Icon name="pen" />
                          {PAYMENT_STEP_FORM_STRINGS.EDIT_CREDITS_TITLE}
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                <Flex
                  width="full"
                  align="center"
                  gap="xl"
                  justify="space-between"
                >
                  <Text as="label" size="sm">
                    {PAYMENT_STEP_FORM_STRINGS.AMOUNT_TO_PAY_TITLE}
                  </Text>
                  <Flex maxWidth="170px">
                    <CurrencyField
                      aria-labelledby="amount-to-pay-label"
                      value={amountToPay}
                      disabled={
                        haveLinkedLienWaiver ||
                        !enhancedCanPay ||
                        paymentOptions.isLoading
                      }
                      placeholder="0.00"
                      helperMessage={
                        haveLinkedLienWaiver &&
                        STRINGS.PAYMENT_AMOUNT_DISABLED_LIEN_WAIVER
                      }
                      align="right"
                      onChange={onChangePaymentAmount}
                      autoFocus
                      triggerChangeOnFocusedUnmount={false}
                      allowNegative={false}
                      data-testid="payment-amount"
                      errorMessage={
                        formValidationStatus.amountToPay ||
                        formValidationStatus.amountToPayTooHigh
                      }
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                width="full"
                align="center"
                gap="xl"
                justify="space-between"
              >
                <Text size="sm">
                  {PAYMENT_STEP_FORM_STRINGS.REMAINING_BALANCE_TITLE}
                </Text>
                <Text weight="bold">
                  {formatCurrency(sum(balance, -amountToPay), {
                    currencySign: true,
                    allowNegative: true,
                  })}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex width="full" justify="flex-start" direction="column">
            <Flex width="full" direction="column">
              <BankAccountComboBox
                disabled={isArchivedByUser || !enhancedCanPay}
                errorMessage={
                  touched &&
                  (formValidationStatus.fromAccount ||
                    formValidationStatus.fromAccountLinking)
                }
                label={PAYMENT_STEP_FORM_STRINGS.FROM_ACCOUNT_TITLE}
                value={payment.value.accountBalance}
                paymentOptions={paymentOptions.data}
                data-testid="from-account"
                loading={paymentOptions.isLoading}
                onChange={onChangeBankAccount}
              />
              {formValidationStatus.fromAccountLinking &&
                !paymentOptions.isLoading && (
                  <Flex gap="sm" wrap={true} margin={["none", "none", "lg"]}>
                    <Text size="sm" color="neutral-700" weight="regular">
                      {PAYMENT_STEP_FORM_STRINGS.LINK_ACCOUNT_TITLE}
                    </Text>
                    <Link
                      as="button"
                      type="button"
                      variant="success"
                      onClick={accountSelectDialog.show}
                    >
                      {PAYMENT_STEP_FORM_STRINGS.CLICK_HERE_TITLE}
                    </Link>
                  </Flex>
                )}
            </Flex>
            {paymentMethods.length ? (
              <ComboBox
                disabled={!enhancedCanPay}
                label={PAYMENT_STEP_FORM_STRINGS.PAYMENT_METHOD_TITLE}
                value={payment.value.method}
                data={paymentMethods}
                errorMessage={
                  formValidationStatus.vendorAddress ||
                  formValidationStatus.paymentMethod
                }
                data-testid="payment-method"
                onChange={(value) =>
                  payment?.onChange((previousPayment) => ({
                    ...previousPayment,
                    method: value as PaymentMethod,
                    currentDebitDate: currentDate,
                  }))
                }
              />
            ) : null}
            {payment.value.method === PAYMENT_METHOD.MAIL_CHECK &&
              canBeReviewedAndPaid && (
                <Flex direction="column" margin={["none", "none", "lg"]}>
                  <TextField
                    disabled
                    label={PAYMENT_STEP_FORM_STRINGS.VENDOR_ADDRESS_TITLE}
                    messageVariant="hidden"
                    value={
                      vendorAddresses.length ? vendorAddresses[0].label : ""
                    }
                  />
                  <Text size="xs" color="neutral-500">
                    {PAYMENT_STEP_FORM_STRINGS.ADD_VENDOR_ADDRESS_TITLE}{" "}
                    <Link
                      variant="success"
                      size="xs"
                      onClick={curriedOpenVendor("info")}
                    >
                      {PAYMENT_STEP_FORM_STRINGS.ADD_IT_HERE_TITLE}
                    </Link>
                  </Text>
                </Flex>
              )}
            {showCardTransactionField && (
              <CardTransactionField
                cost={costForCardTransaction!}
                filtersAsFixed
                label={PAYMENT_STEP_FORM_STRINGS.CARD_TRANSACTION_TITLE}
                cardFilters={enhancedCardFilters}
                valueVariant="long"
                suffix={<Icon size="md" name="merge" />}
                placeholder={
                  PAYMENT_STEP_FORM_STRINGS.CARD_TRANSACTION_PLACEHOLDER
                }
                tooltipMessage={
                  PAYMENT_STEP_FORM_STRINGS.CARD_TRANSACTION_PLACEHOLDER
                }
                onChange={onChangeCardTransaction}
                selectCard={{
                  title:
                    PAYMENT_STEP_FORM_STRINGS.MATCHING_TRANSACTION_TO_NEW_PAYMENT_DIALOG_TITLE,
                  subtitle:
                    PAYMENT_STEP_FORM_STRINGS.MATCHING_TRANSACTION_TO_NEW_PAYMENT_DIALOG_SUBTITLE,
                }}
                daysThreshold={DAYS_THRESHOLD}
                skipRequest
              />
            )}
            {!formValidationStatus.fromAccount &&
              payment.value.accountBalance && (
                <Flex gap="sm" direction="column">
                  <Flex direction="column">
                    {(achShouldBeRequested ||
                      selectedAccountBalance?.markAsPaidOption) && (
                      <Label htmlFor="payment-when-collected">
                        {PAYMENT_STEP_FORM_STRINGS.DEBIT_DATE_TITLE}
                      </Label>
                    )}
                    <Flex direction="column" gap="xl">
                      {((achShouldBeRequested && canBeReviewedAndPaid) ||
                        selectedAccountBalance?.markAsPaidOption) && (
                        <Flex direction="column" gap="sm">
                          <DateField
                            toDate={
                              nextAvailablePaymentSchedule?.processOn ||
                              currentDate
                            }
                            messageVariant={
                              debitDateHelperMessage ? "relative" : "hidden"
                            }
                            value={debitDateDisabled ? null : debitDate.value}
                            onChange={(value) =>
                              debitDate.onChange(value as Date)
                            }
                            highlightedDates={highlightedDates}
                            disabled={debitDateDisabled}
                            helperMessage={debitDateHelperMessage}
                          />
                        </Flex>
                      )}
                      {achShouldBeRequested && canBeReviewedAndPaid && (
                        <Flex direction="column">
                          <Checkbox
                            id="payment-when-collected"
                            label={
                              PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_ACH_DATA_COLLECTED
                            }
                            checked
                            disabled
                          />
                          <Text size="xs" color="neutral-500">
                            {PAYMENT_STEP_FORM_STRINGS.ADD_ACH_DETAILS_TITLE}{" "}
                            <Link
                              variant="success"
                              size="xs"
                              onClick={curriedOpenVendor("payments")}
                            >
                              {PAYMENT_STEP_FORM_STRINGS.ADD_IT_HERE_TITLE}
                            </Link>
                          </Text>
                        </Flex>
                      )}
                      {canManageLienWaivers &&
                      !haveLinkedLienWaiver &&
                      canBeReviewedAndPaid &&
                      !paymentOptions.isLoading ? (
                        <Checkbox
                          label={
                            PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_LIEN_WAIVER_SIGNED_TITLE
                          }
                          onChange={onSetPayWhenLienWaiverIsSigned}
                          checked={
                            payment.value.payWhenLienWaiverIsSigned || false
                          }
                        />
                      ) : null}
                    </Flex>
                  </Flex>
                </Flex>
              )}
          </Flex>
        </Card>
      </Flex>
      {accountSelectDialog.isRendered && (
        <AccountSelectDialog
          dialog={accountSelectDialog}
          selectHeader={PAYMENT_STEP_FORM_STRINGS.LINK_ACCOUNT_TITLE}
          selectSubHeader={`Bank account: ${selectedAccountBalance?.label}`}
          selectLabel={PAYMENT_STEP_FORM_STRINGS.LINK_ACCOUNT_TITLE}
          putObject={onPutObject}
          isBankAccount={true}
          selectedAccount={undefined}
        />
      )}
    </>
  );
};

export const PayBillStepFormV2 = () => {
  const { user } = useUserInfo();

  const { checkTwoFactorAuth } = useTwoFactorAuth();

  const { save, close: onClose } = useBillFormActions();

  const [currentSignature, setSignature] = useState<Signature | undefined>();

  const currentDate = useMemo(() => {
    return new Date();
  }, []);

  const [currentDebitDate, setCurrentDebitDate] = useState<Date | null>(
    currentDate
  );

  const [cancellingPayment, setCancellingPayment] = useState(false);

  const hasSignature = !lodashIsEmpty(currentSignature);

  const updateSignatureDialog = useDialog();

  const showPaymentConfirmationDialog = useDialog({
    lazy: true,
  });

  const [currentCardTransaction, setCurrentCardTransaction] = useState<
    CardTransaction | undefined
  >();

  const [cancelledPaymentId, setCancelledPaymentId] = useState(null);

  const dispatch = useAppDispatch();

  const isDirty = useAppSelector(isDirtyBillSelector);

  const permissions = useBillFormPermissions();

  const { canManageLienWaivers } = useClientSettings();

  const cycle = useCycle();

  const billLines = useAppSelector(camelCaseLinesBillSelector);

  const workflows = useAppSelector(workflowsBillSelector);

  const billVendor = useAppSelector(vendorBillSelector);

  const billPayments = useAppSelector<BillPaymentListingV2[]>(
    paymentsBillV2Selector
  );

  const { fetchById: fetchVendor } = useVendorAction();

  const [triggerCreatePayment, { isLoading: isLoadingMarkingAsPaid }] =
    useCreatePaymentV2Mutation();

  const billCustomerUrls = useMemo(
    () =>
      Array.from(
        new Set(
          billLines
            .filter((line) => !line.deleted && line.customer?.url)
            .map((line) => line.customer?.url)
        )
      ),
    [billLines]
  );

  const {
    id,
    url: billUrl,
    balance,
    docNumber,
    isArchivedByUser,
    tooManyLines,
    reviewStatus,
    isVendorCredit,
    shouldShowPurchaseOrders,
    billLienWaiverTemplate,
    lienWaivers,
    paymentDetails,
  } = useAppSelector(camelCaseBillSelector);

  const { onViewBillPayment } = useViewBillPayment();

  const rawBill = useAppSelector(selectStaticBill, isEqual);

  const amountToPay = useAppSelector(billParsedAmountToPay);

  const isInPaymentStatus = isInPaymentStatusBill(reviewStatus);

  const enhancedShouldShowPurchaseOrders =
    shouldShowPurchaseOrders &&
    permissions.canViewPurchaseOrder &&
    !isInPaymentStatus;

  const billInternalVersion = useObjectInternalVersion(rawBill);

  const isPaid = balance === 0;

  const signatureNameKey = `${SIGNATURE_NAME}-${id}`;

  const lienWaiver = useMemo(
    () => lienWaivers.find((item) => !item.billPayment),
    [lienWaivers]
  );

  const haveLinkedLienWaiver =
    lienWaiver &&
    LIEN_WAIVER_LINKED_STATUS.some((status) => status === lienWaiver.status);

  const enhancedCanPay = useMemo(
    () => permissions.canPayBill && !isPaid,
    [permissions.canPayBill, isPaid]
  );

  const {
    data: paymentOptions,
    getPaymentMethods,
    isLoading: paymentOptionsLoading,
    refetch: refetchPaymentOptions,
  } = usePaymentOptionsInfo({
    billIds: id ? [id] : [],
    vendorIds: billVendor?.id ? [billVendor.id] : [],
    skip: !enhancedCanPay || !id,
  });

  const paymentOptionsForBill = useDeepMemo(
    () => (paymentOptions.length ? paymentOptions[0] : undefined),
    [paymentOptions]
  );

  const enhancedPaymentOptions = useMemo(() => {
    return {
      data: paymentOptionsForBill,
      isLoading: paymentOptionsLoading,
    };
  }, [paymentOptionsForBill, paymentOptionsLoading]);

  const { vendor, recipientOptions: vendors } = useAppSelector(
    selectVendorPaymentData
  );

  const [payment, setPayment] = useState<PaymentDetail>({
    method:
      paymentDetails.method ||
      ((window.ACH_CHECK_ENABLED
        ? PAYMENT_METHOD.ACH
        : PAYMENT_METHOD.MARK_AS_PAID) as PaymentMethod),
    vendor: paymentDetails.vendor || vendor.email || vendors[0]?.value || "",
    accountBalance: paymentDetails.accountBalance || "",
    account: paymentDetails.account || "",
    vendorBankingAch:
      paymentDetails.vendorBankingAch || vendor?.banking?.url || "",
    vendorEmail: paymentDetails.vendorEmail || vendor.email || "",
    vendorPhoneNumber:
      paymentDetails.vendorPhoneNumber || vendor.phoneNumber || "",
    signature: paymentDetails.signature || currentSignature?.url || "",
    currentDebitDate: paymentDetails.currentDebitDate || currentDate,
    customerLienWaivers: paymentDetails.customerLienWaivers || [],
    payWhenLienWaiverIsSigned:
      paymentDetails.payWhenLienWaiverIsSigned || false,
  });

  const paymentVendorEmail = useMemo(
    () => payment.vendorEmail || billVendor.email || "",
    [payment, billVendor]
  );

  const selectedAccountBalance = useMemo<BankAccountOption | undefined>(
    () =>
      payment.accountBalance
        ? paymentOptionsForBill?.accounts.find(
            (option) => option.value === payment.accountBalance
          )
        : undefined,
    [payment.accountBalance, paymentOptionsForBill]
  );

  const selectedPaymentMethod = useMemo(
    () =>
      selectedAccountBalance?.paymentMethods.find(
        (item) => item.paymentMethod === payment.method
      ) || selectedAccountBalance?.markAsPaidOption,
    [selectedAccountBalance, payment.method]
  );

  const paymentMethods = useMemo(() => {
    if (!selectedAccountBalance) return [];

    return (
      getPaymentMethods(
        selectedAccountBalance?.paymentMethods.map((item) => item.paymentMethod)
      ) || []
    );
  }, [getPaymentMethods, selectedAccountBalance]);

  const canBenReviewedAndPaid = useMemo(() => {
    const allowedPaymentMethods = [
      PAYMENT_METHOD.ACH,
      PAYMENT_METHOD.MAIL_CHECK,
      PAYMENT_METHOD.PRINT_CHECK,
    ];
    return (
      selectedAccountBalance?.paymentMethods.some(
        (item) => item.paymentMethod === PAYMENT_METHOD.ACH
      ) &&
      allowedPaymentMethods.some(
        (paymentMethod) => payment.method === paymentMethod
      ) &&
      !currentCardTransaction
    );
  }, [payment.method, selectedAccountBalance, currentCardTransaction]);

  const nextAvailablePaymentSchedule = useMemo(() => {
    return selectedPaymentMethod?.paymentSchedules?.[0];
  }, [selectedPaymentMethod?.paymentSchedules]);

  useEffect(() => {
    if (
      selectedPaymentMethod &&
      nextAvailablePaymentSchedule &&
      nextAvailablePaymentSchedule.processOn
    ) {
      setCurrentDebitDate(nextAvailablePaymentSchedule.processOn);
    } else if (
      selectedPaymentMethod?.paymentMethod === PAYMENT_METHOD.MARK_AS_PAID
    ) {
      setCurrentDebitDate(currentDate);
    }
  }, [
    currentDate,
    setCurrentDebitDate,
    selectedPaymentMethod?.paymentSchedules,
    nextAvailablePaymentSchedule,
    selectedPaymentMethod,
    selectedPaymentMethod?.paymentMethod,
  ]);

  const curriedMarkAsPaid = () => {
    if (selectedAccountBalance?.markAsPaidOption) {
      checkTwoFactorAuth(async () => {
        dispatch(setBillStatus("loading"));
        try {
          const createdPayments = await triggerCreatePayment([
            {
              bills: [billUrl],
              options: {
                appliedAmount: amountToPay,
                customerBankAccount:
                  selectedAccountBalance.customerBankAccount?.url,
                customerCard: selectedAccountBalance.customerCard?.url,
                customerPaymentAccount:
                  selectedAccountBalance.markAsPaidOption
                    ?.customerPaymentAccount?.url,
                vendorBankAccount:
                  selectedAccountBalance.markAsPaidOption?.vendorBankAccount
                    ?.url,
                appliedCardTransaction: currentCardTransaction?.url,
                paymentMethod: PAYMENT_METHOD.MARK_AS_PAID,
                appliedSignature:
                  payment.method === PAYMENT_METHOD.MAIL_CHECK
                    ? getSignatureString(payment.signature)
                    : undefined,
                processPrerequisites:
                  selectedAccountBalance.markAsPaidOption?.processPrerequisites,
                appliedProcessOn: formatDate(
                  currentDebitDate || currentDate,
                  "iso-8601-now"
                ),
              },
              lienWaivers: payment.customerLienWaivers.map((lienWaiver) => ({
                ...lienWaiver,
                lienWaiverTemplateId:
                  lienWaiver.sendLienWaiverOnPayment &&
                  lienWaiver?.lienWaiverTemplate
                    ? `${parseRefinementIdFromUrl(
                        lienWaiver?.lienWaiverTemplate
                      )}`
                    : null,
                status:
                  !lienWaiver.sendLienWaiverOnPayment &&
                  lienWaiver.status !== LIEN_WAIVER_STATUS.NOT_REQUIRED
                    ? LIEN_WAIVER_STATUS.NOT_SELECTED
                    : lienWaiver.status,
              })),
            },
          ]).unwrap();
          setCurrentCardTransaction(undefined);
          dispatch(refetchCurrentBill(FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION));
          toast.success(
            `${PAYMENT_STEP_FORM_STRINGS.PAID_SUCCESSFULLY} #${docNumber}!`
          );
          analytics.track("billPay", { billId: id });
          if (createdPayments?.length === 1) {
            onViewBillPayment(createdPayments[0].id);
          }
        } catch (e) {
          handleErrors(e);
        } finally {
          dispatch(setBillStatus("loaded"));
        }
      });
    }
  };

  const commonValidationStatus = useMemo<FormValidationStatus>(() => {
    return {
      billAlreadyPaid:
        reviewStatus === BILL_STATUS.PAID
          ? PAYMENT_STEP_FORM_STRINGS.BILL_ALREADY_PAID_MESSAGE
          : undefined,
      unsavedChanges: isDirty
        ? PAYMENT_STEP_FORM_STRINGS.UNSAVED_CHANGES_ALERT
        : undefined,
      amountToPay: !amountToPay
        ? PAYMENT_STEP_FORM_STRINGS.AMOUNT_TO_PAY_REQUIRED
        : undefined,
      amountToPayTooHigh:
        amountToPay > balance
          ? PAYMENT_STEP_FORM_STRINGS.PAYMENT_AMOUNT_T0_HIGH
          : undefined,
      fromAccount: !payment.accountBalance
        ? PAYMENT_STEP_FORM_STRINGS.SELECT_ACCOUNT_TO_PAY
        : undefined,
      fromAccountLinking:
        payment.accountBalance &&
        selectedPaymentMethod &&
        !selectedPaymentMethod?.customerPaymentAccount?.url
          ? PAYMENT_STEP_FORM_STRINGS.PAYMENT_ACCOUNT_NOT_LINKED
          : undefined,
      paymentMethod:
        paymentMethods.length &&
        !paymentMethods.some(
          (paymentMethod) => paymentMethod.value === payment.method
        )
          ? PAYMENT_STEP_FORM_STRINGS.SELECT_PAYMENT_METHOD_TITLE
          : undefined,
      currentDebitDate: !currentDebitDate
        ? PAYMENT_STEP_FORM_STRINGS.SELECT_DEBIT_DATE_TITLE
        : undefined,
      canPayBill: !enhancedCanPay ? STRINGS.ACTION_NOT_ALLOWED : undefined,
      lienWaiversData:
        canManageLienWaivers &&
        !haveLinkedLienWaiver &&
        payment.customerLienWaivers.some(
          (lienWaiver) =>
            lienWaiver.lienWaiverTemplate &&
            !lienWaiver.lienWaiverTemplateIsValid
        )
          ? PAYMENT_STEP_FORM_STRINGS.LIEN_WAIVER_TEMPLATE_MISSING_DATA
          : undefined,
    };
  }, [
    canManageLienWaivers,
    haveLinkedLienWaiver,
    reviewStatus,
    isDirty,
    amountToPay,
    balance,
    payment.accountBalance,
    payment.customerLienWaivers,
    payment.method,
    selectedPaymentMethod,
    paymentMethods,
    currentDebitDate,
    enhancedCanPay,
  ]);

  const markAsPaidValidationStatus = useMemo<FormValidationStatus>(() => {
    const getCurrentDebitDateError = () => {
      if (commonValidationStatus.currentDebitDate) {
        return commonValidationStatus.currentDebitDate;
      }
      if (payment.payWhenLienWaiverIsSigned) {
        return PAYMENT_STEP_FORM_STRINGS.CANNOT_MARK_AS_PAID_FOR_PAY_WHEN_LIEN_WAIVER_IS_SIGNED;
      }
      const today = new Date();
      if (currentDebitDate && isAfter(currentDebitDate, endOfDay(today))) {
        return PAYMENT_STEP_FORM_STRINGS.CANNOT_MARK_AS_PAID_FOR_A_FUTURE_DATE;
      }
    };

    return {
      ...commonValidationStatus,
      currentDebitDate: getCurrentDebitDateError(),
    };
    /**
     * @todo we should remove all eslint-disable comments
     */
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDebitDate, commonValidationStatus]);

  const reviewAndPayValidationStatus = useMemo<FormValidationStatus>(() => {
    const getCurrentDebitDateError = () => {
      if (commonValidationStatus.currentDebitDate) {
        return commonValidationStatus.currentDebitDate;
      }
      if (
        currentDebitDate &&
        nextAvailablePaymentSchedule?.processOn &&
        isBefore(
          endOfDay(currentDebitDate),
          endOfDay(nextAvailablePaymentSchedule.processOn)
        )
      ) {
        return PAYMENT_STEP_FORM_STRINGS.SELECT_NEXT_AVAILABLE_BUSINESS_DAY_TO_BE_ABLE_TO_PAY;
      }
    };

    return {
      ...commonValidationStatus,
      currentDebitDate: getCurrentDebitDateError(),
      lienWaivers:
        payment.payWhenLienWaiverIsSigned &&
        !payment.customerLienWaivers.some(
          (lienWaiver) => lienWaiver.lienWaiverTemplate
        )
          ? PAYMENT_STEP_FORM_STRINGS.LIEN_WAIVER_TEMPLATE_MISSING
          : undefined,
      vendorAddress:
        payment.method === PAYMENT_METHOD.MAIL_CHECK &&
        (!vendor?.address || !addressIsValid(vendor.address))
          ? PAYMENT_STEP_FORM_STRINGS.ADD_VENDOR_ADDRESS_ERROR_TITLE
          : undefined,
      canReviewAndPay: !canBenReviewedAndPaid
        ? PAYMENT_STEP_FORM_STRINGS.REVIEW_AND_PAY_UNAVAILABLE_MESSAGE
        : undefined,
      pastDate:
        startOfDay(currentDebitDate!) < startOfDay(currentDate)
          ? PAYMENT_STEP_FORM_STRINGS.PAST_DATE_ERROR
          : undefined,
    };
  }, [
    commonValidationStatus,
    payment.payWhenLienWaiverIsSigned,
    payment.customerLienWaivers,
    payment.method,
    vendor.address,
    canBenReviewedAndPaid,
    currentDebitDate,
    currentDate,
    nextAvailablePaymentSchedule?.processOn,
  ]);

  const billFormValidationStatus = useMemo<FormValidationStatus>(
    () => ({
      canEditBill: !permissions.canEditBill
        ? STRINGS.ACTION_NOT_ALLOWED
        : undefined,
      tooManyLines: tooManyLines ? STRINGS.BUTTON_TOO_MANY_LINES : undefined,
    }),
    [permissions.canEditBill, tooManyLines]
  );

  const formValidationStatus = useMemo<FormValidationStatus>(
    () => ({
      ...reviewAndPayValidationStatus,
      ...billFormValidationStatus,
    }),
    [billFormValidationStatus, reviewAndPayValidationStatus]
  );

  const reviewAndPayValidationError = useMemo(() => {
    const errors = Object.values(reviewAndPayValidationStatus).filter(
      (status) => status
    );

    return errors?.[0] || undefined;
  }, [reviewAndPayValidationStatus]);

  const markAsPaidValidationError = useMemo(() => {
    const errors = Object.values(markAsPaidValidationStatus).filter(
      (status) => status
    );

    return errors?.[0] || undefined;
  }, [markAsPaidValidationStatus]);

  const billFormValidationError = useMemo(() => {
    const errors = Object.values(billFormValidationStatus).filter(
      (status) => status
    );

    return errors?.[0] || undefined;
  }, [billFormValidationStatus]);

  const renderLabel = useCallback(
    (label: string) => (cancellingPayment ? <Loader /> : label),
    [cancellingPayment]
  );

  const onUnarchive = useEvent(() => {
    cycle.disable();
    dispatch(unarchiveCurrentBill());
  });

  const primaryActionButtons = useMemo(() => {
    let buttonProps = {
      onClick: () => {
        checkTwoFactorAuth(async () => {
          showPaymentConfirmationDialog.show();
        });
      },
      disabled:
        isArchivedByUser ||
        paymentOptionsLoading ||
        isLoadingMarkingAsPaid ||
        !!reviewAndPayValidationError ||
        !canBenReviewedAndPaid,

      children: renderLabel(PAY_BUTTON_LABEL.REVIEW_AND_PAY),
    };

    let tooltipMessage = reviewAndPayValidationError ?? null;

    if (isArchivedByUser) {
      buttonProps = {
        onClick: onUnarchive,
        children: PAYMENT_STEP_FORM_STRINGS.RESTORE_TITLE,
        disabled: false,
      };
    } else if (isVendorCredit) {
      buttonProps = {
        onClick: onClose,
        disabled: false,
        children: PAYMENT_STEP_FORM_STRINGS.CLOSE_TITLE,
      };
      tooltipMessage = null;
    }

    if (tooltipMessage && !buttonProps.disabled) {
      buttonProps.disabled = true;
    }

    const content = <Button {...buttonProps} size="lg" />;

    return tooltipMessage ? (
      <Tooltip message={tooltipMessage} placement="left">
        {content}
      </Tooltip>
    ) : (
      content
    );
  }, [
    canBenReviewedAndPaid,
    checkTwoFactorAuth,
    isArchivedByUser,
    isLoadingMarkingAsPaid,
    isVendorCredit,
    onClose,
    onUnarchive,
    paymentOptionsLoading,
    renderLabel,
    reviewAndPayValidationError,
    showPaymentConfirmationDialog,
  ]);

  const onChangeLienWaiverRequest = useEvent(() =>
    dispatch(refetchCurrentBill(["lien_waivers"]))
  );

  const mapLienWaiverCustomer = useCallback(
    (
      customer?: string,
      lienWaiverTemplateUrl?: string,
      sendLienWaiverOnPayment?: boolean,
      lienWaiverTemplateIsValid?: boolean
    ) => {
      const lienWaiverTemplate =
        lienWaiverTemplateUrl &&
        lienWaiverTemplateUrl !== LIEN_WAIVER_STATUS.NOT_REQUIRED
          ? lienWaiverTemplateUrl
          : undefined;

      const status = (
        !lienWaiverTemplateUrl
          ? LIEN_WAIVER_STATUS.NOT_SELECTED
          : lienWaiverTemplateUrl === LIEN_WAIVER_STATUS.NOT_REQUIRED
            ? lienWaiverTemplateUrl
            : undefined
      ) as LienWaiverCustomer["status"];

      return {
        customer,
        lienWaiverTemplate,
        status,
        lienWaiverTemplateIsValid,
        sendLienWaiverOnPayment:
          (lienWaiverTemplate &&
            (sendLienWaiverOnPayment || payment.payWhenLienWaiverIsSigned)) ||
          false,
      } as LienWaiverCustomer;
    },
    [payment.payWhenLienWaiverIsSigned]
  );

  const onLoadTemplateData = useEvent<OnLoadTemplateDataHandler>(
    ({ templateData, customer }) => {
      setPayment((prev) => ({
        ...prev,
        customerLienWaivers: prev.customerLienWaivers.map((item) =>
          item.customer === customer
            ? {
                ...item,
                lienWaiverTemplateIsValid:
                  !templateData.missingFields ||
                  !templateData.missingFields.length,
              }
            : item
        ),
      }));
    }
  );

  const onChangeLienWaiverTemplate = useEvent(
    (
      value: string,
      extra?: { customer?: string; lienWaiverTemplateIsValid?: boolean }
    ) => {
      setPayment((prev) => ({
        ...prev,
        customerLienWaivers: prev.customerLienWaivers.map((item) =>
          item.customer === extra?.customer
            ? mapLienWaiverCustomer(
                extra?.customer,
                value,
                item.sendLienWaiverOnPayment,
                extra.lienWaiverTemplateIsValid
              )
            : item
        ),
      }));
    }
  );

  const onChangeRequestOnPayment = useEvent(
    (value: boolean, extra?: { customer?: string }) => {
      setPayment((prev) => ({
        ...prev,
        customerLienWaivers: prev.customerLienWaivers.map((item) =>
          item.customer === extra?.customer
            ? { ...item, sendLienWaiverOnPayment: value }
            : item
        ),
      }));
    }
  );

  const requestOnPayment = useMemo(
    () => ({
      onChange: onChangeRequestOnPayment,
      disabled: payment.payWhenLienWaiverIsSigned,
    }),
    [onChangeRequestOnPayment, payment.payWhenLienWaiverIsSigned]
  );

  const paymentPayload = useMemo(
    () => ({
      payments: [
        {
          billUrls: [`${billUrl}`],
          amountToPay,
          paymentMethod: payment.method,
          balance,
          signature:
            payment.method === PAYMENT_METHOD.MAIL_CHECK
              ? getSignatureString(payment.signature)
              : undefined,
          bankAccountOption: selectedAccountBalance as BankAccountOption,
          payWhenLienWaiverIsSigned: payment.payWhenLienWaiverIsSigned,
          vendor: {
            id: billVendor.id,
            email: paymentVendorEmail,
          },
          currentDebitDate:
            nextAvailablePaymentSchedule &&
            nextAvailablePaymentSchedule.processOn
              ? formatDate(
                  nextAvailablePaymentSchedule.processOn,
                  "iso-8601-now"
                )
              : undefined,
        },
      ],
      lienWaivers: payment.customerLienWaivers,
    }),
    [
      billUrl,
      amountToPay,
      payment.method,
      payment.signature,
      payment.payWhenLienWaiverIsSigned,
      payment.customerLienWaivers,
      balance,
      selectedAccountBalance,
      billVendor.id,
      paymentVendorEmail,
      nextAvailablePaymentSchedule,
    ]
  );

  const refetchBill = useCallback(() => dispatch(loadBill(id)), [dispatch, id]);

  const onSave = useEvent(() => {
    const snakeCasePayment = transformKeysToSnakeCase(payment);

    dispatch(recordBillUpdate({ payment_details: snakeCasePayment }));
    return save();
  });

  const defaultPaymentOptionValue = useDeepMemo(
    () =>
      paymentOptionsForBill?.accounts.find((option) =>
        option.paymentMethods?.some((item) => item.default)
      )?.value,
    [paymentOptionsForBill]
  );

  useEffect(() => {
    if (isPaid) return;

    setPayment((prevState) => {
      const hasChangedVendor =
        prevState.vendorEmail !== vendor.email ||
        prevState.vendorPhoneNumber !== vendor.phoneNumber ||
        prevState.vendorBankingAch !== vendor?.banking?.url ||
        prevState.vendor !== vendor.url;

      return {
        ...prevState,
        accountBalance:
          prevState.accountBalance || defaultPaymentOptionValue || "",
        vendorEmail:
          (hasChangedVendor ? vendor.email : prevState.vendorEmail) || "",
        vendorPhoneNumber:
          (hasChangedVendor
            ? vendor.phoneNumber
            : prevState.vendorPhoneNumber) || "",
        vendorBankingAch:
          (hasChangedVendor
            ? vendor?.banking?.url
            : prevState.vendorBankingAch) || "",
        vendor: (hasChangedVendor ? vendor.url : prevState.vendor) || "",
        signature: paymentDetails.signature || currentSignature?.url || "",
      };
    });
  }, [
    isPaid,
    defaultPaymentOptionValue,
    vendor?.banking?.url,
    vendor.email,
    vendor.phoneNumber,
    vendor.url,
    paymentDetails.signature,
    currentSignature?.url,
  ]);

  useEffect(() => {
    if (cancelledPaymentId && cancellingPayment) {
      refetchBill();
      const cancelledPayment = billPayments.find(
        (payment) => payment.id === cancelledPaymentId
      );

      if (cancelledPayment?.isVoided) {
        setCancelledPaymentId(null);
        setCancellingPayment(false);
      }
    }
  }, [cancelledPaymentId, cancellingPayment, billPayments, refetchBill]);

  const setDefaultSignature = useCallback(async () => {
    if (!hasSignature) {
      const savedSignatureName = localStorage.getItem(signatureNameKey);

      setSignature(
        await generateSignature(savedSignatureName || user.full_name)
      );
    }
  }, [hasSignature, user.full_name, signatureNameKey]);

  const updateSignature = useEvent((signature?: Signature) => {
    setSignature(signature);
    localStorage.setItem(signatureNameKey, signature?.name || "");
  });

  useEffect(() => {
    setDefaultSignature();
  }, [setDefaultSignature]);

  useEffect(() => {
    if (!vendor || billVendor.id != vendor.id) {
      fetchVendor(billVendor.id);
    }
  }, [billVendor.id, fetchVendor, vendor]);

  useEffect(() => {
    if (haveLinkedLienWaiver) return;
    setPayment((prev) => {
      const newLienWaivers = billCustomerUrls
        .filter(
          (customer) =>
            !prev.customerLienWaivers.some((item) => item.customer === customer)
        )
        .map((customer) =>
          mapLienWaiverCustomer(customer, billLienWaiverTemplate)
        );

      const remainingLienWaivers = prev.customerLienWaivers.filter((item) => {
        return billCustomerUrls.some((customer) => item.customer === customer);
      });

      return {
        ...prev,
        customerLienWaivers: [...remainingLienWaivers, ...newLienWaivers],
      };
    });
  }, [
    billCustomerUrls,
    billLienWaiverTemplate,
    haveLinkedLienWaiver,
    mapLienWaiverCustomer,
  ]);

  const debitDateProps = useMemo(
    () => ({
      value: currentDebitDate,
      onChange: setCurrentDebitDate,
    }),
    [currentDebitDate, setCurrentDebitDate]
  );

  const paymentProps = useMemo(
    () => ({ value: payment, onChange: setPayment }),
    [payment, setPayment]
  );

  const cardTransactionProps = useMemo(
    () => ({
      value: currentCardTransaction,
      onChange: setCurrentCardTransaction,
    }),
    [currentCardTransaction, setCurrentCardTransaction]
  );

  return (
    <>
      <div className="steps-section-content">
        <Flex direction="column" padding={["3xl", "5xl"]}>
          <Flex gap="5xl" direction="column">
            <BillAlerts />
            <Flex gap="5xl" direction="column">
              {isVendorCredit || isArchivedByUser ? null : (
                <Flex gap="3xl" direction="column">
                  {!isPaid && (
                    <Flex gap="3xl" direction="column">
                      <BillPaymentForm
                        debitDate={debitDateProps}
                        paymentOptions={enhancedPaymentOptions}
                        formValidationStatus={formValidationStatus}
                        nextAvailablePaymentSchedule={
                          nextAvailablePaymentSchedule
                        }
                        payment={paymentProps}
                        selectedAccountBalance={selectedAccountBalance}
                        paymentMethods={paymentMethods}
                        cardTransaction={cardTransactionProps}
                        onSetPaymentAccount={refetchPaymentOptions}
                      />

                      {payment.method === PAYMENT_METHOD.MAIL_CHECK &&
                        currentSignature &&
                        selectedPaymentMethod?.paymentMethod ===
                          PAYMENT_METHOD.MAIL_CHECK && (
                          <SignatureField
                            signature={currentSignature}
                            onChange={updateSignature}
                            dialog={updateSignatureDialog}
                          />
                        )}

                      {canManageLienWaivers && balance > 0 && (
                        <BillLienWaivers
                          lienWaiverCustomers={payment.customerLienWaivers}
                          onLoadTemplateData={onLoadTemplateData}
                          onChange={onChangeLienWaiverTemplate}
                          recipientEmail={paymentVendorEmail}
                          vendor={vendor}
                          paymentAmount={amountToPay || undefined}
                          billId={id}
                          billLienWaiver={lienWaiver}
                          onRequestUpdate={onChangeLienWaiverRequest}
                          requestOnPayment={requestOnPayment}
                          billInternalVersion={billInternalVersion}
                        />
                      )}
                    </Flex>
                  )}

                  {billPayments?.length > 0 && (
                    <Flex gap="xl" direction="column">
                      <Text size="xl" weight="bold">
                        {
                          PAYMENT_STEP_FORM_STRINGS.PREVIOUS_PAYMENT_SECTION_TITLE
                        }
                      </Text>
                      <BillPaymentInfo billPayments={billPayments} />
                    </Flex>
                  )}
                </Flex>
              )}

              <Flex gap="xl" direction="column">
                <Form onEnterSubmit={onSave}>
                  <Info />
                </Form>
              </Flex>

              {enhancedShouldShowPurchaseOrders && <PurchaseOrders />}

              <Form onEnterSubmit={onSave}>
                <Items />
              </Form>

              {/**
               * We need it as backward compatibility since old bills could not have
               * workflows attached, new bills will always have at least one workflow
               */}
              {workflows.length > 0 && (
                <ApprovalsSection
                  editable={false}
                  objectId={Number(id)}
                  objectType="Bill"
                  workflows={workflows}
                  helperMessage={(mode) => (mode === "EMPTY" ? false : "")}
                />
              )}

              <Comments />
            </Flex>
          </Flex>
        </Flex>
      </div>

      <footer className="steps-section-footer">
        <Flex gap="xl">
          <Button variant="text" size="lg" color="neutral" onClick={onClose}>
            {PAYMENT_STEP_FORM_STRINGS.CANCEL_TITLE}
          </Button>
          <DynamicActions />
        </Flex>
        <Flex gap="xl">
          <Tooltip message={billFormValidationError} placement="left">
            <Button
              disabled={
                isArchivedByUser ||
                !!billFormValidationError ||
                isLoadingMarkingAsPaid
              }
              variant="ghost"
              size="lg"
              onClick={onSave}
            >
              {PAYMENT_STEP_FORM_STRINGS.SAVE_TITLE}
            </Button>
          </Tooltip>
          {!markAsPaidValidationStatus.billAlreadyPaid && (
            <>
              {selectedAccountBalance?.markAsPaidOption && !isVendorCredit && (
                <Tooltip message={markAsPaidValidationError} placement="left">
                  <Button
                    disabled={!!markAsPaidValidationError}
                    variant="ghost"
                    size="lg"
                    onClick={curriedMarkAsPaid}
                  >
                    {PAY_BUTTON_LABEL.MARK_AS_PAID}
                  </Button>
                </Tooltip>
              )}
              {primaryActionButtons}
            </>
          )}
        </Flex>
      </footer>
      {showPaymentConfirmationDialog &&
        id &&
        billUrl &&
        selectedAccountBalance &&
        vendor && (
          <BillPaymentConfirmationDialog
            onClose={showPaymentConfirmationDialog.hide}
            paymentsInfo={paymentPayload}
            dialog={showPaymentConfirmationDialog}
          />
        )}
    </>
  );
};
