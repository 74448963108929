import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { getBills } from "../api/bills";
import { syncBill } from "./billSlice";
import { getOrderingByStatus } from "@src/bills/utils";
import { selectRealmId } from "./user/selectors-memoized";
import { handleErrors } from "../api/handle-errors";
/**
 * @todo replace it with abortController
 */
const handleAxiosCancelException = (e) => {
  if (!axios.isCancel(e)) {
    throw e;
  } else {
    return undefined;
  }
};

const getInitialFilters = (statuses) => [
  { dataIndex: "offset", value: 0 },
  { dataIndex: "ordering", value: getOrderingByStatus(statuses[0]) },
  ...(!statuses.includes("ALL")
    ? [
        { dataIndex: "archived", value: false },
        ...statuses.map((status) => ({
          dataIndex: "review_status",
          value: status,
        })),
      ]
    : []),
];

const initialState = {
  allBills: {
    loading: "idle",
    isError: false,
    bills: [],
    total: 0,
    filter: {
      query: getInitialFilters(["ALL"]),
      values: {},
    },
    controller: undefined,
  },
  draftBills: {
    loading: "idle",
    isError: false,
    bills: [],
    total: 0,
    filter: {
      query: getInitialFilters(["DRAFT"]),
      values: {},
    },
    controller: undefined,
  },
  approvalBills: {
    loading: "idle",
    isError: false,
    bills: [],
    total: 0,
    filter: {
      query: getInitialFilters(["APPROVAL"]),
      values: {},
    },
    controller: undefined,
  },
  forPaymentBills: {
    loading: "idle",
    isError: false,
    bills: [],
    total: 0,
    filter: {
      query: getInitialFilters([
        "FOR_PAYMENT",
        "ACH_INFO_REQUESTED",
        "PAYMENT_SCHEDULED",
        "PARTIALLY_PAID",
      ]),
      values: {},
    },
    controller: undefined,
  },
  filter: {
    query: [{ dataIndex: "limit", value: 10 }],
    values: {},
  },
};

const updateBillsList = (billsList, action) => {
  if (billsList.loading === "pending" && action.payload.data) {
    billsList.loading = "loaded";
    billsList.controller = undefined;
    billsList.bills = action.payload.data?.results || [];
    billsList.total = action.payload.data?.count || 0;
  }
};

const STATUS_STATE = {
  all: "all",
  "for-payment": "forPayment",
  approval: "approval",
  draft: "draft",
};

const STATUS_QUERY = {
  all: ["ALL"],
  "for-payment": [
    "FOR_PAYMENT",
    "ACH_INFO_REQUESTED",
    "PAYMENT_SCHEDULED",
    "PARTIALLY_PAID",
  ],
  approval: ["APPROVAL"],
  draft: ["DRAFT"],
};

const selectFilters = ({ state, status }) => {
  const { filter, ...billList } = state.billList;
  const statusFilter = billList[`${status}Bills`].filter.query;
  const currentFilters = [...filter.query, ...statusFilter];
  const realmId = selectRealmId(state);
  const hasRealm = currentFilters.some((item) => item.dataIndex === "realm");
  return hasRealm
    ? currentFilters
    : [{ dataIndex: "realm", value: realmId }, ...currentFilters];
};

const billListSlice = createSlice({
  name: "billList",
  initialState,
  reducers: {
    draftBillsLoading: (state, action) => {
      state.draftBills.loading = "pending";
      state.draftBills.isError = false;
      state.draftBills.controller?.abort?.();
      state.draftBills.controller = action.payload;
    },
    draftBillsErrored: (state) => {
      state.draftBills.loading = "loaded";
      state.draftBills.isError = true;
    },
    draftBillsReceived: (state, action) => {
      updateBillsList(state.draftBills, action);
    },
    addDraftBill: (state, action) => {
      state.draftBills.bills = [action.payload, ...state.draftBills.bills];
      state.draftBills.total = state.draftBills.total + 1;
    },
    addAllBill: (state, action) => {
      state.allBills.bills = [action.payload, ...state.draftBills.bills];
      state.allBills.total = state.allBills.total + 1;
    },
    updateFilter: (state, action) => {
      const limit =
        state.filter.query.find((item) => item.dataIndex === "limit")?.value ||
        10;

      state.filter = {
        query: [{ dataIndex: "limit", value: limit }, ...action.payload.query],
        values: action.payload.values,
      };
    },
    updateOffset: (state, action) => {
      const { status, value } = action.payload;
      const key = `${STATUS_STATE[status]}Bills`;

      state[key].filter.query = [
        { dataIndex: "offset", value },
        ...state[key].filter.query.filter(
          (item) => item.dataIndex !== "offset"
        ),
      ];
    },
    updateLimit: (state, action) => {
      ["allBills", "forPaymentBills", "draftBills", "approvalBills"].forEach(
        (key) => {
          state[key].filter.query = [
            { dataIndex: "offset", value: 0 },
            ...state[key].filter.query.filter(
              (item) => item.dataIndex !== "offset"
            ),
          ];
        }
      );
      state.filter.query = [
        { dataIndex: "limit", value: action.payload },
        ...state.filter.query.filter((item) => item.dataIndex !== "limit"),
      ];
    },
    updateDraftBill: (state, action) => {
      state.draftBills.bills = state.draftBills.bills.map((bill) =>
        bill.id === action.payload.id ? { ...bill, ...action.payload } : bill
      );
    },
    updateAllBill: (state, action) => {
      state.allBills.bills = state.allBills.bills.map((bill) =>
        bill.id === action.payload.id ? { ...bill, ...action.payload } : bill
      );
    },
    updateRequiresMyApproval: (state, action) => {
      state.approvalBills.filter.query = [
        ...(action.payload
          ? [{ dataIndex: "requires_my_approval", value: true }]
          : []),
        ...state.approvalBills.filter.query.filter(
          (item) => item.dataIndex !== "requires_my_approval"
        ),
      ];
    },
    updateContextualFilter: (state, action) => {
      const { status, query, values } = action.payload;
      const key = `${STATUS_STATE[status]}Bills`;
      const isRejected = query.some(
        (item) => item.dataIndex === "is_rejected" && item.value
      );
      const isNotRejected = query.some(
        (item) => item.dataIndex === "is_rejected" && item.value === false
      );
      const hasReviewStatus = query.some(
        (item) => item.dataIndex === "review_status"
      );

      getInitialFilters(STATUS_QUERY[status])
        .filter((item) => {
          /**
           * To filter by "rejected" we should also remove the "draft" filter
           * otherwise it will returns both
           */
          return (
            !(
              status === "draft" &&
              isRejected &&
              !isNotRejected &&
              item.dataIndex === "review_status" &&
              item.value === "DRAFT"
            ) && item.dataIndex !== "ordering"
          );
        })
        .forEach((item) => {
          const hasQuery = query.some(
            ({ dataIndex, value }) =>
              (item.dataIndex === "review_status" && hasReviewStatus) ||
              (dataIndex === item.dataIndex && value == item.value)
          );
          if (!hasQuery) query.push(item);
        });

      const ordering = state[key].filter.query.find(
        (item) => item.dataIndex === "ordering"
      );

      if (ordering) query.push(ordering);

      state[key].filter = { query, values };
    },
    updateOrder: (state, action) => {
      ["allBills", "forPaymentBills", "draftBills", "approvalBills"].forEach(
        (key) => {
          state[key].filter.query = [
            { dataIndex: "ordering", value: action.payload },
            ...state[key].filter.query.filter(
              (item) => item.dataIndex !== "ordering"
            ),
          ];
        }
      );
    },
    removeDraftBill: (state, action) => {
      state.draftBills.bills = state.draftBills.bills.filter(
        (bill) => bill.id !== action.payload.id
      );
      state.draftBills.total = state.draftBills.total - 1;
    },
    removeAllBill: (state, action) => {
      state.allBills.bills = state.allBills.bills.filter(
        (bill) => bill.id !== action.payload.id
      );
      state.allBills.total = state.allBills.total - 1;
    },
    approvalBillsLoading: (state, action) => {
      state.approvalBills.loading = "pending";
      state.approvalBills.isError = false;
      state.approvalBills.controller?.abort?.();
      state.approvalBills.controller = action.payload;
    },
    approvalBillsReceived: (state, action) => {
      updateBillsList(state.approvalBills, action);
    },
    approvalBillsErrored: (state) => {
      state.approvalBills.loading = "loaded";
      state.approvalBills.isError = true;
    },
    forPaymentBillsLoading: (state, action) => {
      state.forPaymentBills.loading = "pending";
      state.forPaymentBills.isError = false;
      state.forPaymentBills.controller?.abort?.();
      state.forPaymentBills.controller = action.payload;
    },
    forPaymentBillsReceived: (state, action) => {
      updateBillsList(state.forPaymentBills, action);
    },
    forPaymentBillsErrored: (state) => {
      state.forPaymentBills.loading = "loaded";
      state.forPaymentBills.isError = true;
    },
    allBillsLoading: (state, action) => {
      state.allBills.loading = "pending";
      state.allBills.isError = false;
      state.allBills.controller?.abort?.();
      state.allBills.controller = action.payload;
    },
    allBillsErrored: (state) => {
      state.allBills.loading = "loaded";
      state.allBills.isError = true;
    },
    allBillsReceived: (state, action) => {
      updateBillsList(state.allBills, action);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBillsThunk.rejected, (state, action) => {
      handleErrors(action.error);
    });
  },
});

export const {
  draftBillsLoading,
  draftBillsErrored,
  draftBillsReceived,
  approvalBillsLoading,
  approvalBillsErrored,
  approvalBillsReceived,
  forPaymentBillsLoading,
  forPaymentBillsErrored,
  forPaymentBillsReceived,
  allBillsLoading,
  allBillsErrored,
  allBillsReceived,
  addDraftBill,
  addAllBill,
  updateDraftBill,
  updateAllBill,
  removeDraftBill,
  removeAllBill,
  updateFilter,
  updateOffset,
  updateLimit,
  updateOrder,
  updateContextualFilter,
  updateRequiresMyApproval,
} = billListSlice.actions;

/**
 * Sometimes after doing some batch actions, the results is empty because we
 * moved all the bills to another status, so we need to adjust the offset
 * to fetch the previous page.
 */
const adjustFetchedData =
  ({ data, status, filters }) =>
  (dispatch) => {
    const limit =
      filters.find((item) => item.dataIndex === "limit")?.value ?? 10;

    const offset =
      filters.find((item) => item.dataIndex === "offset")?.value ?? 0;

    if (data?.results.length === 0 && offset > 0) {
      dispatch(updateOffset({ status, value: offset - limit }));

      return true;
    }

    return false;
  };

export const fetchBillsThunk = createAsyncThunk(
  "billList/fetchBills",
  async ({ filters, signal }) =>
    getBills({ signal, filters }).catch(handleAxiosCancelException)
);

export const fetchDraftBills =
  ({ filters } = {}) =>
  async (dispatch, getState) => {
    const controller = new AbortController();
    const currentFilter = selectFilters({ state: getState(), status: "draft" });
    const enhancedFilters = filters || currentFilter;

    dispatch(draftBillsLoading(controller));

    try {
      const data = await dispatch(
        fetchBillsThunk({ signal: controller.signal, filters: enhancedFilters })
      ).unwrap();

      const shouldAdjustOffset = dispatch(
        adjustFetchedData({ data, status: "draft", filters: enhancedFilters })
      );

      if (shouldAdjustOffset) return;

      dispatch(draftBillsReceived({ data }));
      // Sync pending bills from nanonets
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      (data?.results ?? [])
        .filter((bill) =>
          ["deferred", "requested", "in-progress"].includes(
            bill.file_sync_status
          )
        )
        .forEach((bill) => dispatch(syncBill(bill.id, false, source)));

      return data;
    } catch (e) {
      dispatch(draftBillsErrored());
      throw e;
    }
  };

export const fetchApprovalBills =
  ({ filters } = {}) =>
  async (dispatch, getState) => {
    const controller = new AbortController();
    const currentFilter = selectFilters({
      state: getState(),
      status: "approval",
    });
    const enhancedFilters = filters || currentFilter;

    dispatch(approvalBillsLoading(controller));

    try {
      const data = await dispatch(
        fetchBillsThunk({ signal: controller.signal, filters: enhancedFilters })
      ).unwrap();

      const shouldAdjustOffset = dispatch(
        adjustFetchedData({
          data,
          status: "approval",
          filters: enhancedFilters,
        })
      );

      if (shouldAdjustOffset) return;

      dispatch(approvalBillsReceived({ data }));
      return data;
    } catch (e) {
      dispatch(approvalBillsErrored());
      throw e;
    }
  };

export const fetchForPaymentBills =
  ({ filters } = {}) =>
  async (dispatch, getState) => {
    const controller = new AbortController();
    const currentFilter = selectFilters({
      state: getState(),
      status: "forPayment",
    });
    const enhancedFilters = filters || currentFilter;

    dispatch(forPaymentBillsLoading(controller));

    try {
      const data = await dispatch(
        fetchBillsThunk({ signal: controller.signal, filters: enhancedFilters })
      ).unwrap();
      const shouldAdjustOffset = dispatch(
        adjustFetchedData({
          data,
          status: "for-payment",
          filters: enhancedFilters,
        })
      );

      if (shouldAdjustOffset) return;

      dispatch(forPaymentBillsReceived({ data }));
      return data;
    } catch (e) {
      dispatch(forPaymentBillsErrored());
      throw e;
    }
  };

export const fetchAllBills =
  ({ filters } = {}) =>
  async (dispatch, getState) => {
    const controller = new AbortController();
    const currentFilter = selectFilters({ state: getState(), status: "all" });
    const enhancedFilters = filters || currentFilter;

    dispatch(allBillsLoading(controller));

    try {
      const data = await dispatch(
        fetchBillsThunk({ signal: controller.signal, filters: enhancedFilters })
      ).unwrap();

      const shouldAdjustOffset = dispatch(
        adjustFetchedData({ data, status: "all", filters: enhancedFilters })
      );

      if (shouldAdjustOffset) return;

      dispatch(allBillsReceived({ data }));
      return data;
    } catch (e) {
      dispatch(allBillsErrored());
      throw e;
    }
  };

export const fetchBillsByStatus =
  ({ status, filters }) =>
  async (dispatch) => {
    if (status === "all") {
      return dispatch(fetchAllBills({ filters }));
    } else if (status === "draft") {
      return dispatch(fetchDraftBills({ filters }));
    } else if (status === "approval") {
      return dispatch(fetchApprovalBills({ filters }));
    } else if (status === "for-payment") {
      return dispatch(fetchForPaymentBills({ filters }));
    }
  };

export const { reducer } = billListSlice;
