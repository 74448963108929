import React, { useEffect } from "react";
import {
  Button,
  Card,
  Flex,
  Loader,
  PasswordField,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useForm } from "@adaptive/design-system/hooks";
import { formatDate } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { z } from "zod";

import { useBuildertrend } from "../hooks/use-buildertrend";

const schema = z.object({
  username: z.string().min(1, "Username is required"),
  password: z.string().min(1, "Password is required"),
});
type Fields = z.infer<typeof schema>;

const INITIAL_VALUES = {
  username: "",
  password: "xxxxxx",
};

export const Settings = () => {
  const {
    credential,
    updateBuilderTrendCredentials,
    syncBuilderTrendCredentials,
    createBuilderTrendCredentials,
    syncInProgress,
    syncBuilderTrendCredentialsIsLoading,
  } = useBuildertrend();

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      try {
        if (!credential?.id) {
          await createBuilderTrendCredentials({
            username: values.username,
            password: values.password,
          }).unwrap();
        } else {
          await updateBuilderTrendCredentials({
            id: credential?.id || "",
            username: values.username,
            password: values.password,
          }).unwrap();
        }
        toast.success("Credentials updated");
      } catch (error) {
        handleErrors(error);
      }
    },
    initialValues: INITIAL_VALUES,
  });

  const setValue = form.setValue;
  useEffect(() => {
    if (credential) {
      setValue("username", credential.username || INITIAL_VALUES.username);
      setValue("password", credential.password || INITIAL_VALUES.password);
    }
  }, [credential, setValue]);

  return (
    <Flex direction="column" gap="xl" padding={["2xl", "none", "none"]}>
      <Card>
        <Flex
          as="form"
          {...form.props}
          shrink={false}
          direction="column"
          gap="md"
        >
          <TextField label="Username" required {...form.register("username")} />
          <PasswordField
            label="Password"
            required
            {...form.register("password")}
          />
          <Button type="submit" disabled={form.isSubmitting}>
            {form.isSubmitting ? <Loader /> : "Save"}
          </Button>
        </Flex>
      </Card>
      {credential ? (
        <Card>
          <Flex direction="column" gap="md">
            <Text>
              Last synced at:{" "}
              {credential?.lastSyncedAt
                ? formatDate(credential.lastSyncedAt)
                : "Never"}
            </Text>
            <Text>Sync status: {credential?.syncStatus || "Never synced"}</Text>
            {credential?.id && (
              <Button
                onClick={() => syncBuilderTrendCredentials(credential.id)}
                disabled={
                  syncBuilderTrendCredentialsIsLoading || syncInProgress
                }
              >
                {syncBuilderTrendCredentialsIsLoading || syncInProgress ? (
                  <Loader />
                ) : (
                  "Sync now"
                )}
              </Button>
            )}
          </Flex>
        </Card>
      ) : null}
    </Flex>
  );
};
