import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Label,
  Loader,
  Table,
  type TableColumn,
  type TableHeaderAddon,
  type TableSelectAddon,
  TagGroup,
  Text,
  toast,
} from "@adaptive/design-system";
import { type useDialog, useEvent } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import { useUploadAttachableMutation } from "@api/attachables";
import { handleErrors } from "@api/handle-errors";
import {
  BatchApplyObject,
  type BatchApplyObjectOnChangeHandler,
} from "@components/batch-apply-object";
import { CostCodeAccountComboBox } from "@components/cost-code-account-combobox";
import { CostCodeAccountInfo } from "@components/cost-code-account-info";
import { CustomersComboBox } from "@components/customers-combobox";
import { SelectVendor } from "@components/form";
import { Thumbnail } from "@components/thumbnail";
import { useExpenses, useUpdateExpense } from "@expenses/hooks";
import type { Expense } from "@expenses/types";
import { type Option } from "@shared/types";
import { useClientInfo } from "@store/user";
import { isAccount } from "@utils/is-account";
import { isCostCode } from "@utils/is-cost-code";
import { noop } from "@utils/noop";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { createContext, useContextSelector } from "use-context-selector";

import {
  CREATE_RECEIPTS_DIALOG_TABLE_ID,
  STRINGS,
} from "../constants/constants";
import type { CardTransaction } from "../types";

export type CreateReceiptsDialogProps = {
  dialog: ReturnType<typeof useDialog>;
  onSuccess?: () => void;
  transactions: CardTransaction[];
};

type UpdateExpenseHandler = (params: {
  key: string;
  index: number;
  value: unknown;
}) => void;

type CreateReceiptsContextType = {
  isSubmitting: boolean;
  updateExpense: UpdateExpenseHandler;
};

type EnhancedAttachable = Exclude<Expense["attachables"], undefined>[number] & {
  file?: File;
};

type EnhancedExpense = Expense & { attachables?: EnhancedAttachable[] };

type EnhancedExpenseWithIndex = EnhancedExpense & { index: number };

type ApplyChangesHandler = (params: {
  data: EnhancedExpense[];
  reviewStatus: Expense["reviewStatus"];
}) => Promise<{ failed: EnhancedExpense[]; succeeded: EnhancedExpense[] }>;

const CreateReceiptsContext = createContext<CreateReceiptsContextType>({
  isSubmitting: false,
  updateExpense: noop,
});

const useCreateReceipts = () =>
  useContextSelector(CreateReceiptsContext, (context) => context);

const CostCodeAccountRender = memo((row: EnhancedExpenseWithIndex) => {
  const { isSubmitting, updateExpense } = useCreateReceipts();

  const info = useMemo(() => {
    const items = row.items ?? [];

    const accounts = row.accounts ?? [];

    const hasMultipleValues =
      items.length > 1 ||
      accounts.length > 1 ||
      (items.length && accounts.length);

    const value = hasMultipleValues
      ? ""
      : items[0]?.url || accounts[0]?.url || "";

    return {
      items,
      value,
      accounts,
      hasMultipleValues,
    };
  }, [row.items, row.accounts]);

  const onChange = useEvent((value: string) => {
    updateExpense({
      key: "items",
      index: row.index,
      value: isCostCode(value) ? [{ url: value || null }] : [],
    });
    updateExpense({
      key: "accounts",
      index: row.index,
      value: isAccount(value) ? [{ url: value || null }] : [],
    });
  });

  const filterProps = useMemo(() => {
    const customerId = row.customers?.[0]?.url
      ? parseRefinementIdFromUrl(row.customers[0].url)
      : undefined;
    const vendorId = row.vendor?.url
      ? parseRefinementIdFromUrl(row.vendor.url)
      : undefined;

    const filters = {
      customerId,
      vendorId,
    };

    return {
      accountFilters: {
        ...filters,
      },
      costCodeFilters: {
        ...filters,
      },
    };
  }, [row]);

  return info.hasMultipleValues ? (
    <CostCodeAccountInfo items={info.items} accounts={info.accounts} />
  ) : (
    <CostCodeAccountComboBox
      size="sm"
      label=""
      value={info.value}
      portal
      onChange={onChange}
      disabled={isSubmitting}
      placeholder="Select"
      messageVariant="hidden"
      {...filterProps}
    />
  );
});

CostCodeAccountRender.displayName = "CostCodeAccountRender";

const JobRender = memo((row: EnhancedExpenseWithIndex) => {
  const { isSubmitting, updateExpense } = useCreateReceipts();

  const value = row.customers?.[0]?.url || "";

  const onChange = useEvent((value: string) => {
    updateExpense({
      key: "customers",
      index: row.index,
      value: [{ url: value || null }],
    });
  });

  return (row.customers ?? []).length > 1 ? (
    <TagGroup data={row.customers} limit="auto" render="displayName" />
  ) : (
    <CustomersComboBox
      size="sm"
      label=""
      value={value}
      portal
      disabled={isSubmitting}
      onChange={onChange}
      messageVariant="hidden"
    />
  );
});

JobRender.displayName = "JobRender";

const VendorRender = memo((row: EnhancedExpenseWithIndex) => {
  const { isSubmitting, updateExpense } = useCreateReceipts();

  const selector = useCallback(
    () => (row.vendor?.url ? row.vendor : undefined),
    [row.vendor]
  );

  const onChange = useEvent((_, option?: Option) => {
    updateExpense({
      key: "vendor",
      index: row.index,
      value: option ? { url: option.value, displayName: option.label } : null,
    });
  });

  return (
    <SelectVendor
      size="sm"
      label=""
      portal
      selector={selector}
      disabled={isSubmitting}
      onChange={onChange}
      allowOtherNames
      messageVariant="hidden"
    />
  );
});

VendorRender.displayName = "VendorRender";

const AttachmentRender = memo((row: EnhancedExpenseWithIndex) => {
  const { isSubmitting, updateExpense } = useCreateReceipts();

  const onChange = useEvent(async (file: File) => {
    if (!file) return;

    updateExpense({
      key: "attachables",
      index: row.index,
      value: [{ file, thumbnail: URL.createObjectURL(file) }],
    });
  });

  return (
    <Thumbnail
      alt={`Transaction${row.docNumber ? ` #${row.docNumber}` : ""}`}
      src={row.attachables?.[0]?.thumbnail || undefined}
      onChange={onChange}
      disabled={isSubmitting}
    />
  );
});

AttachmentRender.displayName = "AttachmentRender";

const COLUMNS: TableColumn<EnhancedExpense>[] = [
  {
    id: "attachment",
    name: "Attachment",
    render: (row, index) => <AttachmentRender {...row} index={index} />,
    width: 115,
  },
  {
    id: "card_transaction",
    name: "Card transaction",
    render: "cardTransaction.displayName",
    width: 250,
  },
  {
    id: "vendor",
    name: "Vendor name",
    render: (row, index) => <VendorRender {...row} index={index} />,
    width: 175,
  },
  {
    id: "job",
    name: "Job",
    render: (row, index) => <JobRender {...row} index={index} />,
    width: 175,
  },
  {
    id: "cost_code_account",
    name: (
      <Label as="p" required>
        <Text as="span" weight="bold">
          Cost code / Account
        </Text>
      </Label>
    ),
    render: (row, index) => <CostCodeAccountRender {...row} index={index} />,
    width: 200,
  },
];

export const CreateReceiptsDialog = ({
  dialog,
  onSuccess,
  transactions,
}: CreateReceiptsDialogProps) => {
  const { realm } = useClientInfo();

  const [reviewStatus, setReviewStatus] = useState<
    Expense["reviewStatus"] | ""
  >("");

  const isLoadedRef = useRef(false);

  const [triggerUpdateExpense, infoUpdateExpense] = useUpdateExpense();

  const [triggerUploadAttachable, infoUploadAttachable] =
    useUploadAttachableMutation();

  const isSubmitting =
    infoUpdateExpense.isLoading || infoUploadAttachable.isLoading;

  const [data, setData] = useState<EnhancedExpense[]>([]);

  const query = useMemo(
    () => [
      {
        dataIndex: "include_transaction_generated_drafts",
        value: null,
      },
      {
        dataIndex: "limit",
        value: -1,
      },
      ...transactions
        .filter((transaction) => transaction.expense)
        .map((transaction) => ({
          value: parseRefinementIdFromUrl(transaction.expense!),
          dataIndex: "id",
        })),
    ],
    [transactions]
  );

  const expensesInfo = useExpenses({ query });

  const [selected, setSelected] = useState<Expense[]>([]);

  const onRemove = useEvent(() => {
    setData((data) =>
      data.filter(
        (item) => !selected.some((selectedItem) => selectedItem.id === item.id)
      )
    );
    setSelected([]);
  });

  const onBatchApply = useEvent<BatchApplyObjectOnChangeHandler>((params) => {
    setData((data) =>
      data.map((item) => {
        if (selected.some((selectedItem) => selectedItem.id === item.id)) {
          if (params.fieldName === "vendor") {
            return dotObject.set(item, "vendor", {
              id: parseRefinementIdFromUrl(params.value),
              url: params.value,
              displayName: params.option?.label ?? "",
            });
          } else if (params.fieldName === "customer") {
            return dotObject.set(item, "customers", [{ url: params.value }]);
          } else if (isCostCode(params.value)) {
            const temporaryItem = dotObject.set(item, "items", [
              { url: params.value },
            ]);
            return dotObject.set(temporaryItem, "accounts", []);
          } else if (isAccount(params.value)) {
            const temporaryItem = dotObject.set(item, "accounts", [
              { url: params.value },
            ]);
            return dotObject.set(temporaryItem, "items", []);
          }
        }

        return item;
      })
    );
  });

  const updateExpense = useEvent<UpdateExpenseHandler>(
    ({ key, value, index }) => {
      setData((data) => dotObject.set(data, `${index}.${key}`, value));
    }
  );

  const applyChanges = useCallback<ApplyChangesHandler>(
    async ({ data, reviewStatus }) => {
      if (!realm?.url) return { failed: data, succeeded: [] };

      setSelected([]);

      setReviewStatus(reviewStatus);

      const failed: EnhancedExpense[] = [];
      const succeeded: EnhancedExpense[] = [];

      await Promise.all(
        data.map(async (item) => {
          try {
            const file = item.attachables?.[0]?.file;

            if (file) {
              await triggerUploadAttachable({
                realm: realm.url,
                parent: item.url,
                document: file,
              }).unwrap();
            }

            const line = {
              item: item.items?.[0]?.url ?? null,
              amount: item.totalAmount ?? 0,
              account: item.accounts?.[0]?.url ?? null,
              customer: item.customers?.[0]?.url ?? null,
            };

            await triggerUpdateExpense({
              id: item.id,
              lines: [line],
              vendor: item.vendor?.url ?? null,
              reviewStatus,
              isTransactionGeneratedDraft: false,
            }).unwrap();

            succeeded.push(item);
          } catch (e) {
            failed.push(item);
            handleErrors(e);
          }
        })
      );

      setReviewStatus("");

      return { failed, succeeded };
    },
    [realm?.url, triggerUpdateExpense, triggerUploadAttachable]
  );

  const submitForReview = useCallback(
    async (data: EnhancedExpense[]) => {
      const { failed, succeeded } = await applyChanges({
        data,
        reviewStatus: "FOR_REVIEW",
      });

      if (succeeded.length) {
        toast.success(
          `${succeeded.length} receipt${succeeded.length > 1 ? "s" : ""} created and submitted for review`
        );
      }

      return { failed, succeeded };
    },
    [applyChanges]
  );

  const onSubmitForReview = useEvent(async () => {
    const { failed, succeeded } = await submitForReview(data);

    setData((data) =>
      data.filter(
        (item) => !succeeded.some((innerItem) => innerItem.id === item.id)
      )
    );

    if (!failed.length) {
      onSuccess?.();
      dialog.hide();
    }
  });

  const onBatchSubmitForReview = useEvent(async () => {
    const { failed, succeeded } = await submitForReview(selected);

    if (!failed.length) setSelected([]);

    setData((data) => {
      const nextData = data.filter(
        (item) =>
          !succeeded.some((succeededItem) => succeededItem.id === item.id)
      );

      if (!nextData.length) {
        onSuccess?.();
        dialog.hide();
      }

      return nextData;
    });
  });

  const sync = useCallback(
    async (data: EnhancedExpense[]) => {
      for (const item of data) {
        const hasItems = !!item.items?.length;
        const hasAccounts = !!item.accounts?.length;

        if (!hasItems && !hasAccounts) {
          throw new Error(
            STRINGS.CREATE_RECEIPTS_DIALOG_SYNC_COST_CODE_ACCOUNT_ERROR
          );
        }

        const hasCustomers = !!item.customers?.length;

        if (!hasCustomers && !hasAccounts) {
          throw new Error(STRINGS.CREATE_RECEIPTS_DIALOG_SYNC_JOB_ERROR);
        }
      }

      const { failed, succeeded } = await applyChanges({
        data,
        reviewStatus: "REVIEWED",
      });

      if (succeeded.length) {
        toast.success(
          `${succeeded.length} receipt${succeeded.length > 1 ? "s" : ""} created and synced to QuickBooks`
        );
      }

      return { failed, succeeded };
    },
    [applyChanges]
  );

  const onSync = useEvent(async () => {
    try {
      const { failed, succeeded } = await sync(data);

      setData((data) =>
        data.filter(
          (item) => !succeeded.some((innerItem) => innerItem.id === item.id)
        )
      );

      if (!failed.length) {
        onSuccess?.();
        dialog.hide();
      }
    } catch (e) {
      handleErrors(e);
    }
  });

  const onBatchSync = useEvent(async () => {
    try {
      const { failed, succeeded } = await sync(selected);

      if (!failed.length) setSelected([]);

      setData((data) => {
        const nextData = data.filter(
          (item) =>
            !succeeded.some((succeededItem) => succeededItem.id === item.id)
        );

        if (!nextData.length) {
          onSuccess?.();
          dialog.hide();
        }

        return nextData;
      });
    } catch (e) {
      handleErrors(e);
    }
  });

  const hasData = expensesInfo.data.length > 0;

  const header = useMemo<TableHeaderAddon>(
    () => ({ hide: !hasData }),
    [hasData]
  );

  const select = useMemo<TableSelectAddon<Expense>>(
    () => ({
      value: selected,
      onChange: setSelected,
      isDisabled: () => isSubmitting,
    }),
    [isSubmitting, selected]
  );

  useEffect(() => {
    if (isLoadedRef.current || !expensesInfo.data.length) return;

    setData(expensesInfo.data);
    setSelected([]);
    setReviewStatus("");
    isLoadedRef.current = true;
  }, [expensesInfo.data]);

  return (
    <Dialog
      size="auto"
      show={dialog.isVisible}
      variant="dialog"
      onClose={dialog.hide}
    >
      <DialogHeader>
        <Flex direction="column">
          <Text size="xl" weight="bold">
            {STRINGS.CREATE_RECEIPTS_DIALOG_TITLE}
          </Text>
          <Flex justify="space-between" gap="xl" height="3xl">
            <Text size="md">{STRINGS.CREATE_RECEIPTS_DIALOG_SUBTITLE}</Text>
            {selected.length > 0 && (
              <Dropdown placement="bottom-end">
                <DropdownTrigger
                  as={Button}
                  size="sm"
                  style={{ marginRight: "calc(var(--spacing-4xl) * -1)" }}
                >
                  Actions <Icon name="ellipsis-vertical" variant="solid" />
                </DropdownTrigger>
                <DropdownList>
                  <DropdownItem onClick={onRemove}>
                    {STRINGS.CREATE_RECEIPTS_DIALOG_REMOVE_ACTION}
                  </DropdownItem>
                  <DropdownItem onClick={onBatchSubmitForReview}>
                    {STRINGS.CREATE_RECEIPTS_DIALOG_FOR_REVIEW_ACTION}{" "}
                  </DropdownItem>
                  <DropdownItem onClick={onBatchSync}>
                    {STRINGS.CREATE_RECEIPTS_DIALOG_SYNC_ACTION}
                  </DropdownItem>
                  <DropdownItem>
                    <BatchApplyObject
                      onChange={onBatchApply}
                      accountFilters={{ only_line_item_accounts: true }}
                    />
                  </DropdownItem>
                </DropdownList>
              </Dropdown>
            )}
          </Flex>
        </Flex>
      </DialogHeader>
      <DialogContent>
        <Flex minWidth="930px">
          <CreateReceiptsContext.Provider
            value={{ isSubmitting, updateExpense }}
          >
            <Table
              id={CREATE_RECEIPTS_DIALOG_TABLE_ID}
              size="sm"
              data={data}
              header={header}
              select={select}
              loading={expensesInfo.isLoading}
              columns={COLUMNS}
              maxHeight="500px"
            />
          </CreateReceiptsContext.Provider>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button
          block
          variant="ghost"
          onClick={onSubmitForReview}
          disabled={isSubmitting}
        >
          {isSubmitting && reviewStatus === "FOR_REVIEW" ? (
            <Loader />
          ) : (
            STRINGS.CREATE_RECEIPTS_DIALOG_FOR_REVIEW_ALL_ACTION
          )}
        </Button>
        <Button block onClick={onSync} disabled={isSubmitting}>
          {isSubmitting && reviewStatus === "REVIEWED" ? (
            <Loader />
          ) : (
            STRINGS.CREATE_RECEIPTS_DIALOG_SYNC_ALL_ACTION
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
