import { is } from "@adaptive/design-system/utils";
import type {
  BudgetLine,
  BudgetLineGetPayload,
  BudgetLineMarkupResponse,
  BudgetLinesBulkCreatePayload,
  BudgetLinesDeletePayload,
  BudgetLinesGetPayload,
  BudgetLineUpdateSchemaPayload,
  MarkupResponse,
} from "@api/budgets";
import { modifyBudgetLineSchema } from "@api/budgets/request/schema";
import {
  budgetLineMarkupsResponseSchema,
  markupsSchema,
} from "@api/budgets/response/schema";
import type {
  BudgetLineItems,
  BudgetLineItemsResponse,
  LeafBudgetLine,
} from "@api/jobs";
import {
  budgetLineItemsResponseSchema,
  budgetLineResponseSchema,
} from "@api/jobs/response";
import type { Option } from "@shared/types";
import { api } from "@store/api-simplified";
import { updateBudgetLineFields } from "@store/jobs/thunks";
import {
  getSearchParamsFromFilters,
  handleRequest,
  handleResponse,
} from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

const transformBudgetLineMarkupToMarkup = (
  budgetLineMarkup: BudgetLineMarkupResponse
): MarkupResponse => {
  const { markup } = budgetLineMarkup;
  return {
    id: markup?.id ?? "0",
    value: markup?.value,
    ownersValue: markup?.ownersValue,
    markupType: markup?.markupType || "percentage",
    isSeparateLine: markup?.isSeparateLine,
    budgetLine: budgetLineMarkup,
    invoicedAmount: budgetLineMarkup.invoicedAmount,
    builderAmount: budgetLineMarkup.builderAmount,
    changeAmount: budgetLineMarkup.changeAmount,
    builderRevisedAmount: budgetLineMarkup.builderRevisedAmount,
    externalChangeAmount: budgetLineMarkup.externalChangeAmount,
    ownersRevisedAmount: budgetLineMarkup.ownersRevisedAmount,
    ownersAmount: budgetLineMarkup.ownersAmount,
    jobCostMethod: budgetLineMarkup.jobCostMethod,
    sourceType: budgetLineMarkup.sourceType,
  };
};

export const budgetLinesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBudgetLines: builder.query<BudgetLineItems[], BudgetLinesGetPayload>({
      query: ({ customerId, params }) => {
        const enhancedParams = getSearchParamsFromFilters(params ?? []);
        enhancedParams.append("markups", "false");

        return {
          url: `customers/${customerId}/budgetlines/?${enhancedParams}`,
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              data.map(transformKeysToCamelCase),
              budgetLineItemsResponseSchema
            );
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "BudgetLines" as const, id })),
              "BudgetLines",
            ]
          : ["BudgetLines"],
    }),
    getBudgetLine: builder.query<BudgetLine, BudgetLineGetPayload>({
      query: (id) => {
        return {
          url: `/budgetlines/${id}/`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              budgetLineResponseSchema
            );
          },
        };
      },
      providesTags: (result) =>
        result
          ? [{ type: "BudgetLines" as const, id: result.id }, "BudgetLines"]
          : ["BudgetLines"],
    }),
    getBudgetLineMarkups: builder.query<
      MarkupResponse[],
      BudgetLinesGetPayload
    >({
      query: ({ customerId, params }) => {
        const enhancedParams = getSearchParamsFromFilters(params ?? []);
        enhancedParams.append("markups", "true");

        return {
          url: `customers/${customerId}/budgetlines/?${enhancedParams}`,
          responseHandler: async (
            response
          ): Promise<BudgetLineMarkupResponse[]> => {
            const data = await response.json();
            return handleResponse(
              data.map(transformKeysToCamelCase),
              budgetLineMarkupsResponseSchema
            );
          },
        };
      },
      transformResponse: (responseData: BudgetLineMarkupResponse[]) => {
        return handleResponse(
          responseData.map(transformBudgetLineMarkupToMarkup),
          markupsSchema
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "BudgetLines" as const, id })),
              "BudgetLines",
            ]
          : ["BudgetLines"],
    }),
    addBudgetLinesToJob: builder.mutation<
      BudgetLineItems[],
      BudgetLinesBulkCreatePayload
    >({
      query: ({ customerId, ...rest }) => {
        const body = transformKeysToSnakeCase(rest);
        return {
          url: `customers/${customerId}/budgetlines/bulk_create/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [
        "BudgetLines",
        "CustomerItems",
        "CustomerMarkup",
        "BudgetLineItems",
        "CostCodesAccountsSimplified",
      ],
    }),
    updateBudgetLine: builder.mutation<
      LeafBudgetLine[],
      BudgetLineUpdateSchemaPayload
    >({
      query: ({ customerId, budgetLineId, ...rest }) => {
        const body = transformKeysToSnakeCase(
          handleRequest({ ...rest, id: budgetLineId }, modifyBudgetLineSchema)
        );
        return {
          url: `customers/${customerId}/budgetlines/${budgetLineId}/`,
          method: "PUT",
          body,
        };
      },
      async onQueryStarted(
        {
          budgetLineId,
          ownersAmount,
          builderAmount,
          builderRevisedAmount,
          category,
          customerId,
        },
        { dispatch, getState, queryFulfilled }
      ) {
        const budgetLine = {
          id: budgetLineId,
          category,
          ownersAmount,
          builderAmount,
          builderRevisedAmount,
        };

        const patches = [];

        for (const {
          endpointName,
          originalArgs,
        } of api.util.selectInvalidatedBy(getState(), ["BudgetLines"])) {
          if (endpointName !== "getBudgetLines") continue;

          const categories =
            (Object.values(getState().api.queries).find(
              (query) =>
                query &&
                is.object(query.originalArgs) &&
                query.endpointName === "getCustomerCategories" &&
                query.originalArgs.customerId === customerId
            )?.data as Option[]) || [];

          patches.push(
            dispatch(
              updateBudgetLineFields({ budgetLine, categories, originalArgs })
            )
          );
        }

        try {
          await queryFulfilled;
        } catch {
          patches.forEach((patch) => patch.undo());
        }

        dispatch(
          api.util.invalidateTags([
            "Customer",
            "BudgetLines",
            "CustomerItems",
            "CustomerMarkup",
          ])
        );
      },
    }),
    deleteBudgetLine: builder.mutation<
      BudgetLineItemsResponse,
      BudgetLinesDeletePayload
    >({
      query: ({ customerId, budgetLineId }) => {
        return {
          url: `customers/${customerId}/budgetlines/${budgetLineId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: [
        "BudgetLines",
        "CustomerItems",
        "BudgetLineItems",
        "CustomerMarkup",
      ],
    }),
  }),
});

export const {
  useAddBudgetLinesToJobMutation,
  useGetBudgetLinesQuery,
  useGetBudgetLineMarkupsQuery,
  useUpdateBudgetLineMutation,
  useDeleteBudgetLineMutation,
  useGetBudgetLineQuery,
} = budgetLinesApi;
