import { useCallback, useMemo } from "react";
import { useAsSimplifiedData } from "@shared/hooks/useSimplifiedData";
import { type Option } from "@shared/types";
import {
  useGetCustomersSimplifiedQuery,
  useLazyGetCustomersSimplifiedQuery,
} from "@store/api-simplified";
import { useClientInfo } from "@store/user";

export type UseCustomersSimplifiedProps = {
  active?: boolean | null;
  enabled?: boolean;
  includeLabel?: boolean;
  withCategories?: boolean;
  showDisableAsEnabled?: boolean;
};

export type CustomerSimplifiedOption = Option & {
  budgetCodesEnabled?: boolean;
};

export const useCustomersSimplified = ({
  active = true,
  enabled = true,
  includeLabel = false,
  withCategories,
  showDisableAsEnabled = false,
}: UseCustomersSimplifiedProps = {}) => {
  const { realmId } = useClientInfo();

  const skip = !enabled || !realmId;

  const query = useGetCustomersSimplifiedQuery(
    { realm: realmId as number, active, with_categories: withCategories },
    { skip }
  );

  const [trigger] = useLazyGetCustomersSimplifiedQuery();

  const { data, ...results } = useAsSimplifiedData(query, {
    groupLabel: includeLabel ? "Job" : undefined,
    skip,
  });

  const fetch = useCallback(
    async (active?: boolean) => {
      if (!realmId) return [];

      const data = await trigger({ realm: realmId, active }).unwrap();

      return data ?? [];
    },
    [realmId, trigger]
  );

  const enhancedData = useMemo<CustomerSimplifiedOption[]>(
    () =>
      data.map((customer) => ({
        ...customer,
        disabled:
          !showDisableAsEnabled && customer.disabled
            ? "This job is disabled"
            : undefined,
      })),
    [data, showDisableAsEnabled]
  );

  return {
    ...results,
    data: enhancedData,
    fetch,
    refetch: query.refetch,
  };
};
