import React, {
  memo,
  type PropsWithChildren,
  useCallback,
  useMemo,
} from "react";
import { Link as ReactRouterLink } from "react-router";
import {
  Button,
  Flex,
  Icon,
  Link,
  Loader,
  Switch,
  Tag,
  type TagProps,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, useMultiStepDialog } from "@adaptive/design-system/hooks";
import { formatCurrency, formatDate } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { SelectVendor } from "@components/form";
import {
  TableFilterControls,
  type TableFilterControlsProps,
} from "@components/table-filter";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { useBaseCardsSimplified } from "@hooks/use-base-cards-simplified";
import { useVendorsSimplified } from "@hooks/use-vendors-simplified";
import type { UseTableFilterOptionsProps } from "@hooks/useTableFilterOptions";
import { useUsersSimplified } from "@hooks/useUsersSimplified";
import { LinkedCard as SharedLinkedCard } from "@shared/components/linked-card";
import { generateUrlByHumanReadableType } from "@utils/generate-url-by-human-readable-type";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import type { CardTransaction } from "../api/types";
import { STATUS_FILTER, STRINGS } from "../constants/constants";
import { useUpdateCardTransaction } from "../hooks/use-update-card-transaction";

import { MatchingTransactionDialog } from "./matching-transaction-dialog";
import { usePermissions } from "./page-context";
import type {
  MatchingTransactionDialogSteps,
  Status as StatusType,
} from "./types";

export const TransactionDate = memo(({ date }: CardTransaction) =>
  date ? formatDate(date) : null
);

TransactionDate.displayName = "CardFeedDate";

export const Vendor = memo(({ id, vendor, match }: CardTransaction) => {
  const { canEdit } = usePermissions();

  const isEditable = canEdit && !match;

  const [updateCardTransaction, updateCardTransactionInfo] =
    useUpdateCardTransaction();

  const selector = useCallback(() => vendor || undefined, [vendor]);

  const onChange = useEvent(async (value) => {
    try {
      await updateCardTransaction({
        cardTransactionId: id,
        vendor: value || null,
      }).unwrap();
    } catch (e) {
      handleErrors(e);
    }
  });

  return (
    <>
      {updateCardTransactionInfo.isLoading && <Loader position="fixed" />}
      {isEditable ? (
        <SelectVendor
          size="sm"
          label=""
          selector={selector}
          disabled={updateCardTransactionInfo.isLoading}
          onChange={onChange}
          allowOtherNames
          messageVariant="hidden"
        />
      ) : (
        <Text truncate>{vendor?.displayName}</Text>
      )}
    </>
  );
});

Vendor.displayName = "CardFeedVendor";

export const Description = memo(({ displayName }: CardTransaction) =>
  displayName ? <Text truncate>{displayName}</Text> : null
);

Description.displayName = "CardFeedDescription";

export const LinkedCard = memo(
  // eslint-disable-next-line react/prop-types
  (cardTransaction: Pick<CardTransaction, "mask" | "plaidAccountOwner">) => (
    <SharedLinkedCard
      mask={cardTransaction.mask}
      name={cardTransaction.plaidAccountOwner?.accountOwner}
    />
  )
);

LinkedCard.displayName = "CardFeedLinkedCard";

export const Status = memo(({ match, isArchived }: CardTransaction) => {
  let color: TagProps["color"] = "neutral";
  let content = "";

  if (isArchived) {
    content = STRINGS.ARCHIVED_STATUS;
  } else if (match) {
    color = "success";
    content = STRINGS.MATCHED_STATUS;
  } else {
    content = STRINGS.UNMATCHED_STATUS;
  }

  return <Tag color={color}>{content}</Tag>;
});

Status.displayName = "CardFeedStatus";

// eslint-disable-next-line react/prop-types
export const Amount = memo(({ amount }: CardTransaction) =>
  formatCurrency(amount || 0, {
    allowNegative: true,
    currencySign: true,
  })
);

Amount.displayName = "CardFeedAmount";

/**
 * @todo display activity correctly
 */
export const Activity = memo(() => null);

Activity.displayName = "CardFeedActivity";

type LinkedCostLinkProps = {
  id?: string | number;
  variant?: "short" | "full";
  docNumber?: string | null;
  humanReadableType?: string;
};

export const LinkedCostLink = memo(
  ({
    id,
    variant = "short",
    docNumber,
    humanReadableType,
  }: LinkedCostLinkProps) => {
    if (!id || !docNumber || !humanReadableType) return null;

    let content = `#${docNumber}`;

    if (variant === "full") {
      content = `${humanReadableType} ${content}`;
    }

    return (
      <Flex>
        <Text
          as={Link}
          href={generateUrlByHumanReadableType({ id, humanReadableType })}
          size="sm"
          truncate
          target="_blank"
          variant="success"
        >
          {content}
        </Text>
      </Flex>
    );
  }
);

LinkedCostLink.displayName = "CardFeedLinkedCostLink";

export const Match = memo(({ match }: CardTransaction) =>
  match ? (
    <LinkedCostLink variant="full" {...(match.parent ? match.parent : match)} />
  ) : null
);

Match.displayName = "CardFeedMatch";

export const Actions = memo((row: CardTransaction) => {
  const [updateCardTransaction, updateCardTransactionInfo] =
    useUpdateCardTransaction();

  const dialog = useMultiStepDialog<MatchingTransactionDialogSteps>({
    lazy: true,
    initialStep: row.match ? "edit" : "select",
  });

  const expenseId = parseRefinementIdFromUrl(row.expense || "")!;

  const { canEditTransaction } = usePermissions();

  const canEdit = canEditTransaction(row);

  const onUnarchiveClick = useEvent(async () => {
    try {
      await updateCardTransaction({
        isArchived: false,
        cardTransactionId: row.id,
      }).unwrap();
    } catch (e) {
      handleErrors(e);
    }
  });

  if (row.isArchived) {
    return (
      <Flex width="full" maxWidth="122px">
        <Button
          size="sm"
          color="neutral"
          block
          variant="ghost"
          onClick={onUnarchiveClick}
          disabled={updateCardTransactionInfo.isLoading}
        >
          {updateCardTransactionInfo.isLoading ? (
            <Loader />
          ) : (
            <>
              <Icon name="folder-open" />
              {STRINGS.UNARCHIVE_ACTION}
            </>
          )}
        </Button>
      </Flex>
    );
  }

  return (
    <>
      {row.match ? (
        <Tooltip message={!canEdit ? STRINGS.NO_PERMISSION_TOOLTIP : ""}>
          <Button
            size="sm"
            color="neutral"
            variant="ghost"
            onClick={dialog.show}
            disabled={!canEdit}
          >
            <Icon name="pen" /> {STRINGS.EDIT_ACTION}
          </Button>
        </Tooltip>
      ) : (
        <Flex gap="md">
          <Tooltip
            message={
              !canEdit
                ? STRINGS.NO_PERMISSION_TOOLTIP
                : STRINGS.MATCH_ACTION_TOOLTIP
            }
          >
            <Button
              disabled={!canEdit}
              size="sm"
              variant="ghost"
              color="neutral"
              onClick={dialog.show}
              data-testid="card-feed-match-transaction-button"
            >
              <Icon name="merge" />
              {STRINGS.MATCH_ACTION}
            </Button>
          </Tooltip>
          <Tooltip
            message={
              !canEdit
                ? STRINGS.NO_PERMISSION_TOOLTIP
                : STRINGS.CREATE_ACTION_TOOLTIP
            }
          >
            <Button
              size="sm"
              color="neutral"
              variant="ghost"
              {...(canEdit
                ? {
                    as: ReactRouterLink as any,
                    to: `/expenses/${expenseId}`,
                    state: { prev: location.pathname + location.search },
                  }
                : { disabled: true })}
            >
              <Icon name="plus" />
              {STRINGS.CREATE_ACTION}
            </Button>
          </Tooltip>
        </Flex>
      )}
      {dialog.isRendered && (
        <MatchingTransactionDialog dialog={dialog} cardTransaction={row} />
      )}
    </>
  );
});

Actions.displayName = "CardFeedActions";

const DATE_PROPS = { grow: true };

const INCLUDE_FILTERS: UseTableFilterOptionsProps["includeFilters"] = [];

type FilterProps = PropsWithChildren<{
  status?: StatusType;
  filters?: TableFilterControlsProps["filters"];
  onClear?: () => void;
  onChange: TableFilterControlsProps["onFiltersChange"];
  myTransactions?: boolean;
  onChangeMyTransactions?: (value: boolean) => void;
  fixedTags?: TableFilterControlsProps["fixedTags"];
}>;

export const Filter = memo(
  ({
    status,
    filters,
    onClear,
    onChange,
    children,
    myTransactions,
    onChangeMyTransactions,
    fixedTags,
  }: FilterProps) => {
    const users = useUsersSimplified({
      filters: { is_staff: false },
      includeLabel: "Card holder",
    });

    const vendors = useVendorsSimplified({ includeLabel: true });

    const baseCards = useBaseCardsSimplified({ includeLabel: true });

    const paymentAccounts = useAccountsSimplified({
      filters: {
        only_payment_accounts: true,
        can_accounts_link_to_lines_desktop: true,
      },
      includeLabel: "Payment account",
    });

    const hasFilters =
      Object.values(filters ?? {}).some((filter) => !!filter) || myTransactions;

    const extraData = useMemo(
      () => [
        ...vendors.data,
        ...baseCards.data,
        ...paymentAccounts.data,
        ...users.data,
        ...(status === "all" ? STATUS_FILTER : []),
      ],
      [status, baseCards.data, paymentAccounts.data, users.data, vendors.data]
    );

    const extraDataLoading =
      users.isLoading ||
      baseCards.isLoading ||
      paymentAccounts.isLoading ||
      vendors.status === "loading";

    return (
      <TableFilterControls
        filters={filters}
        dateProps={DATE_PROPS}
        extraData={extraData}
        fixedTags={fixedTags}
        withFilterTags
        withDateFilter
        includeFilters={INCLUDE_FILTERS}
        onFiltersChange={onChange}
        extraDataLoading={extraDataLoading}
      >
        <Flex width="full" gap="xl" align="center">
          {hasFilters && onClear && (
            <Button onClick={onClear}>{STRINGS.CLEAR_FILTERS_ACTION}</Button>
          )}
          {myTransactions !== undefined ? (
            <Flex width="full" gap="2xl" justify="flex-end">
              <Switch
                label={STRINGS.MY_TRANSACTIONS_SWITCH}
                checked={myTransactions}
                onChange={onChangeMyTransactions}
                hintMessage={STRINGS.MY_TRANSACTIONS_SWITCH_HINT}
              />
              {children}
            </Flex>
          ) : (
            children
          )}
        </Flex>
      </TableFilterControls>
    );
  }
);

Filter.displayName = "CardFeedFilter";
