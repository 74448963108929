import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
  Textarea,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { type useDialog, useForm } from "@adaptive/design-system/hooks";
import { formatCurrency } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  useCreatePaymentV2Mutation,
  useViewBillPayment,
} from "@bill-payment/hooks";
import { type BillPaymentFormPayload } from "@bill-payment/types";
import type { CommentRef } from "@components/comments/comment";
import { LIEN_WAIVER_STATUS } from "@lien-waiver/constants";
import {
  CONFIRMATION_PAYMENT_STRINGS,
  FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION,
} from "@src/bills/constants";
import { refetchCurrentBill } from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useClientSettings } from "@store/user";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { sumBy } from "@utils/sumBy";
import { useVendorAction } from "@vendors/hooks";
import { z } from "zod";

import { selectVendorPaymentData } from "./utils";

type BillPaymentConfirmationDialogProps = {
  onClose: () => void;
  paymentsInfo: BillPaymentFormPayload;
  dialog: ReturnType<typeof useDialog>;
};
const paymentFormSchema = z.object({
  payments: z
    .array(
      z.object({
        message: z.string().nullish(),
        vendorEmail: z.string().email(),
      })
    )
    .min(1),
});

export const BillPaymentConfirmationDialog = ({
  paymentsInfo,
  onClose,
  dialog,
}: BillPaymentConfirmationDialogProps) => {
  const { vendorCreditsEnabled } = useClientSettings();
  const { fetchById: fetchVendor } = useVendorAction();
  const dispatch = useAppDispatch();

  const { vendor } = useAppSelector(selectVendorPaymentData);
  const { onViewBillPayment } = useViewBillPayment();

  const [createPayment] = useCreatePaymentV2Mutation();

  const commentRef = useRef<CommentRef>(null);

  const totalAmountToPay = useMemo(
    () => sumBy(paymentsInfo.payments, "amountToPay"),
    [paymentsInfo]
  );

  //TODO: to be settled when multiple payments are implemented
  const [currentPaymentIndex] = useState(0);

  const currentPayment = useMemo(
    () => paymentsInfo.payments[currentPaymentIndex],
    [paymentsInfo, currentPaymentIndex]
  );

  const curriedOnClose = useCallback(() => {
    dialog.hide();
    onClose();
  }, [dialog, onClose]);

  const initialValues = useMemo(
    () => ({
      payments: paymentsInfo.payments.map((payment) => ({
        message: "",
        vendorEmail: payment.vendor.email,
      })),
    }),
    [paymentsInfo.payments]
  );

  const form = useForm({
    schema: paymentFormSchema,
    initialValues,
    onSubmit: async (values) => {
      try {
        const paymentOption =
          currentPayment.bankAccountOption.paymentMethods.find(
            (option) => option.paymentMethod === currentPayment.paymentMethod
          );

        const processPrerequisites =
          paymentOption?.processPrerequisites.filter(
            (prerequisite) => prerequisite.type !== "vendor_banking_ach"
          ) || [];

        const payload = [
          {
            bills: currentPayment.billUrls,
            options: {
              appliedProcessOn: currentPayment.currentDebitDate,
              appliedAmount: currentPayment.amountToPay,
              customerBankAccount:
                currentPayment.bankAccountOption?.customerBankAccount?.url,
              customerCard: currentPayment.bankAccountOption?.customerCard?.url,
              customerPaymentAccount:
                paymentOption?.customerPaymentAccount?.url,
              vendorBankAccount:
                paymentOption?.vendorBankAccount?.url || vendor?.banking?.url,
              paymentMethod: paymentOption?.paymentMethod,
              appliedSignature: currentPayment.signature,
              processPrerequisites: [
                ...processPrerequisites,
                ...(currentPayment.payWhenLienWaiverIsSigned
                  ? [{ type: "lien_waiver_signature" }]
                  : []),
                ...(!vendor.hasBankingAch
                  ? [{ type: "vendor_banking_ach" }]
                  : []),
              ],
              appliedCommentText:
                values.payments[currentPaymentIndex]?.message ?? "",
              appliedVendorEmail:
                values.payments[currentPaymentIndex]?.vendorEmail ?? "",
            },
            lienWaivers: paymentsInfo.lienWaivers.map((lienWaiver) => ({
              ...lienWaiver,
              lienWaiverTemplateId:
                lienWaiver.sendLienWaiverOnPayment &&
                lienWaiver?.lienWaiverTemplate
                  ? `${parseRefinementIdFromUrl(
                      lienWaiver?.lienWaiverTemplate
                    )}`
                  : null,
              status:
                !lienWaiver.sendLienWaiverOnPayment &&
                lienWaiver.status !== LIEN_WAIVER_STATUS.NOT_REQUIRED
                  ? LIEN_WAIVER_STATUS.NOT_SELECTED
                  : lienWaiver.status,
            })),
          },
        ];

        const createdPayments = await createPayment(payload).unwrap();

        toast.success(
          `${CONFIRMATION_PAYMENT_STRINGS.SUCCESSFUL_PAYMENT_TITLE} ${
            paymentsInfo.payments.length > 1 ? "s" : ""
          }`
        );
        dispatch(refetchCurrentBill(FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION));
        fetchVendor(currentPayment.vendor.id);
        if (createdPayments.length === 1) {
          onViewBillPayment(createdPayments[0].id);
        }
        curriedOnClose();
      } catch (error) {
        handleErrors(error);
      }
    },
  });

  const formReset = form.reset;

  useEffect(() => {
    formReset(initialValues);
  }, [initialValues, formReset]);

  return dialog.isRendered ? (
    <Dialog
      show={dialog.isVisible}
      variant="dialog"
      onClose={curriedOnClose}
      size="auto"
    >
      <DialogHeader>
        <Flex gap="md">
          <Text weight="bold">
            {CONFIRMATION_PAYMENT_STRINGS.CONFIRMATION_PAYMENT_TITLE}{" "}
          </Text>
          <Text color="brand-2">
            {formatCurrency(totalAmountToPay, {
              currencySign: true,
              allowNegative: true,
            })}
          </Text>
        </Flex>
      </DialogHeader>
      <DialogContent>
        <Flex
          gap="2xl"
          direction="row"
          minWidth="800px"
          as="form"
          {...form.props}
        >
          <Card as={Flex} width="full">
            <Flex gap="xl" width="full" direction="column" separator>
              <Flex gap="xl" width="full" direction="column">
                <Flex
                  width="full"
                  align="center"
                  gap="xl"
                  justify="space-between"
                >
                  <Text size="sm" color="neutral-500">
                    {CONFIRMATION_PAYMENT_STRINGS.BILLS_TITLE}
                  </Text>
                  <Text weight="bold">
                    {formatCurrency(currentPayment.balance, {
                      currencySign: true,
                      allowNegative: true,
                    })}
                  </Text>
                </Flex>
                {vendorCreditsEnabled && (
                  <Flex direction="column">
                    <Flex
                      width="full"
                      align="center"
                      gap="xl"
                      justify="space-between"
                    >
                      <Text size="sm" color="neutral-500">
                        {CONFIRMATION_PAYMENT_STRINGS.CREDITS_TITLE}
                      </Text>
                      {/* Update this amounts when vendor credits are implemented */}
                      <Text weight="bold">$0.00</Text>
                    </Flex>
                    <Flex
                      width="full"
                      align="center"
                      gap="xl"
                      justify="space-between"
                    >
                      {/* Update this amounts when vendor credits are implemented */}
                      <Text size="sm" color="neutral-500">
                        $0 available
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Flex
                gap="3xl"
                width="full"
                justify="space-between"
                direction="column"
              >
                <Flex
                  width="full"
                  align="center"
                  gap="xl"
                  justify="space-between"
                >
                  <Text size="sm" color="neutral-500">
                    {CONFIRMATION_PAYMENT_STRINGS.PAYMENT_TITLE}
                  </Text>
                  <Text weight="bold">
                    {formatCurrency(currentPayment.amountToPay, {
                      currencySign: true,
                      allowNegative: true,
                    })}
                  </Text>
                </Flex>
                <Flex width="full" gap="lg" direction="column">
                  <Text size="sm" color="neutral-500">
                    {CONFIRMATION_PAYMENT_STRINGS.FROM_ACCOUNT_TITLE}
                  </Text>
                  <Flex
                    width="full"
                    align="center"
                    gap="xl"
                    justify="space-between"
                  >
                    <Text size="md" weight="bold">
                      {currentPayment.bankAccountOption?.label}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Card>
          <Flex gap="xl" width="full" direction="column" separator>
            <Flex gap="xl" width="full" direction="column">
              <Flex direction="column" gap="xs">
                <TextField
                  label={
                    CONFIRMATION_PAYMENT_STRINGS.VENDOR_EMAIL_ADDRESS_TITLE
                  }
                  disabled={form.isSubmitting}
                  messageVariant="hidden"
                  {...form.register(
                    `payments.${currentPaymentIndex}.vendorEmail`
                  )}
                />
                <Flex direction="column" gap="md">
                  {currentPayment.payWhenLienWaiverIsSigned && (
                    <Text size="xs" color="neutral-500">
                      {CONFIRMATION_PAYMENT_STRINGS.SEND_LIEN_WAIVER_TITLE}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Textarea
              ref={commentRef}
              minHeight={94}
              maxHeight={200}
              disabled={form.isSubmitting}
              messageVariant="absolute"
              label={CONFIRMATION_PAYMENT_STRINGS.ADD_COMMENT}
              placeholder={CONFIRMATION_PAYMENT_STRINGS.ADD_COMMENT_PLACEHOLDER}
              {...form.register(`payments.${currentPaymentIndex}.message`)}
            />
          </Flex>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Flex grow width="full">
          <Button
            variant="text"
            color="neutral"
            block
            size="lg"
            onClick={curriedOnClose}
            disabled={form.isSubmitting}
          >
            {paymentsInfo.payments.length > 1 && currentPaymentIndex > 0
              ? CONFIRMATION_PAYMENT_STRINGS.BACK_TITLE
              : CONFIRMATION_PAYMENT_STRINGS.CANCEL_TITLE}
          </Button>
        </Flex>
        <Tooltip
          message={
            !form.isValid ? CONFIRMATION_PAYMENT_STRINGS.ADD_VENDOR_EMAIL : ""
          }
          as={Flex}
          width="full"
          grow
        >
          <Button
            variant="solid"
            size="lg"
            block
            disabled={form.isSubmitting || !form.isValid}
            type="submit"
            form={form.id}
          >
            {CONFIRMATION_PAYMENT_STRINGS.PAY_TITLE}{" "}
            {formatCurrency(totalAmountToPay, {
              currencySign: true,
              allowNegative: true,
            })}
          </Button>
        </Tooltip>
      </DialogFooter>
    </Dialog>
  ) : null;
};
