import React, { memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  DialogContent,
  DialogHeader,
  Flex,
  Loader,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { budgetLinesApi, useGetBudgetLineQuery } from "@api/budgets";
import { Comments, type OnAddCommentHandler } from "@components/comments";
import { type AnyAction } from "redux";

type BudgetLineCommentsProps = {
  id: string;
};

export const BudgetLineComments = memo(({ id }: BudgetLineCommentsProps) => {
  const dispatch = useDispatch();

  const { data, isLoading } = useGetBudgetLineQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const commentsSelector = useMemo(
    () => ({ url: data?.url ?? "", comments: data?.comments || [] }),
    [data?.url, data?.comments]
  );

  const onAddComment = useEvent<OnAddCommentHandler>(
    ({ user, text, id, url, parentCommentUrl, files }) => {
      if (!data?.customer.id) return;

      const newComment: any = {
        id: id || "",
        url,
        text,
        files,
        author: user,
        createdAt: new Date(),
        attachable: "",
        timelineEventType: "COMMENT",
      };

      const action = budgetLinesApi.util.updateQueryData(
        "getBudgetLine",
        data.id,
        (budgetLine) => {
          if (parentCommentUrl) {
            budgetLine.comments = budgetLine.comments.map((comment) =>
              comment.url === parentCommentUrl
                ? {
                    ...comment,
                    replies: [...(comment.replies || []), newComment],
                  }
                : comment
            );
          } else {
            budgetLine.comments.push({ ...newComment, replies: [] });
          }
        }
      ) as unknown as AnyAction;

      dispatch(action);

      for (const key of ["getBudgetLines", "getBudgetLineMarkups"] as const) {
        const action = budgetLinesApi.util.updateQueryData(
          key,
          { customerId: data.customer.id },
          (lines) => {
            const line = lines.find(
              (line) =>
                ("budgetLine" in line ? line.budgetLine?.id : line.id) ==
                data?.id
            );

            if (!line) return;

            const enhancedLine = "budgetLine" in line ? line.budgetLine : line;

            if (!enhancedLine || !("commentsCount" in enhancedLine)) return;

            enhancedLine.commentsCount += 1;
          }
        ) as unknown as AnyAction;

        dispatch(action);
      }
    }
  );

  return (
    <>
      <DialogHeader>
        <Flex direction="column" align="flex-start">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {data?.jobCostMethod.displayName ?? "Unknown"}
              <Text size="md">Comments</Text>
            </>
          )}
        </Flex>
      </DialogHeader>
      <DialogContent>
        {isLoading ? null : (
          <Comments
            title=""
            selector={commentsSelector}
            autoFocus
            onAddComment={onAddComment}
          />
        )}
      </DialogContent>
    </>
  );
});

BudgetLineComments.displayName = "BudgetLineComments";
