import React from "react";
import { useSearchParams } from "react-router";
import {
  Flex,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
} from "@adaptive/design-system";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@components/main";

import { CostCodesTable } from "./cost-codes-table";
import { JobsTable } from "./jobs-table";
import { PurchaseOrdersTable } from "./purchase-orders-table";
import { Settings } from "./settings";
import { VendorsTable } from "./vendors-table";

export const BuilderTrend = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") || "settings";

  const onTabChange = (tab: string) => {
    setSearchParams({ tab });
  };

  return (
    <Main>
      <MainHeader>
        <Flex align="center" gap="xl">
          <Flex direction="column" grow>
            <MainTitle>BuilderTrend Integration</MainTitle>
            <MainSubtitle>
              Pull in your data from BuilderTrend and map it to your Adaptive
              account.
            </MainSubtitle>
          </Flex>
        </Flex>
      </MainHeader>
      <MainContent>
        <Tabs value={tab} onChange={onTabChange}>
          <TabsList>
            <TabsTab value="settings">Settings</TabsTab>
            <TabsTab value="jobs">Jobs</TabsTab>
            <TabsTab value="cost-codes">Cost codes</TabsTab>
            <TabsTab value="vendors">Vendors</TabsTab>
            <TabsTab value="purchase-orders">Purchase orders</TabsTab>
          </TabsList>
          <TabsPanel>
            {tab === "settings" && <Settings />}
            {tab === "jobs" && <JobsTable />}
            {tab === "cost-codes" && <CostCodesTable />}
            {tab === "vendors" && <VendorsTable />}
            {tab === "purchase-orders" && <PurchaseOrdersTable />}
          </TabsPanel>
        </Tabs>
      </MainContent>
    </Main>
  );
};
