import React, {
  type ForwardedRef,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  type DateRange as DayPickerDateRange,
  DayPicker,
  type DayPickerProps,
  type DayProps,
  type Matcher,
  type MonthChangeEventHandler,
  type SelectRangeEventHandler,
  type SelectSingleEventHandler,
  useDayRender,
} from "react-day-picker";
import cn from "clsx";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";

import { useEvent } from "../../hooks/use-event";
import { is } from "../../utils/is";
import { Icon } from "../icon";
import { Tooltip } from "../tooltip";

import { parseModifiers } from "./utils";
import styles from "./date-picker.module.css";

export type DatePickerMode = "single" | "range";

export type DatePickerSingleValue = Date | null;

export type DatePickerRangeValue = { from?: Date; to?: Date };

type Value<Mode extends DatePickerMode> = Mode extends "single"
  ? DatePickerSingleValue
  : DatePickerRangeValue;

export type DatePickerRef = {
  setMonth: (date: Date) => void;
};

export type CustomModifier = Matcher | { message: string; matcher: Matcher };

export type DatePickerProps<Mode extends DatePickerMode> = {
  mode?: Mode;
  value?: Value<Mode>;
  toDate?: DayPickerProps["toDate"];
  fromDate?: DayPickerProps["fromDate"];
  disabled?: CustomModifier | CustomModifier[];
  onChange?: (value: Value<Mode>) => void;
  className?: string;
  highlighted?: CustomModifier | CustomModifier[];
  "data-testid"?: string;
};

const IconLeft = memo(() => <Icon name="arrow-left" />);

IconLeft.displayName = "IconLeft";

const IconRight = memo(() => <Icon name="arrow-right" />);

IconRight.displayName = "IconRight";

const Day = memo((props: DayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const dayRender = useDayRender(
    props.date,
    props.displayMonth,
    buttonRef as any
  );

  const message: string[] = [];
  let disabled = false;
  let highlighted: string | boolean = false;

  for (const key of Object.keys(dayRender.activeModifiers)) {
    if (key.startsWith("disabled")) {
      message.push(key.replace(/^disabled\.?/, ""));
      disabled = true;
    } else if (key.startsWith("highlighted")) {
      message.push(key.replace(/^highlighted\.?/, ""));
      highlighted = true;
    }
  }

  const formattedMessage = message.filter(Boolean).join("\n");

  if (dayRender.isHidden) return <div role="gridcell" />;

  if (!dayRender.isButton) {
    return (
      <Tooltip
        as="div"
        message={formattedMessage}
        {...dayRender.divProps}
        aria-disabled={!!disabled || dayRender.buttonProps.disabled}
        data-highlighted={highlighted}
      />
    );
  }

  return (
    <Tooltip message={formattedMessage}>
      <Button
        name="day"
        ref={buttonRef}
        {...dayRender.buttonProps}
        aria-disabled={!!disabled || dayRender.buttonProps.disabled}
        data-highlighted={highlighted}
      />
    </Tooltip>
  );
});

Day.displayName = "Day";

const COMPONENTS = { IconLeft, IconRight, Day };

const DatePicker = <Mode extends DatePickerMode = "single">(
  {
    mode,
    value,
    toDate,
    fromDate,
    disabled,
    onChange,
    className,
    highlighted,
    ...props
  }: DatePickerProps<Mode>,
  ref: ForwardedRef<DatePickerRef>
) => {
  const [month, setMonth] = useState<Date>();

  const [selectedDate, setSelectedDate] = useState<
    Date | DatePickerRangeValue | undefined
  >(
    value ?? (mode === "range" ? { from: undefined, to: undefined } : undefined)
  );

  const [possibleSelectedToDate, setPossibleSelectedToDate] = useState<
    Date | undefined
  >(undefined);

  const previousSelectedDateRef = useRef(selectedDate);

  const enhancedSelectedDate = useMemo(() => {
    if (value === undefined) {
      return selectedDate as Date | DatePickerRangeValue | undefined;
    }

    if (mode === "range") {
      const hasPreviousRangeSelected =
        !(previousSelectedDateRef.current as DatePickerRangeValue).from && // eslint-disable-line
        !(previousSelectedDateRef.current as DatePickerRangeValue).to; // eslint-disable-line

      const isSelecting =
        (selectedDate &&
          ((!(selectedDate as DatePickerRangeValue).from &&
            (selectedDate as DatePickerRangeValue).to) ||
            ((selectedDate as DatePickerRangeValue).from &&
              !(selectedDate as DatePickerRangeValue).to))) ||
        (!(selectedDate as DatePickerRangeValue).from &&
          !(selectedDate as DatePickerRangeValue).to &&
          hasPreviousRangeSelected);

      const nextValue = (
        isSelecting ? selectedDate : value
      ) as DatePickerRangeValue;
      previousSelectedDateRef.current = nextValue; // eslint-disable-line

      return nextValue;
    }

    return (value as Date) ?? undefined;
  }, [mode, selectedDate, value]);

  const onSingleSelect = useEvent<SelectSingleEventHandler>((value) => {
    setMonth(value);
    setSelectedDate(value);
    (onChange as DatePickerProps<"single">["onChange"])?.(value || null);
  });

  const onRangeSelect = useEvent<SelectRangeEventHandler>((value) => {
    if (enhancedSelectedDate instanceof Date) return;

    const enhancedValue = value || { from: undefined, to: undefined };
    const hasRangeSelected =
      enhancedSelectedDate?.from && enhancedSelectedDate.to;

    if (hasRangeSelected) {
      if (enhancedValue.from !== enhancedSelectedDate?.from) {
        enhancedValue.to = undefined;
      } else if (enhancedValue.to !== enhancedSelectedDate?.to) {
        enhancedValue.from = enhancedValue.to;
        enhancedValue.to = undefined;
      }
    }

    setSelectedDate(enhancedValue);

    if (enhancedValue.from && enhancedValue.to) {
      setMonth(enhancedValue.from);
      (onChange as DatePickerProps<"range">["onChange"])?.(enhancedValue);
    }
  });

  const onMonthChange = useEvent<MonthChangeEventHandler>((value) => {
    setMonth(value);
  });

  const isSelected =
    ((mode === "single" || !mode) && enhancedSelectedDate) ||
    (mode === "range" &&
      ((enhancedSelectedDate as DatePickerRangeValue).from ||
        (enhancedSelectedDate as DatePickerRangeValue).to));

  const modifiers = useMemo(() => {
    const internalDisabled: CustomModifier[] = [];

    if (fromDate) internalDisabled.push({ before: fromDate });

    if (toDate) internalDisabled.push({ after: toDate });

    return parseModifiers([
      { type: "disabled", modifiers: disabled },
      { type: "disabled", modifiers: internalDisabled },
      { type: "highlighted", modifiers: highlighted },
      ...(enhancedSelectedDate ? [enhancedSelectedDate] : []),
    ]);
  }, [disabled, fromDate, toDate, highlighted, enhancedSelectedDate]);

  let enhancedProps: DayPickerProps = {
    month,
    toDate,
    fromDate,
    modifiers,
    className: styles["rdp"],
    fixedWeeks: true,
    classNames: styles,
    components: COMPONENTS,
    onMonthChange,
    showOutsideDays: true,
    ...props,
  };

  if (mode === "range") {
    const to = (enhancedSelectedDate as DatePickerRangeValue).to;
    const from = (enhancedSelectedDate as DatePickerRangeValue).from;
    const isAfterRange = isAfter(possibleSelectedToDate || 0, from || 0);
    const isBeforeRange = isBefore(possibleSelectedToDate || 0, from || 0);

    enhancedProps = {
      ...enhancedProps,
      mode: "range",
      month: enhancedProps.month || from,
      selected: enhancedSelectedDate as DayPickerDateRange,
      onSelect: onRangeSelect,
      className: cn(enhancedProps.className, {
        [styles["-after"]]: isAfterRange,
        [styles["-before"]]: isBeforeRange,
      }),
      modifiers: {
        ...modifiers,
        ...(from && !to && possibleSelectedToDate
          ? { range: { from, to: possibleSelectedToDate } }
          : {}),
      },
      onDayFocus: setPossibleSelectedToDate,
      numberOfMonths: 2,
      onDayMouseEnter: setPossibleSelectedToDate,
      modifiersClassNames: { range: styles["-range"] },
    };
  }

  if (!mode || mode === "single") {
    enhancedProps = {
      ...enhancedProps,
      mode: "single",
      month: enhancedProps.month || (enhancedSelectedDate as Date | undefined),
      selected: enhancedSelectedDate as Date | undefined,
      onSelect: onSingleSelect,
      required: true,
      numberOfMonths: 1,
    };
  }

  useImperativeHandle(
    ref,
    () => {
      return { setMonth };
    },
    [setMonth]
  );

  useEffect(() => {
    if (value === undefined) return;

    setSelectedDate(value ?? undefined);

    if (is.date(value)) {
      setMonth(value);
    } else if (is.object(value) && value.from) {
      setMonth(value.from as Date);
    }
  }, [value]);

  return (
    <DayPicker
      {...enhancedProps}
      className={cn(className, enhancedProps.className, {
        [styles["-selected"]]: isSelected,
      })}
    />
  );
};

const ForwardedDatePicker = forwardRef(DatePicker) as <
  Mode extends DatePickerMode = "single",
>(
  props: DatePickerProps<Mode> & { ref?: ForwardedRef<DatePickerRef> }
) => ReturnType<typeof DatePicker>;

const MemoizedDatePicker = memo(
  ForwardedDatePicker
) as typeof ForwardedDatePicker;

export { MemoizedDatePicker as DatePicker };
