import { useMemo } from "react";
import { useJobInfo } from "@store/jobs";
import { BasePermissions, useUserInfo } from "@store/user";
import { useContextSelector } from "use-context-selector";

import { JobContext } from "./context";

export const useJobActions = () => ({
  invoiceCreate: useContextSelector(
    JobContext,
    (context) => context.invoiceCreate
  ),
  setBudgetSelectedLines: useContextSelector(
    JobContext,
    (context) => context.setBudgetSelectedLines
  ),
  setInvoiceSelectedDraws: useContextSelector(
    JobContext,
    (context) => context.setInvoiceSelectedDraws
  ),
  refetchJob: useContextSelector(JobContext, (context) => context.refetchJob),
  updateJobCategoriesEnabled: useContextSelector(
    JobContext,
    (context) => context.updateJobCategoriesEnabled
  ),
});

export const useJobSettings = () =>
  useContextSelector(JobContext, (context) => context.settings);

export const useJobCategories = () =>
  useContextSelector(JobContext, (context) => context.categories);

export const useJobPermissions = () =>
  useContextSelector(JobContext, (context) => context.permissions);

export const useJobInvoiceIsLoading = () =>
  useContextSelector(JobContext, (context) => context.invoiceIsLoading);

export const useJobCategoriesIsLoading = () =>
  useContextSelector(JobContext, (context) => context.categoriesIsLoading);

export const useJobBudgetSelectedLines = (unique = false) =>
  useContextSelector(JobContext, (context) => {
    if (!unique) return context.budgetSelectedLines;

    return context.budgetSelectedLines.filter(
      (line, index, self) => index === self.findIndex((t) => t.id === line.id)
    );
  });

export const useJobInvoiceSelectedDraws = () =>
  useContextSelector(JobContext, (context) => context.invoiceSelectedDraws);

export const useJobPermission = () => {
  const { job } = useJobInfo();
  const { hasPermission } = useUserInfo();

  const canManageJobs = useMemo(
    () => hasPermission(BasePermissions.MANAGE_JOBS),
    [hasPermission]
  );

  const canEditInitialBudget = useMemo(
    () => hasPermission(BasePermissions.EDIT_INITIAL_BUDGET),
    [hasPermission]
  );

  const budgetReadOnly = useMemo(
    () => job.budget_read_only ?? false,
    [job.budget_read_only]
  );

  const canViewAllJobs = useMemo(
    () => hasPermission(BasePermissions.VIEW_ALL_JOBS),
    [hasPermission]
  );

  const canSyncJobs = useMemo(
    () =>
      hasPermission(BasePermissions.MANAGE_JOBS) &&
      hasPermission(BasePermissions.SYNC_INVOICES_TO_ACCOUNTING_SOFTWARE),
    [hasPermission]
  );

  return {
    canManageJobs,
    canEditInitialBudget,
    budgetReadOnly,
    canViewAllJobs,
    canSyncJobs,
  };
};
