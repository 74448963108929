import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Flex,
  Icon,
  Link,
  Loader,
  Switch,
  Table,
  type TableColumn,
  TableConfigurationButton,
  TableProvider,
  Tag,
  TagGroup,
  Text,
  TextField,
  toast,
  Tooltip,
  Wrapper,
} from "@adaptive/design-system";
import {
  useDebouncedValue,
  useDialog,
  useEvent,
  usePagination,
} from "@adaptive/design-system/hooks";
import { formatCurrency, formatDate } from "@adaptive/design-system/utils";
import {
  type GetPurchaseOrdersPayload,
  type GetPurchaseOrdersResponse,
  useGetPurchaseOrdersQuery,
} from "@api/purchase-orders";
import { CostCodeAccountInfo } from "@components/cost-code-account-info";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";
import {
  type StrictValuesFilters,
  TableFilterControls,
  type TableFilterControlsProps,
} from "@components/table-filter";
import { setFormatter } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { isListEmpty } from "@shared/utils/usefulFunctions";
import {
  useBillFormActions,
  useBillFormPermissions,
} from "@src/bills/bill-form-context";
import {
  billIdSelector,
  billPublishedToQuickbooksSelector,
  billSubTotalSelector,
  billTaxValueSelector,
  camelCaseLinesBillSelector,
  getLineTotals,
  staticBillSubTotalSelector,
  staticBillTaxValueSelector,
  staticLinesBillSelector,
  vendorBillSelector,
} from "@src/bills/utils";
import { PurchaseOrderIcon } from "@src/purchase-orders";
import {
  HUMAN_READABLE_PO_SIGNATURE_STATUS,
  PO_SIGNATURE_STATUS_OPTIONS,
  PO_SIGNATURE_STATUSES,
  TYPE_STATUSES,
} from "@src/purchase-orders/constants";
import { promptMergeToast } from "@src/purchase-orders/utils";
import {
  addLinkedLines,
  loadBill,
  recordLineUpdate,
  removeLine,
  unlinkPOLine,
  updateTotalFromLines,
} from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  type OnClosePurchaseOrderHandler,
  usePurchaseOrderDrawer,
} from "@store/ui";
import { useClientInfo, useClientSettings } from "@store/user";
import * as analytics from "@utils/analytics";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { transformKeysToSnakeCase } from "@utils/schema/converters";
import { poSignatureTagColor } from "@utils/usefulFunctions";
import type { AnyAction } from "redux";

import {
  PurchaseOrdersMergeDialog,
  type PurchaseOrdersMergeDialogOnSubmitHandler,
} from "./purchase-orders-merge-dialog";

export type Line = ReturnType<typeof camelCaseLinesBillSelector>[number];

export type PurchaseOrder = GetPurchaseOrdersResponse["results"][number] & {
  added: boolean;
};

const FILTERS_PROPS: Exclude<
  TableFilterControlsProps["filterProps"],
  undefined
> = {
  grow: true,
  suffix: undefined,
  portal: true,
  addonAfter: undefined,
};

const INCLUDE_FILTERS: Exclude<
  TableFilterControlsProps["includeFilters"],
  undefined
> = ["customers", "accounts", "costCodes"];

const INITIAL_FILTERS = {} as StrictValuesFilters;

const INITIAL_MERGE_DATA: { lines: Line[]; purchaseOrder?: PurchaseOrder } = {
  lines: [],
  purchaseOrder: undefined,
};

const filterByUnlinkedLines = (line: Line) => {
  const isDirty =
    Number(line.amount) ||
    line.item?.url ||
    line.description ||
    line.account?.url ||
    line.customer?.url;

  return !line.deleted && !line.linkedTransaction && isDirty;
};

export const PurchaseOrders = memo(() => {
  const latestPurchaseOrderIdRef = useRef("");

  const lines = useAppSelector(camelCaseLinesBillSelector);

  const { save } = useBillFormActions();

  const billId = useAppSelector(billIdSelector);

  const publishedToQuickbooks = useAppSelector(
    billPublishedToQuickbooksSelector
  );

  const vendor = useAppSelector(vendorBillSelector);

  const dispatch = useAppDispatch();

  const settings = useClientSettings();

  const pagination = usePagination();

  const taxValue = useAppSelector(billTaxValueSelector);

  const subTotal = useAppSelector(billSubTotalSelector);

  const staticLines = useAppSelector(staticLinesBillSelector);

  const staticTaxValue = useAppSelector(staticBillTaxValueSelector);

  const staticSubTotal = useAppSelector(staticBillSubTotalSelector);

  const setPage = pagination.setPage;

  const mergeDialog = useDialog({ lazy: true });

  const [mergeData, setMergeData] = useState(INITIAL_MERGE_DATA);

  const { realmId } = useClientInfo();

  const permissions = useBillFormPermissions();

  const [offset, setOffset] = useState(0);

  const [sortBy, setSortBy] = useState("created_at");

  const [docNumber, setDocNumber] = useState("");

  const previousVendorsRef = useRef([vendor.id]);

  const [isLoading, setIsLoading] = useState(true);

  const [debouncedDocNumber] = useDebouncedValue(docNumber, 300);

  const {
    filters = [],
    setFilters,
    rawFilters,
  } = useTableFilters({
    formatter: setFormatter,
    initialFilters: INITIAL_FILTERS,
  });

  const getStaticLine = useCallback(
    (lineId: string) => staticLines.find((line) => line.id == lineId),
    [staticLines]
  );

  const enhancedFilters = useMemo(() => {
    const { account, customer, cost_code, signature, po_type } =
      filters as Record<string, Set<string>>;
    return {
      type: settings.adaptivePosEnabled && po_type ? [...po_type] : [],
      account: [...account],
      customer: [...customer],
      cost_code: [...cost_code],
      signature: signature ? [...signature] : [],
    };
  }, [filters, settings.adaptivePosEnabled]);

  const sort = useMemo(
    () => ({ value: sortBy, onChange: setSortBy }),
    [sortBy]
  );

  const linkedPurchaseOrdersIds = useMemo(
    () => [
      ...lines.reduce((acc, line) => {
        if (line.linkedTransaction?.objectId) {
          acc.add(line.linkedTransaction.objectId);
        }

        return acc;
      }, new Set<string | number>()),
    ],
    [lines]
  );

  const queryFilters = useMemo<GetPurchaseOrdersPayload>(
    () => ({
      type: settings.adaptivePosEnabled ? enhancedFilters.type : undefined,
      realm: realmId!,
      vendor: vendor.id!,
      account: enhancedFilters.account,
      poStatus: "Open",
      customer: enhancedFilters.customer,
      costCode: enhancedFilters.cost_code,
      docNumber: debouncedDocNumber,
      signature: enhancedFilters.signature,
      additionalIds: linkedPurchaseOrdersIds,
    }),
    [
      realmId,
      vendor.id,
      enhancedFilters.account,
      enhancedFilters.customer,
      enhancedFilters.cost_code,
      enhancedFilters.type,
      enhancedFilters.signature,
      settings.adaptivePosEnabled,
      debouncedDocNumber,
      linkedPurchaseOrdersIds,
    ]
  );

  const poListQuery = useGetPurchaseOrdersQuery(
    {
      ...queryFilters,
      limit: pagination.perPage,
      detail: true,
      offset: pagination.offset,
      ordering: sortBy,
    },
    {
      skip: !vendor.id || !realmId,
      selectFromResult: ({ data, isFetching }) => ({
        data: (data?.results ?? []).map((po) => ({
          ...po,
          added: lines.some(
            (line) =>
              String(line?.linkedTransaction?.objectId) == po.id &&
              !line.deleted
          ),
        })),
        count: data?.count ?? 0,
        isFetching,
      }),
    }
  );

  const poMatchQuery = useGetPurchaseOrdersQuery(
    {
      ...queryFilters,
      limit: 1,
      offset: 0,
    },
    {
      skip: !vendor.id || !realmId,
      selectFromResult: ({ data, isFetching, isUninitialized }) => ({
        count: data?.count ?? 0,
        isFetching,
        isUninitialized,
      }),
    }
  );

  const onClosePurchaseOrder = useEvent<OnClosePurchaseOrderHandler>(
    (purchaseOrder) => {
      if (!purchaseOrder) return;

      const hasChangedPurchaseOrderId =
        latestPurchaseOrderIdRef.current &&
        latestPurchaseOrderIdRef.current !== purchaseOrder.id;

      if (hasChangedPurchaseOrderId) {
        return dispatch(loadBill(billId) as unknown as AnyAction);
      }

      const propagateLines = purchaseOrder.propagate?.lines;

      if (!propagateLines) return;

      for (const line of lines) {
        const lineLinkedTransaction = line.linkedTransaction;

        if (!lineLinkedTransaction) return;

        const purchaseOrderLinkedLine = purchaseOrder.lines.find(
          (poLine) => poLine.id == String(lineLinkedTransaction.id)
        );

        if (!purchaseOrderLinkedLine) return;

        const fieldsToUpdate: Partial<typeof line> = {};

        for (const purchaseOrderLine of propagateLines) {
          for (const [field, value] of Object.entries(
            purchaseOrderLine.fields
          )) {
            const isSameLine = value.line_ids.some(
              (id) => String(id) == line.id
            );

            if (!isSameLine) continue;

            const valueToUpdate =
              purchaseOrderLinkedLine[
                field as keyof typeof purchaseOrderLinkedLine
              ];

            if (!valueToUpdate) continue;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fieldsToUpdate[field as keyof typeof line] = valueToUpdate;
          }
        }

        dispatch(
          recordLineUpdate({
            id: line.id,
            payload: {
              ...fieldsToUpdate,
              linked_transaction: {
                id: purchaseOrderLinkedLine.id,
                url: purchaseOrderLinkedLine.url,
                parent: {
                  id: purchaseOrder.id,
                  url: purchaseOrder.url,
                  type: purchaseOrder.type,
                  doc_number: purchaseOrder.docNumber,
                },
                spent: purchaseOrder.spent,
                amount: purchaseOrderLinkedLine.amount,
                balance: purchaseOrderLinkedLine.balance,
                object_id: purchaseOrder.id,
              },
            },
          })
        );
      }
    }
  );

  const { show: showPurchaseOrder } = usePurchaseOrderDrawer({
    onClose: onClosePurchaseOrder,
  });

  const curriedEditPurchaseOrder = useCallback(
    (purchaseOrder: PurchaseOrder) => () => {
      latestPurchaseOrderIdRef.current = purchaseOrder.id;

      const linkedLines = [
        ...lines.reduce((acc, line) => {
          if (
            !line.deleted &&
            line.linkedTransaction?.id &&
            String(line.linkedTransaction?.objectId) == purchaseOrder.id
          ) {
            acc.add(line.linkedTransaction.id);
          }

          return acc;
        }, new Set<string | number>()),
      ];

      showPurchaseOrder({
        id: purchaseOrder.id,
        linkedLines,
        beforeConversion: () =>
          new Promise((resolve) => save({ onSuccess: resolve })),
      });
    },
    [lines, save, showPurchaseOrder]
  );

  const onResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) => {
    setOffset(sticky.height);
  });

  const curriedOnToggle = useCallback(
    (purchaseOrder: PurchaseOrder) => (value: boolean) => {
      if (!value) {
        const linesToUnlink = purchaseOrder.lines.filter((purchaseOrderLine) =>
          lines.some(
            (line) => String(line.linkedTransaction?.id) == purchaseOrderLine.id
          )
        );

        linesToUnlink.forEach((line) => {
          analytics.track("purchaseOrderUnlinkLine", {
            purchaseOrderId: purchaseOrder.id,
            purchaseOrderLineId: line.id,
            location: "purchase-orders",
            action: "unlink",
          });
          dispatch(unlinkPOLine(line.id));
        });

        return toast.success(
          `${linesToUnlink.length} line${
            linesToUnlink.length > 1 ? "s" : ""
          } unlinked from PO#${purchaseOrder.docNumber}`
        );
      }

      const unlinkedLines = lines.filter(filterByUnlinkedLines);

      if (!unlinkedLines.length) {
        const snakedPurchaseOrder = transformKeysToSnakeCase(purchaseOrder);
        dispatch(
          addLinkedLines({
            lines: snakedPurchaseOrder.lines,
            purchaseOrder: snakedPurchaseOrder,
          })
        );
        dispatch(updateTotalFromLines());

        if (
          (settings.adaptivePosEnabled && purchaseOrder.type === "qb") ||
          purchaseOrder.type === "bt"
        ) {
          return promptMergeToast({
            count: purchaseOrder.lines.length,
            action: "link",
            docNumber: purchaseOrder.docNumber,
            type: purchaseOrder.type,
          });
        }

        return toast.success(
          `${purchaseOrder.lines.length} line${
            purchaseOrder.lines.length > 1 ? "s" : ""
          } linked to PO#${purchaseOrder.docNumber}`
        );
      }

      setMergeData({ lines: unlinkedLines, purchaseOrder: purchaseOrder });
      analytics.track("purchaseOrderMergeDialogOpen", {
        action: "merge-dialog-open",
        location: "switch",
        purchaseOrderId: purchaseOrder.id,
      });
      mergeDialog.show();
    },
    [dispatch, lines, mergeDialog, settings.adaptivePosEnabled]
  );

  const curriedOnEdit = useEvent((purchaseOrder: PurchaseOrder) => () => {
    const linkedLines = lines.filter(
      (line) =>
        !line.deleted &&
        String(line.linkedTransaction?.objectId) === purchaseOrder.id
    );
    const unlinkedLines = lines.filter(filterByUnlinkedLines);
    setMergeData({ lines: [...linkedLines, ...unlinkedLines], purchaseOrder });
    analytics.track("purchaseOrderMergeDialogOpen", {
      action: "merge-dialog-edit",
      location: "edit",
      purchaseOrderId: purchaseOrder.id,
    });
    mergeDialog.show();
  });

  const onSubmitMerge = useCallback<PurchaseOrdersMergeDialogOnSubmitHandler>(
    async ({ linkedLines, removedLines, unlinkedLines, unmergedLines }) => {
      await mergeDialog.hide();

      [...linkedLines, ...removedLines].forEach((line) =>
        dispatch(removeLine(line.id))
      );

      const linkedPurchaseOrderLines = linkedLines.map(
        (line) =>
          mergeData.purchaseOrder!.lines.find(
            (poLine) => poLine.id == String(line.linkedTransaction?.id)
          )!
      );

      unlinkedLines.forEach((line) => dispatch(unlinkPOLine(line.id)));

      dispatch(
        addLinkedLines({
          lines: [...linkedPurchaseOrderLines, ...unmergedLines].map(
            transformKeysToSnakeCase
          ),
          purchaseOrder: transformKeysToSnakeCase(mergeData.purchaseOrder!),
        })
      );

      linkedLines.forEach((line) => {
        dispatch(
          recordLineUpdate({
            id: line.linkedTransaction?.id,
            payload: {
              id: line.id,
              amount: line.amount,
              created: !!line.created,
              billable_status: line.billableStatus,
            },
          })
        );
      });

      dispatch(updateTotalFromLines());

      if (unmergedLines.length) {
        if (
          (settings.adaptivePosEnabled &&
            mergeData.purchaseOrder?.type === "qb") ||
          mergeData.purchaseOrder?.type === "bt"
        ) {
          promptMergeToast({
            count: unmergedLines.length,
            action: "link",
            docNumber: mergeData.purchaseOrder!.docNumber,
            type: mergeData.purchaseOrder!.type,
          });
        } else {
          toast.success(
            `${unmergedLines.length} line${
              unmergedLines.length > 1 ? "s" : ""
            } linked to PO#${mergeData.purchaseOrder!.docNumber}`
          );
        }
      }

      if (linkedLines.length) {
        if (
          (settings.adaptivePosEnabled &&
            mergeData.purchaseOrder?.type === "qb") ||
          mergeData.purchaseOrder?.type === "bt"
        ) {
          promptMergeToast({
            count: linkedLines.length,
            action: "merge",
            docNumber: mergeData.purchaseOrder!.docNumber,
            type: mergeData.purchaseOrder!.type,
          });
        } else {
          toast.success(
            `${linkedLines.length} line${
              linkedLines.length > 1 ? "s" : ""
            } merged to PO#${mergeData.purchaseOrder!.docNumber}`
          );
        }
      }

      if (removedLines.length) {
        toast.warning(
          `${removedLines.length} line${
            removedLines.length > 1 ? "s" : ""
          } deleted`
        );
      }

      if (unlinkedLines.length) {
        toast.success(
          `${unlinkedLines.length} line${
            unlinkedLines.length > 1 ? "s" : ""
          } unlinked from PO#${mergeData.purchaseOrder!.docNumber}`
        );
      }

      const overBudget = linkedPurchaseOrderLines.some((line) => {
        const amount =
          linkedLines.find(
            (linkedLine) => String(linkedLine.linkedTransaction?.id) == line.id
          )?.amount ?? 0;

        const staticLine = getStaticLine(line.id!);

        const { purchaseOrderIsOverBudget } = getLineTotals({
          tax: taxValue,
          line: { amount, linkedTransaction: line },
          subTotal,
          staticTax: staticTaxValue,
          staticSubTotal,
          staticLineAmount: staticLine?.amount || 0,
        });

        return purchaseOrderIsOverBudget;
      });

      if (overBudget) {
        toast.warning(
          `This bill puts PO#${mergeData.purchaseOrder!.docNumber} over budget`
        );
      }

      setMergeData(INITIAL_MERGE_DATA);
    },
    [
      mergeDialog,
      dispatch,
      mergeData.purchaseOrder,
      settings.adaptivePosEnabled,
      getStaticLine,
      taxValue,
      subTotal,
      staticTaxValue,
      staticSubTotal,
    ]
  );

  const hasQbLines =
    settings.adaptivePosEnabled &&
    lines.some(
      (line) => !line.deleted && line.linkedTransaction?.parent.type === "qb"
    );

  const linkedLength = [
    ...new Set(
      lines.map((line) =>
        !line.deleted ? line?.linkedTransaction?.objectId : false
      )
    ),
  ].filter(Boolean).length;

  const columns = useMemo(
    () =>
      [
        {
          id: "actions",
          name: "Apply to bill",
          width: 115,
          render: (row) => {
            const cannotUnlink =
              hasQbLines && publishedToQuickbooks && row.added;
            const hasZeroBalance = row.openBalance <= 0 && !row.added;
            const hasPermission =
              permissions.canEditBill && permissions.canManagePurchaseOrder;

            return (
              <Flex gap="md" align="center">
                <Tooltip
                  as={Flex}
                  shrink={false}
                  message={
                    hasZeroBalance
                      ? "You can't link a PO with zero balance"
                      : cannotUnlink
                        ? "You can't unlink this PO"
                        : !hasPermission
                          ? "You don't have permission to link/unlink this PO"
                          : ""
                  }
                >
                  <Switch
                    checked={row.added}
                    onChange={curriedOnToggle(row)}
                    disabled={hasZeroBalance || cannotUnlink || !hasPermission}
                    placement="top"
                    data-testid="link-unlink"
                  />
                </Tooltip>
                {row.added && hasPermission && (
                  <Button
                    size="sm"
                    color="neutral"
                    variant="text"
                    onClick={curriedOnEdit(row)}
                    aria-label={`Edit purchase order PO#${row.docNumber} linking`}
                  >
                    <Icon name="pen" />
                  </Button>
                )}
              </Flex>
            );
          },
          maxWidth: 115,
          visibility: "always-visible",
        },
        {
          id: "doc_number",
          name: "PO #",
          render: (row) => (
            <Flex gap="md">
              <Wrapper
                when={permissions.canViewPurchaseOrder}
                render={(children) => (
                  <Link
                    as="button"
                    type="button"
                    style={{ display: "grid" }}
                    onClick={curriedEditPurchaseOrder(row)}
                  >
                    {children}
                  </Link>
                )}
              >
                <Text as="span" size="sm" truncate>
                  PO#{row.docNumber}
                </Text>
              </Wrapper>
              {settings.adaptivePosEnabled && (
                <Flex width="2xl" shrink={false}>
                  <PurchaseOrderIcon size={24} type={row.type} />
                </Flex>
              )}
            </Flex>
          ),
          minWidth: 150,
          sortable: true,
          visibility: "always-visible",
        },
        {
          id: "total_amount",
          name: "Amount",
          render: (row) =>
            formatCurrency(row.totalAmount, {
              currencySign: true,
              allowNegative: true,
            }),
          sortable: true,
          minWidth: 150,
          textAlign: "right",
        },
        {
          id: "open_balance",
          name: "Open balance",
          render: (row) =>
            formatCurrency(row.openBalance, {
              currencySign: true,
              allowNegative: true,
            }),
          minWidth: 155,
          sortable: true,
          textAlign: "right",
        },
        {
          id: "created_at",
          name: "Date",
          render: (row) => formatDate(row.date),
          sortable: true,
          minWidth: 120,
        },
        {
          id: "job_cost_method_display_name",
          name: "Cost codes / Accounts",
          width: "fill",
          minWidth: 215,
          visibility: "hidden",
          render: (row) => (
            <CostCodeAccountInfo items={row.items} accounts={row.accounts} />
          ),
        },
        {
          id: "customer",
          name: "Jobs",
          width: "fill",
          minWidth: 215,
          visibility: "hidden",
          render: (row) => {
            const customers = row.customers.reduce(
              (acc, customer) =>
                customer?.displayName ? [...acc, customer.displayName] : acc,
              [] as string[]
            );

            return customers.length > 0 ? (
              <TagGroup data={customers} limit="auto" />
            ) : null;
          },
        },
      ] as TableColumn<PurchaseOrder>[],
    [
      hasQbLines,
      curriedOnEdit,
      curriedOnToggle,
      publishedToQuickbooks,
      permissions.canEditBill,
      curriedEditPurchaseOrder,
      settings.adaptivePosEnabled,
      permissions.canViewPurchaseOrder,
      permissions.canManagePurchaseOrder,
    ]
  );

  if (window.VENDOR_PO_SIGNATURE_REQUEST_ENABLED) {
    columns.push({
      id: "signature",
      sortable: true,
      width: 150,
      name: "Signature",
      visibility: "hidden",
      render: (row) => {
        const primaryStatus = !isListEmpty(row.poSignatures)
          ? PO_SIGNATURE_STATUSES.SIGNED
          : row.latestPendingPoSignatureRequest
            ? PO_SIGNATURE_STATUSES.REQUEST_SENT
            : undefined;

        const message =
          primaryStatus == PO_SIGNATURE_STATUSES.SIGNED
            ? // in case there are multiple signatures, we only show the first one
              `${row.poSignatures[0].signedBy}   (${formatDate(row.poSignatures[0].createdAt, "P")})`
            : PO_SIGNATURE_STATUSES.REQUEST_SENT
              ? row.latestPendingPoSignatureRequest &&
                formatDate(row.latestPendingPoSignatureRequest.createdAt, "P")
              : undefined;

        return (
          primaryStatus && (
            <Tooltip as={Flex} placement="top" message={message}>
              <Tag color={poSignatureTagColor(primaryStatus)}>
                {HUMAN_READABLE_PO_SIGNATURE_STATUS[primaryStatus]}
              </Tag>
            </Tooltip>
          )
        );
      },
    });
  }

  const onFiltersChange = useEvent((filters) => {
    pagination.setPage(0);
    setFilters(filters);
  });

  const onClearFilters = useEvent(() => {
    setDocNumber("");
    onFiltersChange({});
  });

  const fixedTags = useMemo(
    () => [
      ...(vendor.display_name
        ? [{ groupLabel: "Vendor", label: vendor.display_name }]
        : []),
    ],
    [vendor.display_name]
  );

  const extraData = useMemo(() => {
    let data: Exclude<TableFilterControlsProps["extraData"], undefined> = [];

    if (window.VENDOR_PO_SIGNATURE_REQUEST_ENABLED) {
      data = [...data, ...PO_SIGNATURE_STATUS_OPTIONS];
    }

    if (settings.adaptivePosEnabled) {
      data = [...data, ...TYPE_STATUSES];
    }

    return data;
  }, [settings.adaptivePosEnabled]);

  const tablePagination = useMemo(
    () => ({
      page: pagination.page,
      total: poListQuery.count,
      perPage: pagination.perPage,
      onChange: pagination.setPage,
    }),
    [pagination.page, poListQuery.count, pagination.perPage, pagination.setPage]
  );

  const hasFilters =
    Object.values(rawFilters).some((filter) => !!filter) ||
    !!debouncedDocNumber;

  useEffect(() => {
    setPage(0);
  }, [debouncedDocNumber, setPage]);

  /**
   * This trick is used to avoid loading indicator when we
   * are refetching the PO information, in that way we only
   * show the loading indicator when we are fetching the
   * information for the first time or when we change the
   * vendor.
   */
  useEffect(() => {
    if (!previousVendorsRef.current.includes(vendor.id)) {
      previousVendorsRef.current.push(vendor.id);
      setIsLoading(true);
    }
  }, [vendor.id]);

  useEffect(() => {
    if (
      isLoading &&
      !poMatchQuery.isFetching &&
      !poMatchQuery.isUninitialized
    ) {
      setIsLoading(false);
    }
  }, [isLoading, poMatchQuery.isFetching, poMatchQuery.isUninitialized]);

  return (
    <>
      <Flex gap="xl" direction="column">
        <Flex align="center" justify="space-between">
          <Text size="xl" weight="bold">
            Purchase orders
          </Text>
          {permissions.canAddPurchaseOrder && (
            <Button
              onClick={() =>
                showPurchaseOrder({
                  vendorId: parseRefinementIdFromUrl(vendor?.url ?? ""),
                })
              }
              size="sm"
              variant="ghost"
              data-testid="new-purchase-order-button"
            >
              <Icon name="plus" />
              Add purchase order
            </Button>
          )}
        </Flex>
        <Card>
          <CardHeader data-testid="purchase-order-card">
            {isLoading ? (
              <Flex align="center" gap="md" height="3xl">
                <Loader />
                <Text>Loading purchase orders...</Text>
              </Flex>
            ) : (
              <Flex align="center" gap="md">
                <Tag color={linkedLength > 0 ? "success" : "neutral"}>
                  {linkedLength > 0 ? linkedLength : poMatchQuery.count}
                </Tag>
                <Text>
                  PO
                  {linkedLength > 0
                    ? `${linkedLength === 1 ? "" : "s"} applied to`
                    : `${poMatchQuery.count === 1 ? "" : "s"} matching`}{" "}
                  this bill
                </Text>
              </Flex>
            )}
          </CardHeader>
          <CardContent hidden style={{ paddingBottom: "var(--spacing-none)" }}>
            <TableProvider id="bill-purchase-orders-table">
              <StickyProvider onResize={onResize}>
                <Flex maxHeight="392px" gap="xl" direction="column">
                  <Sticky
                    style={{
                      paddingBottom: "var(--spacing-xl)",
                      marginBottom: "calc(var(--spacing-xl) * -1)",
                    }}
                  >
                    <TableFilterControls
                      size="sm"
                      filters={rawFilters}
                      fixedTags={fixedTags}
                      filterProps={FILTERS_PROPS}
                      withFilterTags
                      includeFilters={INCLUDE_FILTERS}
                      extraData={extraData}
                      onFiltersChange={onFiltersChange}
                      renderBefore={() => (
                        <Flex grow minWidth="115px">
                          <TextField
                            size="sm"
                            value={docNumber}
                            onChange={setDocNumber}
                            addonAfter={<Icon size="sm" name="search" />}
                            placeholder="PO#"
                            messageVariant="hidden"
                          />
                        </Flex>
                      )}
                    >
                      <Flex width="104px">
                        {hasFilters && (
                          <Button block size="sm" onClick={onClearFilters}>
                            Clear filters
                          </Button>
                        )}
                      </Flex>
                      <Flex justify="flex-end">
                        <TableConfigurationButton size="sm" />
                      </Flex>
                    </TableFilterControls>
                  </Sticky>
                  <StickyMeasurer>
                    <Table
                      sort={sort}
                      size="sm"
                      data={poListQuery.data}
                      header={{
                        hide: poListQuery.data.length === 0,
                        sticky: { offset },
                      }}
                      columns={columns}
                      loading={isLoading || poListQuery.isFetching}
                      pagination={tablePagination}
                      style={{ marginBottom: "var(--spacing-xl)" }}
                      emptyState={{
                        title:
                          "There are no purchase orders that match the current filters",
                      }}
                    />
                  </StickyMeasurer>
                </Flex>
              </StickyProvider>
            </TableProvider>
          </CardContent>
        </Card>
      </Flex>
      {mergeDialog.isRendered && (
        <PurchaseOrdersMergeDialog
          {...mergeDialog}
          lines={mergeData.lines}
          onSubmit={onSubmitMerge}
          purchaseOrder={mergeData.purchaseOrder!}
        />
      )}
    </>
  );
});

PurchaseOrders.displayName = "PurchaseOrders";
