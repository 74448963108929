import { useGetBillPaymentV2Query } from "@bill-payment/hooks";

export type UseBillPaymentV2Props = {
  billPaymentId?: string | null;
  skip?: boolean;
};

export const useBillPaymentV2 = ({
  billPaymentId,
  skip,
}: UseBillPaymentV2Props) => {
  const { data, isLoading, refetch, isFetching } = useGetBillPaymentV2Query(
    { billPaymentId: billPaymentId || "" },
    {
      skip: skip || !billPaymentId,
    }
  );

  return { data, isLoading, refetch, isFetching };
};
