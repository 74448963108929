import { createAction, createReducer, type Selector } from "@reduxjs/toolkit";

import { type RootState } from "../types";

type Visible = {
  step?: string;
  state?: Record<string, unknown>;
  visible: boolean;
  callback?: () => void;
};

type Modals = Record<string, Visible>;
type Drawers = {
  job: Visible;
  vendor: Visible;
  workflow: Visible;
  purchaseOrder: Visible;
  notifications: Visible;
  workflowApprovals: Visible;
  billPayment: Visible;
  budgetLineComments: Visible;
};

export type UIState = {
  modals: Modals;
  drawers: Drawers;
  sidebarOpen: boolean;
  globalSearchOpen: boolean;
};

const initialState: UIState = {
  modals: {
    SalesTax: { visible: false, state: {} },
    twoFactoAuth: { visible: false, callback: undefined, state: {} },
  },
  drawers: {
    job: { step: "info", visible: false, state: {} },
    vendor: { visible: false, state: {} },
    workflow: { visible: false, state: {} },
    purchaseOrder: { visible: false, state: {} },
    notifications: { visible: false, state: {} },
    workflowApprovals: { visible: false, state: {} },
    billPayment: { visible: false, state: {} },
    budgetLineComments: { visible: false, state: {} },
  },
  sidebarOpen: true,
  globalSearchOpen: false,
};

export const withPayloadType =
  <T>() =>
  (payload: T) => ({ payload });

export const setModalVisible = createAction(
  "modal/setVisibility",
  withPayloadType<{ key: keyof UIState["modals"]; value: boolean }>()
);

export const setModalCallback = createAction(
  "modal/setCallback",
  withPayloadType<{ key: keyof UIState["modals"]; value: () => void }>()
);

export const setModalStep = createAction(
  "modal/setStep",
  withPayloadType<{ key: keyof UIState["modals"]; value: string }>()
);

export const setModalState = createAction(
  "modal/setState",
  withPayloadType<{
    key: keyof UIState["modals"];
    value: Record<string, unknown>;
  }>()
);

export const resetModalState = createAction(
  "drawer/resetModalState",
  withPayloadType<keyof UIState["modals"]>()
);

export const resetModalStep = createAction(
  "drawer/resetModalStep",
  withPayloadType<keyof UIState["modals"]>()
);

export const setDrawerVisibility = createAction(
  "drawer/setVisibility",
  withPayloadType<{ key: keyof UIState["drawers"]; value: boolean }>()
);

export const setDrawerState = createAction(
  "drawer/setState",
  withPayloadType<{
    key: keyof UIState["drawers"];
    value: Record<string, unknown>;
  }>()
);

export const resetDrawerStep = createAction(
  "drawer/resetDrawerStep",
  withPayloadType<keyof UIState["drawers"]>()
);

export const resetDrawerState = createAction(
  "drawer/resetDrawerState",
  withPayloadType<keyof UIState["drawers"]>()
);

export const setDrawerStep = createAction(
  "drawer/setStep",
  withPayloadType<{ key: keyof UIState["drawers"]; value: string }>()
);

export const setSidebarOpen = createAction(
  "sidebar/open",
  withPayloadType<boolean>()
);

export const setGlobalSearchOpen = createAction(
  "global-search/open",
  withPayloadType<boolean>()
);

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setModalVisible, ({ modals }, { payload }) => {
    modals[payload.key].visible = payload.value;
  });

  builder.addCase(setModalCallback, ({ modals }, { payload }) => {
    modals[payload.key].callback = payload.value;
  });

  builder.addCase(setModalState, ({ modals }, { payload }) => {
    modals[payload.key].state = payload.value;
  });

  builder.addCase(resetModalState, ({ modals }, { payload }) => {
    modals[payload].state = initialState.modals[payload].state;
  });

  builder.addCase(resetModalStep, ({ modals }, { payload }) => {
    modals[payload].step = initialState.modals[payload].step;
  });

  builder.addCase(setDrawerVisibility, ({ drawers }, { payload }) => {
    drawers[payload.key].visible = payload.value;
  });

  builder.addCase(resetDrawerStep, ({ drawers }, { payload }) => {
    drawers[payload].step = initialState.drawers[payload].step;
  });

  builder.addCase(resetDrawerState, ({ drawers }, { payload }) => {
    drawers[payload].state = initialState.drawers[payload].state;
  });

  builder.addCase(setDrawerStep, ({ drawers }, { payload }) => {
    drawers[payload.key].step = payload.value;
  });

  builder.addCase(setDrawerState, ({ drawers }, { payload }) => {
    drawers[payload.key].state = payload.value;
  });

  builder.addCase(setSidebarOpen, (state, { payload }) => {
    state.sidebarOpen = payload;
  });

  builder.addCase(setGlobalSearchOpen, (state, { payload }) => {
    state.globalSearchOpen = payload;
  });
});

type DrawerVisibilitySelectors = {
  [Field in keyof UIState["drawers"]]: Selector<RootState, boolean>;
};

type DrawerStepSelectors = {
  [Field in keyof UIState["drawers"]]: Selector<RootState, string>;
};

type DrawerStateSelectors = {
  [Field in keyof UIState["drawers"]]: Selector<
    RootState,
    Record<string, unknown>
  >;
};

type ModalVisibilitySelectors = {
  [Field in keyof UIState["modals"]]: Selector<RootState, boolean>;
};

type ModalCallbackSelectors = {
  [Field in keyof UIState["modals"]]: Selector<RootState, () => void>;
};

type ModalStateSelectors = {
  [Field in keyof UIState["modals"]]: Selector<
    RootState,
    Record<string, unknown>
  >;
};

export const modalVisibility = Object.keys(initialState.modals).reduce(
  (selectors, modal) => {
    return {
      ...selectors,
      [modal]: (state: RootState) => state.ui.modals[modal].visible,
    };
  },
  {}
) as ModalVisibilitySelectors;

export const modalCallback = Object.keys(initialState.modals).reduce(
  (selectors, modal) => {
    return {
      ...selectors,
      [modal]: (state: RootState) => state.ui.modals[modal].callback,
    };
  },
  {}
) as ModalCallbackSelectors;

export const modalState = Object.keys(initialState.modals).reduce(
  (selectors, modal) => {
    return {
      ...selectors,
      [modal]: (state: RootState) => state.ui.modals[modal].state,
    };
  },
  {}
) as ModalStateSelectors;

export const drawerVisibility = (
  Object.keys(initialState.drawers) as (keyof UIState["drawers"])[]
).reduce((selectors, drawer) => {
  return {
    ...selectors,
    [drawer]: (state: RootState) => state.ui.drawers[drawer].visible,
  };
}, {}) as DrawerVisibilitySelectors;

export const drawerStep = (
  Object.keys(initialState.drawers) as (keyof UIState["drawers"])[]
).reduce((selectors, drawer) => {
  return {
    ...selectors,
    [drawer]: (state: RootState) => state.ui.drawers[drawer].step,
  };
}, {}) as DrawerStepSelectors;

export const drawerState = (
  Object.keys(initialState.drawers) as (keyof UIState["drawers"])[]
).reduce((selectors, drawer) => {
  return {
    ...selectors,
    [drawer]: (state: RootState) => state.ui.drawers[drawer].state,
  };
}, {}) as DrawerStateSelectors;
