import React, { memo, useCallback } from "react";
import { TagGroup } from "@adaptive/design-system";
import { useDeepMemo } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import { useClientSettings } from "@store/user";
import { isCostCode } from "@utils/is-cost-code";
import { type KeysToCamelCase } from "@utils/schema/converters";

type Reference = {
  url?: string | null;
  description?: string | null;
  display_name?: string | null;
};

type EnhancedReference = Reference | KeysToCamelCase<Reference>;

type CostCodeAccountInfoProps = {
  items?: EnhancedReference[];
  accounts?: EnhancedReference[];
  "data-testid"?: string;
};

const getDisplayName = (item: EnhancedReference) =>
  dotObject.get(
    item,
    "displayName",
    dotObject.get(item, "display_name", "Unknown")
  );

export const CostCodeAccountInfo = memo(
  ({
    items = [],
    accounts = [],
    "data-testid": testId,
  }: CostCodeAccountInfoProps) => {
    const { itemDescriptionEnabled } = useClientSettings();

    const hasCostCode = items.length > 0;
    const hasAccounts = accounts.length > 0;

    const data = useDeepMemo(() => [...items, ...accounts], [items, accounts]);

    const render = useCallback(
      (item: (typeof data)[number]) => {
        const suffix = isCostCode(item?.url ?? "")
          ? "(Cost code)"
          : "(Account)";
        const hasDescription =
          isCostCode(item?.url ?? "") &&
          itemDescriptionEnabled &&
          item.description;

        return {
          hidden: hasDescription
            ? `${getDisplayName(item)} ${suffix}\nDescription: ${item.description}`
            : `${getDisplayName(item)} ${suffix}`,
          visible: hasDescription
            ? `${getDisplayName(item)} - ${item.description} ${suffix}`
            : `${getDisplayName(item)} ${suffix}`,
        };
      },
      [itemDescriptionEnabled]
    );

    if (!hasCostCode && !hasAccounts) return null;

    return (
      <TagGroup data={data} render={render} limit="auto" data-testid={testId} />
    );
  }
);

CostCodeAccountInfo.displayName = "CostCodeAccountInfo";
