import { useAsSimplifiedData } from "@shared/hooks/useSimplifiedData";
import { useGetLocationSimplifiedQuery } from "@store/api-simplified";
import { useClientInfo } from "@store/user";

type UseQBClassSimplifiedProps = {
  enabled?: boolean;
};

export const useLocationSimplified = ({
  enabled = true,
}: UseQBClassSimplifiedProps) => {
  const { realmId } = useClientInfo();

  const skip = !enabled || !realmId;

  const query = useGetLocationSimplifiedQuery(
    {
      realm: realmId as number,
    },
    {
      skip,
    }
  );

  return useAsSimplifiedData(query, { skip });
};
