import React, {
  type ComponentRef,
  type ElementType,
  type ForwardedRef,
  forwardRef,
  useMemo,
  useState,
} from "react";
import {
  Button,
  ComboBox,
  type ComboBoxActionAddon,
  type ComboBoxProps,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import type { Job } from "@api/jobs";
import { useCustomersSimplified } from "@shared/hooks/useCustomersSimplified";
import * as analytics from "@utils/analytics";

import {
  batchStatusUpdate,
  CustomersTable,
  type CustomersTableProps,
} from "../customers-table";

type Ref = ComponentRef<typeof ComboBox>;

type CustomersComboBoxProps<IsMultiple extends boolean> =
  ComboBoxProps<IsMultiple> & {
    as?: ElementType;
    active?: boolean | null;
    withAction?: boolean;
  };

type SingleOnChangeHandler = CustomersComboBoxProps<false>["onChange"];

type MultipleOnChangeHandler = CustomersComboBoxProps<true>["onChange"];

type CustomersDialogProps = {
  dialog: ReturnType<typeof useDialog>;
  refetch: ReturnType<typeof useCustomersSimplified>["refetch"];
  onChange?: SingleOnChangeHandler | MultipleOnChangeHandler;
  multiple?: boolean;
};

const CustomersDialog = ({
  dialog,
  refetch,
  onChange,
  multiple,
}: CustomersDialogProps) => {
  const [selected, setSelected] = useState<Job[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const select = useMemo<CustomersTableProps["select"]>(
    () => ({ value: selected, onChange: setSelected }),
    [selected]
  );

  const visibleColumns = useMemo(() => ["display_name", "parent"], []);

  const onActive = useEvent(async () => {
    setIsLoading(true);

    await batchStatusUpdate(selected, true);

    const { data = [] } = await refetch();

    if (multiple) {
      const options = data.filter((item) =>
        selected.some((selectedItem) => selectedItem.url === item.value)
      );

      if (options.length) {
        (onChange as MultipleOnChangeHandler)?.(
          options.map((option) => option.value),
          options
        );
      }
    } else if (selected.length === 1) {
      const option = data.find((item) => item.value === selected[0].url);

      if (option) (onChange as SingleOnChangeHandler)?.(option.value, option);
    }

    analytics.track("jobBatchActions", {
      action: "activate",
      jobIds: selected.map((job) => job.id),
      location: "dialog",
    });

    setSelected([]);
    setIsLoading(false);
    dialog.hide();
  });

  return (
    <>
      {isLoading && <Loader position="fixed" />}

      <Dialog
        show={dialog.isVisible}
        variant="dialog"
        size="auto"
        onClose={dialog.hide}
      >
        <DialogHeader>Inactive jobs</DialogHeader>
        <DialogContent>
          <Flex minWidth="800px" margin={["-24px", "none", "none", "none"]}>
            <CustomersTable
              id="customers-combobox-table-inactive-jobs-table"
              active={false}
              select={select}
              sticky={false}
              maxHeight="400px"
              resetOnMount
              visibleColumns={visibleColumns}
            />
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Button block variant="text" color="neutral" onClick={dialog.hide}>
            Cancel
          </Button>
          <Button block disabled={selected.length === 0} onClick={onActive}>
            Activate
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

const CustomersComboBox = <IsMultiple extends boolean = false>(
  {
    as: Component = ComboBox,
    data,
    label = "Job",
    active = true,
    loading,
    disabled,
    onChange,
    multiple,
    withAction = true,
    ...props
  }: CustomersComboBoxProps<IsMultiple>,
  ref: ForwardedRef<Ref>
) => {
  const dialog = useDialog({ lazy: true });

  const showDialog = dialog.show;

  const customers = useCustomersSimplified({
    active,
    enabled: !disabled || data === undefined,
    showDisableAsEnabled: !active,
  });

  const enhancedData = useMemo(
    () => data ?? customers.data,
    [data, customers.data]
  );

  const enhancedLoading = useMemo(
    () => loading ?? customers.status === "loading",
    [loading, customers.status]
  );

  const action = useMemo<ComboBoxActionAddon | undefined>(
    () => ({
      icon: "hammer",
      mode: withAction ? "option" : "hide",
      onClick: showDialog,
      children: "Missing job? Click here",
    }),
    [withAction, showDialog]
  );

  return (
    <>
      <Component
        ref={ref}
        data={enhancedData}
        label={label}
        action={action}
        loading={enhancedLoading}
        disabled={disabled}
        multiple={multiple}
        onChange={onChange}
        {...props}
      />

      {dialog.isRendered && (
        <CustomersDialog
          dialog={dialog}
          refetch={customers.refetch}
          multiple={!!multiple}
          onChange={onChange as SingleOnChangeHandler | MultipleOnChangeHandler}
        />
      )}
    </>
  );
};

const ForwardedCustomersComboBox = forwardRef(CustomersComboBox) as <
  IsMultiple extends boolean = false,
>(
  props: CustomersComboBoxProps<IsMultiple> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof CustomersComboBox>;

export { ForwardedCustomersComboBox as CustomersComboBox };
