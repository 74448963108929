import React, { useMemo } from "react";
import { useParams } from "react-router";
import {
  Button,
  Flex,
  Link,
  Loader,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useForm, useResponsiveProp } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  generateSignature,
  getBase64File,
  parseStringCopy,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { Comments } from "@components/comments";
import { ExternalLayout } from "@components/external-layout";
import { Layout } from "@components/layout";
import { MainContent } from "@components/main";
import { type SignatureSavedValue } from "@components/signature-field";
import { SignatureFieldButton } from "@components/signature-field-button";
import * as analytics from "@utils/analytics";

import {
  useGetExternalLienWaiverQuery,
  useSignLienWaiverMutation,
} from "../api/api";
import { type SignLienWavierRequestPayloadSchema } from "../api/types";
import { STRINGS } from "../constants";

import { BillLienWaiverFieldSigned } from "./bill-lien-waiver-field-signed";

const INITIAL_VALUES = {
  title: "",
  printedName: "",
  signature: {} as SignatureSavedValue,
};

const LienWaiverSignatureRequestForm = ({
  signatureFields,
}: {
  signatureFields: string[];
}) => {
  const { lienWaiverUid } = useParams<{ lienWaiverUid: string }>();

  const [signLienWaiverMutation] = useSignLienWaiverMutation();

  const form = useForm({
    initialValues: INITIAL_VALUES,
    onSubmit: async (values) => {
      const payload: SignLienWavierRequestPayloadSchema = {
        id: lienWaiverUid!,
        ...values,
      };

      const signatureBase64 = values.signature.typeSignature
        ? (await generateSignature(values.signature.typeSignature)).url
        : values.signature.drawSignature;

      if (signatureBase64) {
        const signatureFile = await getBase64File({
          base64: signatureBase64,
          type: "image/png",
          fileName: `lien-waiver-signature-${lienWaiverUid}`,
        });
        payload.signature = signatureFile;
      }

      try {
        analytics.track("signLienWaiver", {
          signatureType: values.signature.typeSignature ? "type" : "draw",
        });

        await signLienWaiverMutation(payload).unwrap();

        toast.success(STRINGS.LIEN_WAIVER_SIGNED_SUCCESS);
      } catch (error) {
        handleErrors(error);
      }
    },
  });

  return (
    <Flex gap="2xl" direction="column" maxWidth="420px">
      <SignatureFieldButton
        form={form.id}
        mode="type"
        onChange={(value) => form.setValue("signature", value)}
        disabled={form.isSubmitting}
      />

      {signatureFields.includes("printed_name") && (
        <TextField
          form={form.id}
          label={STRINGS.LIEN_WAIVER_FIELD_PRINTED_NAME}
          required
          messageVariant="absolute"
          disabled={form.isSubmitting}
          {...form.register("printedName")}
        />
      )}

      {signatureFields.includes("title") && (
        <TextField
          form={form.id}
          label={STRINGS.LIEN_WAIVER_FIELD_TITLE}
          required
          messageVariant="absolute"
          disabled={form.isSubmitting}
          {...form.register("title")}
        />
      )}

      {/** Workaround to be able to do nested forms we have one here
       * and another one inside the Dialog on SignatureFieldButton  */}
      <Flex as="form" align="flex-start" {...form.props}>
        <Button type="submit" form={form.id} disabled={form.isSubmitting}>
          {STRINGS.LIEN_WAIVER_SIGN_SUBMIT_BUTTON}
        </Button>
      </Flex>
    </Flex>
  );
};

export const LienWaiverSignatureRequestPage = () => {
  const isMobile = useResponsiveProp({ mobile: true, tablet: false }, false);
  const { lienWaiverUid } = useParams<{ lienWaiverUid: string }>();

  const { data: externalLienWaiver, isLoading: isExternalLienWaiverLoading } =
    useGetExternalLienWaiverQuery(
      { id: lienWaiverUid! },
      { skip: !lienWaiverUid }
    );
  const attachable = useMemo(() => {
    if (!externalLienWaiver) return undefined;

    return {
      id: lienWaiverUid!,
      pdf: externalLienWaiver.pdf ?? externalLienWaiver.fileExport.document,
    };
  }, [externalLienWaiver, lienWaiverUid]);

  const commentsSelector = useMemo(
    () => ({
      url: externalLienWaiver?.bill.url || "",
      comments: externalLienWaiver?.comment || [],
    }),
    [externalLienWaiver]
  );

  const onAddThreadComment = () =>
    toast.success(STRINGS.LIEN_WAIVER_REPLY_COMMENT_ADDED);

  if (isExternalLienWaiverLoading) {
    return <Loader position="fixed" />;
  }

  if (!lienWaiverUid || !externalLienWaiver) {
    return (
      <Layout
        contentMaxWidth="700px"
        title={STRINGS.NOT_FOUND_TITLE}
        subtitle={STRINGS.NOT_FOUND_SUBTITLE}
      />
    );
  }

  return (
    <ExternalLayout
      attachable={attachable}
      snapToggleId="show-lien-waiver-toggle"
    >
      <MainContent variant={{ mobile: "spaced-sm", tablet: "spaced-md" }}>
        <Flex
          direction="column"
          gap="2xl"
          width="full"
          separator
          shrink={false}
        >
          <Flex gap="2xl" direction="column">
            <Flex gap="md" direction="column">
              <Text size="xl" weight="bold">
                {parseStringCopy(STRINGS.LIEN_WAIVER_FROM, {
                  requester: externalLienWaiver.client,
                })}
              </Text>
              <Text>
                {STRINGS.LIEN_WAIVER_JOB}:{" "}
                {externalLienWaiver.job ?? externalLienWaiver.bill.job}
                <br />
                {STRINGS.LIEN_WAIVER_PAYMENT_AMOUNT}:{" "}
                {formatCurrency(externalLienWaiver.paymentAmount, {
                  currencySign: true,
                })}
                <br />
                {STRINGS.LIEN_WAIVER_ASSOCIATED_INVOICE}:{" "}
                <Link
                  as="a"
                  href={`/bills/${externalLienWaiver.bill.id}/conversation/?token${externalLienWaiver.bill.token}`}
                  target="_blank"
                >
                  #{externalLienWaiver.bill.docNumber}
                </Link>
              </Text>
            </Flex>

            {externalLienWaiver.signedAt ? (
              <Flex direction="column" maxWidth="420px" gap="2xl">
                <BillLienWaiverFieldSigned
                  vendor={externalLienWaiver.vendor}
                  time={externalLienWaiver.signedAt}
                />
                {externalLienWaiver.pdf && (
                  <Flex
                    align={{ mobile: "space-between", tablet: "flex-start" }}
                    direction="column"
                    gap="md"
                  >
                    {isMobile && (
                      <Button
                        as="label"
                        htmlFor="show-lien-waiver-toggle"
                        variant="ghost"
                      >
                        {STRINGS.VIEW_LIEN_WAIVER}
                      </Button>
                    )}
                    <Button
                      as="a"
                      href={externalLienWaiver.pdf}
                      target="_blank"
                      download
                    >
                      {STRINGS.DOWNLOAD_SIGNED_LIEN_WAIVER}
                    </Button>
                  </Flex>
                )}
              </Flex>
            ) : (
              <>
                {isMobile && (
                  <Button
                    as="label"
                    htmlFor="show-lien-waiver-toggle"
                    variant="ghost"
                  >
                    {STRINGS.VIEW_LIEN_WAIVER}
                  </Button>
                )}
                <LienWaiverSignatureRequestForm
                  signatureFields={externalLienWaiver.signatureFields}
                />
              </>
            )}
          </Flex>

          <Comments
            title=""
            selector={commentsSelector}
            onAddThreadComment={onAddThreadComment}
            showExternalWarning={false}
            showExternalFlag={false}
            placeholder={`${STRINGS.LIEN_WAIVER_REPLY_PLACEHOLDER} ${externalLienWaiver.client}`}
          />
        </Flex>
      </MainContent>
    </ExternalLayout>
  );
};
