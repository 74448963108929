import {
  billPaymentDetailV2Schema,
  cancelBillPaymentRequestSchema,
  createBillPaymentV2ResponseSchema,
  paymentOptionResponseSchema,
} from "@src/bill-payment/api/schemas";
import { api } from "@store/api-simplified";
import { handleResponse } from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import type {
  BillPaymentDetailV2,
  BillPaymentV2Payload,
  CancelBillPaymentPayload,
  CancelBillPaymentResponse,
  CreateBillPaymentV2Response,
  GetBillPaymentDetailPayload,
  GetPaymentOptionsPayload,
  PaymentOptionsResponse,
  VoidBillPaymentPayload,
} from "./types";

export const BILL_PAYMENT_DETAIL_TAG = "GetBillPaymentDetail";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: [BILL_PAYMENT_DETAIL_TAG],
});

export const billPaymentApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getBillPaymentOptions: builder.query<
      PaymentOptionsResponse,
      GetPaymentOptionsPayload
    >({
      query: ({ billIds }) => {
        const params = billIds.map((id) => `bill_id=${id}`).join("&");

        return {
          url: `v2/billpayments/payment_options/?${params}`,
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              data.map(transformKeysToCamelCase),
              paymentOptionResponseSchema
            );
          },
        };
      },
      providesTags: ["GetBillPaymentOptions"],
    }),
    createPaymentV2: builder.mutation<
      CreateBillPaymentV2Response,
      BillPaymentV2Payload[]
    >({
      query: (payload) => {
        const body = payload.map(transformKeysToSnakeCase);
        return {
          url: `v2/billpayments/`,
          method: "POST",
          body,
          responseHandler: async (response) => {
            const data = await response.json();

            return response.status === 200
              ? handleResponse(
                  data.map(transformKeysToCamelCase),
                  createBillPaymentV2ResponseSchema
                )
              : data;
          },
        };
      },
    }),
    getBillPaymentV2: builder.query<
      BillPaymentDetailV2,
      GetBillPaymentDetailPayload
    >({
      query: ({ billPaymentId }) => {
        return {
          url: `v2/billpayments/${billPaymentId}`,
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              transformKeysToCamelCase(data),
              billPaymentDetailV2Schema
            );
          },
        };
      },
      providesTags: (response) =>
        response && "id" in response && response?.id
          ? [{ type: BILL_PAYMENT_DETAIL_TAG, id: response.id }]
          : [{ type: BILL_PAYMENT_DETAIL_TAG, id: "DETAIL" }],
    }),
    cancelBillPaymentV2: builder.mutation<
      CancelBillPaymentResponse,
      CancelBillPaymentPayload
    >({
      query: ({ billPaymentId }) => {
        return {
          url: `v2/billpayments/${billPaymentId}/cancel/`,
          method: "POST",
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              transformKeysToCamelCase(data),
              cancelBillPaymentRequestSchema
            );
          },
        };
      },
    }),
    voidBillPaymentV2: builder.mutation<
      CancelBillPaymentResponse,
      VoidBillPaymentPayload
    >({
      query: ({ billPaymentId }) => {
        return {
          url: `v2/billpayments/${billPaymentId}/void/`,
          method: "POST",
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              transformKeysToCamelCase(data),
              cancelBillPaymentRequestSchema
            );
          },
        };
      },
    }),
  }),
});

export const {
  useGetBillPaymentOptionsQuery,
  useCreatePaymentV2Mutation,
  useGetBillPaymentV2Query,
  useCancelBillPaymentV2Mutation,
  useVoidBillPaymentV2Mutation,
} = billPaymentApi;
