import React, { memo, useMemo } from "react";
import { BillPaymentForm } from "@bill-payment/components";
import { List as Notifications } from "@notifications/components";
import { BudgetLineComments } from "@src/jobs/detail-view/budget/budget-line-comments";
import { JobForm } from "@src/jobs/job-form";
import { PurchaseOrderForm } from "@src/purchase-orders";
import { WorkflowForm } from "@src/settings/components/approval-workflows/form";
import { WorkflowApprovalsForm } from "@src/settings/components/approval-workflows/form/approvals-form";
import {
  BasePermissionCategory,
  BasePermissions,
  useClientSettings,
  useUserInfo,
} from "@store/user";
import { Form as VendorForm } from "@vendors/components";

import { type DrawerName, DrawerStackContext } from "./context";
import { Drawer } from "./drawer";
import { useStack } from "./stack";

const BILL_PAYMENT_DRAWER_PARAMS = ["billPaymentId"] as const;

const BUDGET_LINE_COMMENTS_DRAWER_PARAMS = ["commentsForBudgetLineId"] as const;

export const Dresser = memo(() => {
  const settings = useClientSettings();

  const drawerStack = useStack<DrawerName>();

  const { hasPermission, hasSomePermission, getPermissionsFromCategory } =
    useUserInfo();

  const canViewPurchaseOrders = useMemo(
    () =>
      hasSomePermission(getPermissionsFromCategory(BasePermissionCategory.POS)),
    [hasSomePermission, getPermissionsFromCategory]
  );

  const canManageVendors = useMemo(
    () =>
      hasSomePermission(
        getPermissionsFromCategory(BasePermissionCategory.VENDORS)
      ),
    [getPermissionsFromCategory, hasSomePermission]
  );

  const canManageJobs = useMemo(
    () => hasPermission(BasePermissions.MANAGE_JOBS),
    [hasPermission]
  );

  const canManageApprovalWorkflows = useMemo(
    () => hasPermission(BasePermissions.MANAGE_APPROVAL_WORKFLOWS),
    [hasPermission]
  );

  return (
    <DrawerStackContext.Provider value={drawerStack}>
      {canManageVendors && (
        <Drawer name="vendor">
          <VendorForm />
        </Drawer>
      )}

      {settings.budgetsCommentsEnabled && (
        <Drawer
          name="budgetLineComments"
          params={BUDGET_LINE_COMMENTS_DRAWER_PARAMS}
          render={(params) => (
            <BudgetLineComments id={params.commentsForBudgetLineId} />
          )}
        />
      )}

      {canManageApprovalWorkflows && (
        <>
          <Drawer size="lg" name="workflow">
            <WorkflowForm />
          </Drawer>
          <Drawer size="lg" name="workflowApprovals">
            <WorkflowApprovalsForm />
          </Drawer>
        </>
      )}

      {canManageJobs && (
        <Drawer name="job">
          <JobForm />
        </Drawer>
      )}

      {settings.paymentsRevampEnabled && (
        <Drawer
          name="billPayment"
          params={BILL_PAYMENT_DRAWER_PARAMS}
          render={(params) => <BillPaymentForm {...params} />}
        />
      )}

      {canViewPurchaseOrders && (
        <Drawer size="auto" name="purchaseOrder">
          <PurchaseOrderForm />
        </Drawer>
      )}

      {settings.notificationCenterEnabled && (
        <Drawer name="notifications" autoFocusOnHide={false}>
          <Notifications />
        </Drawer>
      )}
    </DrawerStackContext.Provider>
  );
});

Dresser.displayName = "Dresser";
