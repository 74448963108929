import React, { memo, useCallback, useMemo, useState } from "react";
import {
  Alert,
  AlertContent,
  Button,
  CurrencyField,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  LabelValue,
  Link,
  Tag,
  Text,
  toast,
  Tooltip,
  Wrapper,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  useForm,
  useResponsiveProvider,
} from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatPercentage,
  parseCurrency,
} from "@adaptive/design-system/utils";
import { Items as LineItems, type ItemsProps } from "@components/items";
import { useCostCodesAccountsSimplified } from "@hooks/use-cost-codes-accounts-simplified";
import { type Option } from "@shared/types";
import {
  getBillableData,
  isBillable as isBillableLine,
} from "@shared/utils/billable-status";
import {
  useBillFormActions,
  useBillFormPermissions,
} from "@src/bills/bill-form-context";
import { BILL_STATUS } from "@src/bills/constants";
import {
  billSelector,
  billSubTotalSelector,
  billTaxValueSelector,
  camelCaseBillSelector,
  getLineTotals,
  isInPaymentStatusBill,
  linesBillSelector,
  staticBillSubTotalSelector,
  staticBillTaxValueSelector,
  staticLinesBillSelector,
} from "@src/bills/utils";
import {
  addLine,
  collapseLines,
  recordBillUpdate,
  recordLineUpdate,
  removeLine,
  runOcrWithLines,
  unlinkPOLine,
  updateTotalFromLines,
} from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { usePurchaseOrderDrawer } from "@store/ui";
import { useClientSettings } from "@store/user";
import { useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { noop } from "@utils/noop";
import { sum } from "@utils/sum";

type Line = ReturnType<typeof linesBillSelector>[number];

type TaxDialogProps = {
  show: boolean;
  onClose: () => void;
  onSubmit: (value: number) => void;
  initialValue: number;
};

const TaxDialog = ({
  show,
  onClose,
  onSubmit,
  initialValue,
}: TaxDialogProps) => {
  const initialValues = useMemo(
    () => ({ amount: initialValue }),
    [initialValue]
  );

  const form = useForm({
    onSubmit: (values) => onSubmit(values.amount),
    initialValues,
  });

  const [isInvalid, setIsInvalid] = useState(!initialValue);

  const onInput = useEvent((e) => {
    const parsedValue = parseCurrency(e.currentTarget.value);
    setIsInvalid(!parsedValue);
  });

  return (
    <Dialog show={show} variant="dialog" onClose={onClose} size="sm">
      <DialogHeader>Add sales tax</DialogHeader>
      <DialogContent>
        <form {...form.props}>
          <CurrencyField
            label="Amount"
            required
            onInput={onInput}
            autoFocus
            allowNegative
            messageVariant="hidden"
            {...form.register({ name: "amount", type: "currency" })}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <Button block form={form.id} type="submit" disabled={isInvalid}>
          Add proportionally to lines
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

type CurriedUnlinkPoHandler = (payload: {
  lineId: string;
  purchaseOrderId: number;
  purchaseOrderLineId: number;
}) => () => void;

type ActionsProps = { mode: "collapse" | "expand" };

const Actions = ({ mode }: ActionsProps) => {
  const { current } = useResponsiveProvider();

  const dispatch = useAppDispatch();

  const label = mode === "collapse" ? "Collapse lines" : "Expand lines";

  const icon = mode === "collapse" ? "arrows-to-line" : "arrows-from-line";

  const onClick = useEvent(() => {
    dispatch(mode === "collapse" ? collapseLines() : runOcrWithLines());
  });

  if (current === "mobile") {
    return (
      <Tooltip message={label}>
        <Button size="sm" onClick={onClick} variant="ghost">
          <Icon name={icon} />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button size="sm" onClick={onClick} variant="ghost">
      <Icon name={icon} />
      {label}
    </Button>
  );
};

export const Items = memo(() => {
  const dispatch = useAppDispatch();

  const lines = useAppSelector(linesBillSelector);

  const bill = useAppSelector(billSelector);

  const vendorId = bill.vendor?.id;

  const { save } = useBillFormActions();

  const taxDialog = useDialog({ lazy: true });

  const taxValue = useAppSelector(billTaxValueSelector);

  const subTotal = useAppSelector(billSubTotalSelector);

  const settings = useClientSettings();

  const staticTaxValue = useAppSelector(staticBillTaxValueSelector);

  const staticSubTotal = useAppSelector(staticBillSubTotalSelector);

  const { show: showPurchaseOrder } = usePurchaseOrderDrawer();

  const permissions = useBillFormPermissions();

  const staticLines = useAppSelector(staticLinesBillSelector);

  const { canViewAllCodes } = useUserInfo();

  const showAll = canViewAllCodes;

  const vendorFilters = useMemo(
    () => (vendorId && { vendorId }) || {},
    [vendorId]
  );
  const costCodesAccounts = useCostCodesAccountsSimplified({
    accountFilters: {
      only_line_item_accounts: true,
      ...vendorFilters,
    },
    costCodeFilters: { ...vendorFilters },
    showAll,
  });

  const {
    id: billId,
    totalAmount,
    reviewStatus,
    isArchivedByUser,
    publishedToQuickbooks,
    initialReviewStatus,
  } = useAppSelector(camelCaseBillSelector);

  const getStaticLine = useCallback(
    (lineId: string) => staticLines.find((line) => line.id == lineId),
    [staticLines]
  );

  const isInPaymentStatus = isInPaymentStatusBill(reviewStatus);

  const isAchInfoRequested = reviewStatus === BILL_STATUS.ACH_INFO_REQUESTED;
  const isPaymentScheduled = reviewStatus === BILL_STATUS.PAYMENT_SCHEDULED;

  const componentsProps = useMemo<ItemsProps["componentsProps"]>(
    () => ({
      costCodeAccount: {
        accountFilters: { only_line_item_accounts: true, ...vendorFilters },
        costCodeFilters: { ...vendorFilters },
        showAll,
      },
    }),
    [vendorFilters, showAll]
  );

  const hasQbLines =
    settings.adaptivePosEnabled &&
    lines.some(
      (line) => !line.deleted && line.linked_transaction?.parent.type === "qb"
    );

  const hasBuilderTrendLines =
    settings.adaptivePosEnabled &&
    lines.some(
      (line) =>
        !line.deleted &&
        line.linked_transaction?.parent.type === "builder_trend"
    );

  const isActionsBlocked =
    (hasQbLines && publishedToQuickbooks) ||
    hasBuilderTrendLines ||
    isInPaymentStatus ||
    isAchInfoRequested ||
    isPaymentScheduled;

  const canRemoveLine = useCallback(
    ({ id }: { id?: number | string }) => {
      const line = lines?.find((line) => line.id == id);

      if (isInPaymentStatus) return false;

      if (!line?.linked_transaction || !settings.adaptivePosEnabled) {
        return true;
      }

      if (line.linked_transaction.parent.type === "builder_trend") {
        return false;
      }

      return (
        line.linked_transaction.parent.type === "adaptive" ||
        (line.linked_transaction.parent.type === "qb" && !publishedToQuickbooks)
      );
    },
    [
      lines,
      isInPaymentStatus,
      publishedToQuickbooks,
      settings.adaptivePosEnabled,
    ]
  );

  const disabled = useMemo(
    () =>
      !permissions.canEditBill || isArchivedByUser
        ? true
        : {
            addLine: isActionsBlocked,
            salesTax: isActionsBlocked,
            removeLine:
              isActionsBlocked ||
              ((line: { id?: string | number }) => !canRemoveLine(line)),
            batchRemove: isActionsBlocked,
            batchUpdate: hasQbLines && publishedToQuickbooks,
          },
    [
      permissions.canEditBill,
      isArchivedByUser,
      isActionsBlocked,
      hasQbLines,
      publishedToQuickbooks,
      canRemoveLine,
    ]
  );

  const curriedUnlinkPo = useCallback<CurriedUnlinkPoHandler>(
    ({ lineId, purchaseOrderId, purchaseOrderLineId }) =>
      () => {
        analytics.track("purchaseOrderUnlinkLine", {
          purchaseOrderId,
          purchaseOrderLineId,
          location: "items",
          action: "unlink",
        });
        dispatch(unlinkPOLine(lineId));
      },
    [dispatch]
  );

  const getLineEditableFields = useCallback(
    (line: Line) => {
      const isDisabled = hasQbLines && publishedToQuickbooks;
      const isEditable =
        !isDisabled &&
        (!line.linked_transaction ||
          line.linked_transaction.type !== "adaptive");

      const isAmountOrBillableEditable =
        !isDisabled &&
        !isInPaymentStatus &&
        !isAchInfoRequested &&
        !isPaymentScheduled &&
        (!line.linked_transaction ||
          !settings.adaptivePosEnabled ||
          line.linked_transaction.parent.type === "adaptive" ||
          !publishedToQuickbooks);

      return {
        amount: isAmountOrBillableEditable,
        billable: true,
        jobCustomer: isEditable,
        description: isEditable,
        costCodeAccount: isEditable,
      };
    },
    [
      hasQbLines,
      isInPaymentStatus,
      isAchInfoRequested,
      isPaymentScheduled,
      publishedToQuickbooks,
      settings.adaptivePosEnabled,
    ]
  );

  const itemsData = useMemo(
    () =>
      (lines || [])
        .filter((line) => !line.deleted)
        .map((line) => {
          const amountValue = line.amount || 0;

          let costCodeAccountValue: string | Option = "";

          if (line.account?.url) {
            costCodeAccountValue = !line.account?.display_name
              ? line.account.url
              : {
                  label: line.account.display_name,
                  value: line.account.url,
                };
          } else if (line.item?.url) {
            costCodeAccountValue = !line.item?.display_name
              ? line.item.url
              : {
                  label: line.item.display_name,
                  value: line.item.url,
                };
          }

          const editable = getLineEditableFields(line);

          /**
           * @todo replace with `reviewStatus !== BILL_STATUS.DRAFT` as soon as the backend is ready
           */
          const isCostCodeAccountRequired = !settings.skipCostValidationOnDraft;

          const jobCustomerValue =
            line.customer?.url && line.customer?.display_name
              ? { label: line.customer.display_name, value: line.customer.url }
              : line.customer?.url
                ? line.customer?.url
                : "";

          const isBillable = isBillableLine(line);

          /**
           * @todo include `reviewStatus !== BILL_STATUS.DRAFT` in the conditional as soon as the backend is ready
           */
          const isJobCustomerRequired = !!line.item?.url || isBillable;

          const hasRemainingBudget =
            !!jobCustomerValue && !!costCodeAccountValue;

          const staticLine = getStaticLine(line.id!);

          const {
            purchaseOrderAmount,
            purchaseOrderUsage,
            purchaseOrderIsOverBudget,
          } = getLineTotals({
            tax: taxValue,
            line,
            subTotal,
            staticTax: staticTaxValue,
            staticSubTotal,
            staticLineAmount: staticLine?.amount || 0,
          });

          const linkedLines = [
            ...lines.reduce((acc, otherLine) => {
              if (
                !otherLine.deleted &&
                otherLine.linked_transaction?.id &&
                otherLine.linked_transaction?.object_id ===
                  line.linked_transaction?.object_id
              ) {
                acc.add(otherLine.linked_transaction.id);
              }

              return acc;
            }, new Set<number | string>()),
          ];

          return {
            id: line.id!,
            amount: {
              value: amountValue,
              required: true,
              disabled: !editable.amount,
              errorMessage: amountValue === 0 ? "Amount cannot be empty" : "",
              helperMessage:
                permissions.canEditBill && !isArchivedByUser
                  ? isInPaymentStatus
                    ? "This field cannot be edited because this bill has already been paid"
                    : isAchInfoRequested
                      ? "This field cannot be edited because this bill has already been requested for ACH info"
                      : isPaymentScheduled
                        ? "This field cannot be edited because this bill is scheduled for payment"
                        : !editable.amount && line.linked_transaction
                          ? "Not editable when linked to a PO"
                          : ""
                  : undefined,
            },
            billable: getBillableData(line, !editable.billable),
            extra: [
              {
                label: "Remaining budget",
                variant:
                  line.remaining_budget_after_this_bill === null ||
                  line.remaining_budget_after_this_bill < 0 ||
                  line.remaining_budget_after_this_bill_blocked
                    ? "warning"
                    : "neutral",
                value:
                  !hasRemainingBudget ||
                  line.remaining_budget_after_this_bill === null ? (
                    <Text>Not on budget</Text>
                  ) : line.remaining_budget_after_this_bill_blocked ? (
                    <Flex as="span" align="center" gap="sm">
                      <Text>Save draft </Text>
                      <Tooltip
                        as={Icon}
                        size="sm"
                        name="info-circle"
                        color="neutral-800"
                        message={`Click "Save draft" below to update the Remaining budget`}
                      />
                    </Flex>
                  ) : (
                    (Number(line.remaining_budget_after_this_bill) ?? 0)
                  ),
                onClick: line.remaining_budget_after_this_bill_blocked
                  ? undefined
                  : () => {
                      window
                        .open(`/jobs/${line.customer?.id}`, "_blank")
                        ?.focus();
                    },
              },
              line.linked_invoice_line
                ? {
                    label: "Linked draw",
                    value: (
                      <Flex gap="md" align="center">
                        <Tag
                          size="md"
                          color={
                            line.linked_invoice_line.invoice_review_status ===
                            "PAID"
                              ? "success"
                              : "info"
                          }
                        >
                          {line.linked_invoice_line.invoice_review_status ===
                          "PAID"
                            ? "Paid"
                            : "Unpaid"}
                        </Tag>
                        <Tooltip message="Open linked draw">
                          <Button
                            as="a"
                            color="neutral"
                            variant="text"
                            size="sm"
                            href={`/jobs/${line.linked_invoice_line?.customer_id}/invoices/${line.linked_invoice_line?.invoice_id}?status=line-items&focus=${line.linked_invoice_line?.id}`}
                            target="_blank"
                          >
                            <Icon size="sm" name="link" />
                          </Button>
                        </Tooltip>
                      </Flex>
                    ),
                  }
                : null,
            ],
            description: {
              value: line.description,
              disabled: !editable.description,
              helperMessage:
                !editable.description && line.linked_transaction
                  ? "Not editable when linked to a PO"
                  : "",
            },
            jobCustomer: {
              value: jobCustomerValue,
              required: isJobCustomerRequired,
              disabled: !editable.jobCustomer,
              errorMessage:
                isJobCustomerRequired && !jobCustomerValue
                  ? "Job is required if a cost code is selected or the billable flag is set"
                  : "",
              helperMessage:
                !editable.jobCustomer && line.linked_transaction
                  ? "Not editable when linked to a PO"
                  : "",
            },
            costCodeAccount: {
              value: costCodeAccountValue,
              required: isCostCodeAccountRequired,
              disabled: !editable.costCodeAccount,
              errorMessage:
                isCostCodeAccountRequired && !costCodeAccountValue
                  ? "Items must be attributed"
                  : "",
              helperMessage:
                !editable.jobCustomer && line.linked_transaction
                  ? "Not editable when linked to a PO"
                  : "",
            },
            footer: line.linked_transaction ? (
              <Flex direction="column" gap="xl">
                <Flex gap="md" width="full" justify="space-between">
                  <Flex
                    gap={{ mobile: "xl", tablet: "5xl" }}
                    width="full"
                    align="flex-start"
                  >
                    <Flex
                      as={LabelValue}
                      label={{
                        size: "xs",
                        children: "Linked PO",
                      }}
                      value={{
                        weight: "regular",
                        children: (
                          <Wrapper
                            when={permissions.canViewPurchaseOrder}
                            render={(children) => (
                              <Link
                                as="button"
                                type="button"
                                variant="success"
                                onClick={() => {
                                  const purchaseOrderId =
                                    line.linked_transaction!.object_id!;

                                  analytics.track(
                                    "billOpenLinkedPurchaseOrder",
                                    {
                                      billId,
                                      purchaseOrderId,
                                    }
                                  );

                                  showPurchaseOrder({
                                    id: purchaseOrderId,
                                    linkedLines,
                                    beforeConversion: () =>
                                      new Promise((resolve) =>
                                        save({ onSuccess: resolve })
                                      ),
                                  });
                                }}
                              >
                                {children}
                              </Link>
                            )}
                          >
                            PO #{line.linked_transaction.parent.doc_number}
                          </Wrapper>
                        ),
                      }}
                    />
                    <Flex
                      as={LabelValue}
                      label={{
                        size: "xs",
                        children: "PO line amount",
                      }}
                      value={{
                        weight: "regular",
                        children: formatCurrency(purchaseOrderAmount, {
                          currencySign: true,
                          allowNegative: true,
                        }),
                      }}
                    />
                    <Flex
                      as={LabelValue}
                      label={{
                        size: "xs",
                        children: "Current PO line usage",
                      }}
                      hintMessage="Total amount from bills linked to this line / PO line amount"
                      value={{
                        weight: "regular",
                        children: (
                          <Tag
                            size="sm"
                            color={
                              purchaseOrderIsOverBudget ? "warning" : "neutral"
                            }
                          >
                            {formatPercentage(purchaseOrderUsage, {
                              percentSign: true,
                            })}
                          </Tag>
                        ),
                      }}
                    />
                  </Flex>
                  {editable.amount &&
                    editable.billable &&
                    permissions.canManagePurchaseOrder && (
                      <Flex justify="flex-end">
                        <Flex>
                          <Tooltip message="Unlink PO">
                            <Button
                              block
                              size="sm"
                              color="neutral"
                              variant="ghost"
                              onClick={curriedUnlinkPo({
                                lineId: line.id!,
                                purchaseOrderId:
                                  line.linked_transaction.object_id!,
                                purchaseOrderLineId: line.linked_transaction.id,
                              })}
                              aria-label="Unlink PO"
                              data-testid="unlink-purchase-order-button"
                            >
                              <Icon name="link-slash" />
                            </Button>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    )}
                </Flex>
                {purchaseOrderIsOverBudget && (
                  <Alert variant="warning">
                    <AlertContent>
                      <Text as="span">
                        This PO line has exceeded its allocated amount.{" "}
                        {permissions.canViewPurchaseOrder &&
                          "Click below to review its linked bills and optionally log a change."}
                      </Text>
                      {permissions.canViewPurchaseOrder && (
                        <Flex as="span">
                          <Link
                            onClick={() => {
                              const purchaseOrderId =
                                line.linked_transaction!.object_id!;

                              analytics.track(
                                "billOpenLinkedPurchaseOrderOverBudget",
                                {
                                  billId,
                                  purchaseOrderId,
                                }
                              );

                              showPurchaseOrder({
                                id: purchaseOrderId,
                                linkedLines,
                                beforeConversion: () =>
                                  new Promise((resolve) =>
                                    save({ onSuccess: resolve })
                                  ),
                              });
                            }}
                          >
                            <Text as="span" weight="bold">
                              View purchase order
                            </Text>
                          </Link>
                        </Flex>
                      )}
                    </AlertContent>
                  </Alert>
                )}
              </Flex>
            ) : null,
          } as ItemsProps["data"][number];
        }),
    [
      billId,
      curriedUnlinkPo,
      getLineEditableFields,
      getStaticLine,
      isAchInfoRequested,
      isArchivedByUser,
      isInPaymentStatus,
      isPaymentScheduled,
      lines,
      permissions.canEditBill,
      permissions.canManagePurchaseOrder,
      permissions.canViewPurchaseOrder,
      save,
      settings.skipCostValidationOnDraft,
      showPurchaseOrder,
      staticSubTotal,
      staticTaxValue,
      subTotal,
      taxValue,
    ]
  );

  const onSubmitTaxDialog = useEvent((value: number) => {
    dispatch(
      recordBillUpdate({
        tax: { value, isSet: true },
        total_amount: sum(totalAmount, value),
      })
    );

    taxDialog.hide();
  });

  const onItemsAdd = useEvent(() => {
    dispatch(addLine({ type: "" }));
    analytics.track("billLineAdded", { billId });
  });

  const onRemoveSalesTax = useEvent(() => {
    dispatch(
      recordBillUpdate({
        tax: { isSet: false, value: "" },
        total_amount: sum(totalAmount, -salesTax.amount),
      })
    );
  });

  const salesTax = useMemo(
    () => ({
      onAdd: taxDialog.show,
      amount: taxValue,
      subTotal,
      disabled: isInPaymentStatus || isAchInfoRequested || isPaymentScheduled,
      onRemove: onRemoveSalesTax,
    }),
    [
      taxValue,
      subTotal,
      taxDialog.show,
      onRemoveSalesTax,
      isInPaymentStatus,
      isAchInfoRequested,
      isPaymentScheduled,
    ]
  );

  const setAmount = useEvent((id: string, value: number) => {
    dispatch(recordLineUpdate({ id, payload: { amount: value } }));

    dispatch(updateTotalFromLines());

    const line = lines.find((line) => line.id == id);

    if (!line || line.amount == value || !line.linked_transaction) return;

    const staticLine = getStaticLine(id);

    const { purchaseOrderIsOverBudget } = getLineTotals({
      tax: taxValue,
      line: { ...line, amount: value },
      subTotal,
      staticTax: staticTaxValue,
      staticSubTotal,
      staticLineAmount: staticLine?.amount || 0,
    });

    if (purchaseOrderIsOverBudget) {
      toast.warning(
        `This bill puts PO#${line.linked_transaction?.parent?.doc_number} over budget`
      );
    }
  });

  const setBillable = useCallback(
    (id: string, value: boolean) => {
      const staticLine = getStaticLine(id);
      dispatch(
        recordLineUpdate({
          id,
          payload: {
            billable_status: !value
              ? "NotBillable"
              : staticLine?.billable_status &&
                  staticLine?.billable_status !== "NotBillable"
                ? staticLine?.billable_status
                : "Billable",
          },
        })
      );
    },
    [dispatch, getStaticLine]
  );

  const setJob = useCallback(
    (id: string, value?: Option) => {
      dispatch(
        recordLineUpdate({
          id,
          payload: {
            customer: value
              ? { url: value.value, display_name: value.label }
              : null,
          },
        })
      );
    },
    [dispatch]
  );

  const setDescription = useCallback(
    (id: string, value: string) => {
      dispatch(recordLineUpdate({ id, payload: { description: value } }));
    },
    [dispatch]
  );

  const setCostAttribution = useCallback(
    (id: string, value?: Option) => {
      dispatch(
        recordLineUpdate({
          id,
          payload: costCodesAccounts.transform(value ?? ""),
        })
      );
    },
    [costCodesAccounts, dispatch]
  );

  const mutateStrategy = {
    amount: setAmount,
    billable: setBillable,
    closable: noop,
    jobCustomer: setJob,
    description: setDescription,
    costCodeAccount: setCostAttribution,
  };

  const onItemsChange = useEvent<ItemsProps["onChange"]>(
    ({ id, name, value }) => {
      const line = lines.find((line) => line.id == id);

      if (!line) return false;

      const editable = getLineEditableFields(line);

      if (
        (name === "amount" && !editable.amount) ||
        (name === "billable" && !editable.billable) ||
        (name === "jobCustomer" && !editable.jobCustomer) ||
        (name === "description" && !editable.description) ||
        (name === "costCodeAccount" && !editable.costCodeAccount)
      ) {
        return false;
      }

      mutateStrategy[name](id, value);
    }
  );

  const onItemsRemove = useEvent((id) => {
    const line = lines.find((line) => line.id == id);

    if (!line || !canRemoveLine(line)) return false;

    dispatch(removeLine(line.id));

    analytics.track("billLineRemoved", { billId, lineId: id });

    dispatch(updateTotalFromLines());
  });

  const isNewItem = useCallback<ItemsProps["isNewItem"]>(
    (item) => typeof item.id !== "number",
    []
  );

  return (
    <>
      <LineItems
        id="bill-items"
        data={itemsData}
        onAdd={onItemsAdd}
        total={totalAmount}
        disabled={disabled}
        salesTax={salesTax}
        onChange={onItemsChange}
        onRemove={onItemsRemove}
        isNewItem={isNewItem}
        data-testid="items"
        componentsProps={componentsProps}
        actions={
          initialReviewStatus === BILL_STATUS.DRAFT ? (
            <Actions mode={itemsData?.length > 1 ? "collapse" : "expand"} />
          ) : null
        }
      />

      {taxDialog.isRendered && (
        <TaxDialog
          show={taxDialog.isVisible}
          onClose={taxDialog.hide}
          onSubmit={onSubmitTaxDialog}
          initialValue={salesTax.amount}
        />
      )}
    </>
  );
});

Items.displayName = "Items";
